import React from "react"
import Setting from "pages/setting/components/Setting"
import nex from "assets/images/nex.png"

const Nex = () => {
  return (
    <Setting title={"Who's NEX"}>
      <div className="text-sm max-w-2xl space-y-6 py-4 px-10 my-2 lg:my-10">
        <div>
          NEX is an artificial intelligence (A.I.) system that learns from
          thousands of applicants to discover the best match for a job. NEX is
          trained to think, learn, and act like a high-quality recruiter through
          a set of algorithms that monitors your choices and suggests optimum
          outcomes.
        </div>
        <div>
          NEX is built with machine learning capabilities that makes her
          self-improving. In <b>adnexio</b>, NEX produces and learns from
          prediction models of what kind of candidates best fits what type of
          jobs. Looking through tons of data points like personal details,
          education, work experience, personality type, confidence level,
          leadership qualities, and others, NEX could create a function of these
          parameters and then shifts the coefficients to each of these
          parameters as it piles through more data.
        </div>
        <div>
          NEX also learns from employers' decision to offer a job to each
          candidate to spot correlations between specific traits with hiring
          success. Similar to the machine learning process on jobseekers, NEX
          then builds algorithms to represent the employers' thinking and train
          these algorithms on the candidates in its database.
        </div>
        <div>
          As NEX trains on more and more data, the prediction model will execute
          iteratively to produce hiring recommendations with higher accuracy.
          The only difference is that, unlike a human, NEX does not need to rest
          or sleep.
        </div>
        <div className="font-bold text-2xl text-adx-darkGreen">
          BENEFIT TO JOB-SEEKERS
        </div>
        <div>
          For every person looking for a job, NEX will help you find the job
          that best suits your credentials, interest, and skills. NEX
          understands that if you have a job that you love with the right sets
          of skills to excel in, it is more likely that you will find
          satisfaction in what you do. It is in NEX’s interest to make sure you
          enjoy your work so that society benefits from your true potential.
        </div>
        <div>
          As the days go on, NEX will suggest ways for you to become more
          employable. She will look at your strengths and weaknesses, and make
          sure you turn your weaknesses into your strengths. NEX will remind you
          of what to do and helps you get the best training offers to improve
          your skills at the lowest prices.
        </div>
        <div className="font-bold text-4xl text-center text-adx-darkGreen">
          NEX will be your career coach that guides you every step of the way.
        </div>
        <img src={nex} alt="nex" />
        <div className="font-bold text-2xl text-adx-darkGreen">
          BENEFIT TO EMPLOYERS
        </div>
        <div>
          The most significant benefit NEX could offer employers on{" "}
          <b>adnexio</b> is to do away with unsuitable job applications. We
          understand that most businesses, especially smaller ones, struggle to
          find the right talent. They spend a lot of time and money to get the
          word out, but only to find absent interviewees or half-hearted
          employees. The frustratingly difficult process to source for the right
          candidates to build an enterprise's human capital is increasingly
          becoming a big stumbling block to its value growth.
        </div>
        <div>
          NEX uses her A.I. capabilities to close the gap of the job match. The
          direction that NEX marches to is to create a society where the right
          candidates perform the right jobs. Learning through thousands of
          applicant’s backgrounds will help NEX understand the optimal
          combination of education level, skill sets and personality traits for
          each job vacancy.
        </div>
        <div>
          When an employer posts a job vacancy on adnexio, NEX will filter and
          shortlist the list of top candidates who are most likely to be a good
          fit for the vacancy. Most importantly, NEX will provide the employer
          with interview videos ranked against the population, so that the
          employer can compare the paper qualification with a five-minute
          assessment of the candidate in real life.
        </div>
        <div>
          This feature solves the problem of a quality mismatch when an employer
          calls a candidate for an interview, only to find the candidate had
          misrepresented himself/herself in the application. Therefore, when an
          employer decides to call a candidate shortlisted by NEX for an
          interview, there is already a high likelihood of acceptance into
          employment because NEX had done all the pre-screening processes.
        </div>
        <div>
          Employers no longer have to depend on luck to find the right talents
          for the job, nor are they as susceptible to human fallibility as they
          do in the past.
        </div>
        <div>
          NEX is the equaliser that makes sure all businesses (big, medium, or
          small) fully benefit from the A.I and data revolution to lower down
          the cost of hiring and access to the best people for the right job.
        </div>
      </div>
    </Setting>
  )
}

export default Nex
