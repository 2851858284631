import React, { useState, useEffect, useContext } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Loader from 'react-loader-spinner'

import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import CustomHelmet from 'components/smart/CustomHelmet'
import { authContext } from 'contexts/AuthContext'
import { externalJobAdvertisements } from 'services/api/external_job_advertisement'
import { exterlnalApplied } from 'services/api/job_application'

import Alert from '@mui/material/Alert'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import Snackbar from '@mui/material/Snackbar'
import SearchIcon from '@mui/icons-material/Search'
import ShareIcon from '@mui/icons-material/Share'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'

import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'

const useStyles = makeStyles({
  btn1: {
    color: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btn2: {
    textTransform: 'capitalize',
    height: 40,
    width: '100%',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btnContained: {
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btnShareDesktop: {
    color: '#E2E2E2',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    '&:hover': {
      backgroundColor: '#1B8379',
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
})

const ExternalJobAdsDetails = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const param = useParams()
  const { auth } = useContext(authContext)
  let isLoggedIn = auth.isAuth
  const [jobAdvertisement, setJobAdvertisement] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  const [openSnack, setOpenSnack] = useState(false)
  const [snackProps, setSnackProps] = useState({
    severity: 'success',
    message: 'Your application has been submitted.',
  })

  const [preview, setPreview] = useState(false)

  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes('preview')) {
      setPreview(true)
    }
  }, [location.pathname])

  useEffect(() => {
    getExternalJobAdvertisements(param.id)
  }, [param.id, jobAdvertisement.applied])

  const getExternalJobAdvertisements = async (id) => {
    try {
      const response = await externalJobAdvertisements('get', id)
      setJobAdvertisement(response.data)
    } catch (e) {
      if (e.status === 404) {
        window.location.href = '/job-not-found'
      }
      console.log(e)
    }
  }

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  const processExternalApplied = async (data, id) => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        await exterlnalApplied(data)
        setSnackProps({
          severity: 'success',
          message: 'Your application has been submitted.',
        })
        setOpenSnack(true)
        await getExternalJobAdvertisements(id)
      } catch (e) {
        if (e.status === 422) {
          setSnackProps({
            severity: 'error',
            message: 'Your have already applied for the job.',
          })
          setOpenSnack(true)
        } else {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        }
      }
      setLoading(false)
    } else {
      navigate('/signin', { state: { from: 'jobs/ext', id: id } })
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }

  return (
    <div className="h-screen">
      {jobAdvertisement.position_title !== undefined &&
        jobAdvertisement.company_name !== undefined && (
          <CustomHelmet
            title={`${jobAdvertisement.position_title} | ${jobAdvertisement.company_name} | adnexio.jobs`}
            description={jobAdvertisement.job_description}
            url={`https://adnexio.jobs/jobs/ext/${param.id}`}
            image={jobAdvertisement.company_logo}
          />
        )}

      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}

      <Snackbar
        open={openSnack}
        autoHideDuration={3500}
        onClose={closeSnack}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={closeSnack} severity={snackProps.severity} variant="filled">
          {snackProps.message}
        </Alert>
      </Snackbar>

      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="lg">
        <div className="flex flex-col justify-between p-8 item-center h-80 font-poppins lg:w-96">
          <p className="text-lg font-bold text-center">Share this job in Social Media</p>
          <div className="mb-4">
            <p className="font-bold text-center truncate text-adx-darkGreen">
              {jobAdvertisement.position_title}
            </p>
            <p className="font-bold text-center truncate">{jobAdvertisement.company_name}</p>
          </div>
          <div className="flex justify-between">
            <FacebookShareButton url={jobAdvertisement.share_url_web_external}>
              <FacebookIcon borderRadius="50%" size={48} />
            </FacebookShareButton>

            <LinkedinShareButton url={jobAdvertisement.share_url_web_external}>
              <LinkedinIcon borderRadius="50%" size={48} />
            </LinkedinShareButton>

            <TwitterShareButton url={jobAdvertisement.share_url_web_external}>
              <TwitterIcon borderRadius="50%" size={48} />
            </TwitterShareButton>

            <WhatsappShareButton url={jobAdvertisement.share_url_web_external}>
              <WhatsappIcon borderRadius="50%" size={48} />
            </WhatsappShareButton>
          </div>

          <CopyToClipboard
            text={jobAdvertisement.share_url_web_external}
            onCopy={() => setCopied(true)}
          >
            <Button className={classes.btnShareDesktop} color="primary">
              Copy to clipboard{' '}
            </Button>
          </CopyToClipboard>
          {copied ? <p className="text-xs text-center text-red-500">Copied</p> : null}
        </div>
      </Dialog>

      <div>
        {/* <Header page="disableBottom" /> */}
        <NewHeader/>
      </div>

      <div className="">
        <div
          className="fixed top-0 flex-col items-center justify-center hidden w-5/12 h-full bg-adx-gray lg:flex"
          style={{}}
        >
          <div className="w-2/3">
            <div className="pl-3 mt-2 text-lg font-semibold lg:text-2xl">
              Find the right job with NEX
            </div>
            <form
              className="p-1 mt-2 rounded-4xl bg-adx-white lg:mt-5 lg:mb-24 lg:w-full lg:py-2 "
              method="GET"
              action="/jobs"
              autoComplete="off"
            >
              <SearchIcon className={`mx-2 ${classes.searchIcon}`} />
              <InputBase
                className={`${classes.searchBar} w-80percent`}
                placeholder="Search"
                name="search"
              />
            </form>
            <div className="text-center">
              <p className="mb-2 text-black">For better experience</p>
              <div className="flex justify-center gap-x-2">
                <img
                  className="rounded-lg cursor-pointer h-11 w-36"
                  src={apple_store_badges}
                  alt="..."
                  onClick={() => appDownload('apple')}
                />
                <img
                  className="rounded-lg cursor-pointer h-11 w-36"
                  src={google_play_badges}
                  alt="..."
                  onClick={() => appDownload('android')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="inline-block w-full ">
          <div className="inline-block lg:w-5/12"></div>
          <div className="inline-block w-full px-4 lg:w-7/12">
            <div className="justify-end hidden my-4 mr-2 lg:flex">
              {!preview && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnContained}
                  onClick={() =>
                    processExternalApplied(
                      { external_job_advertisement_id: jobAdvertisement.id },
                      param.id
                    )
                  }
                  disabled={jobAdvertisement.applied}
                >
                  {!jobAdvertisement.applied ? 'Apply Now' : 'Applied'}
                </Button>
              )}
            </div>

            {!preview && (
              <div className="grid h-12 grid-cols-12 my-4 gap-x-4 lg:hidden">
                <>
                  <div className="flex items-center h-full col-span-5">
                    <Button
                      onClick={() => handleClickOpen()}
                      className={classes.btn1}
                      variant="contained"
                      startIcon={<ShareIcon />}
                    >
                      Share
                    </Button>
                  </div>
                  <div className="flex items-center h-full col-span-7">
                    <Button
                      onClick={() =>
                        processExternalApplied(
                          {
                            external_job_advertisement_id: jobAdvertisement.id,
                          },
                          param.id
                        )
                      }
                      color="primary"
                      variant="contained"
                      className={classes.btn2}
                      disabled={jobAdvertisement.applied}
                    >
                      {!jobAdvertisement.applied ? 'Apply Now' : 'Applied'}
                    </Button>
                  </div>
                </>
              </div>
            )}

            {jobAdvertisement.length !== 0 && (
              <div className="w-full min-h-screen my-4 overflow-auto border rounded-xl bg-adx-darkGray">
                <div className="box-border relative grid w-full grid-cols-3 py-4 mx-auto shadow border-xl rounded-t-md bg-adx-white">
                  <div className="grid place-items-center justify-self-center">
                    <img
                      src={jobAdvertisement.company_logo}
                      alt="logo"
                      className="object-cover w-20 h-20 rounded-full shadow"
                    />
                  </div>
                  <div className="flex flex-col justify-center col-span-2">
                    <p className="w-5/6 text-sm font-semibold capitalize text-adx-darkGreen lg:text-lg">
                      {jobAdvertisement.position_title}
                    </p>
                    <p className="w-5/6 text-xs font-semibold capitalize lg:text-sm">
                      {jobAdvertisement.company_name}
                    </p>
                  </div>
                  <div className="absolute hidden bottom-2 right-2 lg:block">
                    <IconButton
                      onClick={() => handleClickOpen()}
                      className={classes.ShareIconButton}
                      size="large"
                    >
                      <ShareIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="my-2 mt-5 space-y-1 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Job Details</p>
                  <p className="w-5/6 text-xs lg:text-sm">
                    RM{jobAdvertisement.min_salary} - RM
                    {jobAdvertisement.max_salary}
                  </p>
                  <p className="w-5/6 text-xs lg:text-sm">{jobAdvertisement.location}</p>
                </div>
                <hr className="mx-4 my-5 border-1 border-adx-darkerGray" />

                <div className="my-2 space-y-2 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Job Description</p>
                  <p
                    className="text-xs text-justify whitespace-pre-line lg:text-sm"
                    style={{ lineHeight: '1.55rem' }}
                  >
                    {jobAdvertisement.job_description}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExternalJobAdsDetails
