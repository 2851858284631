import { Button } from '@mui/material'
import icon from '../assets/courseFullIcon.svg'

const HRDCourseFull = ({ handleClose }) => {
  return (
    <>
      <div className="max-w-[90%] md:max-w-[80%] mx-auto">
        <div className="flex items-center rounded-full bg justify-center">
          <img alt='' src={icon} />
        </div>
        <h3 className="font-medium text-[24px] whitespace-pre-line leading-[calc(24px * 1.5)] mt-[40px] mb-[16px] text-center">We’re sorry,<br />your application is declined due to full quota.</h3>
        <p className="text-adx-darkerGray text-center mb-[16px]">Sadly to inform you that this Bootcamp has now fully booked. Your HRD application for this intake is automatically declined.</p>
        <p className="text-adx-darkerGray text-center">No worries, we have more Bootcamp coming soon for you to participate! Stay updated with us.</p>
      </div>
      <Button onClick={handleClose} variant='outlined' fullWidth sx={{ minHeight: '45px', mt: '30px', mb: '10px' }} color='primary'>Close</Button>
    </>
  )
}

export default HRDCourseFull