const { createTheme } = require('@mui/material')

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#1B8379', //darkgreen
    },
    secondary: {
      main: '#FCD94E', //yellow
    },
    error: {
      main: '#EB5757', //red
    },
    'gray-background': {
      main: '#EDEFF0' //gray
    },
    'dark-text': {
      main: '#3A3A3A',
    },
    'light-gray-text': {
      main: '#8A8A8A',
    },
    'light-gray-text-2': {
      main: '#C6CAC9',
    },
    'gray-line': {
      main: '#E5E5E5',
    },
    'default-background': {
      main: '#F7F9FA',
    },
    success: {
      main: '#2FED64',
    },
    'light-success': {
      main: '#E0FCE8',
    },
    'light-error': {
      main: '#FDEEEE',
    },
    warning: {
      main: '#E2B93B',
    },
    'light-warning': {
      main: '#FBF5E2',
    },
    information: {
      main: '#27ABF5',
    },
    'light-information': {
      main: '#EAF2FD',
    }
    // test
  },
  typography: {
    fontFamily: 'Poppins',
    button: {
      textTransform: 'capitalize',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536,
    },
  },
})
