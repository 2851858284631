import { useContext } from 'react'
import { Link } from 'react-router-dom'
import MobileView from './MobileView'
import DesktopView from './DesktopView'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { RecentJobContext } from 'contexts/RecentJobContext'

const mui = {
  jobAdsBtn: {
    fontSize: '15px',
    fontWeight: '600',
    textTransform: 'none',
    fontFamily: 'Poppins',
    color: '#8E8E8E',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  loader: {
    color: '#1B8379',
  },
}

const RecentJobCarousel = () => {
  const { jobList, loading } = useContext(RecentJobContext)

  return (
    <div className="py-6 pb-2 font-poppins">
      <div className="space-y-4">
        <div className="mx-auto flex max-w-5xl items-center justify-between px-6">
          <div className="text-xl font-semibold">Recent Jobs</div>
          <Button sx={mui.jobAdsBtn} endIcon={<KeyboardArrowRightIcon />}>
            <Link to="/jobs">Job Ads</Link>
          </Button>
        </div>

        {loading ? (
          <div className="mx-auto w-max p-6">
            <CircularProgress sx={mui.loader} />
          </div>
        ) : (
          <>
            <MobileView jobList={jobList} />

            <DesktopView jobList={jobList} />
          </>
        )}
      </div>
    </div>
  )
}

export default RecentJobCarousel
