import axios, { getData } from '../../../lib/axios'

export const assessmentId = async () => {
  return axios.get('/personality_tests').then(getData)
}

export const questionList = async () => {
  return axios.get('/personality_tests/questions').then(getData)
}

export const answerQuestion = async (data) => {
  return axios.put('/personality_tests/answer', data).then(getData)
}

export const bulkAnswerQuestion = async (data) => {
  return axios.put('personality_tests/bulk_answers', data).then(getData)
}

export const personalityScore = async () => {
  return axios.get('personality_tests/results').then(getData)
}

// New personality score 2024
export const submitTest = async (data) => {
  return axios.post('v3/personality-test', data).then(getData)
}

export const getTestResult = async () => {
  return axios.get('v3/personality-test').then(getData)
}
