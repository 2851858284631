import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'

import { ProfileContext } from 'contexts/ProfileContext'
import { skills as skillSubmit } from 'services/api/profile'
import { skill as skillApi } from 'services/api/dropdown_list'

import Autocomplete from '@mui/lab/Autocomplete'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'

const SkillForm = ({
  skillOpen,
  setSkillOpen,
  skillSplit,
  setOpenSnack,
  setSnackProps,
  loading,
  setLoading,
}) => {
  const { getSkill } = useContext(ProfileContext)

  const [skillsDropdown, setSkillsDropdown] = useState(null)

  const getSkillDropdown = async () => {
    try {
      let res = await skillApi()
      setSkillsDropdown(res.data)
    } catch (e) {
      console.log(e)
    }
  }

  let skillPopulate = []
  for (let i = 0; i < skillSplit.length; i++) {
    skillPopulate.push({ name: skillSplit[i] })
  }

  const initialValues = {
    skill: skillPopulate,
  }

  const submitSkill = async (value, setErrors) => {
    setLoading(true)
    setOpenSnack(false)
    try {
      let valueArray = []
      for (let i = 0; i < value.skill.length; i++) {
        valueArray.push(value.skill[i].name)
      }

      await skillSubmit('post', { skill: valueArray.toString() })
      getSkill('get')
      setSnackProps({
        severity: 'success',
        message: 'Your save was successful.',
      })
      setOpenSnack(true)
    } catch (e) {
      if (e.status === 422) {
        setErrors(e.data.errors)
      } else {
        setSnackProps({
          severity: 'error',
          message: e.data.message,
        })
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSkillDropdown()
  }, [])

  window.scroll(0, 0)

  return (
    <div>
      <div className="mb-6 flex items-center justify-between">
        <div className="font-semibold">Skills</div>
        <Button
          color="primary"
          startIcon={<ChevronLeftRoundedIcon />}
          onClick={() => setSkillOpen(!skillOpen)}
          style={{
            textTransform: 'none',
            fontFamily: 'Poppins',
            color: 'gray',
            outline: 'none',
          }}
        >
          Back
        </Button>
      </div>

      {skillsDropdown !== null && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(data, { setErrors }) => {
            submitSkill(data, setErrors)
          }}
        >
          {({ setFieldValue, values, dirty, touched, errors }) => (
            <Form autoComplete="off">
              <div className="flex flex-col space-y-6 pl-2">
                <Autocomplete
                  name="skill"
                  options={skillsDropdown}
                  multiple
                  getOptionLabel={(option) => option.name}
                  value={values.skill}
                  onChange={(e, value) => {
                    setFieldValue('skill', value)
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="skill"
                      label="Skills"
                      variant="outlined"
                      fullWidth
                      error={Boolean(touched.skill && errors.skill)}
                      helperText={touched.skill && errors.skill}
                    />
                  )}
                />
              </div>
              <div className="relative mt-8 flex items-center justify-center">
                <Button
                  disabled={!dirty || loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '13px',
                    padding: '5px 20px',
                    boxShadow: 'none',
                  }}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default SkillForm
