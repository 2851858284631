import React from "react"
import jobseeker from "assets/images/jobseeker.jpg"

const CommentCard = ({ item }) => {
  return (
    <div className="flex p-4 pb-8 ">
      <div className="mr-4">
        <img
          className="w-12 h-12 object-cover rounded-full"
          src={jobseeker}
          alt="tutor_photo"
        />
      </div>
      <div className="flex-1 ">
        <div className="flex justify-between items-center">
          <p className="text-lg font-poppins">Tutor</p>
          {/* <p className = "italic text-sm">10 mins ago</p> */}
        </div>
        <p className="text-sm font-poppins">{item}</p>
      </div>
    </div>
  )
}

export default CommentCard
