import ProgressBar from 'components/smart/ProgressBar'
import { format, parseISO } from 'date-fns'
import React from 'react'

const ProgressCard = ({ title, icon, data }) => {
  return (
    <div className="flex flex-col rounded-xl bg-white p-6 lg:flex-row">
      <div className="flex w-full items-center">
        <div className="mr-5 rounded-full bg-adx-softerGreen p-3">{icon}</div>
        <div className="flex flex-col items-start justify-around">
          <div className="font-medium text-adx-darkBlack">{`${title} (${data?.full_score}%)`}</div>
          {data?.last_attendance && (
            <p className="text-xs text-adx-darkBlack">
              Your last attendance was on {format(parseISO(data?.last_attendance), 'dd/MM/yyyy')}
            </p>
          )}
        </div>
      </div>
      <div className="mt-3 flex w-full items-center lg:mt-0 lg:space-x-4">
        <ProgressBar
          value={((data?.score > data?.full_score ? data?.full_score : data?.score) / data?.full_score) * 100}
          sx={{
            flex: 1,
            marginRight: 4,
          }}
        />
        <div className="font-medium text-adx-darkGreen">
          {data?.score ? `${parseFloat(data?.score).toFixed(1)}%` : '0%'}
        </div>
      </div>
    </div>
  )
}

export default ProgressCard
