import ExplorePanel from "../components/menuPanels/ExplorePanel";
import ProductPanel from "../components/menuPanels/ProductPanel";

// For standard nav use: 
// {
//     name: "example",
//     link: "/example",
// }

// For menu use:
// {
//     name: "example",
//     menuComponent: <ExampleComponent/>,
//     fullWidth: true || false,
// }

export const new_header = [
    {
        name: "jobs",
        link: "/jobs",
    },
    {
        name: "track",
        link: "/track",
    },
    {
        name: "explore",
        menuComponent: <ExplorePanel/>,
        fullWidth: true,
    },
    {
        name: "products",
        menuComponent: <ProductPanel/>,
        fullWidth: false,
    },
]

export const mobile_new_header = [
    {
        name: "jobs",
        link: "/jobs",
    },
    {
        name: "track",
        link: "/track",
    },
    {
        name: "explore",
        list: [
            {
                name: "personality test",
                link: "/personality-test"
            },
            {
                name: "video interview",
                link: "/video-interview"
            },
        ]
    },
    {
        name: "products",
        list: [
            {
                name: "jobs",
                link: "/jobs",
            },
            {
                name: "EDU",
                link: "/bootcamp",
            },
            {
                name: "HR",
                link: "https://hr.adnexio.jobs/",
            },
            {
                name: "gigs",
                link: "/gigs",
            },
        ]
    },
]