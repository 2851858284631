import React, { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Element } from 'react-scroll'

import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'
import IconButton from '@mui/material/IconButton'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

const ClassDetailsModal = ({ showTnCModal, onCloseTnCModal, sx, handleClickOpen, courseData }) => {
  const navigate = useNavigate()
  const [expandedPanel, setExpandedPanel] = useState(false)
  const lessonPlanRef = useRef(null)

  const handleScrollToLessonPlanContainer = () => {
    lessonPlanRef.current.scrollIntoView({
      behavior: 'smooth',
      top: lessonPlanRef.current.offsetTop,
    })
  }

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }
  return (
    <Dialog
      open={showTnCModal}
      scroll={'body'}
      onClose={onCloseTnCModal}
      maxWidth="lg"
      PaperProps={{ sx: sx.customRadius }}
    >
      <Element className="w-full p-4 overflow-auto" id="courseDetail">
        <div className="flex items-center justify-between mb-4">
          <p className="font-bold ">Class Details</p>
          <div className="flex">
            {/* Make payment button at top right modal */}
            {[2].includes(courseData?.nex_edu_application_status_id) && (
              <Button
                variant="contained"
                sx={sx.marginRight}
                onClick={() =>
                  navigate(`/payment-summary/${courseData.nex_edu_payment_schedule_id}`)
                }
                disabled={!courseData?.nex_edu_payment_schedule_id}
              >
                Make Payment
              </Button>
            )}

            <IconButton onClick={onCloseTnCModal} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <hr className="my-2" />
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="pt-4 bg-white rounded-xl lg:mt-2">
            <div className="px-4">
              <div
                className="relative grid w-full grid-cols-12 py-6 mx-auto mb-4 rounded-md border-xl bg-adx-white"
                id="topCourseDetail"
              >
                <div className="grid col-span-3 place-items-center justify-self-center">
                  {courseData && courseData?.course_logo ? (
                    <img
                      src={courseData?.course_logo}
                      alt="course_logo"
                      className="object-cover w-10 h-10 rounded-full shadow lg:h-20 lg:w-20"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-10 h-10 text-3xl text-black rounded-full shadow bg-adx-white lg:h-20 lg:w-20">
                      {courseData?.trainer_name?.charAt(0)}
                    </div>
                  )}
                </div>
                <div className="flex flex-col justify-center col-span-7">
                  <p className="w-5/6 text-sm font-semibold capitalize text-adx-darkGreen lg:text-lg">
                    {courseData && courseData?.course_title}
                  </p>
                  <div className="flex items-center justify-between w-full pr-8 mt-1">
                    <p className="text-xs font-semibold lg:text-sm">
                      {courseData?.trainer_name && `by ${courseData?.trainer_name}`}
                    </p>
                  </div>
                </div>
                <div className="hidden col-span-2 lg:absolute lg:top-0 lg:right-0 lg:block">
                  <IconButton
                    onClick={() => handleClickOpen(courseData?.share_url_web)}
                    size="large"
                  >
                    <ShareIcon />
                  </IconButton>
                </div>

                <div className="col-span-2 lg:absolute lg:top-0 lg:right-0 lg:hidden">
                  <IconButton
                    onClick={() => handleClickOpen(courseData?.share_url_web)}
                    sx={sx.ShareButton}
                    size="large"
                  >
                    <ShareIcon sx={sx.ShareIcon} />
                  </IconButton>
                </div>

                <div className="col-span-12">
                  <hr className="my-5 border-1 border-adx-darkerGray lg:mx-4" />
                </div>
                <div className="grid grid-cols-2 col-span-12 gap-2 lg:grid-cols-4 lg:px-4 ">
                  <div className="w-full justify-self-center">
                    <div className="flex flex-col items-center justify-center w-full p-1 rounded-md bg-adx-lightTonedGreen">
                      <p className="text-xs font-semibold text-adx-darkGreen">Spots</p>
                      <p className="text-sm font-bold text-center">
                        {courseData && courseData?.total_spots}
                      </p>
                    </div>
                  </div>
                  <div className="w-full justify-self-center">
                    <div className="flex flex-col items-center justify-center w-full p-1 rounded-md bg-adx-lightTonedGreen">
                      <p className="text-xs font-semibold text-adx-darkGreen">Duration</p>
                      <p className="text-sm font-bold text-center">
                        {courseData && `${courseData?.course_duration} months`}
                      </p>
                    </div>
                  </div>
                  <div className="w-full justify-self-center">
                    <div className="flex flex-col items-center justify-center w-full p-1 rounded-md bg-adx-lightTonedGreen">
                      <p className="text-xs font-semibold text-adx-darkGreen">Class</p>
                      <p className="text-sm font-bold text-center">
                        {courseData && courseData?.course_setting}
                      </p>
                    </div>
                  </div>
                  <div className="w-full justify-self-center">
                    <div className="flex flex-col items-center justify-center w-full p-1 rounded-md bg-adx-lightTonedGreen">
                      <p className="text-xs font-semibold text-adx-darkGreen">Mode</p>
                      <p className="text-sm font-bold text-center">
                        {courseData && courseData?.course_mode}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 mt-5 lg:px-4">
                  <Button
                    onClick={handleScrollToLessonPlanContainer}
                    color="primary"
                    variant="contained"
                    sx={{ ...sx.btnContained, ...sx.btnCourseStructure }}
                  >
                    Lesson Plan
                  </Button>
                </div>
              </div>

              {/* Video for SEC 2 course */}
              {courseData?.nex_edu_course_testimonials && (
                <div className="mt-5 mb-8 space-y-1">
                  <video className="w-full h-full p-0" controls key={1} controlsList="nodownload">
                    <source src={courseData?.nex_edu_course_testimonials} />
                  </video>
                </div>
              )}
            </div>

            <div className="pt-16 pb-12 bg-white">
              <div className="" id="lessonPlanContainer" ref={lessonPlanRef}>
                <p className="mb-4 text-xs font-semibold lg:text-lg">Browse Lesson Plan</p>
                {courseData &&
                  courseData.nex_edu_course_topics.map((item, index) => (
                    <Accordion
                      key={index}
                      sx={{ ...sx.accordionBox, ...sx.disableAccordion }}
                      expanded={expandedPanel === index}
                      onChange={handleAccordionChange(index)}
                    >
                      <AccordionSummary
                        sx={{ ...sx.accordionTitle, ...sx.disableAccordionSummary }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <p className="text-xs font-semibold lg:text-sm">{item.topic_title}</p>
                      </AccordionSummary>
                      <AccordionDetails sx={sx.accordionDescription}>
                        <p
                          className="text-xs whitespace-pre-line lg:text-sm"
                          style={{ lineHeight: '1.55rem' }}
                        >
                          {item.topic_description}
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </div>
            </div>
          </div>
          <div className="pt-4 bg-white rounded-xl lg:mt-2">
            <div className="px-0 lg:px-4">
              <div className="px-2 mt-5 mb-2 space-y-1 lg:px-7">
                <p className="text-xs font-semibold lg:text-sm">Application Guide</p>
                <p className="text-sm whitespace-pre-line" style={{ lineHeight: '1.55rem' }}>
                  1. Sign up/Login to adnexio accounts.
                  <br />
                  2. Complete your profile with phone number and birth date.
                  <br />
                  3. Click “Buy Now” to purchase the course.
                  <br />
                  4. Make payment to confirm your enrollment and gain access to the course.
                </p>
                <br />
                <p
                  className="text-sm text-justify whitespace-pre-line"
                  style={{ lineHeight: '1.55rem' }}
                >
                  <span className="text-blue-500 underline">
                    <Link to="/contact-us">Contact us</Link>
                  </span>{' '}
                  if you have any further questions!
                </p>
              </div>
              <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" />

              <div className="px-2 mt-5 mb-2 space-y-1 lg:px-7">
                <p className="text-xs font-semibold lg:text-sm">Description</p>
                <p
                  className="text-sm text-justify whitespace-pre-line"
                  style={{ lineHeight: '1.55rem' }}
                >
                  {courseData && courseData?.course_description}
                </p>
                {
                  courseData?.class_schedule && (
                    <>
                      <p className="text-xs font-semibold lg:text-sm">Class Schedule</p>
                      <p
                        className="text-sm text-justify whitespace-pre-line"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {courseData?.class_schedule}
                      </p>
                    </>
                  )
                }
                {
                  courseData?.grad_requirement && (
                    <>
                      <p className="text-xs font-semibold lg:text-sm">{courseData.course_title} - Graduation Requirement</p>
                      <p
                        className="text-sm text-justify whitespace-pre-line"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {courseData?.grad_requirement}
                      </p>
                    </>
                  )
                }
              </div>
              <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" />

              <div className="px-2 my-2 mb-8 space-y-2 lg:px-7">
                <p className="text-xs font-semibold lg:text-sm">Benefits</p>
                <p className="text-sm whitespace-pre-line" style={{ lineHeight: '1.55rem' }}>
                  {courseData && courseData?.course_benefits}
                </p>
              </div>
              <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" />

              <div className="px-2 my-2 mb-8 space-y-2 lg:px-7">
                <p className="text-xs font-semibold lg:text-sm">Requirements</p>
                <p className="text-sm whitespace-pre-line" style={{ lineHeight: '1.55rem' }}>
                  {courseData && courseData?.course_requirements}
                </p>
              </div>
              <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" />

              <div className="px-2 my-2 mb-8 space-y-2 lg:px-7">
                <p className="text-xs font-semibold lg:text-sm">Career Paths</p>
                <p className="text-sm whitespace-pre-line" style={{ lineHeight: '1.55rem' }}>
                  {courseData && courseData?.course_careers}
                </p>
              </div>
              <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" />

              <div className="px-2 my-2 mb-8 space-y-2 lg:px-7">
                <p className="text-xs font-semibold lg:text-sm">Teaching Method</p>
                <p className="text-sm whitespace-pre-line" style={{ lineHeight: '1.55rem' }}>
                  {courseData && courseData?.course_teaching_method}
                </p>
              </div>
              <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" />

              <div className="px-2 my-2 mb-8 space-y-2 lg:px-7">
                <p className="text-xs font-semibold lg:text-sm">Intake</p>
                {courseData &&
                  courseData?.nex_edu_course_intakes?.map((item, index) => {
                    const start_date = moment(item.start_date).format('DD MMMM YYYY')
                    return (
                      <p
                        key={index}
                        className="text-sm text-justify whitespace-pre-line"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {start_date}
                      </p>
                    )
                  })}
                <p
                  className="text-sm text-justify whitespace-pre-line"
                  style={{ lineHeight: '1.55rem' }}
                >
                  More intakes to open in the future!
                </p>
              </div>
              <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" />

              <div className="px-2 my-2 mb-8 space-y-2 lg:px-7">
                <p className="text-xs font-semibold lg:text-sm">Length of Course</p>
                <p
                  className="text-sm text-justify whitespace-pre-line"
                  style={{ lineHeight: '1.55rem' }}
                >
                  {courseData && courseData?.course_length}
                </p>
              </div>
              <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" />

              <div className="px-2 my-2 mb-8 space-y-2 lg:px-7">
                <p className="text-xs font-semibold lg:text-sm">Fee</p>
                <p
                  className="text-xs text-justify whitespace-pre-line lg:text-sm"
                  style={{ lineHeight: '1.55rem' }}
                >
                  {console.log(courseData)}
                  {`Total Fee: ${courseData?.currency_code}${courseData?.course_fee}, with 2 payment plan options:`}
                </p>
                <p
                  className="text-xs text-justify whitespace-pre-line lg:text-sm"
                  style={{ lineHeight: '1.55rem' }}
                >
                  {courseData &&
                    `i) ${courseData?.currency_code}${parseInt(courseData?.course_fee) - courseData?.lump_sum_discount
                    } for a one-off payment`}
                </p>
                <p
                  className="text-xs text-justify whitespace-pre-line lg:text-sm"
                  style={{ lineHeight: '1.55rem' }}
                >
                  {courseData &&
                    `ii) ${courseData?.currency_code}${parseInt(
                      courseData?.course_fee / courseData?.num_of_installments
                    )} x ${courseData?.num_of_installments} via installments`}
                </p>
              </div>

              {
                courseData?.tnc_link &&
                <>
                  <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" />
                  <div className="px-2 my-2 mb-8 space-y-2 lg:px-7">
                    <p className="text-xs font-semibold lg:text-sm">Terms and Conditions</p>
                    <p
                      className="text-sm text-justify whitespace-pre-line"
                      style={{ lineHeight: '1.55rem' }}
                    >
                      Read your full {" "}
                      <span
                        className="text-blue-500 cursor-pointer"
                        onClick={() => window.open(courseData?.tnc_link)}
                      >Terms & Conditions</span>
                      {" "} here
                    </p>
                  </div>
                </>
              }

              {/* TEMPORARILY REMOVED FOR SPEAK */}
              {/* <hr className="mx-2 my-5 border-1 border-adx-darkerGray lg:mx-4" />

              <div className="px-2 my-2 mb-8 space-y-2 lg:px-7">
                <p className="text-xs font-semibold lg:text-sm">Class Schedule</p>
                <p
                  className="text-sm text-justify whitespace-pre-line"
                  style={{ lineHeight: '1.55rem' }}
                >
                  Monday to Friday
                </p>
                <p
                  className="text-sm text-justify whitespace-pre-line"
                  style={{ lineHeight: '1.55rem' }}
                >
                  {' '}
                  9 am - 12 pm / 3 pm - 6 pm
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </Element>
    </Dialog>
  )
}

export default ClassDetailsModal
