import React, { useState, useEffect } from "react"
import { videoInterviewList } from "services/api/profile"
import { nexPlayVideo } from "services/api/nex_play"

const TabVideo = () => {
  const [videoList, setVideoList] = useState([])
  const [nexVideoList, setNexVideoList] = useState([])

  const getInterviewList = async () => {
    try {
      let res = await videoInterviewList()
      setVideoList(res.data)
    } catch (e) {
      console.log(e)
    }
  }

  const getNexPlayList = async () => {
    try {
      let res = await nexPlayVideo()
      setNexVideoList(res.data.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getInterviewList()
    getNexPlayList()
  }, [])

  return (
    <div className="flex justify-center items-center">
      <div className="max-w-lg flex flex-wrap pt-4">
        {!videoList.length && !nexVideoList.length && (
          <div className="m-20">No video available</div>
        )}

        {videoList.map((item) => (
          <>
            <video className="p-3" controls key={item.id}>
              <source src={item.content_media_url} />
            </video>
          </>
        ))}

        {nexVideoList.map((item) => (
          <>
            <video className="p-3" controls key={item.id}>
              <source src={item.content_media_url} />
            </video>
          </>
        ))}
      </div>
    </div>
  )
}

export default TabVideo
