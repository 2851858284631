import { useState, useContext } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack' //https://github.com/wojtekmaj/react-pdf/wiki/Known-issues
import axios from 'axios'

import AboutMeForm from './AboutMeForm'
import ExperienceForm from './ExperienceForm'
import EducationForm from './EducationForm'
import ExtracurricularForm from './ExtracurricularForm'
import SkillForm from './SkillForm'

import { authContext } from 'contexts/AuthContext'
import { ProfileContext } from 'contexts/ProfileContext'
import { storeCv } from 'services/api/profile'
import { signUrl } from 'services/api/storage'
import { generateFileName, getFileExtension } from 'services/helper/index'

import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import ButtonBase from '@mui/material/ButtonBase'
import Button from '@mui/material/Button'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Bugsnag from '@bugsnag/js'

const mui = {
  customBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '13px',
  },
}

const TabProfile = ({
  profileData,
  experienceData,
  educationData,
  extracurricularData,
  skillData,
  cvData,
  dobData,
  setOpenSnack,
  loading,
  setLoading,
  setSnackProps,
  historyData,
}) => {
  const { auth, setAuthData } = useContext(authContext)
  const { getCv } = useContext(ProfileContext)

  const [cvOpen, setCvOpen] = useState(false)
  const [aboutOpen, setAboutOpen] = useState(false)
  const [experienceOpen, setExperienceOpen] = useState(false)
  const [educationOpen, setEducationOpen] = useState(false)
  const [extracurricularOpen, setExtracurricularOpen] = useState(false)
  const [skillOpen, setSkillOpen] = useState(false)

  const [experienceID, setExperienceID] = useState(null)
  const [educationID, setEducationID] = useState(null)

  const isFromCourseEdu = historyData?.from === 'edu'

  const handleExperienceForm = (id) => {
    setExperienceOpen(!experienceOpen)
    setExperienceID(id)
  }

  const handleEducationForm = (id) => {
    setEducationOpen(!educationOpen)
    setEducationID(id)
  }

  const handleExtracurricularForm = () => {
    setExtracurricularOpen(!extracurricularOpen)
  }

  const handleSkillForm = () => {
    setSkillOpen(!skillOpen)
  }

  const handleViewCv = async () => {
    try {
      if (!cvOpen) {
        await getCv()
      }
      setPageNumber(1)
      setCvOpen(!cvOpen)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const uploadCv = async () => {
    setLoading(true)

    const extension = getFileExtension(selectedCv.name)
    const fileName = generateFileName(auth.userId, 'document', extension.toLowerCase())

    try {
      const results = await signUrl({
        bucket: process.env.REACT_APP_AWS_BUCKET,
        key: 'cv_upload/' + fileName,
      })
      const awsRequest = axios.create()
      delete awsRequest.defaults.headers.common['Authorization']
      await awsRequest.put(results.data.url, selectedCv, {
        headers: {
          'Content-Type': selectedCv.type,
        },
      })
      await storeCv({ filename: fileName })
      await getCv()
      setSnackProps({
        severity: 'success',
        message: 'Your upload was successful.',
      })
      setAuthData()
      setOpenSnack(true)
    } catch (e) {
      Bugsnag.notify(e)
      setSnackProps({
        severity: 'error',
        message: 'Upload error, please try again!',
      })
      setOpenSnack(true)
    } finally {
      setLoading(false)
      closeCvUpload()
    }
  }

  let skillSplit = skillData !== null && skillData.skill.split(',')

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  let dateUpload = cvData !== null && new Date(cvData.updated_at)

  const formatAMPM = (date) => {
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ampm
    return strTime
  }

  const formattedDOB = (val) => {
    if (val !== null) {
      if (val.dob !== undefined && val.dob !== null) {
        let splitDOB = val.dob !== null && val.dob.split('/')
        let newDOB = splitDOB[0] + ' ' + months[parseInt(splitDOB[1]) - 1] + ' ' + splitDOB[2]
        return newDOB
      }
    }
    return '-'
  }

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const goToNextPage = () => {
    if (pageNumber === numPages) {
      return
    }
    setPageNumber(pageNumber + 1)
  }

  const goToPrevPage = () => {
    if (pageNumber === 1) {
      return
    }
    setPageNumber(pageNumber - 1)
  }

  const [isCvPicked, setIsCvPicked] = useState(false)
  const [selectedCv, setSelectedCv] = useState(null)

  const handleCvUpload = (e) => {
    if (e.target.files[0].type !== 'application/pdf') {
      setSnackProps({
        severity: 'error',
        message: 'Only pdf formatted file is accepted.',
      })
      setOpenSnack(true)
      return
    }

    if (e.target.files[0].size > 2000000) {
      setSnackProps({
        severity: 'error',
        message: 'File size must be less than 2MB',
      })
      setOpenSnack(true)
      return
    }

    setPageNumber(1)
    setIsCvPicked(true)
    setSelectedCv(e.target.files[0])
  }

  const closeCvUpload = () => {
    setIsCvPicked(false)
    setSelectedCv(null)
  }

  return (
    <div className="mx-auto max-w-lg space-y-10 p-6 text-sm lg:max-w-2xl">
      {aboutOpen && (
        <AboutMeForm
          aboutOpen={aboutOpen}
          setAboutOpen={setAboutOpen}
          profileData={profileData}
          newDOB={formattedDOB(dobData)}
          dobData={dobData}
          setOpenSnack={setOpenSnack}
          setSnackProps={setSnackProps}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {experienceOpen && (
        <ExperienceForm
          experienceOpen={experienceOpen}
          setExperienceOpen={setExperienceOpen}
          aboutOpen={aboutOpen}
          setAboutOpen={setAboutOpen}
          setOpenSnack={setOpenSnack}
          experienceID={experienceID}
          loading={loading}
          setLoading={setLoading}
          setSnackProps={setSnackProps}
        />
      )}

      {educationOpen && (
        <EducationForm
          educationOpen={educationOpen}
          setEducationOpen={setEducationOpen}
          aboutOpen={aboutOpen}
          setAboutOpen={setAboutOpen}
          educationID={educationID}
          setOpenSnack={setOpenSnack}
          setSnackProps={setSnackProps}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {extracurricularOpen && (
        <ExtracurricularForm
          extracurricularOpen={extracurricularOpen}
          setExtracurricularOpen={setExtracurricularOpen}
          extracurricularData={extracurricularData}
          setOpenSnack={setOpenSnack}
          setSnackProps={setSnackProps}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {skillOpen && (
        <SkillForm
          skillOpen={skillOpen}
          setSkillOpen={setSkillOpen}
          skillSplit={skillSplit}
          setOpenSnack={setOpenSnack}
          setSnackProps={setSnackProps}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {!aboutOpen && !experienceOpen && !educationOpen && !extracurricularOpen && !skillOpen && (
        <>
          <div className="">
            <div className="mb-2 flex items-center justify-between">
              <div className="flex items-center">
                <div className="mr-2 font-semibold">About Me</div>
                {isFromCourseEdu && !auth.profileStatus && (
                  <ErrorOutlineIcon className="text-red-500 " />
                )}
              </div>

              <IconButton
                style={{ outline: 'none' }}
                onClick={() => setAboutOpen(!aboutOpen)}
                size="large"
              >
                <EditRoundedIcon fontSize="small" />
              </IconButton>
            </div>
            <div>
              <div className="mb-[16px]">{profileData.name}</div>
              <div className="flex flex-col gap-[8px]">
                <div className="flex gap-[16px]">
                  <div className="w-[102px] text-adx-darkerGray">Birth Date</div>
                  <div>{formattedDOB(dobData)}</div>
                </div>
                <div className="flex gap-[16px]">
                  <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">Phone</div>
                  <div>
                    {profileData.contact_number !== null && profileData.contact_number !== '-'
                      ? profileData.contact_number
                      : '-'}
                  </div>
                </div>
                <div className="flex gap-[16px]">
                  <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">Email</div>
                  <div>{profileData.email ?? '-'}</div>
                </div>
                <div className="flex gap-[16px]">
                  <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">
                    Home Address
                  </div>
                  <div>{dobData.address ?? '-'}</div>
                </div>
                <div className="flex gap-[16px]">
                  <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">Postal Code</div>
                  <div>{dobData.postal_code ?? '-'}</div>
                </div>
                <div className="flex gap-[16px]">
                  <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">State</div>
                  <div>{dobData.state ?? '-'}</div>
                </div>
                <div className="flex gap-[16px]">
                  <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">Country</div>
                  <div>{dobData.country ?? '-'}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="mb-2 flex items-center justify-between">
              <div className="font-semibold">Work Experience</div>
              <IconButton
                style={{ outline: 'none' }}
                onClick={() => handleExperienceForm(null)}
                size="large"
              >
                <AddCircleOutlineRoundedIcon fontSize="small" />
              </IconButton>
            </div>
            <div className="space-y-4 pl-2">
              {experienceData === null && <div>No experience available</div>}

              {experienceData !== null &&
                experienceData.map((item) => (
                  <ButtonBase
                    key={item.id}
                    className="w-full"
                    style={{ outline: 'none', borderRadius: '0.5rem' }}
                    onClick={() => handleExperienceForm(item.id)}
                  >
                    <div className="flex w-full items-center rounded-lg bg-adx-gray px-3 py-4 sm:px-8">
                      <div
                        className="mr-4 flex h-16 w-16 items-center overflow-hidden rounded-full bg-adx-white shadow sm:mr-6"
                        style={{ minWidth: '4rem', minHeight: '4rem' }}
                      >
                        <div className="flex h-full w-full items-center justify-center bg-gray-900 text-3xl capitalize text-white">
                          {item.company_name.charAt(0)}
                        </div>
                      </div>
                      <div className="text-left">
                        <div className="font-semibold capitalize text-adx-darkGreen">
                          {item.position}
                        </div>
                        <div className="mb-1 text-xs font-semibold capitalize">
                          {item.company_name}
                        </div>
                        <div className="text-xs">
                          {`${item.start_month_name} ${item.start_year} - `}{' '}
                          {item.end_month_name !== 'Present'
                            ? `${item.end_month_name} ${item.end_year}`
                            : 'Current'}
                        </div>
                      </div>
                    </div>
                  </ButtonBase>
                ))}
            </div>
          </div>

          <div>
            <div className="mb-2 flex items-center justify-between">
              <div className="flex items-center font-semibold">
                <p className="mr-2">Education</p>
              </div>
              <IconButton
                style={{ outline: 'none' }}
                onClick={() => handleEducationForm(null)}
                size="large"
              >
                <AddCircleOutlineRoundedIcon fontSize="small" />
              </IconButton>
            </div>

            <div className="space-y-4 pl-2">
              {educationData === null && <div>No education available</div>}

              {educationData !== null &&
                educationData.map((item) => (
                  <ButtonBase
                    key={item.id}
                    className="w-full"
                    style={{ outline: 'none', borderRadius: '0.5rem' }}
                    onClick={() => handleEducationForm(item.id)}
                  >
                    <div className="flex w-full items-center rounded-lg bg-adx-gray px-3 py-4 sm:px-8">
                      <div
                        className="mr-4 flex h-16 w-16 items-center overflow-hidden rounded-full bg-adx-white shadow sm:mr-6"
                        style={{ minWidth: '4rem', minHeight: '4rem' }}
                      >
                        <div className="flex h-full w-full items-center justify-center bg-gray-900 text-3xl capitalize text-white">
                          {item.university !== null ? item.university.charAt(0) : 'U'}
                        </div>
                      </div>
                      <div className="text-left">
                        <div className="font-semibold text-adx-darkGreen ">{item.university}</div>
                        <div className="mb-1 text-xs font-semibold ">{item.qualification}</div>
                        <div className="text-xs ">{item.course}</div>
                        <div className="text-xs">
                          {`${item.start_year} - `}{' '}
                          {item.end_year !== 'Present' ? `${item.end_year}` : 'Current'}
                        </div>
                      </div>
                    </div>
                  </ButtonBase>
                ))}
            </div>
          </div>

          <div>
            <div className="mb-2 flex items-center justify-between">
              <div className="font-semibold">Extracurricular</div>
              <IconButton
                style={{ outline: 'none' }}
                onClick={handleExtracurricularForm}
                size="large"
              >
                <EditRoundedIcon fontSize="small" />
              </IconButton>
            </div>

            <div className="space-y-2 break-words pl-2">
              {extracurricularData === null && <div>No extracurricular available</div>}
              {extracurricularData !== null && (
                <div className="whitespace-pre-line">{extracurricularData.extracurricular}</div>
              )}
            </div>
          </div>

          <div>
            <div className="mb-2 flex items-center justify-between">
              <div className="font-semibold">Skills</div>
              <IconButton style={{ outline: 'none' }} onClick={handleSkillForm} size="large">
                <EditRoundedIcon fontSize="small" />
              </IconButton>
            </div>

            <div className="flex flex-wrap break-words">
              {skillData === null && <div>No skills available</div>}

              {skillData !== null &&
                skillSplit.map((item, index) => (
                  <div className="mx-2 mb-2 rounded bg-adx-darkGray px-4 py-2" key={index}>
                    {item}
                  </div>
                ))}
            </div>
          </div>

          <div>
            <div className="mb-4 flex items-center justify-between">
              <div className="flex items-center font-semibold">
                <p className="mr-2">Resume</p>
                {isFromCourseEdu && cvData === null && (
                  <ErrorOutlineIcon className="text-red-500 " />
                )}
              </div>
            </div>

            <div className="space-y-2 break-words pl-2">
              {cvData === null && (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    component="label"
                    sx={mui.customBtn}
                    onChange={handleCvUpload}
                    onClick={(e) => {
                      e.target.value = null
                    }}
                  >
                    Upload
                    <input type="file" hidden accept=".pdf" />
                  </Button>
                  <div>No resume available</div>
                </>
              )}

              {cvData !== null && (
                <>
                  <div className="flex space-x-2">
                    <Button
                      color="primary"
                      variant="contained"
                      component="label"
                      sx={mui.customBtn}
                      onClick={handleViewCv}
                    >
                      View
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      component="label"
                      sx={mui.customBtn}
                      onChange={handleCvUpload}
                      onClick={(e) => {
                        e.target.value = null
                      }}
                    >
                      Replace
                      <input type="file" hidden accept=".pdf" />
                    </Button>
                  </div>
                  <div className="flex space-x-2">
                    <div className="font-light text-adx-darkerGray">Uploaded at</div>
                    <div>{`${formatAMPM(dateUpload)} ${dateUpload.getDate()} ${
                      months[dateUpload.getMonth()]
                    } ${dateUpload.getFullYear()}`}</div>
                  </div>

                  {/* OPEN MODAL TO VIEW UXISTING CV */}
                  <Dialog onClose={handleViewCv} open={cvOpen} fullWidth={false} maxWidth={false}>
                    <div className="flex flex-col items-center justify-center bg-slate-100 px-4 pt-4 pb-2">
                      <Document
                        file={cvData.file_url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="flex min-h-[300px] min-w-[400px] items-center justify-center"
                      >
                        <Page pageNumber={pageNumber} height={600} renderAnnotationLayer={false} />
                      </Document>

                      <div className="flex select-none items-center space-x-2 pt-2 font-poppins text-sm">
                        <button onClick={goToPrevPage} className="text-lg font-medium">
                          &lt;
                        </button>
                        <p>
                          Page {pageNumber} of {numPages}
                        </p>
                        <button onClick={goToNextPage} className="text-lg font-medium">
                          &gt;
                        </button>
                      </div>
                    </div>

                    <div className="my-3 flex justify-center space-x-4">
                      <Button
                        color="primary"
                        variant="contained"
                        sx={mui.customBtn}
                        href={cvData.file_url}
                        target="_blank"
                      >
                        Download
                      </Button>
                      <Button variant="outlined" sx={mui.customBtn} onClick={handleViewCv}>
                        Close
                      </Button>
                    </div>
                  </Dialog>
                </>
              )}

              {/* MODAL TO UPLOAD NEW CV */}
              <Dialog onClose={closeCvUpload} open={isCvPicked} fullWidth={false} maxWidth={false}>
                <div className="flex flex-col items-center justify-center bg-slate-100 px-4 pt-4 pb-2">
                  <Document
                    file={selectedCv}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="flex min-h-[300px] min-w-[400px] items-center justify-center"
                  >
                    <Page pageNumber={pageNumber} height={600} renderAnnotationLayer={false} />
                  </Document>

                  <div className="flex select-none items-center space-x-2 pt-2 font-poppins text-sm">
                    <button onClick={goToPrevPage} className="text-lg font-medium">
                      &lt;
                    </button>
                    <p>
                      Page {pageNumber} of {numPages}
                    </p>
                    <button onClick={goToNextPage} className="text-lg font-medium">
                      &gt;
                    </button>
                  </div>
                </div>

                <div className="my-3 flex justify-center space-x-4">
                  <Button color="primary" variant="contained" sx={mui.customBtn} onClick={uploadCv}>
                    Upload
                  </Button>
                  <Button variant="outlined" sx={mui.customBtn} onClick={closeCvUpload}>
                    Cancel
                  </Button>
                </div>
              </Dialog>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default TabProfile
