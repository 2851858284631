import { Button } from "@mui/material"
import icon from '../assets/pendingImg.svg'

const HRDPending = ({ handleClose }) => {
  return (
    <>
      <div className="max-w-[90%] md:max-w-[80%] mx-auto">
        <div className="flex items-center justify-center">
          <img alt='' src={icon} />
        </div>
        <h3 className="font-medium text-[24px] leading-[calc(24px * 1.5)] mt-[40px] mb-[16px] text-center">Application is under review</h3>
        <p className="text-adx-darkerGray text-center">Thank you for submitting your HRD Corp Claimable Course application! We have received your application and are currently reviewing it. You will be notified on the status of your application once your application has been reviewed.</p>
      </div>
      <Button onClick={handleClose} variant='outlined' fullWidth sx={{ minHeight: '45px', mt: '30px', mb: '10px' }} color='primary'>Close</Button>
    </>
  )
}

export default HRDPending