import { Button, Checkbox, Table, TableBody, TableCell, TableRow } from '@mui/material'
import AdnexioEduV2 from 'assets/logos/adnexio-logo-edu-v2-aug.png'
import CustomHelmet from 'components/smart/CustomHelmet'
import { ErrorMessage, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getPaymentSummaryHrdf } from 'services/api/hrd'
import Bugsnag from '@bugsnag/js'
import { eduMakePayment } from 'services/api/course_payment'

const HRDPaymentSummary = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const navigate = useNavigate()
  const { id: nex_edu_payment_schedule_id } = useParams()
  const { state } = useLocation()

  const handleReturnDashboard = () => {
    navigate('/courses')
  }

  const handleSubmission = async (values, actions) => {
    try {
      setLoading(true)
      const res = await eduMakePayment(values)
      window.location.href = res.data
    } catch (e) {
      Bugsnag.notify(e)
    } finally {
      setLoading(false)
    }
  }

  const openTnc = (data) => {
    window.open(data)
  }

  const fetchApi = async () => {
    setLoading(true)
    try {
      const res = await getPaymentSummaryHrdf(nex_edu_payment_schedule_id)
      setData(res)
    } catch (error) {
      setLoading(false)
      Bugsnag.notify(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchApi()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nex_edu_payment_schedule_id])

  if (!state) {
    return <Navigate to={`/payment-summary/${nex_edu_payment_schedule_id}`} />
  }
  return (
    <div className="min-h-screen bg-adx-gray px-4 py-12 ">
      <CustomHelmet title="Payment Summary | adnexio EDU" /> {/* to change the head meta tag */}
      {loading && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}
      <div className="mx-auto max-w-3xl">
        <img src={AdnexioEduV2} className="mx-auto mb-12 h-10" alt="adnexio_edu" />
        <div className="rounded-xl bg-white">
          <div className=" mx-auto py-4 ">
            <div className="mx-auto flex w-5/6 items-center py-4">
              <img
                src={data?.course_logo}
                className="mr-8 h-20 w-20 rounded-lg"
                alt="course logo"
              />
              <p className="text-lg font-bold text-adx-darkGreen">{data?.course_title}</p>
            </div>
            <hr className="my-4" />
            <div className="mx-auto w-5/6 ">
              <div className="my-[30px] flex items-center justify-between ">
                <p className="text-base font-semibold lg:text-xl">
                  Payment summary
                </p>
              </div>
              <div className="mb-[80px] rounded-lg bg-adx-gray py-4">
                <div className=" mx-auto w-11/12">
                  <div className="flex items-center rounded-lg bg-white py-4 px-8">
                    <img
                      src={data?.course_logo}
                      className="mr-8 h-12 w-12 rounded-lg"
                      alt="course logo"
                    />
                    <p className="text-sm font-bold text-adx-darkGreen lg:text-base">
                      {data?.course_title}
                    </p>
                  </div>
                  <div>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{
                            paddingTop: '30px',
                            paddingX: 0,
                            borderBottom: 'none',
                          }} width="70%">
                            <p className="text-sm text-adx-darkerGray lg:text-base">
                              {data?.course_title}
                            </p>
                          </TableCell>
                          <TableCell sx={{
                            paddingTop: '30px',
                            paddingX: 0,
                            borderBottom: 'none',
                          }} width="27.5%" align="right">
                            <p className="text-sm text-adx-darkerGray lg:text-base">
                              {' '}
                              RM{' '}
                              {data?.total_fee?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              paddingTop: '15px',
                              paddingBottom: '30px',
                              paddingX: 0
                            }}
                            width="70%"
                          >
                            <p className="text-sm lg:text-base text-adx-darkerGray">Amount funded by HRD Corp (70% of total course fee)</p>
                          </TableCell>
                          <TableCell
                            sx={{
                              paddingTop: '15px',
                              paddingBottom: '30px',
                              paddingX: 0
                            }}
                            width="25%"
                            align="right"
                          >
                            <p className="text-sm lg:text-base text-adx-darkerGray">
                              - RM{' '}
                              {(parseInt(data?.applicable_discounts?.find((item) => item?.name === 'HRDF Grant')?.value) / 100 * data?.total_fee)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: '30px 0px',
                            }}
                            width="70%"
                          >
                            <p className="text-sm lg:text-base text-adx-darkerGray">Amount need to be paid (30% upfront payment)</p>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '30px 0px',
                            }}
                            width="25%"
                            align="right"
                          >
                            <p className="text-sm lg:text-base font-medium">
                              RM{' '}
                              {((100 - parseInt(data?.applicable_discounts?.find((item) => item?.name === 'HRDF Grant')?.value)) / 100 * data?.total_fee)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: '30px 0px',
                              borderBottom: 'none',
                            }}
                            width="70%"
                          >
                            <p className="text-sm lg:text-base font-medium">Total payment</p>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '30px 0px',
                              borderBottom: 'none',
                            }}
                            width="25%"
                            align="right"
                          >
                            <p className="text-sm lg:text-base font-medium">
                              RM{' '}
                              {((100 - parseInt(data?.applicable_discounts?.find((item) => item?.name === 'HRDF Grant')?.value)) / 100 * data?.total_fee)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>

              <Formik
                onSubmit={handleSubmission}
                initialValues={{
                  nex_edu_discount_id: data?.applicable_discounts?.find((item) => item?.name === 'HRDF Grant')?.id,
                  nex_edu_current_enrolment_id: data?.nex_edu_current_enrolment_id,
                  amount: (100 - parseInt(data?.applicable_discounts?.find((item) => item?.name === 'HRDF Grant')?.value)) / 100 * data?.total_fee,
                  agree_status: false,
                }}
                enableReinitialize
              >
                {({ values, handleChange, handleSubmit }) => (
                  <Form>
                    <div>
                      <label htmlFor="agree_status" className="flex items-center">
                        <Checkbox
                          color="primary"
                          id="agree_status"
                          name="agree_status"
                          onChange={handleChange}
                          checked={values?.agree_status}
                        />
                        <p className="text-sm lg:text-base">
                          I have read and agree to the{' '}
                          <span
                            onClick={
                              () => openTnc(data?.tnc_url)
                            }
                            className="cursor-pointer text-blue-500"
                          >
                            terms and conditions.
                          </span>
                        </p>
                      </label>
                      <ErrorMessage name="agree_status" component="p" className="text-red-500" />
                    </div>
                    <div className="my-2">
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: '#FCD94E',
                          width: '100%',
                          textTransform: 'capitalize',
                          color: '#1B8379',
                          boxShadow: 'none',
                          minHeight: '45px',
                          '&:hover': {
                            color: '#FCD94E'
                          }
                        }}
                        onClick={handleSubmit}
                        disabled={!values?.agree_status}
                      >
                        Continue
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="my-2 text-center">
                <Button fullWidth onClick={handleReturnDashboard} variant="text" sx={{
                  color: '#3294F6',
                  minHeight: '45px',
                }}>
                  Return to dashboard
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HRDPaymentSummary