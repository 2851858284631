import axios from "axios"
import Cookies from "universal-cookie"
import moment from "moment"

import { clearCookies } from "services/helper/clearCookies"

const API_BASE_URL = process.env.REACT_APP_API_URL
const cookies = new Cookies()

const request = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

request.interceptors.request.use(
  async (config) => {
    const access_token = cookies.get("access_token")

    if (access_token) {
      config.headers["Authorization"] = `Bearer ${access_token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  (response) => {
    if (
      response.data.message === "Authentication successful" &&
      response.data.data.account_type === "employer"
    ) {
      clearCookies()

      const errorMessage = {
        status: 401,
        data: { message: "This account is registered under Employer account." },
      }
      throw errorMessage
    } else if (
      response.data.message === "Authentication successful" &&
      response.data.data.account_type === "jobseeker"
    ) {
      const expires = moment(new Date()).add(30, "days")._d
      const domain = process.env.REACT_APP_HR_DOMAIN

      cookies.set("access_token", response.data.data.access_token, {
        path: "/",
        expires,
        domain,
      })
      cookies.set("hr_access_token", response.data.data.hr_access_token, {
        path: "/",
        expires,
        domain,
      })
      cookies.set("token_expired", response.data.data.expired_at, {
        path: "/",
        expires,
        domain,
      })
    }

    return response
  },

  (error) => {
    if (error.response) {
      const { status } = error.response

      if (status === 401) {
        console.log("You dont have authorized to access this page")
        clearCookies()
      } else if (status === 500) {
        console.log("Server Error")
      }

      return Promise.reject(error.response)
    }
  }
)

export function getData(response) {
  return response.data
}

export default request
