import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, DialogContent } from '@mui/material'
import alert from 'assets/icons/alert.png'
import logo from 'assets/logos/adnexio-logo-symbol.svg'

const mui = {
  appBtn: {
    border: '1px solid white',
    borderRadius: '5px',
    outline: 'none',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  btnContained: {
    fontWeight: '400',
    fontSize: '14px',
    width: '200px',
    height: '50px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  btnOutline: {
    fontWeight: '400',
    fontSize: '14px',
    width: '200px',
    height: '50px',
    textAlign: 'center',
  },
}
const HeaderInterview = ({ startInterview }) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const closeInterview = () => {
    setOpen(false)
    navigate('/video-interview')
    navigate(0)
  }

  return (
    <>
      <div
        className={`sm: relative z-30 flex items-center justify-between border-b-[1px] border-inherit bg-adx-white px-2 py-5 font-poppins lg:py-7 lg:px-14`}
      >
        <Box />
        <img src={logo} alt="logo" className="w-36 sm:w-44 lg:w-52" />
        {!startInterview ? (
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => handleClickOpen()} />
        ) : (
          <Box className="w-[24px]" />
        )}
      </div>

      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
        <DialogContent className="flex flex-col items-center">
          <div className="mb-5 flex flex-col items-center gap-5 lg:px-28">
            <img src={alert} alt="logo" className="w-20" />
            <p className="text-center text-2xl font-medium">
              Are you confirm to leave this session?
            </p>
            <p className="max-w-md text-center text-sm font-normal">
              We will not save any of your recording up to this point. You’ll need to start all over
              again if you wish to retake the video interview.
            </p>
          </div>

          <Button sx={mui.btnContained} variant="contained" disableElevation onClick={handleClose}>
            No, continue recording
          </Button>
          <Button sx={mui.btnOutline} component="label" onClick={closeInterview}>
            Yes, leave this session
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default HeaderInterview
