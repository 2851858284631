import React from 'react'
import { useState } from 'react'
import { ReactComponent as GreenCurve } from '../../personality_test/assets/green-curve.svg'
import { ReactComponent as GreenCurveMobile } from '../../personality_test/assets/green-curve-mobile.svg'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const PersonalityCarousel = ({data, isLoggedIn}) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const prev =  () => setCurrentIndex((currentIndex) => (currentIndex === 0 ? data?.length - 1 : currentIndex - 1))
    const next =  () => setCurrentIndex((currentIndex) => (currentIndex === data?.length - 1 ? 0 : currentIndex + 1))
    
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    }

    return (
        <div className='flex flex-col'>
            <p className='mt-10 mb-2 text-center text-adx-darkerGray'>{isLoggedIn ? "Here are your personalities at work!" : "You’ve got 5 personalities at work!"}</p>
            <p className='mb-8 text-xl font-medium text-center'>{data[currentIndex]?.factor.name}</p>
            
            <div className='overflow-hidden h-[200px] flex items-center'>
                <div className='relative z-10 w-fit flex items-center gap-[30px] ml-[50%] ease-out transition-transform duration-[650ms]' style={{ transform: `translateX(-${currentIndex * 190 + 100}px)` }}>
                    {data?.map((_, index) => (
                        <div
                            key={index}
                            onClick={() => setCurrentIndex(index)}
                            className={`${currentIndex === index ? "h-[200px] w-[200px]" : "h-[160px] w-[160px]"} transition-all duration-[850ms] relative cursor-pointer overflow-hidden border-[1px] border-adx-addBackground flex justify-center items-center bg-adx-defaultBackground rounded-[4.5px]`}
                        >
                            {isLoggedIn || index === 0 ? (
                                <>
                                    {currentIndex !== index && <div className='absolute inset-0 w-full h-full bg-white opacity-75' />}
                                    {/* <img src={characterImage} alt="Chameleon" className='object-contain w-full h-full' /> */}
                                    <img src={data[index]?.factor.image} alt={data[index]?.factor.name} className='object-contain w-full h-full' />
                                </>
                            ) : (
                                <LockIcon style={{ color: "#5F31EC", fontSize: "30px" }} />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            
            <div className='relative'>
                <p className='w-full absolute mt-6 text-sm px-10 lg:px-[100px] text-center text-white'>{isLoggedIn || currentIndex === 0 ? data[currentIndex]?.factor.short_description : "Log in to view your other personalities and unlock full extensive result of your work personality."}</p>
                <GreenCurve className='w-full h-fit hidden sm:block sm:-mt-[70px]'/>
                <GreenCurveMobile className='w-full h-fit sm:hidden -mt-[50px]'/>
            </div>
            <div className='relative flex items-center py-8'>
                {currentIndex !== 0 && (
                    <button onClick={prev} className='flex z-10 absolute left-4 items-center justify-center text-white bg-adx-darkGreen rounded-[4.5px] w-[35px] h-[35px]'>
                        <ChevronLeftRoundedIcon/>
                    </button>
                )}
                <div className='absolute flex items-center justify-center w-full gap-2'>
                    {data?.map((_, index) => (
                        <div onClick={() => setCurrentIndex(index)} key={index} className={`h-[6px] w-[6px] rounded-full cursor-pointer ${currentIndex === index ? "bg-adx-darkGreen" : "bg-adx-addBackground"}`} />
                    ))}
                </div>
                {currentIndex !== data?.length - 1 && (
                    <button onClick={next} className='flex z-10 absolute right-4 items-center justify-center text-white bg-adx-darkGreen rounded-[4.5px] w-[35px] h-[35px]'>
                        <ChevronRightRoundedIcon/>
                    </button>
                )}
                {!isLoggedIn && currentIndex !== 0 && (
                    <button onClick={scrollToBottom} className='flex z-10 absolute gap-2 px-3 text-sm right-4 items-center justify-center text-white bg-adx-darkGreen rounded-[4.5px] h-[35px]'>
                        <LockOpenIcon fontSize='small' />
                        <p className='whitespace-nowrap'>Log in to unlock</p>
                    </button>
                )}
            </div>
        </div>
    )
}

export default PersonalityCarousel