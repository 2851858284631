import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Scroll from 'react-scroll'
import Loader from 'react-loader-spinner'
import Bugsnag from '@bugsnag/js'

import Button from '@mui/material/Button'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import IconButton from '@mui/material/IconButton'
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'

import AssignmentCard from '../components/AssignmentCard'
import AssignmentModal from '../components/AssignmentModal'
import Footer from 'components/dumb/Footer'
import { eduProgress } from 'services/api/course_progress'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import CustomHelmet from 'components/smart/CustomHelmet'
import ProgressCard from '../components/ProgressCard'
import { AccessTimeFilled, Assignment, PersonalVideo } from '@mui/icons-material'

const mui = {
  iconStyle: {
    color: '#FFFFFF',
  },
  myClassBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    background: 'white',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  myClassBtnActive: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#E2EAE9',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  applicationBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    fontWeight: '300',
    background: 'transparent',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  backBtn: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    textTransform: 'none',
    fontWeight: '300',
    background: 'transparent',
    color: '#6E6E6E',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '0px 0px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
}

const MyProgress = () => {
  const [animateIconBool, setAnimateIconBool] = useState(true)
  const [loading, setLoading] = useState(false)

  const [openAssignmentModal, setOpenAssignmentModal] = useState(false)
  const [selectedAssignmentData, setSelectedAssignmentData] = useState({})

  const [assignmentData, setAssignmentData] = useState([])
  const [myProgressData, setMyProgressData] = useState([])

  const param = useParams()
  const navigate = useNavigate()

  var scroll = Scroll.animateScroll

  // Icon Rotation
  const animatedClock = 'transform rotate-180 transition duration-1000'
  const animatedAntiClock = 'transform rotate-0 transition duration-1000'

  // Drawer Translation
  const drawerHidden = 'transform -translate-x-full transition duration-1000'
  const drawerDisplayed = 'transform translate-x-0 transition duration-1000'

  const handlePress = () => {
    setAnimateIconBool(!animateIconBool)
    scroll.scrollToTop()
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getAssignmentData(param.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAssignmentData = async (id) => {
    try {
      setLoading(true)
      const res = await eduProgress(id)
      const { assignmentData, ...rest } = res.data
      setAssignmentData(assignmentData)
      setMyProgressData(rest.progress_data)
    } catch (e) {
      if (e.status !== 404) {
        Bugsnag.notify(e)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleClickView = (item) => {
    setOpenAssignmentModal(true)
    setSelectedAssignmentData(item)
  }

  const handleCloseAssignmentModal = () => {
    setOpenAssignmentModal(false)
  }

  return (
    <div className="bg-adx-gray">
      <CustomHelmet title="My Progress | adnexio EDU" />

      {loading && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}
      <HeaderEdu page="my-classes" />
      <div className="hidden lg:relative lg:grid lg:h-full lg:grid-cols-5 lg:bg-adx-gray">
        {animateIconBool ? (
          <div
            className={`h-full w-full bg-white ${animateIconBool ? drawerDisplayed : drawerHidden}`}
          >
            <div className="mt-9 flex w-full justify-center">
              <Button variant="contained" sx={mui.myClassBtnActive} disableRipple={true}>
                <div>
                  <DesktopWindowsIcon className="mr-2" />
                  My Classes
                </div>
                <ArrowForwardIosIcon className="ml-2" />
              </Button>
            </div>
            <div className="mt-9 flex w-full justify-center">
              <Link to="/upskill-payment">
                <Button
                  variant="contained"
                  sx={mui.myClassBtn}
                  onClick={() => navigate('/upskill-payment')}
                >
                  <div>
                    <AccountBalanceWalletIcon className="mr-2" />
                    Payment
                  </div>
                  <ArrowForwardIosIcon className="ml-2 opacity-0" />
                </Button>
              </Link>
            </div>
          </div>
        ) : null}
        {/* Animation to space the column with the left side bar */}
        <div className={`px-12 pt-9 pb-96 ${animateIconBool ? 'col-span-4' : 'col-span-5'}`}>
          <Link to={`/bootcamp/my-classes/${param.id}`}>
            <Button variant="contained" sx={mui.backBtn}>
              <ArrowBackIosIcon />
              Back
            </Button>
          </Link>

          <div className="my-14 space-y-6 rounded-2xl">
            <div className="mt-7 mb-7 text-3xl font-semibold text-adx-lightBlack">My progress</div>
            {
              myProgressData?.length === 0 &&
              <div className='flex w-full flex-col items-center justify-center bg-adx-darkGray p-8'>
                <p className='text-lg font-bold text-adx-darkerGray'>No progress found</p>
                <p className='text-adx-darkerGray'>Start taking your class to see your progress</p>
              </div>
            }
            {myProgressData?.map((item, index) => (
              <ProgressCard
                key={index}
                title={item?.title}
                icon={item?.type === 'attendance' ?
                  <AccessTimeFilled
                    sx={{
                      fontSize: 24,
                      color: 'primary.main',
                    }}
                  /> : item?.type === 'exam' ?
                    <Assignment
                      sx={{
                        fontSize: 24,
                        color: 'primary.main',
                      }}
                    /> :
                    <PersonalVideo
                      sx={{
                        fontSize: 24,
                        color: 'primary.main',
                      }}
                    />
                }
                data={{
                  score: item?.score,
                  full_score: item?.full_score,
                  last_attendance: item?.last_attendance
                }}
              />
            ))}
          </div>

          {/* Animation to space the column to the bottom */}
          <div className="my-14 rounded-2xl">
            <div className="mt-7 mb-7  text-3xl font-semibold text-adx-lightBlack">Assignment</div>
            {assignmentData.length !== 0 ? (
              assignmentData.map((item, index) => (
                <AssignmentCard key={index} item={item} onClick={() => handleClickView(item)} />
              ))
            ) : (
              <div className="flex w-full flex-col items-center justify-center bg-adx-darkGray p-8">
                <p className="text-lg font-bold text-adx-darkerGray">No assignment found</p>
                <p className="text-adx-darkerGray">
                  Submit your first assignment to start tracking your progress
                </p>
              </div>
            )}
            <AssignmentModal
              open={openAssignmentModal}
              onClose={() => handleCloseAssignmentModal()}
              data={selectedAssignmentData}
            />
          </div>
        </div>
        {/* Absolute arrow button */}
        <div
          className={`absolute bottom-8 left-16 flex h-10 w-10 items-center justify-center rounded-full bg-adx-darkGreen ${animateIconBool ? animatedClock : animatedAntiClock
            }`}
        >
          <IconButton onClick={handlePress} size="large">
            <ArrowForwardIosIcon sx={mui.iconStyle} />
          </IconButton>
        </div>
      </div>
      <div className="flex h-screen items-center justify-center lg:hidden">
        <div className="text-center">Please use the web browser to see your progress</div>
      </div>
      {/* FOOTER */}

      <Footer />
    </div>
  )
}

export default MyProgress
