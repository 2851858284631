import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'
import adnexio_logo_white from 'assets/logos/adnexio-logo-white-v2-aug.png'
import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import Footer from 'components/dumb/Footer'

const mui = {
  btnContained: {
    color: '#fff',
    fontWeight: 'bold',
    width: '100%',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    padding: '8px 20px',

    '&:focus': {
      outline: 'none',
      backgroundColor: '#1B8379',
    },
    '&:hover': {
      outline: 'none',
      backgroundColor: '#1B8379',
    },
  },
}

const Complete = () => {
  const navigate = useNavigate()

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }
  return (
    <div>
      <div className="fixed top-0 z-50 block w-full lg:hidden">
        {/* <Header page="disableBottom" loginMode={true} /> */}
        <NewHeader/>
      </div>
      <div>
        <div
          id="anchor"
          className="h-screen pt-20 pb-4 mx-8 lg:mx-0 lg:grid lg:grid-cols-12 lg:pt-0 lg:pb-0 "
        >
          <div className="flex-col hidden lg:col-span-6 lg:flex lg:h-full lg:bg-adx-darkGreen">
            <img className="w-48 mt-12 ml-4 h-9" src={adnexio_logo_white} alt="logo" />
            <div className="flex flex-col items-center justify-center h-full">
              <div className="lg:mb-24 lg:text-7xl lg:text-adx-yellow">
                <p className="font-bold text-left">
                  WELCOME
                  <br />
                  TO ADNEXIO
                </p>
                <p className="text-lg text-white">
                  Sign up with a simple two step registration process.
                </p>
              </div>

              <div className="text-center">
                <p className="mb-2 text-white">For better experience</p>
                <div className="flex justify-center gap-x-2">
                  <img
                    className="rounded-lg cursor-pointer h-11 w-36"
                    src={apple_store_badges}
                    alt="..."
                    onClick={() => appDownload('apple')}
                  />
                  <img
                    className="rounded-lg cursor-pointer h-11 w-36"
                    src={google_play_badges}
                    alt="..."
                    onClick={() => appDownload('android')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between h-full col-span-6 text-center lg:justify-start lg:py-12">
            <div className="flex flex-col lg:mb-4">
              <div className="flex items-center w-auto mx-auto text-center">
                <div className="inline-block h-4 w-8 cursor-pointer rounded-full bg-adx-darkGreen py-0.5  text-center text-sm text-white lg:w-24 lg:px-4  lg:py-2 lg:text-sm"></div>
                <div className="flex items-center">
                  <div className="block  h-0.5 w-2 bg-adx-darkGreen text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-darkGreen text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-darkGreen text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-darkGreen text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGreen text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGreen text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGreen text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGreen text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGreen text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGreen text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="block  h-0.5 w-3 rounded-full bg-adx-darkGreen text-gray-300"></div>
                </div>
                <div className="flex items-center">
                  <div className="block  h-0.5 w-8 bg-adx-darkGreen text-gray-300"></div>
                  <div className="hidden h-0.5  w-8 bg-adx-darkGreen text-gray-300 lg:block"></div>
                </div>
                <div className="inline-block w-20 cursor-pointer rounded-full bg-adx-darkGreen py-0.5 text-center  text-sm text-white lg:w-24 lg:px-4 lg:py-2   lg:text-sm">
                  Complete
                </div>
              </div>
              <div className="w-full mx-auto mt-4 lg:mt-28 lg:w-80 xl:w-96">
                <div
                  alt="... "
                  className="relative flex flex-col items-center justify-center w-full h-64 text-center cursor-pointer rounded-3xl focus:outline-none xl:h-80"
                >
                  <p className="text-lg text-adx-darkGreen">Congratulations!</p>
                  <p className="text-base text-adx-darkGreen">
                    Looking good. Your profile is now complete. Begin your adnexio journey
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center lg:mx-auto lg:w-80">
              <p className="mb-4 text-xs text-adx-darkGreen">
                Thanks for registering an account. Let’s get started.
              </p>
              <span>
                <Button variant="contained" sx={mui.btnContained} onClick={() => navigate('/jobs')}>
                  Next
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Complete
