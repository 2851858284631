import { Button } from '@mui/material'
import icon from '../assets/grantApproved.svg'
import { useNavigate } from 'react-router-dom'

const HRDGrantApproved = ({ nex_edu_payment_schedule_id }) => {
  const navigate = useNavigate()
  const handlePayNow = () => {
    navigate(`/hrd/payment-summary/${nex_edu_payment_schedule_id}`, { state: nex_edu_payment_schedule_id })
  }
  return (
    <>
      <div className="max-w-[90%] md:max-w-[80%] mx-auto">
        <div className="flex items-center justify-center">
          <img alt='' src={icon} />
        </div>
        <h3 className="font-medium text-[24px] whitespace-pre-line leading-[calc(24px * 1.5)] mt-[40px] mb-[16px] text-center">Congratulations!<br />Your grant application has been approved by HRD Corp.</h3>
        {/* <h3 className="font-medium text-[24px] leading-[calc(24px * 1.5)] mb-[16px] text-center">Your grant application has been approved by HRD Corp.</h3> */}
        <p className="text-adx-darkerGray text-center mb-[16px]">You can now proceed with your payment. To secure your place, we require an upfront payment of 30% of the total fee. Please note that your grant will cover the remaining amount.</p>
      </div>

      <Button onClick={handlePayNow} variant='contained' fullWidth sx={{ minHeight: '45px', mt: '30px', mb: '10px' }} color='primary'>Pay now</Button>
    </>
  )
}

export default HRDGrantApproved