import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import { socialAuthentication } from 'services/api/authentication/index'

import { ReactComponent as Facebook } from 'assets/icons/facebook.svg'
import { ReactComponent as Google } from 'assets/icons/google.svg'
import { ReactComponent as AppleID } from 'assets/icons/apple.svg'

const mui = {
  roundBtn: {
    textTransform: 'capitalize',
    borderRadius: '100%',
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  socMedBtn: {},
  socMedBtnLg: {
    textTransform: 'capitalize',
    borderRadius: '4px',
    padding: '8px 0px',
  },
  socMedFacebookBtn: {
    backgroundColor: '#1877F2',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1877F2',
    },
    '&:focus': {
      backgroundColor: '#1877F2',
    },
  },
  socMedGoogleBtn: {
    backgroundColor: '#F2F2F2',
    color: '#787878',
    '&:hover': {
      backgroundColor: '#F2F2F2',
    },
    '&:focus': {
      backgroundColor: '#F2F2F2',
    },
  },
  socMedLinkedInBtn: {
    backgroundColor: '#2867B2',
    color: 'white',
    '&:hover': {
      backgroundColor: '#2867B2',
    },
    '&:focus': {
      backgroundColor: '#2867B2',
    },
  },
  socMedAppleBtn: {
    backgroundColor: '#000000',
    color: 'white',
    '&:hover': {
      backgroundColor: '#000000',
    },
    '&:focus': {
      backgroundColor: '#000000',
    },
  },
}

const SocialMediaButton = ({ type, socmed, redirect, variant = 'square' }) => {
  const matches = useMediaQuery('(min-width:1024px)')

  const apiSocialAuthentication = async (platform, redirect) => {
    let url
    if (redirect === 'edu') {
      url = await socialAuthentication(platform, redirect)
    } else {
      url = await socialAuthentication(platform)
    }
    window.location = url
  }

  const buttonStyle = (socmed, variant) => {
    if (variant === 'square') {
      if (!matches) {
        if (socmed === 'facebook') {
          return { ...mui.socMedBtn, ...mui.socMedFacebookBtn }
        } else if (socmed === 'google') {
          return { ...mui.socMedBtn, ...mui.socMedGoogleBtn }
        } else if (socmed === 'linkedin') {
          return { ...mui.socMedBtn, ...mui.socMedLinkedInBtn }
        } else if (socmed === 'apple') {
          return { ...mui.socMedBtn, ...mui.socMedAppleBtn }
        }
      } else {
        if (socmed === 'facebook') {
          return { ...mui.socMedBtnLg, ...mui.socMedFacebookBtn }
        } else if (socmed === 'google') {
          return { ...mui.socMedBtnLg, ...mui.socMedGoogleBtn }
        } else if (socmed === 'linkedin') {
          return { ...mui.socMedBtnLg, ...mui.socMedLinkedInBtn }
        } else if (socmed === 'apple') {
          return { ...mui.socMedBtnLg, ...mui.socMedAppleBtn }
        }
      }
    } else {
      if (socmed === 'facebook') {
        return { ...mui.roundBtn, ...mui.socMedFacebookBtn }
      } else if (socmed === 'google') {
        return { ...mui.roundBtn, ...mui.socMedGoogleBtn }
      } else if (socmed === 'linkedin') {
        return { ...mui.roundBtn, ...mui.socMedLinkedInBtn }
      } else if (socmed === 'apple') {
        return { ...mui.roundBtn, ...mui.socMedAppleBtn }
      }
    }
  }

  const renderSocialIcon = (socmed) => {
    switch (socmed) {
      case 'facebook':
        return <Facebook width={20} height={20} style={{ fill: 'white' }} />
      case 'google':
        return <Google width={20} height={20} />
      case 'linkedin':
        return <LinkedInIcon width={20} height={20} className="text-white" />
      case 'apple':
        return <AppleID width={20} height={20} style={{ fill: 'white' }} />
      default:
        return null
    }
  }

  const renderButtonText = (variant, type, socmed) => {
    if (variant === 'square') {
      const action = type === 'signup' ? 'Sign up' : 'Sign In'
      const platform = socmed === 'apple' ? 'ID' : 'LinkedIn'
      return `${action} with ${socmed !== 'linkedin' ? socmed : platform}`
    } else {
      return renderSocialIcon(socmed)
    }
  }

  return (
    <>
      {variant === 'square' ? (
        <Button
          startIcon={renderSocialIcon(socmed)}
          sx={() => buttonStyle(socmed, variant)}
          onClick={() => {
            apiSocialAuthentication(socmed, redirect)
          }}
        >
          {renderButtonText(variant, type, socmed)}
        </Button>
      ) : (
        <div
          style={buttonStyle(socmed, variant)}
          onClick={() => {
            apiSocialAuthentication(socmed, redirect)
          }}
        >
          {renderButtonText(variant, type, socmed)}
        </div>
      )}
    </>
  )
}

export default SocialMediaButton
