import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import ButtonBase from '@mui/material/ButtonBase'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div>
      <NewHeader />
      {/* <Header page={'disableBottom'} /> */}
      <div className="absolute top-0 w-full h-full bg-adx-darkGreen">
        <div className="flex flex-col items-center justify-center w-full h-full px-6 text-5xl font-bold text-adx-white lg:text-6xl">
          <div>Page Not Found</div>
          <div className="mb-5 text-xl font-medium lg:text-2xl">
            Sorry, NEX could not find the page you’re looking for
          </div>
          <Link to="/">
            <ButtonBase
              sx={{
                background: '#FCD94E',
                borderRadius: '5px',
                fontSize: '1rem',
                padding: '13px 15px',
                color: '#1B8379',
                fontWeight: '500',
                boxShadow: '2px 4px 2px rgba(0,0,0,0.1)',
                '@media (max-width:1024px)': {
                  fontSize: '1rem',
                },
              }}
            >
              Back to home
            </ButtonBase>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound
