import Link from '@mui/material/Link'
import { Link as RRDLink } from 'react-router-dom'
import AdnexioWhite from 'assets/logos/adnexio-logo-white-v2-aug.png'

const Footer = () => {
  // Auto change year
  let thisDay = new Date()
  let thisYear = thisDay.getFullYear()

  return (
    <div className="bg-black">
      <div className="mx-auto flex max-w-6xl flex-col items-center justify-center space-y-5 py-10 text-center text-sm text-adx-darkGray lg:flex-row lg:justify-between lg:px-10">
        <div className="flex flex-col items-center justify-center space-y-2">
          <Link href="#" underline="hover">
            <img src={AdnexioWhite} className="w-28 lg:w-32" alt="logo" />
          </Link>
          <div className="max-w-xs font-light">
            {`Copyright ${thisYear}`}
            <br />
            All Rights Reserved
          </div>
        </div>
        <div className="flex flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-20">
          <div className="space-y-2 lg:text-left">
            <div className="text-adx-softGreen lg:text-xl">Info</div>
            <div className="flex flex-col space-y-2 text-xs">
              <RRDLink to="/nex" sx={{ color: '#E2E2E2' }}>
                Who's NEX?
              </RRDLink>
              <RRDLink to="/faq" sx={{ color: '#E2E2E2' }}>
                FAQ
              </RRDLink>
              <RRDLink to="/terms-conditions" sx={{ color: '#E2E2E2' }}>
                Terms and Conditions
              </RRDLink>
              <RRDLink to="/privacy-policy" sx={{ color: '#E2E2E2' }}>
                Privacy Policy
              </RRDLink>
              <RRDLink to="/contact-us" sx={{ color: '#E2E2E2' }}>
                Contact Us
              </RRDLink>
            </div>
          </div>
          <div className="space-y-2 lg:text-left">
            <div className="text-adx-softGreen lg:text-xl">Stay Connected</div>
            <div className="flex flex-col space-y-2 text-xs">
              <Link
                href="https://www.facebook.com/adnexio.jobs"
                target="_blank"
                sx={{ color: '#E2E2E2' }}
                underline="hover"
              >
                Facebook
              </Link>
              <Link
                href="https://twitter.com/adnexioJobs"
                target="_blank"
                sx={{ color: '#E2E2E2' }}
                underline="hover"
              >
                Twitter
              </Link>
              <Link
                href="https://www.linkedin.com/company/adnexiojobs/"
                target="_blank"
                sx={{ color: '#E2E2E2' }}
                underline="hover"
              >
                LinkedIn
              </Link>
              <Link
                href="https://www.instagram.com/adnexio.jobs/"
                target="_blank"
                sx={{ color: '#E2E2E2' }}
                underline="hover"
              >
                Instagram
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
