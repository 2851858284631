import React from 'react'
import { Button } from '@mui/material'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';

const StartPage = ({setStart}) => {
    return (
        <div className='flex flex-col sm:w-[600px] justify-between px-[30px] min-h-[calc(100vh-74px)] pt-12 pb-10 bg-adx-white'>
            <div>
                <p className='text-xl font-medium'>Get started</p>
                <p className='mt-4 text-sm text-adx-darkerGray'>This work personality test consists of several questions.</p>
                <div className='flex flex-col gap-5 mt-8 sm:items-center sm:flex-row'>
                    <div className='w-[65px] h-[65px] aspect-square bg-adx-defaultBackground rounded-full flex justify-center items-center'>
                        <AutoFixHighIcon sx={{ color: "#1B8379" }} />
                    </div>
                    <div className='text-sm'>
                        <p className='font-medium mb-[6px]'>Answer truthfully</p>
                        <p className='text-adx-darkerGray'>Answer honestly and trust your instincts. There are no right or wrong answers</p>
                    </div>
                </div>
                <div className='flex flex-col gap-5 mt-8 mb-16 sm:items-center sm:flex-row'>
                    <div className='w-[65px] h-[65px] aspect-square bg-adx-defaultBackground rounded-full flex justify-center items-center'>
                        <TaskOutlinedIcon sx={{ color: "#1B8379" }} />
                    </div>
                    <div className='text-sm'>
                        <p className='font-medium mb-[6px]'>Check your answer before submitting</p>
                        <p className='text-adx-darkerGray'>Remember that you can always go back to the previous question and re-affirm your answer.</p>
                    </div>
                </div>
            </div>
            <Button onClick={() => setStart(true)} variant='contained' className='w-full'>Start now</Button>
        </div>
    )
}

export default StartPage