import { Button } from '@mui/material'
import icon from '../assets/grantFailed.svg'
import { useNavigate } from 'react-router-dom'

const HRDGrantRejected = ({ nex_edu_payment_schedule_id }) => {
  const navigate = useNavigate()
  const checkOtherOptions = () => {
    navigate(`/payment-summary/${nex_edu_payment_schedule_id}`)
  }
  return (
    <>
      <div className="max-w-[90%] md:max-w-[80%] mx-auto">
        <div className="flex items-center justify-center">
          <img alt='' src={icon} />
        </div>
        <h3 className="font-medium text-[24px] leading-[calc(24px * 1.5)] mt-[40px] mb-[16px] text-center">Your grant application has not been approved by HRD Corp.</h3>
        <p className="text-adx-darkerGray text-center mb-[16px]">We regret to inform you that your grant application has not been approved. Please resubmit your grant application to HRD Corp or select other payment options to proceed.</p>
      </div>

      <Button onClick={checkOtherOptions} variant='outlined' fullWidth sx={{ minHeight: '45px', mt: '30px', mb: '10px' }} color='primary'>See available options</Button>
    </>
  )
}

export default HRDGrantRejected