import { downloadQuotation } from 'services/api/hrd';
import icon from '../assets/verifiedImg.svg'
import { useState } from 'react';
import Bugsnag from '@bugsnag/js';

const HRDEligibleSuccess = ({ nex_edu_payment_schedule_id }) => {
  const [loading, setLoading] = useState(false)
  const fetchQuotation = async () => {
    try {
      setLoading(true)
      const res = await downloadQuotation(nex_edu_payment_schedule_id)
      const quotation = 'NEX-HRD-Quotation.pdf'
      const element = document.createElement('a')
      const file = new Blob([res.data], { type: 'application/pdf' })
      element.href = URL.createObjectURL(file)
      element.download = `${quotation}`
      document.body.appendChild(element)
      element.click()
    } catch (error) {
      Bugsnag.notify(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <div className="max-w-[90%] md:max-w-[80%] mx-auto">
        <div className="flex items-center justify-center">
          <img alt='' src={icon} />
        </div>
        <h3 className="font-medium text-[24px] leading-[calc(24px * 1.5)] mt-[40px] mb-[16px] text-center">Your HRD Corp grant application is eligible</h3>
        <p className="text-adx-darkerGray text-center mb-[16px]">Congratulations! Your HRD grant application is eligible, and we're thrilled for you. To help you complete the application process, we've sent you an email with all the required documents.</p>
        <p className="text-adx-darkerGray text-center">You may download your {loading ? <span className='text-adx-darkerGray'>loading...</span> : <span onClick={fetchQuotation} className="underline cursor-pointer text-adx-darkGreen">quotation here</span>}</p>
      </div>
    </>
  )
}

export default HRDEligibleSuccess