import axios, { getData } from "lib/axios"

export const academicFields = async () => {
  return axios.get("/academic_fields").then(getData)
}

export const qualifications = async () => {
  return axios.get("/qualifications").then(getData)
}

export const courses = async () => {
  return axios.get("/courses").then(getData)
}

export const currencies = async () => {
  return axios.get("/currencies'").then(getData)
}

export const grades = async () => {
  return axios.get("grades").then(getData)
}

export const countries = async () => {
  return axios.get("countries").then(getData)
}

export const universities = async () => {
  return axios.get("universities").then(getData)
}

export const jobBackgrounds = async () => {
  return axios.get("job_backgrounds").then(getData)
}

export const jobCategories = async () => {
  return axios.get("job_categories").then(getData)
}

export const jobLevel = async () => {
  return axios.get("job_levels").then(getData)
}

export const skill = async () => {
  return axios.get("skills").then(getData)
}

export const interviewQuestion = async () => {
  return axios.get("interview_questions").then(getData)
}
