import axios, { getData } from "lib/axios"

export const scoreWeight = async () => {
  return axios.get("/score_weights").then(getData)
}

export const profile = async (data) => {
  return axios.put("/profiles", data).then(getData)
}

export const jobseekerImage = async (data) => {
  return axios.post("/jobseeker_images", data).then(getData)
}

export const storeCv = async (data) => {
  return axios.post("/cvs", data).then(getData)
}

export const retrieveCv = async () => {
  return axios.get("/cvs").then(getData)
}

export const showCv = async (id) => {
  return axios.get(`cvs/${id}`).then(getData)
}

export const updateCv = async (id) => {
  return axios.put(`cvs/${id}`).then(getData)
}

export const destroyCv = async (id) => {
  return axios.delete(`cvs/${id}`).then(getData)
}

export const experiences = async (method, data, id) => {
  let requestType = method
  let url = id ? `experiences/${id}` : `/experiences`
  return axios({
    method: requestType,
    url: url,
    data: data,
  }).then(getData)
}

export const educations = async (method, data, id) => {
  let requestType = method
  let url = id ? `/educations/${id}` : `/educations`
  return axios({
    method: requestType,
    url: url,
    data: data,
  }).then(getData)
}

export const skills = async (method, data) => {
  if (method === "get") {
    return axios.get("/user-skills").then(getData)
  } else {
    return axios.post("/user-skills", data).then(getData)
  }
}

export const extracurriculars = async (method, data) => {
  let requestType = method
  return axios({
    method: requestType,
    url: "/user-extracurriculars",
    data: data,
  }).then(getData)
}

export const personalityTest = async (method, data) => {
  let requestType = method
  return axios({
    method: requestType,
    url: "/personality_tests",
    data: data,
  }).then(getData)
}

export const interviewQuestions = async (method, data) => {
  let requestType = method
  return axios({
    method: requestType,
    url: "/interview_questions",
    data: data,
  }).then(getData)
}

export const storeVideoInterview = async (data) => {
  return axios.post("/video_interviews", data).then(getData)
}

export const storeSubtitle = async (data) => {
  return axios.post("/video_interviews", data).then(getData)
}

export const videoInterviewList = async () => {
  return axios.get("/video_interviews").then(getData)
}

export const candidateProfile = async (applicationId) => {
  return axios
    .get(`/company/job_applications/applicant_profile/${applicationId}`)
    .then(getData)
}

export const candidateExperience = async (experienceId) => {
  return axios
    .get(`/company/job_applications/applicant_experience/${experienceId}`)
    .then(getData)
}

export const candidateEducation = async (educationId) => {
  return axios
    .get(`/company/job_applications/applicant_education/${educationId}`)
    .then(getData)
}
