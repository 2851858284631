import { Dialog } from '@headlessui/react'
import loadingImg from '../../personality_test/assets/loading-result.png'
import { LinearProgress } from '@mui/material'

const LoadingResultModal = ({ open, setOpen }) => {
    return (
        <Dialog open={open} className="fixed inset-0 z-50 flex items-center justify-center h-screen bg-opacity-70 bg-adx-darkText" onClose={setOpen}>
            <div className='rounded-[6.5px] bg-adx-white px-6 py-8 w-full lg:w-[500px] flex flex-col justify-between h-full lg:h-[400px]'>
                <div className='flex flex-col items-center justify-center h-full text-center'>
                    <img src={loadingImg} alt="Loading..." />
                    <p className='mt-6 text-xl font-medium text-adx-darkText'>Calculating your result..</p>
                    <p className='mt-4 text-sm text-adx-darkerGray'>Please wait a moment. We're currently analyzing your answer to determine your work personality.</p>
                </div>
                <LinearProgress variant='indeterminate'/>
            </div>    
        </Dialog>
    )
}

export default LoadingResultModal