export const getFileExtension = (filename) => {
    return filename.split('.').pop();
}

export const generateFileName = (userId, type, extension) => {
    const timeInMs = Date.now();

    if (type === "document") {
        return userId + '_' + timeInMs + '.' + extension;
    } else {
        return 'VIDEO-ID' + userId + '-' + timeInMs + '.' + extension;
    }
}