import React, {createContext, useState, useEffect} from 'react'


export const EmployerCardContext = createContext()

const EmployerCardContextProvider = ({ children }) => {

    // const [showBanner, setShowBanner] = useState(false)
    const [jobID, setJobID] = useState(null)
    const [selectedEmployer, setSelectedEmployer] = useState(null)


    useEffect(()=>{
        setSelectedEmployer(selectedEmployer)
    },
    [selectedEmployer])


    const value = {
        // showBanner, 
        // setShowBanner:(x)=>setShowBanner(x),
        jobID,
        setJobID: (x) => setJobID(x),
        selectedEmployer,
        setSelectedEmployer: (x) => { setSelectedEmployer(x) }
    }

    return (
        <EmployerCardContext.Provider value={value}>
            {children}
        </EmployerCardContext.Provider>
    )
}

export default EmployerCardContextProvider
