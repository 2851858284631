import { Close } from "@mui/icons-material"
import AdnexioEduV2 from 'assets/logos/adnexio-logo-edu-v2-aug.png'

const HRDHeader = ({ handleClose }) => {
  return (
    <>
      <header className="sticky border-b border-adx-darkGray top-0 z-20 flex w-full flex-row items-center justify-end bg-[rgba(255,255,255,0.8)] px-[24px] py-[23px] backdrop-blur">
        <div className="absolute left-1/2 -translate-x-1/2">
          <img alt="adnexio EDU" src={AdnexioEduV2} className="h-[20px] md:h-[30px]" />
        </div>
        <Close sx={{ cursor: 'pointer' }} onClick={handleClose} />
      </header>
    </>
  )
}

export default HRDHeader