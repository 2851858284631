import { useState } from 'react'
import Setting from 'pages/setting/components/Setting'

import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: '1px solid rgba(0, 0, 0, .125)',
  boxShadow: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&$expanded': {
    margin: 'auto',
  },
}))

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(() => ({
  color: 'white',
  backgroundColor: '#1B8379',
  borderBottom: '1px solid rgba(0, 0, 0, .125)',
  minHeight: '56px',
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const Faq = () => {
  const [expanded, setExpanded] = useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  function createData(creditValue, priceToPay) {
    return { creditValue, priceToPay }
  }

  const rows = [
    createData('RM 555', 'RM 500'),
    createData('RM 275', 'RM 250'),
    createData('RM 165', 'RM 150'),
  ]

  return (
    <Setting title={'FAQ'}>
      <div className="my-2 max-w-2xl space-y-8 py-4 px-10 text-sm lg:my-10">
        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">FAQ</div>
          <div>
            Before messaging us, please go through the Frequently-Asked-Questions to understand{' '}
            <b>adnexio</b> more.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">Employers</div>
          <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary>
                <div>Joining Adnexio</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-2">
                  <div>
                    <b>1. How do I join adnexio?</b>
                  </div>
                  <div>
                    You need to get a permission from your company to create an account with us.
                    Ideally, your company's adnexio account should be managed by an authorised
                    recruiter.
                  </div>
                  <div>
                    <b>2. Do I have to pay to create an account?</b>
                  </div>
                  <div>No. It is free of charge.</div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary>
                <div>Advertising Job Vacancies</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-2">
                  <div>
                    <b>1. Do I have to pay to advertise?</b>
                  </div>
                  <div>No. It is free of charge.</div>
                  <div>
                    <b>2. Do I have a limit on how many job vacancies I can advertise?</b>
                  </div>
                  <div>No. There is no limit, and you can advertise as many as you want.</div>
                  <div>
                    <b>
                      3. How long will you put each job vacancy on your system? Do you have a
                      validity period for each job vacancy advertised?
                    </b>
                  </div>
                  <div>
                    We don't specify the time limit for each job vacancy advertised on adnexio. It
                    is free, and it can be there for as long as you want it. You have the option to
                    archive a job vacancy that you previously advertised. Once archived, NEX will
                    stop matching candidates to the vacancy.
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary>
                <div>Recruitment</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-2">
                  <div>
                    <b>
                      1. I still don't understand how the system works. Can you explain what happens
                      when I advertise a vacancy?
                    </b>
                  </div>
                  <div>
                    adnexio is developed as an artificial intelligence career platform. An online
                    machine recruiter, which we call NEX, carries out the recruitment tasks. When an
                    employer advertises a vacancy, it will list down the job requirements that
                    include education background expected, work experience, salary expectation,
                    skills required, the certification that is needed, and so on. NEX will digest
                    the requirements listed by the employer and go through its database to see
                    candidates that best matched each of the requirements. NEX will suggest between
                    three to seven top candidates who matched the requirements to the employer. The
                    employer can see the online video interviews and the scores of the referred top
                    candidates.
                  </div>
                  <div>
                    <b>2. What if I like a candidate and want to interview him/her further?</b>
                  </div>
                  <div>
                    If an employer wants to interview further a candidate referred by NEX, he/she
                    can pay a referral fee (either RM10, RM30 or RM100, depending on the type of
                    referrals) to access the candidate's particulars, e.g. name, contact details
                    etc.adnexio will connect the employer and shortlisted candidates to arrange for
                    further interviews.
                  </div>
                  <div>
                    <b>
                      3. What if I am satisfied with the screening process done by NEX and want to
                      offer a candidate a job straight away? Can I do that?
                    </b>
                  </div>
                  <div>
                    Yes, you can. The same standard referral fee (either RM10, RM30 or RM100,
                    depending on the type of referrals) is applicable for an employer to access a
                    candidate's particulars, irrespective of the purpose for contacting the
                    candidate (whether to arrange for a further interview or to offer a job straight
                    away).
                  </div>
                  <div>
                    <b>4. But I thought everything is free?</b>
                  </div>
                  <div>
                    Advertising job vacancies on adnexio are free. NEX carries out the bulk of the
                    screening work to ensure only the best-fit candidates are referred to employers.
                    We believe the pre-screening done by NEX would have cut the recruitment process
                    by 80%, allowing employers to focus on onboarding good talents to harness their
                    potentials. The referral fee (either RM10, RM30 or RM100, depending on the type
                    of referrals) is minimal compared to the cost to advertise, screen and select
                    candidates that employers have to pay currently. A market estimate of the total
                    direct and indirect costs to recruit an employee (including human resource
                    personnel, recruitment, advertisement and onboarding) can range between RM3,000
                    to RM10,000 per successful recruitment (depending on the size of the company).
                  </div>
                  <div>
                    <b>
                      5. I advertise but did not get any match after a while? Is your system
                      working?
                    </b>
                  </div>
                  <div>
                    NEX matches your advertised vacancies to the best-fit candidates in our system.
                    However, the candidates matched to your vacancies must opt to apply to the
                    positions, only then NEX will recommend these candidates to you. Although NEX
                    did find matches to a vacancy, matched candidates may not be interested in
                    applying (because of mismatched expectation on salary, job requirements etc.)
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary>
                <div>Payment</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-2">
                  <div>
                    <b>1. How do I pay the referral fee?</b>
                  </div>
                  <div>
                    We accept various forms of online payment through our payment gateway platform,
                    including credit card, FPX payments, and various e-wallet services. We currently
                    do NOT accept any payments methods which are NOT listed on our payment gateway.{' '}
                    <br />
                    <br />
                    Employers also have the option to purchase adnexio credits, which can be used in
                    our candidate check-out processes. Your adnexio credits do not expire and can be
                    used to deduct from our candidate referral fees in part or in whole, at any
                    time, for no additional charges. <br />
                    <br />
                    adnexio credits can be purchased from the{' '}
                    <a className="italic" href={`${process.env.REACT_APP_ER_URL}/credit`}>
                      credit balance screen
                    </a>
                    , which is accessible from the navigation bar at the top of the{' '}
                    <a className="italic" href={`${process.env.REACT_APP_ER_URL}`}>
                      Home Page
                    </a>
                    . Your credits are linked to your registered adnexio for employers account and
                    cannot be transferred from one account to another.
                    <br />
                    <br />
                    We currently offer the following credits packages for purchase:
                    <Table style={{ maxWidth: 350 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Credit Values</TableCell>
                          <TableCell align="left">Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.creditValue}>
                            <TableCell component="th" scope="row">
                              {row.creditValue}
                            </TableCell>
                            <TableCell align="left">{row.priceToPay}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  <br />
                  <div>
                    <b>2. Can I request for a refund?</b>
                  </div>
                  <div>
                    All transactions performed on adnexio are considered final. If you are not
                    satisfied with your purchase and would like to request a refund, please submit a
                    refund request using the form listed on our Contact Us page. All refund requests
                    must be made within 14 days of the initial transaction. We will review all
                    requests on a case-by-case basis and respond to your enquiry within 10 business
                    days. Refunds may be issued either in cash or in adnexio credits. adnexio
                    reserves the right to refuse a refund according to our sole discretion.
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">Job-Seekers</div>
          <div>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary>
                <div>Joining Adnexio</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-2">
                  <div>
                    <b>1. How do I join adnexio?</b>
                  </div>
                  <div>You can create an account and go through the online interview.</div>
                  <div>
                    <b>2. Do I have to pay to create an account?</b>
                  </div>
                  <div>No. It is free of charge.</div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
              <AccordionSummary>
                <div>Data</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-2">
                  <div>
                    <b>1. Why do you collect IC number?</b>
                  </div>
                  <div>
                    We are licensed under Private Employment Agencies Act 1981 and our license is
                    only limited to job matching for Malaysian citizens. We require a candidate's IC
                    number because it is a legal requirement that we establish you are a Malaysian
                    citizen.
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
              <AccordionSummary>
                <div>Online Assessment</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-2">
                  <div>
                    <b>1. How am I being assessed?</b>
                  </div>
                  <div>
                    You are rated based on five criteria: your academic standing, the work
                    experience you have had, your leadership traits, your personality and your
                    online interview. NEX has specific algorithms to rate and rank you against the
                    other job seekers.
                  </div>
                  <div>
                    <b>
                      2. But I am a fresh graduate; surely I don't have enough work experience to
                      compete with candidates with experience?
                    </b>
                  </div>
                  <div>
                    NEX knows when you are a fresh graduate and will adjust the rating algorithms to
                    take into account that you do not have much work experience.
                  </div>
                  <div>
                    <b>
                      3. Out of curiosity, how exactly do you use a machine to interview and rate
                      people? How do I know this is not a scam?
                    </b>
                  </div>
                  <div>
                    Please read “Who's NEX” to understand machine learning and artificial
                    intelligence. NEX learns from the big data of candidates and job matches that it
                    accumulates to develop artificial intelligence that mirrors a real-life
                    recruiter.
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
              <AccordionSummary>
                <div>Job Offer</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-2">
                  <div>
                    <b>1. Do I have to do the online assessment each time I apply for a vacancy?</b>
                  </div>
                  <div>
                    No, you only do it once. NEX keeps your score and searches for a suitable
                    vacancy every day that matches your score.
                  </div>
                  <div>
                    <b>
                      2. Does that mean my career and education information will be outdated after
                      some time? What if I enrol into a course and complete the course after my
                      online assessment with NEX, how do I reflect that?
                    </b>
                  </div>
                  <div>
                    You can always log in to your account at adnexio and update your career and
                    education information. NEX will re-compute your score and update it instantly.
                  </div>
                  <div>
                    <b>3. How do I know I just got an interview offer?</b>
                  </div>
                  <div>
                    NEX is programmed to send you messages (on your account dashboard and via an
                    email) to inform you that you have been shortlisted for a vacancy by an
                    employer. You will know what the job is and all the relevant information about
                    the job (salary, location etc.). You have to indicate that you are willing to be
                    considered for the job match referred to you. When you indicate your
                    willingness, NEX can inform the employer accordingly. You will get a message
                    with details of the interview arranged with the employer. All messages are
                    system automated.
                  </div>
                  <div>
                    <b>
                      4. I don't see anywhere on my dashboard informing me of an interview? I
                      checked my email and never received an interview offer either.
                    </b>
                  </div>
                  <div>
                    If you have not got any job match on your dashboard, it means there is still no
                    suitable vacancy for you. You don't have to worry because NEX goes through
                    vacancies each day to find a match for you. At some point, you will get job
                    matches. Once you get job matches on your dashboard, you should go through the
                    vacancies and decide whether you want to apply. If you indicate your interest,
                    your profile will be pushed to the employer. Once the employer indicates
                    interest in interviewing you, NEX will push a message to your dashboard asking
                    you to pick interview slots.
                  </div>
                  <div>
                    <b>5. Do you say NEX will help increase my employability?</b>
                  </div>
                  <div>
                    In the next version of adnexio, NEX will be able to advise you why you are not
                    shortlisted for vacancies and ways to improve your score. Improving your score
                    will increase your employability because NEX goes through your score to match
                    you against available jobs.
                  </div>
                  <div>
                    <b>6. I already have a job, so should I bother joining adnexio?</b>
                  </div>
                  <div>
                    NEX goes through each profile and matches it against available vacancies. It
                    does not matter whether you are already in employment or not; once you are on
                    adnexio, NEX will refer you to available vacancies every day. It is up to you
                    whether you want to take up the job interviews referred by NEX.adnexio offers an
                    online headhunting service that is free to job seekers. If your current job does
                    not meet your expectation and NEX refers you to a better job offer, it is in
                    your best interest to consider it.
                  </div>
                  <div>
                    <b>
                      7. So, now and then I get referred to new employers who are interested in
                      hiring me?
                    </b>
                  </div>
                  <div>
                    Yes, without having to leave your room. Only one interview and one online
                    assessment to match you with a stream of offers in the future.
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </Setting>
  )
}

export default Faq
