import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import CustomHelmet from 'components/smart/CustomHelmet'
import { Box, Button, CircularProgress } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import landing from 'assets/images/video-interview-landing.png'
import completed from 'assets/images/video-interview-completed.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { videoInterviewList } from 'services/api/profile'
import { useEffect, useRef, useState } from 'react'
import Bugsnag from '@bugsnag/js'

// import ButtonBase from '@mui/material/ButtonBase'
// import Apple from 'assets/images/app-store-badge.png'
// import Android from 'assets/images/google-play-badge.png'

// const GOOGLE_PLAY = process.env.REACT_APP_GOOGLE_PLAY_URL
// const APP_STORE = process.env.REACT_APP_APPLE_STORE_URL

const mui = {
  appBtn: {
    border: '1px solid white',
    borderRadius: '5px',
    outline: 'none',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  btnContained: {
    fontWeight: '400',
    fontSize: '14px',
    width: '150px',
    height: '50px',
  },
  btnOutline: {
    fontWeight: '400',
    fontSize: '14px',
    width: '150px',
    height: '50px',
  },
  loader: {
    color: '#09A3A9',
  },
}

/**
 * VideoInterview component displays a page with two different layouts, depending
 * on whether the user has completed any video interviews. The component retrieves
 * a list of the user's video interviews via the `videoInterviewList` API. If the
 * list of video interviews is not yet retrieved, a loader is displayed. Once the
 * list of video interviews is retrieved, the page is displayed with the relevant
 * layout.
 *
 * @return {JSX.Element} The VideoInterview component
 */
const VideoInterview = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [videoList, setVideoList] = useState(null)
  const [loading, setLoading] = useState(true)
  const prevLocationRef = useRef(null)

  const handlePopstate = () => {
    if (
      prevLocationRef.current &&
      isChildPath(prevLocationRef.current.pathname, location.pathname)
    ) {
      navigate(0)
    }
  }

  useEffect(() => {
    let handler = null

    prevLocationRef.current = location

    if (location) {
      handler = (event) => handlePopstate(event)
    }

    if (handler) {
      window.addEventListener('popstate', handlePopstate)
    }

    return () => {
      if (handler) {
        window.removeEventListener('beforeunload', handler)
      }
    }
  }, [])

  // Function to check if the path is a child of another path
  const isChildPath = (parentPath, childPath) => {
    const normalizedParent = parentPath.endsWith('/') ? parentPath : parentPath + '/'
    const normalizedChild = childPath.endsWith('/') ? childPath : childPath + '/'
    console.log(normalizedParent, normalizedChild)
    return normalizedChild.startsWith(normalizedParent)
  }

  const content = [
    {
      header: 'Get better engagement to employers',
      body: 'Do a 5 mins video interview with NEX, our AI Career Coach and NEX would match you with the best jobs for you.',
      button1: 'Create now',
      navigate1: () => navigate('/video-interview/create'),
      icon1: <AddIcon sx={{ fontSize: '14px' }} />,
      button2: 'Practice',
      navigate2: () => navigate('/video-interview/practice'),
      img: landing,
    },
    {
      header: 'You have completed your video interview',
      body: 'Thank you for taking the time to create your online video interview. You can now visit your profile to watch it.',
      button2: 'View my video',
      navigate1: () => navigate('/profile', { state: { tabIndex: 2 } }),
      img: completed,
    },
  ]

  /**
   * Fetches the video interview list and sets it in the state, while also
   * setting the loading state to false. If there is an error, it is logged.
   *
   * @return {Promise<void>} Returns nothing.
   */
  const getInterviewList = async () => {
    try {
      let res = await videoInterviewList()
      setVideoList(res?.data)
    } catch (e) {
      Bugsnag.notify(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInterviewList()
  }, [])

  return (
    <>
      <div className="flex flex-col h-screen">
        <CustomHelmet
          title="Video Interview | adnexio.jobs"
          url="https://adnexio.jobs/video-interview"
        />
        <NewHeader/>
        {/* <Header page={'disableBottom'} /> */}
        {/* <div className="absolute top-0 w-full h-full bg-adx-darkGreen lg:hidden">
          <div className="flex flex-col items-center justify-center w-full h-full p-4 text-adx-white">
            <div className="text-3xl font-bold lg:text-5xl">Please login using mobile app.</div>

            <div className="mt-5 text-lg leading-7 lg:text-xl">
              Sign in using your desktop to access Video Interview feature.
            </div>

            <div className="flex flex-col mt-16 space-y-6 lg:flex-row lg:space-y-0 lg:space-x-8">
              <ButtonBase sx={mui.appBtn} href={APP_STORE} target="_blank">
                <div>
                  <img src={Apple} alt="apple" className="w-40 rounded-md lg:w-52" />
                </div>
              </ButtonBase>
              <ButtonBase sx={mui.appBtn} href={GOOGLE_PLAY} target="_blank">
                <div>
                  <img src={Android} alt="android" className="w-40 rounded-md lg:w-52" />
                </div>
              </ButtonBase>
            </div>
          </div>
        </div> */}

        <Box
          justify="center"
          alignItems="center"
          className="flex-1 grid-rows-2 bg-adx-defaultBackground"
        >
          {loading ? (
            <Box className="flex items-center justify-center w-full h-full">
              <CircularProgress sx={mui.loader} />
            </Box>
          ) : videoList ? (
            <Box className="flex flex-col items-center justify-center px-5 mt-5 lg:h-5/6 lg:flex-row-reverse">
              <img
                src={content[1].img}
                alt="landing"
                className="object-cover object-center sm:max-w-lg lg:max-w-xl xl:max-w-2xl"
              />
              <Box className=" md:px-24 lg:max-w-5xl lg:px-0 lg:pl-10">
                <p className="p-5 mx-auto text-xl font-semibold text-center text-adx-black lg:text-left lg:text-4xl">
                  {content[1].header}
                </p>
                <p className="text-center sm:px-20 lg:px-0 lg:pl-5 lg:pr-28 lg:text-left xl:pr-40">
                  {content[1].body}
                </p>

                <Box className="flex items-center justify-center pt-5 md:flex-row lg:justify-start lg:space-x-4 lg:pl-5">
                  <Button
                    sx={mui.btnOutline}
                    variant="outlined"
                    component="label"
                    onClick={content[1].navigate1}
                  >
                    {content[1].button2}
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className="flex flex-col items-center justify-center px-5 mt-5 lg:h-5/6 lg:flex-row-reverse">
              <img
                src={content[0].img}
                alt="landing"
                className="object-cover object-center sm:max-w-xl lg:max-w-2xl xl:max-w-3xl"
              />
              <Box className=" md:px-24 lg:max-w-5xl lg:px-0 lg:pl-10">
                <p className="p-5 mx-auto text-xl font-semibold text-center text-adx-black lg:text-left lg:text-4xl">
                  {content[0].header}
                </p>
                <p className="text-center sm:px-20 lg:px-0 lg:pl-5 lg:text-left xl:pr-40">
                  {content[0].body}
                </p>

                <Box className="flex flex-col items-center justify-center gap-2 pt-5 md:flex-row lg:justify-start lg:space-x-4 lg:pt-10 lg:pl-5">
                  <Button
                    sx={mui.btnContained}
                    variant="contained"
                    disableElevation
                    startIcon={content[0].icon1}
                    onClick={content[0].navigate1}
                  >
                    {content[0].button1}
                  </Button>
                  <Button
                    sx={mui.btnOutline}
                    variant="outlined"
                    component="label"
                    onClick={content[0].navigate2}
                  >
                    {content[0].button2}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </div>
    </>
  )
}

export default VideoInterview
