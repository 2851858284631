import { useContext } from 'react'
import { Navigate, useLocation, Outlet } from 'react-router-dom'
import { authContext } from 'contexts/AuthContext'
import Loader from 'react-loader-spinner'

const PrivateRoute = ({ redirectTo }) => {
  const { auth } = useContext(authContext)
  const { loading } = auth
  let isLoggedIn = auth.isAuth

  let location = useLocation()

  if (loading) {
    return (
      <>
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '999999999',
          }}
        >
          <Loader type="Oval" color="#09b7b7" height={55} width={55} />
        </div>
      </>
    )
  }

  if (isLoggedIn) {
    return <Outlet />
  } else {
    return <Navigate to={redirectTo} state={{ from: location }} />
  }
}

export default PrivateRoute
