import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import Loader from 'react-loader-spinner'
import * as Yup from 'yup'

import { login, myAccount } from 'services/api/authentication'
import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import Footer from 'components/dumb/Footer'
import SocialMediaButton from 'components/smart/SocialMediaButton'

import Button from '@mui/material/Button'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'
import adnexio_logo_white from 'assets/logos/adnexio-logo-white-v2-aug.png'
import banner_text from '../assets/banner_text.png'

const ER_URL = process.env.REACT_APP_ER_URL

const SignIn = ({ data }) => {
  let location = useLocation()
  let historyData = location.state
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const handleClose = (event, reason) => {
    if ((reason === 'clickaway') | (reason === 'backdropClick') | (reason === 'escapeKeyDown')) {
      return
    }
    setOpen(false)
  }

  const handleNavigate = () => {
    navigate('/contact-us')
  }

  useEffect(() => {
    if (data !== undefined && data?.status === 'fail') {
      setOpen(true)
    }
  }, [data])

  const [authError, setAuthError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState)
  }

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  const apiLogin = async (values, actions) => {
    setLoading(true)
    setAuthError(false)
    try {
      await login(values)
      let res = await myAccount()
      if (res.data.profile_status.photo === 0) {
        return (window.location.href = '/photo')
      } else if (res.data.profile_status.cv === 0) {
        return (window.location.href = '/cv')
      } else {
        if (historyData?.from === 'jobs/ext') {
          window.location.href = `/jobs/ext/${historyData.id}`
        } else if (historyData?.from === 'jobs/') {
          window.location.href = `/jobs/${historyData.id}`
        } else if (historyData?.from === 'courses/') {
          window.location.href = `/courses/${historyData.id}`
        } else if (historyData?.from === 'courses') {
          window.location.href = `/courses`
        } else if (historyData?.from === 'edu') {
          window.location.href = `/bootcamp`
        } else {
          return (window.location.href = '/jobs')
        }
      }
    } catch (e) {
      if (e.status === 401) {
        if (
          e.data.message ===
          'This email was used to create account with banned previously. Please proceed to sign in with banned'
        ) {
          setErrorMessage('Your account has been blocked.')
        } else {
          setErrorMessage(e.data.message)
        }
        setAuthError(true)
      } else if (e.status === 422) {
        const errors = e.data.errors
        Object.keys(errors).forEach(function (key) {
          actions.setFieldError(key, errors[key][0])
        })
      } else {
        setErrorMessage(
          'An unexpected error occurred. Please retry or contact us at support@adnexio.jobs for support.'
        )
        setAuthError(true)
      }
      setLoading(false)
    }
  }

  const initialValues = {
    verified: '',
    email: '',
    password: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Email must be a valid email').required('Please fill out this field'),
    password: Yup.string().required('Please fill out this field'),
  })

  return (
    <div>
      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}

      <div className="fixed top-0 z-50 block w-full lg:hidden">
        {/* <Header page="disableBottom" loginMode={true} /> */}
        <NewHeader/>
      </div>

      <div className="flex flex-col items-center justify-between h-screen py-20 text-white bg-adx-darkGreen lg:hidden">
        <p className="opacity-0">.</p>
        <img src={banner_text} alt="banner" className="w-2/3" />

        <LinkScroll to="anchor" spy={true} smooth={true} duration={500}>
          <div className="flex flex-col items-center justify-center w-20 h-20 border border-white rounded-full cursor-pointer">
            <ArrowDownwardIcon />
            <span>Login</span>
          </div>
        </LinkScroll>
      </div>
      <div id="anchor" className="h-screen pt-20 mx-8 lg:mx-0 lg:grid lg:grid-cols-12 lg:pt-0 ">
        <div className="flex-col hidden lg:col-span-6 lg:flex lg:h-full lg:bg-adx-darkGreen">
          {historyData?.from === 'edu' || historyData?.from === 'courses' ? (
            <Link to="/bootcamp">
              <img className="w-48 mt-8 ml-16" src={adnexio_logo_white} alt="logo" />
            </Link>
          ) : (
            <Link to="/">
              <img className="w-48 mt-8 ml-16" src={adnexio_logo_white} alt="logo" />
            </Link>
          )}
          <div className="flex flex-col items-center justify-center h-full">
            <div className="lg:mb-24 lg:text-7xl lg:text-adx-yellow">
              <img src={banner_text} alt="banner" />
            </div>
            <div className="text-center">
              <p className="mb-2 text-white">For better experience</p>
              <div className="flex justify-center gap-x-2">
                <img
                  className="rounded-lg cursor-pointer w-36"
                  src={apple_store_badges}
                  alt="app_store"
                  onClick={() => appDownload('apple')}
                />
                <img
                  className="rounded-lg cursor-pointer w-36"
                  src={google_play_badges}
                  alt="google_play"
                  onClick={() => appDownload('android')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-full col-span-6 lg:flex lg:flex-col lg:text-center">
          {historyData?.from !== 'edu' && (
            <div className="items-center hidden lg:mx-auto lg:mt-12 lg:flex lg:w-full lg:max-w-md lg:flex-row lg:justify-between lg:text-xl">
              <div className="font-semibold select-none text-adx-darkGreen">For Jobseeker</div>
              <div>|</div>
              <a href={ER_URL}>For Employer</a>
            </div>
          )}

          <div className="justify-center lg:mx-auto lg:flex lg:h-full lg:w-full lg:max-w-md lg:flex-col">
            <p className="block mt-4 text-xl text-adx-darkGreen lg:mt-0 lg:hidden ">Welcome back</p>
            <p className="hidden text-3xl lg:mb-4 lg:block lg:font-bold">
              {historyData?.from !== 'edu' && <span>Jobseeker</span>} Login
            </p>

            <div className="block mb-4 text-adx-darkerGray lg:mb-4">
              Not a member yet?{' '}
              <Link to="/signup" className="font-medium text-adx-darkGreen">
                Sign Up
              </Link>
            </div>

            <p className="block mb-2 text-center text-adx-darkerGray lg:mb-4 lg:hidden">
              Login Below
            </p>
            <div className="grid mb-4 gap-y-3">
              {['facebook', 'google', 'linkedin', 'apple'].map((item) => (
                <SocialMediaButton key={item} type="signin" socmed={item} />
              ))}
            </div>
            <p className="mb-2 text-sm text-center text-adx-darkerGray lg:mb-4">
              or login manually
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => apiLogin(values)}
            >
              {() => (
                <Form autoComplete="off">
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    className="w-full px-2 py-3 my-1 text-sm rounded bg-adx-darkGray"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="mt-1 mb-4 text-sm text-left text-red-400"
                  />

                  <div className="relative">
                    <Field name="password">
                      {({ field }) => (
                        <>
                          {showPassword ? (
                            <VisibilityOffIcon
                              className="absolute top-3.5 right-3 h-full w-6 cursor-pointer text-gray-400"
                              onClick={togglePasswordVisibility}
                            />
                          ) : (
                            <VisibilityIcon
                              className="absolute top-3.5 right-3 h-full w-6 cursor-pointer text-gray-400"
                              onClick={togglePasswordVisibility}
                            />
                          )}
                          <input
                            {...field}
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            className="w-full px-2 py-3 my-1 text-sm rounded bg-adx-darkGray"
                          />
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="mt-1 mb-4 text-sm text-left text-red-400"
                    />
                  </div>

                  {authError && (
                    <p className="mt-1 text-sm text-left text-red-400">{errorMessage}</p>
                  )}

                  <div className="flex justify-end my-2 text-sm text-adx-darkGreen">
                    <Link className="" to="/forgot-password">
                      Forgot password?
                    </Link>
                  </div>

                  <div className="text-center">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{
                        backgroundColor: '#1B8379',
                        textTransform: 'capitalize',
                        padding: '6px 20px',
                        boxShadow: 'none',
                        fontWeight: 400,
                      }}
                    >
                      Log In
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">Login Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data?.value?.replaceAll('_', ' ')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
          <Button onClick={handleNavigate}>Contact Us</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SignIn
