import axios, { getData } from 'lib/axios'

export const jobAdvertisements = async (method = 'get', data = null, id = null, page = 1) => {
  let url = id ? `/job_seekers/job_lists/${id}` : `/job_seekers/job_lists?page=${page}`
  return axios({
    method: method,
    url: url,
    data: data,
  }).then(getData)
}

export const jobAdvertisementsByCategory = async (category) => {
  return axios.get(`job_seekers/job_lists/category/${category}`).then(getData)
}

export const postJobLog = async (data) => {
  return axios.post(`/job_seekers/job_lists/log/`, data).then(getData)
}
