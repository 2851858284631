// import Header from 'components/smart/Header/view/Header'
import ButtonBase from '@mui/material/ButtonBase'
import { Link } from 'react-router-dom'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'

const CourseNotFound = () => {
  return (
    <div>
      <HeaderEdu page={'courses'} />
      <div className="absolute top-0 h-full w-full bg-adx-darkGreen">
        <div className="flex h-full w-full flex-col items-center justify-center px-6 text-5xl font-bold text-adx-white lg:text-6xl">
          <div>Course Not Found</div>
          <div className="mb-5 text-xl font-medium lg:text-2xl">
            Sorry, NEX could not find the course that you’re looking for
          </div>
          <Link to="/">
            <ButtonBase
              sx={{
                background: '#FCD94E',
                borderRadius: '5px',
                fontSize: '1rem',
                padding: '13px 15px',
                color: '#1B8379',
                fontWeight: '500',
                boxShadow: '2px 4px 2px rgba(0,0,0,0.1)',
                '@media (max-width:1024px)': {
                  fontSize: '1rem',
                },
              }}
            >
              Back to home
            </ButtonBase>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CourseNotFound
