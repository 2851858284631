import { useState, useEffect, useContext } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Loader from 'react-loader-spinner'

import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import CustomHelmet from 'components/smart/CustomHelmet'
import ShareSocialMedia from 'components/smart/ShareSocialMedia'
import { authContext } from 'contexts/AuthContext'
import { jobAdvertisements } from 'services/api/job_advertisement'
import { jobSaved } from 'services/api/job_saved'
import { selfApplied, matchApplied } from 'services/api/job_application'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import SearchIcon from '@mui/icons-material/Search'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ShareIcon from '@mui/icons-material/Share'

import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'

const mui = {
  btn1: {
    textTransform: 'capitalize',
    height: 40,
    width: '100%',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btn2: {
    textTransform: 'capitalize',
    height: 40,
    width: '100%',
    boxShadow: 'none',
    fontSize: '12px',
  },
  btnContained: {
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    fontSize: '12px',
  },
}

const JobAdsDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()

  let historyData = location.state

  const param = useParams()
  const { auth } = useContext(authContext)
  let isLoggedIn = auth.isAuth
  const [jobAdvertisement, setJobAdvertisement] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)
  const [snackProps, setSnackProps] = useState({
    severity: '',
    message: '',
  })
  const [jobSavedLists, setJobSavedLists] = useState([])
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    if (location.pathname.includes('preview')) {
      setPreview(true)
    }
  }, [location.pathname])

  useEffect(() => {
    getJobAdvertisements(param.id)
    getJobSaved()
  }, [param.id])

  const getJobAdvertisements = async (id) => {
    try {
      const response = await jobAdvertisements('get', null, id)
      setJobAdvertisement(response.data)
    } catch (e) {
      if (e.status === 404) {
        window.location.href = '/job-not-found'
      } else {
        console.log(e.data.message)
      }
    }
  }

  const getJobSaved = async () => {
    try {
      const results = await jobSaved('get', null, null)
      setJobSavedLists(results.data)
    } catch (e) {
      if (e.status === 401) {
        console.log(e.data.message)
      } else {
        console.log(e.data.message)
      }
    }
  }

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  const processSelfApplied = async (data, id) => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        const results = await selfApplied(data)
        setSnackProps({
          severity: 'success',
          message: results.message,
        })
        setOpenSnack(true)
        await getJobAdvertisements(id)
      } catch (e) {
        if (e.status === 422) {
          setSnackProps({
            severity: 'error',
            message: e.data.errors.job_advertisement_id[0],
          })
          setOpenSnack(true)
        } else if (e.status === 400) {
          setSnackProps({
            severity: 'error',
            message: 'Your score is not suitable for this job',
          })
          setOpenSnack(true)
        } else {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        }
      }
      setLoading(false)
    } else {
      navigate('/signin', { state: { from: 'jobs/', id: id } })
    }
  }

  const processMatchApplied = async (data, id) => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        const results = await matchApplied(data)
        setSnackProps({
          severity: 'success',
          message: results.message,
        })
        setOpenSnack(true)
        await getJobAdvertisements(id)
      } catch (e) {
        if (e.status === 422) {
          setSnackProps({
            severity: 'error',
            message: e.data.errors.job_advertisement_id[0],
          })
          setOpenSnack(true)
        } else if (e.status === 400) {
          setSnackProps({
            severity: 'error',
            message: 'Your score is not suitable for this job',
          })
          setOpenSnack(true)
        } else {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        }
      }
      setLoading(false)
    } else {
      navigate('/signin', { state: { from: 'jobs/', id: id } })
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setCopied(false)
  }

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }

  const processJobSaved = async (id) => {
    try {
      await jobSaved('get', null, id)
      getJobSaved()
    } catch (e) {
      console.log(e.data.message)
    }
  }

  const removedJobSaved = async (id) => {
    try {
      var savedJobid = jobSavedLists.find((item) => item.job_advertisement_id === id).id
      await jobSaved('delete', null, savedJobid)
      getJobSaved()
    } catch (e) {
      console.log(e.data.message)
    }
  }

  // Get image file extension
  let re = /(?:\.([^.]+))?$/

  return (
    <div className="h-screen">
      {jobAdvertisement.position_title !== undefined &&
        jobAdvertisement.company_name !== undefined && (
          <CustomHelmet
            title={`${jobAdvertisement.position_title} | ${jobAdvertisement.company_name}  | adnexio.jobs`}
            description={jobAdvertisement.job_description}
            url={`https://adnexio.jobs/jobs/${param.id}`}
            image={jobAdvertisement.company_logo}
          />
        )}

      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}

      <Snackbar
        open={openSnack}
        autoHideDuration={3500}
        onClose={closeSnack}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={closeSnack} severity={snackProps.severity} variant="filled">
          {snackProps.message}
        </Alert>
      </Snackbar>

      <ShareSocialMedia
        jobAdvertisement={jobAdvertisement}
        handleClose={handleClose}
        open={open}
        setCopied={setCopied}
        copied={copied}
      />

      <div>
        <NewHeader/>
        {/* <Header page="disableBottom" /> */}
      </div>

      <div className="">
        <div className="fixed top-0 flex-col items-center justify-center hidden w-5/12 h-full bg-adx-gray lg:flex">
          <div className="w-2/3">
            <div className="pl-3 mt-2 text-lg font-semibold lg:text-2xl">
              Find the right job with NEX
            </div>
            <form
              className="p-1 mt-2 rounded-4xl bg-adx-white lg:mt-5 lg:mb-24 lg:w-full lg:py-2 "
              method="GET"
              action="/jobs"
              autoComplete="off"
            >
              <SearchIcon className="mx-2" />
              <InputBase className="w-80percent" placeholder="Search" name="search" />
            </form>
            <div className="text-center">
              <p className="mb-2 text-black">For a better experience</p>
              <div className="flex justify-center gap-x-2">
                <img
                  className="rounded-lg cursor-pointer h-11 w-36"
                  src={apple_store_badges}
                  alt="apple badge"
                  onClick={() => appDownload('apple')}
                />
                <img
                  className="rounded-lg cursor-pointer h-11 w-36"
                  src={google_play_badges}
                  alt="google badge"
                  onClick={() => appDownload('android')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="inline-block w-full ">
          <div className="inline-block lg:w-5/12"></div>
          <div className="inline-block w-full px-4 lg:w-7/12">
            <div className="justify-end hidden my-4 mr-2 lg:flex">
              {!preview && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={mui.btnContained}
                  onClick={() =>
                    processSelfApplied(
                      {
                        user_employer_id: jobAdvertisement.user_id,
                        job_advertisement_id: jobAdvertisement.id,
                      },
                      param.id
                    )
                  }
                  disabled={jobAdvertisement.applied}
                >
                  {!jobAdvertisement.applied ? 'Apply Now' : 'Applied'}
                </Button>
              )}
            </div>

            {!preview && (
              <div className="grid h-12 grid-cols-12 my-4 gap-x-4 lg:hidden">
                <>
                  <div className="flex items-center h-full col-span-5">
                    <Button
                      onClick={() => handleClickOpen()}
                      sx={mui.btn1}
                      variant="contained"
                      startIcon={<ShareIcon />}
                    >
                      Share
                    </Button>
                  </div>
                  <div className="flex items-center h-full col-span-7">
                    <Button
                      onClick={() => {
                        if (historyData?.matched) {
                          processMatchApplied(
                            {
                              user_employer_id: jobAdvertisement.user_id,
                              job_advertisement_id: jobAdvertisement.id,
                            },
                            param.id
                          )
                        } else {
                          processSelfApplied(
                            {
                              user_employer_id: jobAdvertisement.user_id,
                              job_advertisement_id: jobAdvertisement.id,
                            },
                            param.id
                          )
                        }
                      }}
                      color="primary"
                      variant="contained"
                      sx={mui.btn2}
                      disabled={jobAdvertisement.applied}
                    >
                      {!jobAdvertisement.applied ? 'Apply Now' : 'Applied'}
                    </Button>
                  </div>
                </>
              </div>
            )}

            {jobAdvertisement.length !== 0 && (
              <div className="w-full min-h-screen my-4 overflow-auto border rounded-xl bg-adx-darkGray">
                <div className="box-border relative grid w-full grid-cols-3 py-4 mx-auto shadow border-xl rounded-t-md bg-adx-white">
                  <div className="grid place-items-center justify-self-center">
                    {jobAdvertisement.company_logo === null ||
                    re.exec(jobAdvertisement.company_logo)[1] === 'pdf' ? (
                      <div className="flex items-center justify-center w-20 h-20 text-3xl text-black rounded-full shadow bg-adx-white">
                        {jobAdvertisement.company_name.charAt(0)}
                      </div>
                    ) : (
                      <img
                        src={jobAdvertisement.company_logo}
                        alt="company_logo"
                        className="object-scale-down w-20 h-20 rounded-full shadow"
                      />
                    )}
                  </div>
                  <div className="flex flex-col justify-center col-span-2">
                    <p className="w-5/6 text-sm font-semibold capitalize text-adx-darkGreen lg:text-lg">
                      {jobAdvertisement.position_title}
                    </p>
                    <p className="w-5/6 text-xs font-semibold capitalize lg:text-sm">
                      {jobAdvertisement.company_name}
                    </p>
                  </div>
                  <div className="absolute hidden bottom-2 right-2 lg:block">
                    <IconButton
                      onClick={() => handleClickOpen()}
                      sx={mui.ShareIconButton}
                      size="large"
                    >
                      <ShareIcon />
                    </IconButton>
                  </div>
                  {isLoggedIn && (
                    <div className="absolute top-0 right-2 lg:block">
                      <IconButton
                        sx={mui.iconButton1}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (
                            jobSavedLists
                              .map((job) => job.job_advertisement_id)
                              .includes(jobAdvertisement.id)
                          ) {
                            removedJobSaved(jobAdvertisement.id)
                          } else {
                            processJobSaved(jobAdvertisement.id)
                          }
                        }}
                        size="large"
                      >
                        {jobSavedLists
                          .map((job) => job.job_advertisement_id)
                          .includes(jobAdvertisement.id) ? (
                          <BookmarkIcon className={'text-adx-yellow'} />
                        ) : (
                          <BookmarkBorderIcon />
                        )}
                      </IconButton>
                    </div>
                  )}
                </div>
                <div className="my-2 mt-5 space-y-1 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Job Details</p>
                  <p className="w-5/6 text-xs lg:text-sm">
                    RM{jobAdvertisement.min_salary} - RM
                    {jobAdvertisement.max_salary}
                  </p>
                  <p className="w-5/6 text-xs lg:text-sm">
                    {jobAdvertisement.city}, {jobAdvertisement.state}
                  </p>
                </div>
                <hr className="mx-4 my-5 border-1 border-adx-darkerGray" />

                <div className="my-2 space-y-2 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Job Description</p>
                  <p
                    className="text-xs text-justify whitespace-pre-line lg:text-sm"
                    style={{ lineHeight: '1.55rem' }}
                  >
                    {jobAdvertisement.job_description}
                  </p>
                </div>
                <hr className="mx-4 my-5 border-1 border-adx-darkerGray" />

                <div className="my-2 mb-8 space-y-2 px-7">
                  <p className="text-xs font-semibold lg:text-sm">Job Requirements</p>
                  <p
                    className="text-xs text-justify whitespace-pre-line lg:text-sm"
                    style={{ lineHeight: '1.55rem' }}
                  >
                    {jobAdvertisement.job_requirement}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobAdsDetails
