import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import Button from '@mui/material/Button'
import { authContext } from '../../../contexts/AuthContext'
import { generateFileName, getFileExtension } from '../../../services/helper/index'
import { signUrl } from './../../../services/api/storage/index'
import { jobseekerImage } from './../../../services/api/profile/index'
import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'
import adnexio_logo_white from 'assets/logos/adnexio-logo-white-v2-aug.png'

const mui = {
  btnText: {
    color: '#1B8379',
    fontWeight: 'bold',
    width: '100%',
    maxWidth: 320,

    textTransform: 'capitalize',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      outline: 'none',
      backgroundColor: 'none',
    },
  },
  btnContained: {
    color: '#fff',
    fontWeight: 'bold',
    width: '100%',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    padding: '8px 20px',

    '&:focus': {
      outline: 'none',
      backgroundColor: '#1B8379',
    },
    '&:hover': {
      outline: 'none',
      backgroundColor: '#1B8379',
    },
  },
  btnText2: {
    color: '#1B8379',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      outline: 'none',
      backgroundColor: 'none',
    },
  },
}

const UploadPhoto = () => {
  const navigate = useNavigate()
  const { auth, setAuthData } = useContext(authContext)

  const [files, setFiles] = useState([])
  const [photo, setPhoto] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    showError: false,
    textError: null,
  })

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview))
    return () => {
      //
    }
  }, [files])

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    maxSize: 5242880,
    onDrop: async (acceptedFiles, fileRejections) => {
      try {
        await setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )

        const extension = getFileExtension(acceptedFiles[0].name)
        const fileName = generateFileName(auth.userId, 'document', extension)

        Object.defineProperty(acceptedFiles[0], 'name', {
          writable: true,
          value: fileName,
        })

        setPhoto(acceptedFiles[0])
      } catch (e) {
        if (fileRejections[0].errors[0].code === 'file-too-large') {
          setError({
            showError: true,
            textError: 'Max photo size is 5MB',
          })
        } else if (fileRejections[0].errors[0].code === 'too-many-files') {
          setError({
            showError: true,
            textError: 'Only 1 photo is accepted',
          })
        } else {
          setError({
            showError: true,
            textError: 'An error has occured',
          })
        }
      }
    },
  })

  const closeSnackInvitation = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setError({
      showError: false,
      textError: null,
    })
  }

  const uploadPhoto = async () => {
    try {
      await setLoading(true)
      // TODO to change file name to timestamp + id
      const results = await signUrl({
        bucket: process.env.REACT_APP_AWS_BUCKET,
        key: 'profile_photo/' + photo.name,
      })
      const awsRequest = axios.create()
      delete awsRequest.defaults.headers.common['Authorization']
      await awsRequest.put(results.data.url, photo, {
        headers: {
          'Content-Type': photo.type,
        },
      })
      await jobseekerImage({ profile_image: photo.name })
      await setAuthData()
      // await setDisable(false);
      await setLoading(false)
      // await window.alert("Profile photo has been succesfully uploaded")
      await navigate('/cv')
    } catch (e) {
      alert(e)
      setLoading(false)
    }
  }

  // Preview Image
  const thumbs = files.map((file) => (
    <div className="grid flex-auto w-full h-64 min-w-0 overflow-hidden place-items-center xl:h-80 ">
      <img
        className="block w-full h-64 border-2 border-gray-300 object-fit rounded-xl xl:h-80"
        src={files[0].preview}
        alt="profile_photo"
      />
    </div>
  ))

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  return (
    <div>
      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}

      <Snackbar
        open={error.showError}
        autoHideDuration={3500}
        onClose={closeSnackInvitation}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={closeSnackInvitation} severity="error" variant="filled">
          {error.textError}
        </Alert>
      </Snackbar>

      <div className="fixed top-0 z-50 block w-full lg:hidden">
        {/* <Header page="disableBottom" loginMode={true} /> */}
        <NewHeader/>
      </div>
      <div>
        <div
          id="anchor"
          className="h-screen pt-20 pb-4 mx-8 lg:mx-0 lg:grid lg:grid-cols-12 lg:pt-0 lg:pb-0 "
        >
          <div className="flex-col hidden lg:col-span-6 lg:flex lg:h-full lg:bg-adx-darkGreen">
            <img className="w-48 mt-12 ml-4 h-9" src={adnexio_logo_white} alt="logo" />
            <div className="flex flex-col items-center justify-center h-full">
              <div className="lg:mb-24 lg:text-7xl lg:text-adx-yellow">
                <p className="font-bold text-left">
                  WELCOME
                  <br />
                  TO ADNEXIO
                </p>
                <p className="text-lg text-white">
                  Sign up with a simple two step registration process.
                </p>
              </div>

              <div className="text-center">
                <p className="mb-2 text-white">For better experience</p>
                <div className="flex justify-center gap-x-2">
                  <img
                    className="rounded-lg cursor-pointer h-11 w-36"
                    src={apple_store_badges}
                    alt="..."
                    onClick={() => appDownload('apple')}
                  />
                  <img
                    className="rounded-lg cursor-pointer h-11 w-36"
                    src={google_play_badges}
                    alt="..."
                    onClick={() => appDownload('android')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between h-full col-span-6 text-center lg:justify-start lg:py-12">
            <div className="flex flex-col lg:mb-4">
              <div className="flex items-center w-auto mx-auto text-center">
                <div className="inline-block w-20 cursor-pointer rounded-full bg-adx-darkGreen py-0.5 text-center  text-sm text-white lg:w-24 lg:px-4 lg:py-2   lg:text-sm">
                  Step 1
                </div>
                <div className="flex items-center">
                  <div className="block  h-0.5 w-8 bg-adx-darkGray text-gray-300"></div>
                  <div className="hidden h-0.5  w-8 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-8 bg-adx-darkGray text-gray-300 lg:block"></div>
                </div>
                <div className="inline-block w-20 cursor-pointer rounded-full bg-adx-darkGray py-0.5 text-center  text-sm text-white lg:w-24 lg:px-4 lg:py-2   lg:text-sm">
                  Step 2
                </div>
                <div className="flex items-center">
                  <div className="block  h-0.5 w-2 bg-adx-darkGray text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-darkGray text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-darkGray text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-darkGray text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="block w-3 h-3 text-gray-300 rounded-full bg-adx-darkGray"></div>
                </div>
              </div>

              <div className="w-full mx-auto mt-4 lg:mt-28 lg:w-80 xl:w-96">
                <p className="text-sm text-left text-adx-darkGreen lg:text-lg">
                  Add a profile photo
                </p>
                <p className="mb-2 text-xs text-left lg:text-md text-adx-darkGreen">
                  Add a photo so that people know it’s you
                </p>
                <div
                  {...getRootProps()}
                  className="relative h-64 text-center cursor-pointer rounded-3xl bg-adx-darkGray focus:outline-none xl:h-80"
                >
                  <input {...getInputProps()} />
                  {thumbs}
                  <aside></aside>
                  {!files.length && (
                    <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-adx-darkerGray">
                      <CameraAltIcon />
                      <p className="text-xs">
                        Acceptable format <br /> jpeg, png
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="text-center lg:mx-auto lg:w-80">
              <p className="mb-4 text-xs text-adx-darkGreen">
                Let employers & peers know what you look like.
              </p>
              <span className="block mb-2" {...getRootProps()}>
                <input {...getInputProps()} />
                <Button variant="contained" sx={mui.btnContained}>
                  {!files.length ? 'Add Photo' : 'Change Photo'}
                </Button>
              </span>

              {!files.length ? (
                <Button
                  onClick={() => {
                    navigate('/cv')
                  }}
                  sx={mui.btnText}
                >
                  Skip
                </Button>
              ) : (
                <div className="flex justify-between">
                  <Button
                    onClick={() => {
                      navigate('/cv')
                    }}
                    sx={mui.btnText2}
                  >
                    Skip
                  </Button>
                  <Button
                    onClick={() => {
                      uploadPhoto()
                    }}
                    sx={mui.btnText2}
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadPhoto
