import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Menu = ({children, panelComponent, fullWidth}) => {
  return (
    <Popover>
      {({ open }) => (
        <>
            <Popover.Button className={`${open ? "border-y-[2px] border-t-adx-white border-b-adx-darkGreen text-adx-darkGreen" : ""} border-y-[2px] border-adx-white hover:border-b-adx-darkGreen hover:text-adx-darkGreen flex items-center h-full capitalize pl-[10.5px] gap-[10.5px]`}>
                <p>{children}</p>
                <ExpandMoreIcon className={`${open ? "rotate-180" : ""}`} />
            </Popover.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 -translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1"
            >
                <Popover.Panel className={`${fullWidth ? "w-full left-0" : "rounded-b-[4.5px]"} absolute z-50 overflow-clip shadow-2xl`}>
                  {panelComponent}
                </Popover.Panel>
            </Transition> 
        </>
      )}
    </Popover>
  )
}

export default Menu