import { createContext, useState, useEffect } from "react"
import { jobAdvertisements } from "services/api/job_advertisement"

export const RecentJobContext = createContext()

const RecentJobContextProvider = (props) => {
  const [jobList, setJobList] = useState([])
  const [loading, setLoading] = useState(true)

  const getJobAdvertisements = async () => {
    try {
      const res = await jobAdvertisements()
      setJobList(res.data.data)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getJobAdvertisements()
  }, [])

  return (
    <RecentJobContext.Provider value={{ jobList, loading }}>
      {props.children}
    </RecentJobContext.Provider>
  )
}

export default RecentJobContextProvider
