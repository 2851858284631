import React, { useContext } from 'react'
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'

import { ProfileContext } from 'contexts/ProfileContext'
import { extracurriculars } from 'services/api/profile'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'

const mui = {
  inputCSS: {
    fontSize: '14px',
    '& textarea::placeholder': {
      fontSize: '14px',
    },
  },
  labelCSS: {
    fontSize: '14px',
  },
}

const MyTextField = ({
  placeholder,
  label,
  InputProps,
  InputLabelProps,
  className,
  rows,
  minRows,
  maxRows,
  multiline,
  type,
  ...props
}) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return (
    <TextField
      placeholder={placeholder}
      label={label}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      className={className}
      {...field}
      helperText={errorText}
      error={!!errorText}
      multiline={multiline}
      rows={rows}
      minRows={minRows}
      maxRows={maxRows}
      type={type}
      variant="outlined"
    />
  )
}

const ExtracurricularForm = ({
  extracurricularOpen,
  setExtracurricularOpen,
  extracurricularData,
  setOpenSnack,
  setSnackProps,
  loading,
  setLoading,
}) => {
  const { getExtracurricular } = useContext(ProfileContext)

  const initialValues = {
    extracurricular: extracurricularData !== null ? extracurricularData.extracurricular : '',
  }
  const validationSchema = Yup.object({
    extracurricular: Yup.string().required('This field is required'),
  })

  const updateExtracurricular = async (val, setErrors) => {
    try {
      setOpenSnack(false)
      setLoading(true)
      await extracurriculars('post', val, null)
      await getExtracurricular()
      setSnackProps({
        severity: 'success',
        message: 'Your save was successful.',
      })
      setOpenSnack(true)
    } catch (e) {
      console.log(e)
      if (e.data.errors) {
        setErrors(e.data.errors)
      }
    } finally {
      setLoading(false)
    }
  }

  window.scroll(0, 0)

  return (
    <div>
      <div className="mb-6 flex items-center justify-between">
        <div className="font-semibold">Extracurricular</div>
        <Button
          color="primary"
          startIcon={<ChevronLeftRoundedIcon />}
          onClick={() => setExtracurricularOpen(!extracurricularOpen)}
          style={{
            textTransform: 'none',
            fontFamily: 'Poppins',
            color: 'gray',
            outline: 'none',
          }}
        >
          Back
        </Button>
      </div>

      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(data, { setErrors }) => {
          updateExtracurricular(data, setErrors)
        }}
      >
        {({ dirty }) => (
          <Form autoComplete="off">
            <div className="flex flex-col space-y-6 pl-2">
              <MyTextField
                name="extracurricular"
                minRows={3}
                maxRows={15}
                multiline={true}
                label={null}
                InputProps={{ sx: mui.inputCSS }}
                InputLabelProps={{ sx: mui.labelCSS }}
                placeholder="Insert you extracurricular activity here"
              />
            </div>
            <div className="relative mt-8 flex items-center justify-center">
              <Button
                disabled={!dirty || loading}
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  textTransform: 'none',
                  fontWeight: '400',
                  fontSize: '13px',
                  padding: '5px 20px',
                  boxShadow: 'none',
                }}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ExtracurricularForm
