import { Navigate, useLocation, useNavigate } from "react-router-dom"
import HRDHeader from "../components/HRDHeader"
import { ReactComponent as StepIcon } from '../assets/stepIcon.svg'
import hrdLogo from '../assets/hrd-logo-1.svg'
import { Button } from "@mui/material"
import { useEffect } from "react"

const HRDIntro = () => {
  const navigate = useNavigate()
  const { state: nex_edu_payment_schedule_id } = useLocation()
  const steps = [
    'Fill out the form with your personal and company details, including your Company’s SSM number.',
    'Submit the completed form and wait for the adnexioedu team to contact you via email.',
    'If your company is eligible for HRD Corp claim, you will need to coordinate with your HR department to submit a grant application to HRD Corp and request an upfront payment of 30% of the total course fee from HRD Corp.',
    'Upon approval of the grant by HRD Corp, you will receive a 30% upfront payment which should be used to register for the course through adnexioedu.',
    'Upon successful payment, you will gain access to the course you have purchased.'
  ]
  const handleContinue = () => {
    navigate('/hrd/form', { state: nex_edu_payment_schedule_id })
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [nex_edu_payment_schedule_id])

  if (!nex_edu_payment_schedule_id) {
    return <Navigate to='/courses' />
  }
  return (
    <div className='min-h-screen bg-adx-defaultBackground flex flex-col'>
      <HRDHeader handleClose={() => navigate('/courses')} />
      <div className="mx-auto bg-adx-white max-w-3xl p-[30px] ">
        <div className="flex items-center justify-center">
          <img alt='hrd logo' src={hrdLogo} />
        </div>
        <h3 className="font-medium text-[20px] whitespace-pre-line leading-[calc(20px * 1.5)] mt-[30px] mb-[16px] text-center">Maximize your learning:<br /> Claim this course with HRD Corp</h3>
        <p className="text-adx-darkerGray text-center">Our course is now eligible for HRD Corp claim, providing you with an exclusive opportunity to retrain and upgrade your skills. HRD Corp Claimable Course is a  dedicated  program  designed  to  assist  employers  in  upskilling  their employees  in  line  with  their  operational  and  business  requirements. <br /><span onClick={() => {
          window.open('https://hrdcorp.gov.my/hrd-corp-claimable-course-2/')
        }} className="underline cursor-pointer text-adx-darkGreen">Learn more</span></p>
        <div className="my-[60px] flex flex-col space-y-[30px]">
          {
            steps.map((step, index) => (
              <div key={index} className="flex items-center space-x-[20px]">
                <div className="rounded-full bg-adx-defaultBackground px-[18px] py-[20px] flex items-center justify-center">
                  <StepIcon />
                </div>
                <div className="flex flex-col space-y-[6px]">
                  <p className="font-medium">{steps?.length === index + 1 ? 'Registered' : `Step ${index + 1}`}</p>
                  <p className="text-adx-darkerGray">{step}</p>
                </div>
              </div>
            ))
          }
        </div>
        <Button onClick={handleContinue} variant='contained' fullWidth sx={{ minHeight: '45px', mb: '10px' }} color='primary'>Continue</Button>
      </div>
    </div>
  )
}
export default HRDIntro