import { useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'

import { forgotPassword } from 'services/api/authentication'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import ButtonBase from '@mui/material/ButtonBase'
import Alert from '@mui/material/Alert'

import AdnexioLogo from 'assets/logos/adnexio-logo-white-v2-aug.png'
import Poster from 'assets/images/poster_one.png'
import AppStore from 'assets/images/app-store-badge.png'
import GooglePlay from 'assets/images/google-play-badge.png'
import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import Footer from 'components/dumb/Footer'

const GOOGLE_PLAY = process.env.REACT_APP_GOOGLE_PLAY_URL
const APP_STORE = process.env.REACT_APP_APPLE_STORE_URL

const mui = {
  inputCSS: {
    fontSize: '14px',
  },
  labelCSS: {
    fontSize: '14px',
  },
}

const MyTextField = ({ placeholder, label, InputProps, shrink, InputLabelProps, ...props }) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return (
    <TextField
      placeholder={placeholder}
      label={label}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      {...field}
      helperText={errorText}
      error={!!errorText}
      shrink={shrink}
      variant="filled"
      fullWidth
    />
  )
}

const ForgotPassword = () => {
  const [delivered, setDelivered] = useState(false)
  const [loading, setLoading] = useState(false)
  const snackProps = {
    severity: 'success',
    message: 'Request sent',
  }
  const [openSnack, setOpenSnack] = useState(false)
  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }

  const initialValues = {
    email: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Must be a valid email').required('Enter your email'),
  })

  const resetPassword = async (val, setErrors, resetForm) => {
    try {
      setOpenSnack(false)
      setLoading(true)
      await forgotPassword(val)
      setOpenSnack(true)
      resetForm()
      setDelivered(true)
    } catch (e) {
      setErrors({ email: e.data.message })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '999999',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}

      <Snackbar
        open={openSnack}
        autoHideDuration={3500}
        onClose={closeSnack}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={closeSnack} severity={snackProps.severity} variant="filled">
          {snackProps.message}
        </Alert>
      </Snackbar>

      <div className="lg:hidden">
        {/* <Header page="disableBottom" loginMode={true} /> */}
        <NewHeader/>
      </div>

      <div className="flex">
        <div className="relative hidden w-1/2 h-screen bg-adx-darkGreen lg:block">
          <Link to="/">
            <img className="absolute w-48 top-10 left-10" src={AdnexioLogo} alt="logo" />
          </Link>
          <div className="flex flex-col items-center justify-center h-full">
            <img src={Poster} alt="poster" />
            <div className="flex flex-col items-center mt-24 space-y-4">
              <div className="text-lg text-adx-white">For better experience</div>
              <div className="flex space-x-5">
                <ButtonBase sx={mui.appBtn} href={APP_STORE} target="_blank">
                  <div>
                    <img className="rounded-md w-36" src={AppStore} alt="appStore" />
                  </div>
                </ButtonBase>
                <ButtonBase sx={mui.appBtn} href={GOOGLE_PLAY} target="_blank">
                  <div>
                    <img className="rounded-md w-36" src={GooglePlay} alt="googlePlay" />
                  </div>
                </ButtonBase>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center p-10 mx-auto mt-6 bg-adx-white lg:mt-0 lg:h-screen lg:w-1/2 lg:p-0">
          {!delivered && (
            <>
              <div className="text-2xl font-semibold text-center lg:text-4xl">Forgot Password?</div>
              <div className="max-w-sm mt-5 text-sm text-center lg:text-base">
                Enter the email address associated with your account to receive password reset
                instruction
              </div>

              <Formik
                validateOnChange={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(data, { setErrors, resetForm }) => {
                  resetPassword(data, setErrors, resetForm)
                }}
              >
                {({ dirty }) => (
                  <Form autoComplete="off" className="flex flex-col items-center mt-16">
                    <div className="w-250 lg:min-w-400">
                      <MyTextField
                        label="Email"
                        name="email"
                        InputProps={{ sx: mui.inputCSS }}
                        InputLabelProps={{ sx: mui.labelCSS }}
                      />
                    </div>
                    <div className="mt-5 text-sm">
                      Remember password?{' '}
                      <Link to="/signin" className="font-medium text-adx-darkGreen">
                        Login
                      </Link>
                    </div>
                    <div className="mt-6 lg:mt-16">
                      <Button
                        disabled={!dirty || loading}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: 'none',
                          fontWeight: '400',
                          fontSize: '13px',
                          padding: '10px 20px',
                          boxShadow: 'none',
                        }}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}

          {delivered && (
            <>
              <div className="text-2xl font-semibold text-center lg:text-4xl">Check your email</div>
              <div className="max-w-sm mt-5 text-sm text-center lg:text-base">
                We have sent a password recovery instruction to your email
              </div>
              <div className="mt-8 lg:mt-16">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '13px',
                    padding: '10px 20px',
                    boxShadow: 'none',
                  }}
                  onClick={() => setDelivered(false)}
                >
                  Close
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ForgotPassword
