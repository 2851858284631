import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import Loader from 'react-loader-spinner'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ShareIcon from '@mui/icons-material/Share'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Alert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import Snackbar from '@mui/material/Snackbar'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Pagination from '@mui/material/Pagination'

import { EmployerCardContext } from 'contexts/EmployerCardContext'
import { authContext } from 'contexts/AuthContext'

import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import CustomHelmet from 'components/smart/CustomHelmet'
import Footer from 'components/dumb/Footer'
import ShareSocialMedia from 'components/smart/ShareSocialMedia'
import EmployerCard from '../components/EmployerCard'
import EmployerCardApplication from '../components/EmployerCardApplication'

import { selfApplied, matchApplied } from 'services/api/job_application/index'
import { jobMatchListsV2 } from 'services/api/job_match_list/index'
import { jobSaved } from 'services/api/job_saved/index'
import {
  jobApplications,
  jobinterview,
  responseToInterview,
  resheduleinterview,
} from 'services/api/job_application/index'

import bg_dummy from '../assets/bg_dummy.jpg'

const mui = {
  tab: {
    width: '100px',
    textTransform: 'capitalize',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      outline: 'none',
    },
  },
  btnText: {
    color: '#1B8379',
    fontWeight: 'bold',

    textTransform: 'capitalize',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      outline: 'none',
      backgroundColor: 'none',
    },
  },
  btnContained: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    padding: '8px 20px',
    '&:focus': {
      outline: 'none',
      backgroundColor: '#1B8379',
    },
    '&:hover': {
      outline: 'none',
      backgroundColor: '#1B8379',
    },
  },
  ShareIconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  btn1: {
    color: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    '&:hover': {
      backgroundColor: 'E2E2E2',
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
  btn2: {
    color: '#E2E2E2',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    '&:hover': {
      backgroundColor: '#1B8379',
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
  btn3: {
    color: '#FFF',
    backgroundColor: '#F6832F',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    '&:hover': {
      backgroundColor: '#F6832F',
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
  rescheduleMessage: {
    backgroundColor: '#F5F5F5',
    width: '100%',
    margin: '0 auto',
    borderRadius: 20,
    padding: '10px 10px',
  },
  btnTextInvitationCancel: {
    color: '#1B8379',
    fontSize: '1.25rem',
    textTransform: 'capitalize',
    width: '40%',
  },
  btnTextInvitationConfirm: {
    color: '#000',
    fontSize: '1.25rem',
    textTransform: 'capitalize',
    width: '40%',
  },
  pagination: {
    justifyContent: 'center',
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
  },
}

const Track = () => {
  const { auth } = useContext(authContext)
  const { jobID, setJobID, selectedEmployer } = useContext(EmployerCardContext)
  const navigate = useNavigate()
  let isLoggedIn = auth.isAuth

  const [value, setValue] = useState(0)
  const [matchingLists, setMatchingLists] = useState([])
  const [matchingListsSetting, setMatchingListsSetting] = useState()
  const [jobSavedLists, setJobSavedLists] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)
  const [snackProps, setSnackProps] = useState({
    severity: 'info',
    message: '',
  })
  const [jobApplicationLists, setJobApplicationLists] = useState([])
  const [jobResultLists, setJobResultLists] = useState([])
  const [jobInterviewLists, setJobInterviewLists] = useState([])
  const [jobInterviewListsSetting, setJobInterviewListsSetting] = useState([])
  const [openAcceptInvitation, setOpenAcceptInvitation] = useState(false)
  const [openSnackInvitation, setOpenSnackInvitation] = useState(false)
  const [snackPropsInvitation, setSnackPropsInvitation] = useState({
    severity: 'info',
    message: '',
  })
  const [propsInvitationContent, setPropsInvationContent] = useState({
    title: '',
    text: '',
    warning: '',
    status: null,
  })
  const [openReschedule, setOpenReschedule] = useState(false)
  const [msgtoEmployer, setMsgtoEmployer] = useState('')
  const [sendMsgtoEmployer, setSendMsgtoEmployer] = useState(false)

  useEffect(() => {
    getJobSaved()
    getJobMatchList()
    getJobApplicationList()
    getJobResultList()
    getJobInterviewList()
    setJobID(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleClickTab = () => {
    setJobID(null)
  }

  const handleCloseButton = () => {
    setJobID(null)
  }

  const getJobMatchList = async (page) => {
    try {
      const results = await jobMatchListsV2(page)
      setMatchingListsSetting(results.data)
      setMatchingLists(results.data.data)
    } catch (e) {
      if (e.status === 404) {
        setMatchingLists([])
      } else {
        console.error(e)
      }
    }
  }

  const getJobSaved = async () => {
    try {
      const results = await jobSaved('get', null, null)
      setJobSavedLists(results.data)
    } catch (e) {
      console.error(e)
    }
  }

  const getJobApplicationList = async () => {
    try {
      const results = await jobApplications()
      setJobApplicationLists(
        results.data.filter((item) => [1, 2, 3, 4].includes(item.job_application_status_id))
      )
    } catch (e) {
      console.error(e)
    }
  }

  const getJobResultList = async () => {
    try {
      const results = await jobApplications()
      setJobResultLists(
        results.data.filter((item) => [5, 6, 7, 8, 10].includes(item.job_application_status_id))
      )
    } catch (e) {
      console.error(e)
    }
  }

  const getJobInterviewList = async (page) => {
    try {
      const results = await jobinterview(page)
      if (results.data) {
        setJobInterviewListsSetting(results.data)
        setJobInterviewLists(results.data.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const processSelfApplied = async (data) => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        const results = await selfApplied(data)
        setSnackProps({
          severity: 'success',
          message: results.message,
        })
        setOpenSnack(true)
      } catch (e) {
        if (e.status === 422) {
          setSnackProps({
            severity: 'error',
            message: e.data.errors.job_advertisement_id[0],
          })
          setOpenSnack(true)
        } else if (e.status === 400) {
          setSnackProps({
            severity: 'error',
            message: 'Your score is not suitable for this job',
          })
          setOpenSnack(true)
        } else {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        }
      }
      setLoading(false)
    } else {
      window.location.href = '/signin'
    }
  }

  const processMatchApplied = async (data) => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        const results = await matchApplied(data)
        setSnackProps({
          severity: 'success',
          message: results.message,
        })
        setOpenSnack(true)
      } catch (e) {
        if (e.status === 422) {
          setSnackProps({
            severity: 'error',
            message: e.data.errors.job_advertisement_id[0],
          })
          setOpenSnack(true)
        } else if (e.status === 400) {
          setSnackProps({
            severity: 'error',
            message: 'Your score is not suitable for this job',
          })
          setOpenSnack(true)
        } else {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        }
      }
      setLoading(false)
    } else {
      window.location.href = '/signin'
    }
  }

  const processJobSaved = async (id) => {
    try {
      await jobSaved('get', null, id)
      getJobMatchList()
      getJobSaved()
    } catch (e) {
      console.error(e)
    }
  }

  const removedJobSaved = async (id) => {
    try {
      const savedJobid = jobSavedLists.find((item) => item.job_advertisement_id === id).id
      await jobSaved('delete', null, savedJobid)
      getJobMatchList()
      getJobSaved()
    } catch (e) {
      console.error(e)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    setCopied(false)
  }

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }

  const handleClickOpenAcceptInvitation = () => {
    setPropsInvationContent({
      title: 'Accept Invitation?',
      text: 'Do you want to accept this interview invitation?',
      warning:
        'Failing to attend your interview after accepting this invitation will negatively affect your future applications. ',
      status: 1,
    })

    setOpenAcceptInvitation(true)
  }

  const handleClickOpenDeclineInvitation = () => {
    setPropsInvationContent({
      title: 'Decline Invitation?',
      text: 'Are you sure you want to decline this interview invitation?',
      warning:
        'Declining this invitation will cause your application to be withdrawn. Consider requesting to reschedule instead if you cannot attend the interview on the suggested date.',
      status: 3,
    })

    setOpenAcceptInvitation(true)
  }

  const handleCloseAcceptInvitation = (value) => {
    setOpenAcceptInvitation(false)
  }

  const handleConfirmInvitation = async (id, status) => {
    try {
      const result = await responseToInterview(id, status)
      setSnackPropsInvitation({
        severity: 'success',
        message: result.message,
      })

      setOpenSnackInvitation(true)
      getJobInterviewList()
    } catch (e) {
      setSnackPropsInvitation({
        severity: 'error',
        message: e.data.message,
      })
      getJobInterviewList()
      setOpenSnackInvitation(true)
    }
  }

  const closeSnackInvitation = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackInvitation(false)
  }

  const handleClickOpenReschedule = () => {
    setOpenReschedule(true)
  }

  const handleClickCloseReschedule = (value) => {
    setOpenReschedule(false)
  }

  const handleReschedule = async (data) => {
    try {
      const result = await resheduleinterview(data)
      setSnackPropsInvitation({
        severity: 'success',
        message: result.message,
      })
      setOpenSnackInvitation(true)
      getJobInterviewList()
    } catch (e) {
      setSnackPropsInvitation({
        severity: 'error',
        message: e.data.message,
      })
      getJobInterviewList()
      setOpenSnackInvitation(true)
    }
    setOpenReschedule(false)
  }

  const handlePaginationMatch = (e, page) => {
    getJobMatchList(page)
    window.scrollTo(0, 0)
  }

  const handlePaginationInterview = (e, page) => {
    getJobInterviewList(page)
    window.scrollTo(0, 0)
  }

  // Get image file extension
  let re = /(?:\.([^.]+))?$/

  return (
    <div>
      <CustomHelmet title="Track | adnexio.jobs" url="https://adnexio.jobs/track" />
      <div
        className={`${!isLoggedIn && 'h-screen'} flex min-h-screen flex-col bg-adx-gray lg:h-auto `}
      >
        {loading === true && (
          <>
            <div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: '999999999',
              }}
            >
              <Loader type="Oval" color="#09b7b7" height={55} width={55} />
            </div>
            <div
              style={{
                position: 'fixed',
                zIndex: '5',
                width: '100vw',
                height: '100vh',
                background: 'black',
                opacity: '0.7',
              }}
            ></div>
          </>
        )}
        <Snackbar
          open={openSnack}
          autoHideDuration={3500}
          onClose={closeSnack}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={closeSnack} severity={snackProps.severity} variant="filled">
            {snackProps.message}
          </Alert>
        </Snackbar>

        <Snackbar
          open={openSnackInvitation}
          autoHideDuration={3500}
          onClose={closeSnackInvitation}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={closeSnack} severity={snackPropsInvitation.severity} variant="filled">
            {snackPropsInvitation.message}
          </Alert>
        </Snackbar>

        <NewHeader/>
              
        {/* <div className="z-10">
          <Header page="track" />
        </div> */}

        <div className='relative z-10 lg:p-10 bg-adx-darkGreen'>
          <div className='px-6 pt-3 pb-6'>
            <div className="max-w-lg mx-auto lg:mx-0 lg:ml-10">
              <div className="pl-3 text-base font-semibold text-adx-white lg:text-2xl">Track</div>
              <div className="pl-3 mt-2 text-xs font-light text-adx-white lg:text-base">
                Keep tracks of your future jobs.
              </div>
            </div>
          </div>
        </div>

        <ShareSocialMedia
          jobAdvertisement={selectedEmployer}
          handleClose={handleClose}
          open={open}
          setCopied={setCopied}
          copied={copied}
        />

        <Dialog
          onClose={handleCloseAcceptInvitation}
          aria-labelledby="simple-dialog-title"
          open={openAcceptInvitation}
          maxWidth="lg"
        >
          <div className="flex flex-col items-center px-8 text-center h-80 justify-evenly font-poppins lg:w-96">
            <p className="text-xl font-bold underline">{propsInvitationContent.title}</p>
            <p className="text-lg font-bold">{propsInvitationContent.text}</p>
            <p className="text-base italic font-bold text-red-700">
              {propsInvitationContent.warning}
            </p>
            <div className="flex items-center justify-between w-full">
              <Button
                onClick={() => {
                  handleCloseAcceptInvitation()
                }}
                sx={mui.btnTextInvitationCancel}
              >
                Cancel
              </Button>
              |
              <Button
                onClick={() =>
                  handleConfirmInvitation(selectedEmployer.id, propsInvitationContent.status)
                }
                sx={mui.btnTextInvitationConfirm}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Dialog>

        <Dialog
          onClose={handleClickCloseReschedule}
          aria-labelledby="simple-dialog-title"
          open={openReschedule}
          maxWidth="lg"
        >
          <div className="flex flex-col items-start px-4 text-center h-96 justify-evenly font-poppins lg:w-96">
            <p className="w-full text-xl font-bold text-center underline">Request to Reschedule?</p>
            <p className="text-base font-bold">
              Please indicate your preferred date & time, as well as your reasons for rescheduling
              the interview in the text box below.{' '}
            </p>
            <label className="flex items-start mt-2">
              <div class="relative mr-2 inline-block w-10 select-none transition duration-1000 ease-in">
                <input
                  type="checkbox"
                  id="msgtoEmp"
                  onChange={(e) => setSendMsgtoEmployer(e.target.checked)}
                  class="toggle-checkbox absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 bg-white focus:outline-none"
                />
                <label
                  for="msgtoEmp"
                  class="toggle-label block h-6 cursor-pointer overflow-hidden rounded-full bg-gray-300"
                ></label>
              </div>
            </label>
            <InputBase
              disabled={!sendMsgtoEmployer}
              multiline
              rows={5}
              placeholder="Type your message here"
              sx={mui.rescheduleMessage}
              onChange={(e) => {
                setMsgtoEmployer(e.target.value)
              }}
            />
            <div className="flex items-center justify-between w-full">
              <Button
                sx={mui.btnTextInvitationCancel}
                onClick={() => {
                  handleClickCloseReschedule()
                }}
              >
                Cancel
              </Button>
              |
              <Button
                sx={mui.btnTextInvitationConfirm}
                onClick={() => {
                  handleReschedule({
                    interview_id: selectedEmployer.id,
                    interview_status_id: 4,
                    other_remarks: msgtoEmployer,
                  })
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Dialog>

        {/* Background Photo */}
        <div className="relative flex-1">
          <div
            className={`hidden lg:absolute  lg:grid lg:min-h-screen lg:w-full lg:grid-cols-2 ${
              jobID && 'lg:h-full'
            }`}
          >
            <div className="bg-adx-gray" style={{ zIndex: -100 }}></div>
            {!jobID ? (
              <div
                className="relative -mt-12 text-white bg-cover"
                style={{ backgroundImage: `url(${bg_dummy})` }}
              >
                <div className="absolute w-full h-full bg-black opacity-60" />
                <div className="absolute grid w-full h-full text-center right place-items-center">
                  <div className="w-1/2 mx-auto">
                    <p className="text-2xl">
                      "The only way to do great work is to love what you do. If you haven't found it
                      yet, keep looking."
                    </p>
                    <p className="mt-4 text-lg font-medium">Steve Jobs</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative w-full h-full bg-white"></div>
            )}
          </div>

          <div className="h-full lg:mx-auto lg:flex lg:min-h-screen lg:max-w-screen-2xl ">
            <div className="flex flex-col min-h-full lg:w-1/2 lg:justify-self-center lg:px-16 lg:pb-8 lg:pt-4 ">
              <div className="relative max-w-full ">
                <Tabs
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab
                    label="Matched"
                    sx={mui.tab}
                    {...a11yProps(0)}
                    style={{ borderBottom: '2px solid #E2E2E2' }}
                    onClick={() => {
                      handleClickTab()
                      getJobMatchList(1)
                    }}
                  />
                  <Tab
                    label="Saved"
                    sx={mui.tab}
                    {...a11yProps(1)}
                    style={{ borderBottom: '2px solid #E2E2E2' }}
                    onClick={() => handleClickTab()}
                  />
                  <Tab
                    label="Application"
                    sx={mui.tab}
                    {...a11yProps(2)}
                    style={{ borderBottom: '2px solid #E2E2E2' }}
                    onClick={() => handleClickTab()}
                  />
                  <Tab
                    label="Interview"
                    sx={mui.tab}
                    {...a11yProps(3)}
                    style={{ borderBottom: '2px solid #E2E2E2' }}
                    onClick={() => {
                      handleClickTab()
                      getJobInterviewList(1)
                    }}
                  />
                  <Tab
                    label="Result"
                    sx={mui.tab}
                    {...a11yProps(4)}
                    style={{ borderBottom: '2px solid #E2E2E2' }}
                    onClick={() => handleClickTab()}
                  />
                </Tabs>
              </div>
              {
                // Request User to Login when anonymous
                !isLoggedIn ? (
                  <div className="flex items-center justify-center flex-1 text-center">
                    <div className="px-4 lg:px-8">
                      <p className="text-md text-adx-darkerGray lg:text-xl">
                        Your dream job is only sign-in away
                      </p>
                      <p className="mb-4 text-sm lg:text-md text-adx-darkerGray">
                        Login to view personalised job recommendations from NEX, our AI career coach
                      </p>
                      <div>
                        <Button
                          sx={mui.btnContained}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            navigate('/signin')
                          }}
                        >
                          Login{' '}
                        </Button>
                      </div>
                      <div>
                        <Button
                          sx={mui.btnText}
                          onClick={() => {
                            navigate('/signup')
                          }}
                        >
                          Sign Up{' '}
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : value === 0 && isLoggedIn ? (
                  // Matched job after login
                  <div className="px-2 mt-4 overflow-auto">
                    <div className="px-4 pb-4 rounded-xl">
                      <div className="">
                        <p className="mb-4 text-2xl font-bold text-center">Job Match </p>
                      </div>
                      {matchingLists.length ? (
                        <div>
                          {matchingLists.map((item) => (
                            <div key={item.id}>
                              <EmployerCard
                                isSaved={jobSavedLists
                                  .map((job) => job.job_advertisement_id)
                                  .includes(item.job_advertisement_id)}
                                key={item.id}
                                item={item}
                                matched={true}
                                processJobSaved={() => processJobSaved(item.job_advertisement_id)}
                                removedJobSaved={() => {
                                  removedJobSaved(item.job_advertisement_id)
                                }}
                              />
                            </div>
                          ))}
                          <div className="">
                            <Pagination
                              shape="rounded"
                              sx={mui.pagination}
                              siblingCount={0}
                              boundaryCount={1}
                              showFirstButton
                              showLastButton
                              color="primary"
                              count={matchingListsSetting?.last_page}
                              onChange={(e, page) => {
                                handlePaginationMatch(e, page)
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <p className="text-center text-adx-darkGreen">
                          You have no job match available
                        </p>
                      )}
                    </div>
                  </div>
                ) : value === 1 && isLoggedIn ? (
                  <div className="px-2 mt-4 overflow-auto">
                    <div className="px-4 pb-4 rounded-xl">
                      <div className="">
                        <p className="mb-4 text-2xl font-bold text-center">Job Saved </p>
                      </div>
                      {jobSavedLists.length ? (
                        jobSavedLists.map((item) => (
                          <div key={item.id} item={item}>
                            <EmployerCard
                              isSaved={jobSavedLists
                                .map((job) => job.job_advertisement_id)
                                .includes(item.job_advertisement_id)}
                              key={item.id}
                              item={item}
                              matched={false}
                              processJobSaved={() => processJobSaved(item.job_advertisement_id)}
                              removedJobSaved={() => {
                                removedJobSaved(item.job_advertisement_id)
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <p className="text-center text-adx-darkGreen">
                          Bookmark a job advertisement to save it for later{' '}
                        </p>
                      )}
                    </div>
                  </div>
                ) : value === 2 && isLoggedIn ? (
                  <div className="px-2 mt-4 overflow-auto">
                    <div className="px-4 pb-4 rounded-xl">
                      <div className="">
                        <p className="mb-4 text-2xl font-bold text-center">Job Applications </p>
                      </div>
                      {jobApplicationLists.length ? (
                        jobApplicationLists.map((item) => (
                          <div key={item.id} item={item}>
                            <EmployerCardApplication
                              key={item.id}
                              item={item}
                              showArrow={true}
                              tab="application"
                            />
                          </div>
                        ))
                      ) : (
                        <p className="text-center text-adx-darkGreen">
                          You have not applied for any job applications yet
                        </p>
                      )}
                    </div>
                  </div>
                ) : value === 3 && isLoggedIn ? (
                  <div className="px-2 mt-4 overflow-auto">
                    <div className="px-4 pb-4 rounded-xl">
                      <div className="">
                        <p className="mb-4 text-2xl font-bold text-center">Interview </p>
                      </div>
                      {jobInterviewLists.length ? (
                        <div>
                          {jobInterviewLists.map((item) => (
                            <div key={item.id}>
                              <EmployerCardApplication
                                key={item.id}
                                item={item}
                                showArrow={false}
                                tab="interview"
                              />
                            </div>
                          ))}
                          <div className="">
                            <Pagination
                              shape="rounded"
                              sx={mui.pagination}
                              siblingCount={0}
                              boundaryCount={1}
                              showFirstButton
                              showLastButton
                              color="primary"
                              count={jobInterviewListsSetting?.last_page}
                              onChange={(e, page) => {
                                handlePaginationInterview(e, page)
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <p className="text-center text-adx-darkGreen">
                          No interview invitation found
                        </p>
                      )}
                    </div>
                  </div>
                ) : value === 4 && isLoggedIn ? (
                  <div className="px-2 mt-4 overflow-auto">
                    <div className="px-4 pb-4 rounded-xl">
                      <div className="">
                        <p className="mb-4 text-2xl font-bold text-center">Results</p>
                      </div>
                      {jobResultLists.length ? (
                        jobResultLists.map((item) => (
                          <EmployerCardApplication
                            key={item.id}
                            item={item}
                            showArrow={false}
                            tab="result"
                          />
                        ))
                      ) : (
                        <p className="text-center text-adx-darkGreen">No results found</p>
                      )}
                    </div>
                  </div>
                ) : null
              }
            </div>
            {[0, 1, 2, 3, 4].includes(value) && jobID ? (
              <div className="flex-col hidden overflow-hidden lg:sticky lg:top-0 lg:flex lg:h-full lg:w-full lg:justify-self-center lg:px-16 lg:pb-8 lg:pt-4">
                <div className="lg:flex lg:h-12 lg:items-center lg:justify-between">
                  <Button
                    sx={mui.btnText}
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => handleCloseButton()}
                  >
                    Close
                  </Button>
                  {(value === 0 || value === 1) && (
                    <Button
                      sx={mui.btnContained}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        if (value === 0) {
                          processMatchApplied({
                            user_employer_id: selectedEmployer.user_id,
                            job_advertisement_id: selectedEmployer.job_advertisement_id,
                          })
                        } else {
                          processSelfApplied({
                            user_employer_id: selectedEmployer.user_id,
                            job_advertisement_id: selectedEmployer.job_advertisement_id,
                          })
                        }
                        getJobSaved()
                        getJobMatchList()
                        getJobApplicationList()
                        getJobResultList()
                        getJobInterviewList()
                      }}
                      disabled={selectedEmployer.applied}
                    >
                      {' '}
                      {!selectedEmployer.applied ? 'Apply Now' : 'Applied'}
                    </Button>
                  )}
                </div>
                <div className="px-4 py-4 overflow-auto h-minusTab rounded-xl bg-adx-gray lg:mt-2">
                  <div className="relative grid w-full grid-cols-3 py-4 mx-auto mb-4 rounded-md shadow-sm border-xl bg-adx-white">
                    <div className="grid place-items-center justify-self-center">
                      {selectedEmployer.company_logo === null ||
                      re.exec(selectedEmployer.company_logo)[1] === 'pdf' ? (
                        <div className="flex items-center justify-center w-20 h-20 text-3xl text-black rounded-full shadow bg-adx-white">
                          {selectedEmployer.company_name.charAt(0)}
                        </div>
                      ) : (
                        <img
                          src={selectedEmployer.company_logo}
                          alt="company_logo"
                          className="object-scale-down w-20 h-20 rounded-full shadow"
                        />
                      )}
                    </div>
                    <div className="flex flex-col justify-center col-span-2">
                      <p className="w-5/6 font-bold truncate text-adx-darkGreen">
                        {selectedEmployer.position_title}
                      </p>
                      <p className="w-5/6 text-sm font-bold truncate">
                        {selectedEmployer.company_name}
                      </p>
                    </div>
                    {[0, 1].includes(value) && (
                      <IconButton
                        onClick={() => handleClickOpen()}
                        sx={mui.ShareIconButton}
                        size="large"
                      >
                        <ShareIcon />
                      </IconButton>
                    )}
                  </div>
                  {[0, 1, 2].includes(value) && (
                    <div className="mb-4 ">
                      <p className="font-bold">Job Description</p>
                      <p
                        className="text-sm text-justify whitespace-pre-line"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {selectedEmployer.job_description}
                      </p>
                    </div>
                  )}
                  {[0, 1, 2].includes(value) && (
                    <div className="mb-4">
                      <p className="font-bold">Job Requirements</p>
                      <p
                        className="text-sm text-justify whitespace-pre-line"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {selectedEmployer.job_requirement}
                      </p>
                    </div>
                  )}
                  {[3].includes(value) && (
                    <div className="mb-4">
                      <p className="font-bold">Application Status</p>
                      <p
                        className="text-sm text-justify whitespace-pre-line"
                        style={{ lineHeight: '1.55rem' }}
                      >
                        {selectedEmployer.interview_status}
                      </p>
                    </div>
                  )}
                  {[3].includes(value) && (
                    <div className="mb-4">
                      <p className="font-bold">Schedule</p>
                      <div>
                        <CalendarTodayIcon className="mb-1 transform scale-75 lg:mb-0 lg:mr-2" />
                        <p
                          className="inline text-sm text-justify whitespace-pre-line"
                          style={{ lineHeight: '1.55rem' }}
                        >
                          {' '}
                          {moment(selectedEmployer.date).format(' DD/MM/YYYY')}
                        </p>
                      </div>
                      <div>
                        <AccessTimeIcon className="mb-1 transform scale-75 lg:mb-0 lg:mr-2" />
                        <p
                          className="inline text-sm text-justify whitespace-pre-line"
                          style={{ lineHeight: '1.55rem' }}
                        >
                          {moment(selectedEmployer.interview_time, 'hh:mm:ss').format('LT')}
                        </p>
                      </div>
                      <div>
                        <LocationOnIcon className="mb-1 transform scale-75 lg:mb-0 lg:mr-2" />
                        <p
                          className="inline text-sm text-justify whitespace-pre-line"
                          style={{ lineHeight: '1.55rem' }}
                        >
                          {selectedEmployer.address}
                        </p>
                      </div>
                    </div>
                  )}
                  {[3].includes(value) && (
                    <div className="mb-4">
                      <p className="font-bold">Notes</p>
                      <p className="text-sm text-justify whitespace-pre-line">
                        {selectedEmployer.message}
                      </p>
                    </div>
                  )}
                  {[3].includes(value) && (
                    <div className="mb-4">
                      <div className="grid w-4/5 h-12 grid-cols-12 mx-auto my-2 gap-x-4">
                        <div className="flex items-center h-full col-span-5">
                          <Button
                            onClick={() => handleClickOpenDeclineInvitation()}
                            sx={mui.btn1}
                            variant="contained"
                          >
                            Decline
                          </Button>
                        </div>
                        <div className="flex items-center h-full col-span-7">
                          <Button onClick={() => handleClickOpenAcceptInvitation()} sx={mui.btn2}>
                            Accept Invitation
                          </Button>
                        </div>
                      </div>
                      <div className="flex items-center justify-center w-4/5 mx-auto text-center ">
                        <p className="inline mr-2 text-sm whitespace-nowrap">Wish to reschedule?</p>
                        <Button onClick={() => handleClickOpenReschedule()} sx={mui.btn3}>
                          Reschedule
                        </Button>
                      </div>
                    </div>
                  )}
                  {[4].includes(value) && (
                    <div className="mb-4">
                      <p className="font-bold">Application Result</p>
                      <p
                        className={`whitespace-pre-line text-justify text-sm
                                        ${
                                          [5].includes(selectedEmployer.job_application_status_id)
                                            ? 'text-adx-darkGreen'
                                            : [6, 8].includes(
                                                selectedEmployer.job_application_status_id
                                              )
                                            ? 'text-red-700'
                                            : [7].includes(
                                                selectedEmployer.job_application_status_id
                                              )
                                            ? 'text-adx-yellow'
                                            : [10].includes(
                                                selectedEmployer.job_application_status_id
                                              )
                                            ? 'text-adx-yellow'
                                            : null
                                        }`}
                      >
                        {selectedEmployer.job_application_status_id === 5
                          ? 'Job offered'
                          : selectedEmployer.job_application_status_id === 6
                          ? 'Rejected'
                          : selectedEmployer.job_application_status_id === 7
                          ? 'No Response'
                          : selectedEmployer.job_application_status_id === 8
                          ? 'Declined'
                          : selectedEmployer.job_application_status_id === 10
                          ? 'Reject Interview'
                          : null}
                      </p>
                    </div>
                  )}
                  {[4].includes(value) && (
                    <div className="mb-4">
                      <p className="font-bold">Notes</p>
                      <p className="text-sm text-justify whitespace-pre-line">
                        {selectedEmployer.interview_message}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="hidden overflow-hidden lg:block lg:h-full lg:w-full lg:justify-self-center lg:px-16 lg:pb-8 lg:pt-4"></div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Track
