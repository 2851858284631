import React from "react"
import Setting from "pages/setting/components/Setting"

const PrivacyPolicy = () => {
  return (
    <Setting title={"Privacy Policy"}>
      <div className="text-sm max-w-2xl space-y-10 py-4 px-10 my-2 lg:my-10">
        <div>
          This Privacy Statement sets out how Agensi Pekerjaan Adnexio Sdn Bhd
          ("adnexio", "us" or "we") collect, store and handle personal
          information (as defined below) of individuals in accordance with the
          Personal Data Protection Act 2010 ("PDPA") and the laws of Malaysia.
          Please read this Privacy Statement to understand how we use the
          personal information we may collect from you. By providing your
          Personal Information to us, you are consenting to this Privacy
          Statement and the collection, use, access, transfer, storage and
          processing of your personal information as described in this Privacy
          Statement.
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            What This Privacy Statement Explains
          </div>
          <div>This Privacy Statement explains:</div>
          <ul className="list-disc list-outside ml-6 space-y-1">
            <li>What kind of personal information we collect</li>
            <li>How we collect your personal information</li>
            <li>How we use your personal information</li>
            <li>When we disclose your personal information</li>
            <li>When we transfer your personal information abroad</li>
            <li>
              Your right to access, correct, and remove your personal
              information
            </li>
          </ul>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            Our Commitment to You
          </div>
          <div>
            We value your trust and are committed to protecting your personal
            information. To ensure that you can make informed decisions and feel
            confident about supplying your Personal Information to us when using
            adnexio’s websites, Products and/or Services, we provide you with
            this Privacy Statement outlining our practices and the choices you
            have concerning how your Personal Information is being collected and
            used by us.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            Defining Personal Information
          </div>
          <div>
            Personal information refers to any information which relates
            directly or indirectly to you. This includes any information that
            can be used to distinguish, identify or contact you. For the
            purposes of this Privacy Statement, personal information encompasses
            sensitive personal information which relates to information relating
            to your health, political opinions, religious beliefs, etc. For the
            avoidance of doubt, please note that this Privacy Statement is
            applicable only if you are a natural person / an individual.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            What Kind of Personal Information We Collect
          </div>
          <div>
            In order for us to provide you with our Products and/or Services and
            to operate in an efficient and effective manner by providing you
            with the best service possible, we need to collect relevant personal
            information from you. The personal information collected by us may
            include (but is not limited to) the following:
          </div>
          <ul className="list-disc list-outside ml-6 space-y-1">
            <li>
              contact information such as your name, date of birth,
              identification supporting documents (including NRIC or passport
              number), gender, nationality and race, preferred language, current
              private and/or business address, installation address, telephone
              or mobile phone number, fax number, email address
            </li>
            <li>your credit card information and bank account details</li>
            <li>
              the status of the Products and/or Services you have acquired from
              us or subscribed to
            </li>
            <li>how you use the Products and/or Service</li>
            <li>
              the equipment you use when accessing our Products and/or Services
              (such as your mobile handset, your computer system and platform)
              to customize the service for you
            </li>
            <li>
              your personal interests and preferences to help us tailor
              offerings of our Products and/or Services which would suit you
              best
            </li>
            <li>
              clickstream data when you visit any of our websites (for example
              the pages you access and the Services and/or Products you browsed)
            </li>
          </ul>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            When and How We Collect Your Personal Information
          </div>
          <div>
            We may collect personal information directly from you when you:
          </div>
          <ul className="list-disc list-outside ml-6 space-y-1">
            <li>communicate with us</li>
            <li>
              register or subscribe for a specific Product and/or Service or our
              publications (for example, newsletters)
            </li>
            <li>
              use our network and other Products and/or Services of adnexio
            </li>
            <li>participate in any of our surveys or petitions</li>
            <li>
              enter into or participate in any competitions, contests or loyalty
              programmes run/organised by us
            </li>
            <li>
              register interest and/or request for information of (through our
              online portals or other available channels) or subscribe to our
              Products and/or Services
            </li>
            <li>respond to any marketing materials we send out</li>
            <li>visit any of our offices or booths</li>
            <li>visit or browse our websites</li>
            <li>lodge a complaint with us</li>
            <li>
              provide feedback to us (for example via our websites or in hard
              copy)
            </li>
          </ul>
          <div>
            Other than personal information obtained from you directly (as
            detailed above), we may also obtain your personal information from
            third parties we deal with or are connected with you (credit
            reference agencies or financial institutions), and from such other
            sources where you have given your express and/or implied consent for
            the disclosure of information relating to you, and/or where
            otherwise lawfully permitted.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            How We Use Your Personal Information
          </div>
          <div>
            We may collect and use personal information from you or from the
            category of third parties identified in this Privacy Statement, for
            one or more of the following purposes
          </div>
          <ul className="list-disc list-outside ml-6 space-y-1">
            <li>to verify your identity</li>
            <li>
              to assess and process your application(s) /request(s) for our
              Products and/or Services
            </li>
            <li>
              to provide you with the Products and/or Services you have
              requested
            </li>
            <li>
              to administer and manage the Products and/or Services we provide
              you (including charging, billing, facilitating payments and
              collecting debts)
            </li>
            <li>to detect and prevent fraudulent activity</li>
            <li>
              to keep in contact with you and provide you with any information
              you have requested
            </li>
            <li>
              to engage in business transactions in respect of Products and/or
              Services to be offered and provided to you
            </li>
            <li>
              to establish and better manage any business relationship we may
              have with you
            </li>
            <li>
              to process any communications you send us (for example, answering
              any queries and dealing with any complaints and feedbacks)
            </li>
            <li>
              to maintain and develop our business systems and infrastructure,
              including testing and upgrading of these systems
            </li>
            <li>to manage staff training and quality assurance</li>
            <li>
              to notify you about benefits and changes to the features of our
              Products and/or Services
            </li>
            <li>to determine how can we improve services to you</li>
            <li>
              to produce data, reports and statistics which shall be anonymised
              or aggregated in a manner that does not identify you as an
              individual
            </li>
            <li>
              to investigate, respond to, or defend claims made against, or
              involving adnexio
            </li>
            <li>
              to conduct marketing activities (for example, market research)
            </li>
            <li>
              to maintain records required for security, claims or other legal
              purposes
            </li>
            <li>to comply with legal and regulatory requirements</li>
            <li>
              for any other purposes that is required or permitted by any law,
              regulations, guidelines and/or relevant regulatory authorities
            </li>
          </ul>
          <div>
            As a part of providing you with our Products and/or Services and the
            management and/or operation of the same, we may be required or need
            to disclose information about you to the following third parties:
          </div>
          <ul className="list-disc list-outside ml-6 space-y-1">
            <li>law enforcement agencies</li>
            <li>government agencies</li>
            <li>
              companies and/or organisations that act as our agents,
              contractors, service providers and/or professional advisers
            </li>
            <li>
              companies and/or organisations that assist us in processing and/or
              otherwise fulfilling transactions and providing you with Products
              and/or Services that you have requested or subscribed for
            </li>
            <li>
              our business associates and other parties for purposes that are
              related to the purpose of collecting and using your personal
              information
            </li>
            <li>
              other parties in respect of whom you have given your express or
              implied consent
            </li>
          </ul>
          <div>
            subject at all times to any laws (including regulations, guidelines
            and/or obligations) applicable to adnexio.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            Direct Marketing
          </div>
          <div>We will not sell your data to anyone else.</div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            What If Personal Information Provided by You Is Incomplete?
          </div>
          <div>
            Where indicated (for example in registration/application forms), it
            is obligatory to provide your personal information to us to enable
            us to process your application for our Products and/or Services.
            Should you decline to provide such obligatory personal information,
            we may not be able to process your application or provide you with
            our Products and/or Services.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            Your Rights to Access and Correct Your Personal Information
          </div>
          <div>
            We can assist you to access and correct your personal information
            held by us. Where you wish to have access to your personal
            information in our possession, or where you are of the opinion that
            such personal information held by us is inaccurate, incomplete,
            misleading or where relevant, not up-to-date, you may make a request
            to us by contacting adnexio via the contact details provided below.
            You should quote your name, address and phone/account number and
            provide brief details of the information you want a copy of in any
            such request. We shall charge a processing fee for searching for and
            providing you with access to your personal information to cover
            administration costs. We will use reasonable efforts to comply with
            your request to access or correct your personal information within
            21 days of receiving your request and the relevant processing fee.
            Please note that we may have to withhold access to your personal
            information in certain situations, for example when we are unable to
            confirm your identity or where information requested for is of a
            confidential commercial nature or in the event we receive repeated
            requests for the same information. Nevertheless, we will notify you
            of the reasons for not being able to accede to your request.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            Your Right to Delete and Remove Your Account & Information
          </div>
          <div>
            You may, at any time, for any reason, request to have your employer
            or jobseeker account deleted from adnexio.jobs. This can be done by
            either contacting us via the Contact Us section of our website or by
            using the available account deletion features. Once this request is
            made, our team will respond to your request in a timely and
            reasonable fashion, and promptly inform you when the account
            deletion has been completed.
          </div>
          <div>
            When your account is deleted from adnexio, all personal information
            pertaining to you and your account will be removed from our system.
            We will not retain any information about you or your account. This
            may except any information we may have to retain for legal or
            regulatory purposes. Any deleted information is not recoverable, so
            we advise our users to make personal backups or copies of any
            important information before an account deletion is requested.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            Your Obligations
          </div>
          <div>
            You are responsible for providing accurate and complete information
            to us about yourself and any other person whose personal information
            you provide us and for updating this personal information as and
            when it becomes incorrect or out of date by contacting adnexio via
            the contact details provided below. This obligation is a condition
            to the provision of our Products and/or Services to you and/or any
            other person authorised or permitted by you or your
            organisation/company to use the Products and/or Services. In the
            event you may need to provide us with personal information relating
            to third parties (for example about your spouse or children or where
            you are the designated person in charge (from an organisation or
            company) for dealing with us, if you are acquiring and are
            responsible for a service and/or product that they will use), you
            confirm that you have (i) obtained their consent or otherwise
            entitled to provide their personal information to us and for us to
            use accordingly, and (ii) informed them to read this Privacy
            Statement at our website at adnexio.jobs .
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            Online Dealings with adnexio
          </div>

          <div className="italic">Cookies</div>
          <div>
            We collect information about your use of our websites from cookies.
            Cookies are packets of information stored in your computer which
            assist your website navigation by customizing site information
            tailored to your needs. Cookies in themselves do not identify the
            individual user, just the computer used. You are not obliged to
            accept cookies. If you are concerned, you can set your computer
            either to accept all cookies, to notify you when a cookie is issued,
            or not to receive cookies at any time. However, rejection of cookies
            may affect your use of our websites as we will be unable to
            personalize aspects of your use of the website.
          </div>

          <div className="italic">Links to Third Party Websites</div>
          <div>
            Please be informed that this Privacy Statement applies solely to
            adnexio’s website and does not apply to any third party websites you
            may access from our websites. To determine how they deal with your
            Personal Information, you should ensure that you read their
            respective privacy policies.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            Transfer of Your Personal Information Outside Malaysia
          </div>
          <div>
            We will not transfer your personal information outside Malaysia.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            Updates to Our Privacy Statement
          </div>
          <div>
            We may amend this Privacy Statement from time to time and the
            updated version shall apply and supercede any and all previous
            versions, including but not limited to, leaflets or hard copy
            versions. Please check our website for information on our most
            up-to-date practices.
          </div>
        </div>
      </div>
    </Setting>
  )
}

export default PrivacyPolicy
