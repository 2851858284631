import React, {createContext, useState, useEffect} from 'react'


export const CourseCardContext = createContext()

const CourseCardContextProvider = ({ children }) => {

    // const [showBanner, setShowBanner] = useState(false)
    const [courseID, setCourseID] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)


    useEffect(()=>{
        setSelectedCourse(selectedCourse)
    },
    [selectedCourse])


    const value = {
        // showBanner, 
        // setShowBanner:(x)=>setShowBanner(x),
        courseID,
        setCourseID: (x) => setCourseID(x),
        selectedCourse,
        setSelectedCourse: (x) => { setSelectedCourse(x) }
    }

    return (
        <CourseCardContext.Provider value={value}>
            {children}
        </CourseCardContext.Provider>
    )
}

export default CourseCardContextProvider
