/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import create from 'assets/images/video-interview-create.png'
import end from 'assets/images/video-interview-end.png'
import CustomHelmet from 'components/smart/CustomHelmet'
import HeaderInterview from 'components/smart/Header/view/HeaderInterview'
import { Box, Button, CircularProgress, LinearProgress } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import TimerIcon from '@mui/icons-material/Timer'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import VideoRecording from './VideoRecording'
import { useNavigate } from 'react-router-dom'
import { interviewQuestion } from 'services/api/dropdown_list'
import Bugsnag from '@bugsnag/js'
import { signUrl } from 'services/api/storage'
import { storeVideoInterview, videoInterviewList } from 'services/api/profile'
import moment from 'moment'
import { myAccount } from 'services/api/authentication'

const mui = {
  btnContained: {
    fontWeight: '400',
    fontSize: '14px',
    width: '100%',
    height: '50px',
  },
  btnContainedFull: {
    fontWeight: '400',
    fontSize: '14px',
    width: '100%',
    height: '50px',
  },
  btnOutline: {
    fontWeight: '400',
    fontSize: '14px',
    width: '100%',
    height: '50px',
  },
  btnOutlinedFull: {
    fontWeight: '400',
    fontSize: '14px',
    width: '100%',
    height: '50px',
  },
  loader: {
    color: '#09A3A9',
  },
}
/**
 * A React component for creating video interviews with a timer and upload functionality.
 *
 * @return {JSX.Element} A JSX element representing the video interview creation.
 * @throws {Error} if the API call returns an error with status 500.
 */
function VideoInterviewCreate() {
  const navigate = useNavigate()

  const [state, setState] = useState({
    id: null,
    initialLoading: true,
    videoStart: false,
    continueState: false,
    startInterview: false,
    changeLayer: false,
    closeLayer: false,
    nextQuestion: false,
    finalLayer: false,
    interviewQuestions: [],
    questionNumber: 0,
    blob: null,
    uploadLoading: true,
    finishUpload: true,
    stopVideo: false,
    // retake: 0,
  })
  const [countdown, setCountdown] = useState()

  /**
   * A React component for displaying a timer.
   *
   * @param {Object} props - The props object.
   * @param {number} props.timeLeft - The time left in seconds.
   * @return {JSX.Element} A JSX element representing the timer.
   */
  const Timer = ({ timeLeft }) => {
    const minuteInterval = Math.floor((timeLeft / 60) % 60)
    const secondsInterval = Math.floor(timeLeft % 60)

    const addZeroesIfSingleInteger = (n) => {
      return (n < 10 ? '0' : '') + n
    }

    return (
      <p className={`w-[60px] ${countdown > 10 ? '' : 'text-adx-error'}`}>
        0{minuteInterval}:{addZeroesIfSingleInteger(secondsInterval)}
      </p>
    )
  }

  /**
   * Loads the required API resources asynchronously, sets the state with the
   * obtained account, questions, and video interview information.
   *
   * @throws {Error} if the API call returns an error with status 500
   */
  const loadApi = async () => {
    try {
      const questions = await interviewQuestion()
      const results = await myAccount()
      // const videoCount = await videoInterviewList()
      setState({
        ...state,
        initialLoading: false,
        interviewQuestions: questions.data,
        id: results.data.id,
        // retake: videoCount.data.length,
      })
    } catch (error) {
      if (error.status === 500) {
        Bugsnag.notify(error.data)
      }
    }
  }

  useEffect(() => {
    loadApi()
  }, [])

  useEffect(() => {
    let interval = null

    if (countdown) {
      interval = setInterval(() => {
        setCountdown((secondsTemp) => secondsTemp - 1)
      }, 1000)
    }

    if (countdown === 0) {
      clearInterval(interval)
    }

    return () => {
      clearInterval(interval)
    }
  }, [countdown])

  /**
   * Uploads video with a generated name based on state id and current timestamp.
   * Calls uploadVideo function to handle the actual upload.
   *
   * @return {Promise<void>} - Promise that resolves when the video upload is complete
   */
  const onSubmit = async () => {
    const name = `VIDEO-ID${state.id}-${moment().format('YYYYMD-Hms')}.mp4`
    await uploadVideo(name)
  }

  /**
   * Uploads a video to the AWS S3 bucket using the provided video name.
   *
   * @async
   * @param {string} name - The name of the video file to upload
   */
  const uploadVideo = async (name) => {
    setState({ ...state, finishUpload: false })
    await signUrl({ bucket: process.env.REACT_APP_AWS_BUCKET, key: 'video_interview/' + name })
      .then((responseOne) => {
        fetch(responseOne.data.url, { method: 'PUT', body: state.blob })
          .then(async (responseTwo) => {
            await storeVideoInterview({ video_name: name, video_duration: 300 })
              .then((responseThree) => {
                if (responseThree.success === true) {
                  setState({
                    ...state,
                    uploadLoading: false,
                    startInterview: false,
                    continueState: false,
                    finishUpload: true,
                  })
                }
              })
              .catch((errorThree) => {
                console.log(errorThree)
              })
          })
          .catch((errorTwo) => {
            console.log(errorTwo)
          })
      })
      .catch((errorOne) => {
        console.log(errorOne)
      })
  }

  /**
   * Handling alert before unload
  //  */
  const handleBeforeUnload = (event) => {
    event.preventDefault()
    event.returnValue = ''
  }

  useEffect(() => {
    let handler = null
    if (state.startInterview) {
      handler = (event) => handleBeforeUnload(event)
    }

    if (handler) window.addEventListener('beforeunload', handler)

    return () => {
      if (handler) window.removeEventListener('beforeunload', handler)
    }
  }, [state.startInterview])

  return (
    <>
      <Box className="flex h-screen flex-col ">
        <CustomHelmet
          title="Video Interview | adnexio.jobs"
          url="https://adnexio.jobs/video-interview"
        />
        <HeaderInterview startInterview={state.startInterview} />
        <Box className="flex h-full flex-row bg-adx-defaultBackground sm:items-center sm:justify-center">
          {state.continueState ? (
            // Component to start the practice
            <Box className="flex w-full flex-col bg-adx-white lg:my-5 lg:grid lg:max-w-7xl lg:grid-cols-5 lg:flex-row">
              {/* Left/Top Column */}
              <VideoRecording
                setParentState={setState}
                parentState={state}
                parentCountdown={countdown}
                parentSetCountdown={setCountdown}
              />
              {/* Right/Bottom Column */}
              <Box
                className={`flex h-full flex-col items-center gap-5 lg:col-span-2 lg:items-center lg:justify-center lg:justify-around ${
                  !state.videoStart ? 'lg:space-y-80' : 'lg:space-y-80'
                }`}
              >
                {/* Control the component on top right */}
                {!state.videoStart ? (
                  <Box className="mt-5 flex flex-col items-center justify-center gap-3 px-5 text-center lg:w-full lg:items-start lg:text-start">
                    <p className="text-2xl font-medium text-adx-darkText">Getting ready...</p>
                    <p className=" text-sm font-normal text-adx-darkText">
                      Your session will be ready in just a few moment
                    </p>
                  </Box>
                ) : !state.changeLayer ? (
                  <Box className="mt-5 flex flex-col gap-3 px-5 text-center lg:w-full lg:text-start">
                    <p className="text-2xl font-medium text-adx-darkText">
                      Start your recording once you are ready
                    </p>
                    <p className="text-sm font-normal text-adx-darkText">
                      You will have one minute to answer each of the five questions, for a total of
                      five minutes.
                    </p>
                    <p className="text-sm font-normal text-adx-darkText">Goodluck!</p>
                  </Box>
                ) : !state.finalLayer ? (
                  <Box className="mt-5 flex flex-col gap-3 px-5 text-center lg:w-full lg:text-start">
                    <p className="text-sm">Question {state.questionNumber + 1} out of 5</p>
                    <p className="text-2xl font-medium">
                      {state.interviewQuestions[state.questionNumber]?.question}
                    </p>
                  </Box>
                ) : (
                  <Box className="mt-5 flex flex-col gap-3 px-5 text-center lg:w-full lg:text-start">
                    <p className="text-2xl font-medium text-adx-darkText">
                      Submit now and be recognized!
                    </p>
                    <p className="text-sm font-normal text-adx-darkText">
                      Satisfied with your video? Submit it now for AI scoring and the opportunity to
                      be recognized by our top employers.
                    </p>
                  </Box>
                )}

                {/* Control the component on bottom right */}
                {!state.startInterview ? (
                  <Box className="w-full px-5">
                    <Button
                      sx={mui.btnContained}
                      disabled={!state.videoStart}
                      variant="contained"
                      disableElevation
                      startIcon={
                        <FiberManualRecordIcon
                          sx={{
                            color: state.videoStart ? 'red' : 'gray',
                            width: '12px',
                            height: '12px',
                            backgroundColor: 'white',
                            borderRadius: '100%',
                          }}
                        />
                      }
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          startInterview: true,
                          changeLayer: true,
                        }))
                        setCountdown(65)
                      }}
                    >
                      Start Recording
                    </Button>
                  </Box>
                ) : !state.closeLayer ? (
                  <Box className="flex h-[50px] w-full flex-col justify-center gap-3 px-5  text-center lg:text-start">
                    <p className="text-sm font-medium text-adx-lightGreen">
                      Starting your video...
                    </p>
                    <LinearProgress color="primary" />
                  </Box>
                ) : (
                  <Box className="flex w-full gap-2 px-5">
                    {!state.finalLayer && (
                      <>
                        <Box className="flex w-[85px] items-center justify-center">
                          <TimerIcon sx={{ color: countdown > 10 ? 'blue' : 'red' }} />
                          <Timer timeLeft={countdown} />
                        </Box>
                      </>
                    )}

                    {state.questionNumber !== state.interviewQuestions.length - 1 ? (
                      <Button
                        sx={mui.btnOutline}
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            questionNumber: prevState.questionNumber + 1,
                            nextQuestion: !prevState.nextQuestion,
                          }))
                          setCountdown(65)
                        }}
                        endIcon={<ArrowForwardIcon />}
                        variant="outlined"
                        disableElevation
                      >
                        Next Question
                      </Button>
                    ) : !state.finalLayer ? (
                      <Button
                        sx={mui.btnContained}
                        onClick={() => {
                          setCountdown(null)
                        }}
                        startIcon={
                          <VideocamOffIcon
                            sx={{
                              color: 'white',
                            }}
                          />
                        }
                        variant="contained"
                        disableElevation
                      >
                        Finish Recording
                      </Button>
                    ) : (
                      <Box className="flex w-full flex-col items-center gap-5">
                        {state.finishUpload ? (
                          <Button
                            sx={mui.btnContainedFull}
                            onClick={() => onSubmit()}
                            variant="contained"
                            disableElevation
                          >
                            Submit my video
                          </Button>
                        ) : (
                          // Prittier push this downward
                          // {state.retake < 2 && (
                          //    <Button
                          //      sx={mui.btnContainedFull}
                          //      onClick={() => window.location.reload()}
                          //      variant="outlined"
                          //      disableElevation
                          //    >
                          //      Retake (1 attempt left)
                          //    </Button>
                          //  )}
                          <CircularProgress sx={mui.loader} />
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          ) : state.uploadLoading ? (
            // Component before starting the practice
            <Box className="flex h-full max-w-5xl flex-col items-center justify-center gap-5 bg-adx-white px-5">
              <img src={create} alt="create" className="object-contain object-center sm:max-w-xl" />
              <Box className="flex max-w-5xl flex-col gap-3 sm:max-w-xl lg:max-w-2xl xl:max-w-3xl">
                <p className="text-adx-black mx-auto text-center text-xl font-medium lg:text-2xl">
                  Create your online video interview
                </p>
                <p className="flex text-center text-sm font-normal text-adx-darkerGray">
                  You will be answering 5 interview questions related to your personal and
                  professional background. NEX will evaluate your behavior from the video you’ve
                  recorded and score your performance. Let’s get started!
                </p>
              </Box>
              <Button
                sx={mui.btnContained}
                variant="contained"
                disableElevation
                onClick={() => setState({ ...state, continueState: true })}
                disabled={state.initialLoading}
              >
                Continue
              </Button>
            </Box>
          ) : (
            <Box className="flex h-full max-w-5xl flex-col items-center justify-center gap-5 bg-adx-white px-5 lg:justify-start">
              <img
                src={end}
                alt="end"
                className="object-cover object-center sm:max-w-xl lg:max-w-2xl xl:max-w-3xl"
              />
              <Box className="flex max-w-5xl flex-col gap-3 sm:max-w-xl lg:mt-10 lg:max-w-2xl xl:max-w-3xl">
                <p className="text-adx-black mx-auto text-center text-xl font-medium lg:text-2xl">
                  Congratulations!
                  <br /> You have created your video interview
                </p>

                <p className="mx-auto text-center text-sm font-normal text-adx-darkerGray">
                  You may proceed by clicking the button below to check your video interview!
                </p>
              </Box>
              <Box className="w-full lg:flex lg:h-full lg:items-end lg:pb-20">
                <Button
                  sx={mui.btnOutlinedFull}
                  variant="outlined"
                  disableElevation
                  onClick={() => navigate('/profile', { state: { tabIndex: 2 } })}
                  disabled={state.initialLoading}
                >
                  View my video
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default VideoInterviewCreate
