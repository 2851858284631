import ButtonBase from '@mui/material/ButtonBase'
import { Link } from 'react-router-dom'

const JobCard = ({ job }) => {
  // Get image file extension
  let re = /(?:\.([^.]+))?$/

  return (
    <ButtonBase
      key={job?.id}
      sx={{
        borderRadius: '0.5rem',
        '&:focus': {
          outline: 'none',
          boxShadow: 'none',
        },
      }}
    >
      <Link to={`/jobs/${job.id}`}>
        <div className="flex w-max items-center space-x-3 rounded-lg bg-adx-white py-3 px-3 shadow lg:px-6 lg:py-4">
          <div className="col-span-4 grid place-items-center justify-self-center lg:col-span-3">
            {job?.company_logo === null || re.exec(job?.company_logo)[1] === 'pdf' ? (
              <div className="flex h-16 w-16 items-center justify-center rounded-full bg-adx-white text-3xl text-black shadow">
                {job?.company_name.charAt(0)}
              </div>
            ) : (
              <img
                src={job?.company_logo}
                alt="company_logo"
                className="h-16 w-16 rounded-full object-scale-down shadow"
              />
            )}
          </div>
          <div className="w-44 text-left">
            <div className="truncate text-sm font-medium capitalize text-adx-darkGreen">
              {job?.position_title}
            </div>
            <div className="mb-2 truncate text-xs font-semibold capitalize">
              {job?.company_name}
            </div>
            <div className="text-xs">{`RM${job?.min_salary} - RM${job?.max_salary}`}</div>
            <div className="truncate text-xs capitalize">{`${job?.city.replace(',', '')}, ${
              job?.state
            }`}</div>
          </div>
        </div>
      </Link>
    </ButtonBase>
  )
}

export default JobCard
