import React, { useState, useEffect, useRef } from 'react'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'

import { makeStyles } from '@mui/styles'

import { qualifications } from 'services/api/dropdown_list'

import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'

const useStyles = makeStyles({
  searchBtn: {
    fontSize: '13px',
    fontWeight: '500',
    textTransform: 'none',
    fontFamily: 'Poppins',
    background: '#FCCE4E',
    color: '#000000',
    borderRadius: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  filterBtn: {
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  slider: {
    color: '#1B8379',
  },
  popper: {
    borderRadius: '25px',
    boxShadow: '1px 1px 5px rgba(0,0,0,0.3)',
  },
  clearAllBtn: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    textTransform: 'none',
    fontWeight: 'normal',
    color: '#979797',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
})

const SearchFilter = (props) => {
  const classes = useStyles()

  const buttonRef = useRef()

  const [qualificationList, setQualificationList] = useState([])

  // FILTER BUTTON HANDLER
  const [anchorEl, setAnchorEl] = useState(null)

  const openFilter = Boolean(anchorEl)

  const filterShow = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const filterClose = () => {
    setAnchorEl(null)
  }

  // WORK LOCATION HANDLER
  const locations = [
    'Johor',
    'Kedah',
    'Kelantan',
    'Melaka',
    'Negeri Sembilan',
    'Pahang',
    'Pulau Pinang',
    'Perak',
    'Perlis',
    'Sabah',
    'Sarawak',
    'Selangor',
    'Terengganu',
    'Kuala Lumpur',
    'Labuan',
    'Putrajaya',
  ]

  const [location, setLocation] = useState([])

  const handleLocation = (e) => {
    setLocation(e.target.value)
  }

  // QUALIFICATION HANDLER
  const [qualification, setQualification] = useState('')

  const handleQualification = (e) => {
    setQualification(e.target.value)
  }

  // SALARY RANGE HANDLER
  const [salary, setSalary] = useState([0, 20000])

  const handleSalary = (e, val) => {
    setSalary(val)
  }

  // CLEAR ALL HANDLER
  const handleClearAll = () => {
    setLocation([])
    setQualification('')
    setSalary([0, 20000])
  }

  // API
  const getQualificationList = async () => {
    try {
      const res = await qualifications()
      setQualificationList(res.data)
      // console.log(res.data)
    } catch (e) {
      console.log('Qualifications error', e)
    } finally {
      // setLoading(false)
    }
  }

  useEffect(() => {
    getQualificationList()
  }, [])

  // MENU DROPDOWN PADDING
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  }

  // TO DISABLED FILTER BUTTON
  let locationDisabled =
    location.length === 0 && qualification === '' && salary[0] === 0 && salary[1] === 20000
      ? true
      : false

  return (
    <div className="font-poppins">
      <IconButton onClick={filterShow} className={classes.filterBtn} size="large">
        <FilterListRoundedIcon className="text-adx-darkGreen" />
      </IconButton>

      <Popover
        open={openFilter}
        anchorEl={anchorEl}
        onClose={filterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ classes: { root: classes.popper } }}
      >
        <form method="GET" action="/jobs" autoComplete="off">
          <div className="flex flex-col space-y-8 py-10 px-8">
            <div className="flex justify-between font-poppins">
              <div className="font-medium text-adx-darkGreen">Filters</div>
              <Button className={classes.clearAllBtn} onClick={handleClearAll}>
                Clear all
              </Button>
            </div>

            <div className="flex flex-col items-center justify-center  space-y-2 lg:flex-row lg:space-y-0 lg:space-x-8">
              <div>
                <input type="hidden" value={props.paramSearch} name="search" />

                <div>Work Location</div>
                <div className="w-64 lg:w-44">
                  <Select
                    variant="standard"
                    className="w-full"
                    multiple
                    value={location}
                    onChange={handleLocation}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    name="location">
                    {locations.map((item) => (
                      <MenuItem key={item} value={item}>
                        <Checkbox checked={location.indexOf(item) > -1} color="primary" />
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              <div>
                <div>Qualification</div>
                <div className="w-64 lg:w-44">
                  <Select
                    variant="standard"
                    className="w-full"
                    value={qualification}
                    onChange={handleQualification}
                    MenuProps={MenuProps}
                    name="qualification">
                    {qualificationList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.qualification_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between lg:flex-row lg:items-end">
              <div>
                <div>Salary Range</div>
                <div className="w-60 text-center lg:w-52">
                  <Slider
                    value={salary}
                    onChange={handleSalary}
                    min={0}
                    max={20000}
                    step={500}
                    className={classes.slider}
                    name="salary"
                  />
                </div>

                <div className="text-center font-poppins text-sm lg:text-left">
                  {`RM${salary[0].toLocaleString()} to RM${salary[1].toLocaleString()}`}
                </div>
              </div>

              <div className="mt-4 text-center lg:mt-0 lg:text-left">
                <span onClick={() => buttonRef.current.click()}>
                  <Button className={classes.searchBtn} disabled={locationDisabled}>
                    Search
                  </Button>
                </span>
                <br />
                <button ref={buttonRef} type="hidden" className="hidden">
                  Seach Filter
                </button>
              </div>
            </div>
          </div>
        </form>
      </Popover>
    </div>
  );
}

export default SearchFilter
