import theLeader from '../../../personality_test/assets/the-leader.png'
import theAmbassador from '../../../personality_test/assets/the-ambassador.png'
import theWanderer from '../../../personality_test/assets/the-wanderer.png'
import theBohemian from '../../../personality_test/assets/the-bohemian.png'
import theMellowSeeker from '../../../personality_test/assets/the-mellow-seeker.png'
import theAdventurous from '../../../personality_test/assets/the-adventurous.png'
import theCreator from '../../../personality_test/assets/the-creator.png'
import theBalancer from '../../../personality_test/assets/the-balancer.png'
import theCollaborator from '../../../personality_test/assets/the-collaborator.png'
import theConductor from '../../../personality_test/assets/the-conductor.png'
import theAnchor from '../../../personality_test/assets/the-anchor.png'
import theInventor from '../../../personality_test/assets/the-inventor.png'
import theMentor from '../../../personality_test/assets/the-mentor.png'
import theTranquil from '../../../personality_test/assets/the-tranquil.png'
import theTrailblazer from '../../../personality_test/assets/the-trailblazer.png'

export const traits_avatar = [
  {
    factor: 1, // Freedom
    traits: [
      {
        name: 'The Leader',
        short_description:
          'The Leaders are resolute in nature. They value stability, and focus on tangible outcomes and traditional work settings.',
        long_description:
          'You highly value a firm workplace environment, where clear guidelines, procedures, and expectations are established. Your meticulous attention to detail and strong adherence to protocols ensure that you consistently deliver work of the highest quality. Your ability to navigate complex challenges with precision and unwavering commitment to upholding standards make you an asset in environments that prioritize structure and a disciplined approach to achieving goals.',
        image: theLeader,
      },
      {
        name: 'The Ambassador',
        short_description:
          'The Ambassadors can find balance between adaptability and stability, seamlessly integrating flexible approaches with traditional methods to achieve effective results.',
        long_description:
          'You are someone who strikes a balance between firmness and flexibility in your preferred level of control at the workplace. You appreciate structure and clear guidelines while also valuing the ability to adapt and adjust as needed. With your adaptable nature, you can effectively navigate between established protocols and the need for agility, creating a well-rounded approach that brings stability and adaptability in equal measure.',
        image: theAmbassador,
      },
      {
        name: 'The Wanderer',
        short_description:
          'The Wanderers are able to navigate changing circumstances, take advantage of flexible schedules, and thrive in informal work environments.',
        long_description:
          "You highly value workplace flexibility and thrive in an informal work environment that encourages open communication, collaboration, and creativity. With a preference for a flexible schedule and the freedom to work remotely, you embrace the opportunity to contribute to a team's success while maintaining a comfortable work-life balance. Your adaptability, coupled with your ability to foster a relaxed and inclusive atmosphere, makes you a valuable asset to organizations that promote a flexible and informal work culture.",
        image: theWanderer,
      },
    ],
  },
  {
    factor: 2, // Adventurous
    traits: [
      {
        name: 'The Bohemian',
        short_description:
          'The Bohemians have a calm and open-minded attitude while seeking personal growth and collaborating in a nurturing work setting.',
        long_description:
          'You are an individual who thrives in a laidback workplace, preferring an atmosphere that encourages a relaxed and easygoing approach to work. You appreciate a flexible work environment where the emphasis is on maintaining a healthy work-life balance and fostering a stress-free atmosphere. Your ability to stay calm under pressure, adapt to changes effortlessly, and create a harmonious work environment make you an asset to teams that value a laidback and supportive culture.',
        image: theBohemian,
      },
      {
        name: 'Mellow Seeker',
        short_description:
          'The Mellow Seekers smoothly blend adaptability and calm collaboration, finding a harmonious balance between adjusting to changing situations and maintaining a serene and cooperative work environment.',
        long_description:
          'You thrive in a balanced workplace that combines elements of both laidback and challenging work cultures, appreciating a flexible environment that encourages a relaxed approach to work while offering opportunities for personal and professional growth. Your ability to stay calm under pressure, adapt to changes effortlessly, and tackle complex tasks head-on makes you an invaluable asset in high-pressure environments that value ambitious goals. You seek a harmonious work environment that fosters collaboration and support among team members while providing room for continuous development.',
        image: theMellowSeeker,
      },
      {
        name: 'The Adventurous',
        short_description:
          'The Adventurous flexibly navigate changing landscapes with resilience and versatility.',
        long_description:
          'You are an individual who thrives in a challenging workplace, seeking roles that push you to your full potential. You excel when faced with complex tasks and enjoy tackling difficult problems head-on. Your drive for continuous growth and your ability to handle demanding responsibilities make you an invaluable asset in high-pressure environments that value ambitious goals and provide opportunities for personal and professional development.',
        image: theAdventurous,
      },
    ],
  },
  {
    factor: 3, // Teamwork
    traits: [
      {
        name: 'The Creator',
        short_description:
          'The Creators prefer self-reliance, taking action under stress, being assertive in sharing ideas, and being driven by personal motivations rather than external recognition.',
        long_description:
          'You are a highly independent individual when it comes to your preferred level of collaboration in the workplace. You excel when given the freedom to work autonomously, leveraging your self-reliance and initiative to tackle tasks and make decisions on your own. Your ability to work independently allows you to bring a unique perspective and contribute your individual strengths, ultimately driving your personal and professional success.',
        image: theCreator,
      },
      {
        name: 'The Balancer',
        short_description:
          'The Balancers strike a balance between valuing personal autonomy and contributing positively to group dynamics, promoting collaboration and unity while respecting individuality.',
        long_description:
          'You are someone who strikes a harmonious balance between independence and teamwork in your preferred level of collaboration at the workplace. While you value the autonomy to work on individual tasks and make decisions, you also recognize the importance of collaborating with others to achieve shared goals. Your ability to seamlessly transition between independent work and collaborative efforts allows you to leverage your unique strengths while also fostering effective teamwork and synergy within your work environment.',
        image: theBalancer,
      },
      {
        name: 'The Collaborator',
        short_description: 'The Collaborators create cohesion and synergy within diverse groups.',
        long_description:
          'You are a highly collaborative individual, thriving in an environment that emphasizes teamwork and collective effort. You excel when working closely with others, leveraging your strong communication and interpersonal skills to build effective relationships and contribute to shared goals. Your ability to foster a collaborative atmosphere and work synergistically with team members makes you a valuable asset in achieving collective success at the workplace.',
        image: theCollaborator,
      },
    ],
  },
  {
    factor: 4, // Systematic
    traits: [
      {
        name: 'The Anchor',
        short_description:
          'The Anchors are dependable, value predictability, security, and prefer maintaining a steady course in both their work environment and routines.',
        long_description:
          'You are a highly stability-oriented individual when it comes to your preferred workplace environment. You thrive in settings that offer structure, predictability, and established processes. Your ability to maintain focus, adhere to established protocols, and create a sense of stability fosters a reliable and efficient work environment where tasks are consistently completed with precision and attention to detail.',
        image: theAnchor,
      },
      {
        name: 'The Conductor',
        short_description:
          'The Conductors are skilled in maintaining a steady foundation while embracing new ideas and approaches when necessary.',
        long_description:
          'You are someone who strikes a balance between seeking dynamic opportunities and appreciating stability in your preferred type of dynamics at the workplace. While you value the excitement and adaptability that come with dynamic environments, you also recognize the importance of stability and predictability to ensure consistent progress. With your ability to navigate both realms, you bring a unique perspective that embraces change when necessary while maintaining a strong foundation for sustainable growth and success.',
        image: theConductor,
      },
      {
        name: 'The Inventor',
        short_description:
          'The Inventors have forward-thinking mindset, enthusiasm for new ideas, and has the ability to navigate situations with flexibility and a fresh perspective.',
        long_description:
          'You are a highly dynamic individual when it comes to your preferred workplace environment. You thrive in fast-paced and ever-changing settings, where new challenges and opportunities for growth arise regularly. Your ability to adapt quickly, embrace change, and proactively seek innovative solutions makes you an invaluable asset in driving progress and success in dynamic work environments.',
        image: theInventor,
      },
    ],
  },
  {
    factor: 5, // Ambitious
    traits: [
      {
        name: 'The Mentor',
        short_description:
          'The Mentors have a balanced approach to work, value personal growth and helping others, rather than being solely driven by high-level positions, ambition, and competitiveness.',
        long_description:
          'You are a highly content individual in terms of your aspirations at the workplace. You find fulfillment and satisfaction in your current role, valuing a sense of balance and harmony in your professional life. Your focus is on maintaining a positive work-life balance, deriving happiness from the meaningful contributions you make within your existing position rather than constantly seeking external validation or ambitious career advancements.',
        image: theMentor,
      },
      {
        name: 'The Tranquil',
        short_description:
          'The Tranquils have ambitious dreams and goals while also prioritizing personal growth, well-being, and mentoring others.',
        long_description:
          'You are someone who finds a harmonious balance between contentment and ambition in your workplace aspirations. While you appreciate the fulfillment and satisfaction of your current role, you also possess a drive to continually push yourself and achieve greater success. Striking a balance between contentment and ambition, you seek growth opportunities that align with your values and allow you to make a meaningful impact while still maintaining a sense of satisfaction in the present.',
        image: theTranquil,
      },
      {
        name: 'The Trailblazer',
        short_description:
          'The Trailblazers paints grand visions and strives to turn them into reality through determination and foresight.',
        long_description:
          'You are a highly ambitious individual when it comes to your aspirations at the workplace. You have a strong desire for growth, achievement, and making a significant impact in your field. With a relentless drive to excel, you constantly seek new challenges, take on leadership roles, and strive for continuous personal and professional development, aiming to leave a lasting legacy in your chosen career path.',
        image: theTrailblazer,
      },
    ],
  },
]
