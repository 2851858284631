import ButtonBase from '@mui/material/ButtonBase'

const mui = {
  homeBtn: {
    background: '#FCD94E',
    borderRadius: '5px',
    fontSize: '1rem',
    padding: '12px 13px',
    color: '#1B8379',
    fontWeight: '400',
    boxShadow: '2px 4px 2px rgba(0,0,0,0.1)',
    '@media (max-width:1024px)': {
      fontSize: '1rem',
    },
  },
  issueBtn: {
    background: '#ffffff',
    borderRadius: '5px',
    fontSize: '1rem',
    padding: '12px 13px',
    color: '#1B8379',
    fontWeight: '400',
    boxShadow: '2px 4px 2px rgba(0,0,0,0.1)',
    '@media (max-width:1024px)': {
      fontSize: '1rem',
    },
  },
}

const ErrorView = () => {
  return (
    <div className="flex h-screen w-screen select-none flex-col items-center justify-center bg-adx-darkGreen p-4 font-poppins text-adx-gray">
      <div className="text-3xl font-bold lg:text-5xl xs:text-center">
        Ooops! Something went wrong.
      </div>
      <div className="my-2 text-base lg:text-xl xs:text-center">
        Our team has been notified regarding this issue. You may refresh the page or try again
        later.
      </div>
      <div className="mt-8 flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6">
        <ButtonBase href="/" sx={mui.homeBtn} variant="contained">
          Back to home
        </ButtonBase>
        <ButtonBase href="/contact-us" sx={mui.issueBtn} variant="contained">
          Report an issue
        </ButtonBase>
      </div>
    </div>
  )
}

export default ErrorView
