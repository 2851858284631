import React from 'react'
import personalityImg from '../../assets/personality-test-img.png'
import videoInterviewImg from '../../assets/video-interview-img.png'
import { Link } from 'react-router-dom'
import EastIcon from '@mui/icons-material/East';

const ExplorePanel = () => {
    return (
        <div className="flex justify-center gap-8 py-8 bg-white text-adx-darkText">
            <Link to={"/personality-test"}>
                <div className="w-[300px] rounded-[4.5px] border-[1px] border-adx-addBackground">
                    <img src={personalityImg} alt="Personality test" className="object-cover" />
                    <div className="border-t-[1px] border-adx-addBackground p-5 flex flex-col gap-4">
                        <p className="font-medium">Work Personality Test</p>
                        <p className="text-sm">Take this test and discover what kind of a person you truly are at the workplace in just under 10 minutes.</p>
                        <div className="flex items-center justify-between text-sm text-adx-darkGreen">
                            <p>Try now</p>
                            <EastIcon fontSize='small'/>
                        </div>
                    </div>
                </div>
            </Link>
            <Link to={"/video-interview"}>
                <div className="w-[300px] rounded-[4.5px] border-[1px] border-adx-addBackground">
                    <img src={videoInterviewImg} alt="Video interview" className="object-cover" />
                    <div className="border-t-[1px] border-adx-addBackground p-5 flex flex-col gap-4">
                        <p className="font-medium">Video Interview</p>
                        <p className="text-sm">Record a 5-minute video interview, and NEX would suggest jobs that matches you.</p>
                        <div className="flex items-center justify-between text-sm text-adx-darkGreen">
                            <p>Try now</p>
                            <EastIcon fontSize='small'/>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ExplorePanel