import React from 'react'

const factors = {
  0: { dominant: 'Freedom', recessive: 'Structured' },
  1: { dominant: 'Adventurous', recessive: 'Laidback' },
  2: { dominant: 'Teamwork', recessive: 'Independent' },
  3: { dominant: 'Systematic', recessive: 'Agile' },
  4: { dominant: 'Ambitious', recessive: 'Fulfilled' },
}

const DownloadPersonalityCard = ({ index, data }) => {
  // percentage refers to recessive value
  const traitPercentage = data.score
  const traitName = data.factor.name
  const traitImage = data.factor.image
  const traitDescription = data.factor.long_description

  const personalityFactor = factors[index]
  const personalityType =
    traitPercentage === 50 ? 'balanced' : traitPercentage > 50 ? 'recessive' : 'dominant'

  // percentage refers to recessive value
  const recessiveValue = traitPercentage
  const dominantValue = 100 - traitPercentage

  return (
    <div className="w-full border-t-[1px] border-adx-addBackground bg-white">
      <div className="flex flex-col gap-4 p-4 sm:flex-row">
        <img src={traitImage} alt={traitName} className="h-[150px] rounded-[4.5px] object-cover" />
        <div className="flex flex-col gap-1">
          <div className="-mt-2 flex justify-between font-medium">
            <p className=" text-adx-darkText">{traitName}</p>
            <p className="text-adx-darkGreen">
              {personalityType === 'dominant' ? dominantValue : recessiveValue}%{' '}
              {personalityType === 'dominant'
                ? personalityFactor.dominant
                : personalityFactor.recessive}
            </p>
          </div>
          <p className="text-justify text-sm text-adx-darkerGray">{traitDescription}</p>
        </div>
      </div>
    </div>
  )
}

export default DownloadPersonalityCard
