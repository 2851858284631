import { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'

import { ProfileContext } from 'contexts/ProfileContext'
import { generateFileName, getFileExtension } from 'services/helper/index'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import LoadingScreen from 'pages/courses_ads/components/LoadingScreen'
import AboutMeForm from 'pages/profile/components/AboutMeForm'
import CustomHelmet from 'components/smart/CustomHelmet'
import Footer from 'components/dumb/Footer'
import { authContext } from 'contexts/AuthContext'
import DeleteAccount from 'pages/profile/components/DeleteAccount'

import { signUrl } from 'services/api/storage/index'
import { jobseekerImage } from 'services/api/profile/index'
import { clearCookies } from 'services/helper/clearCookies'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Grow from '@mui/material/Grow'
import Popper from '@mui/material/Popper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

import DefaultProfile from 'assets/images/jobseeker.jpg'

const mui = {
  settingsBtn: {
    fontSize: '14px !important',
    padding: '15px 40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  deleteBtn: {
    fontSize: '14px !important',
    padding: '15px 40px',
    color: 'red',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeBtn: {
    color: 'white',
  },
  popper: {
    zIndex: 1,
    '& .MuiList-padding': {
      padding: '10px 10px 10px 10px',
    },
  },
  imageBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '13px',
  },
}

const EDUProfile = () => {
  const { auth, setAuthData } = useContext(authContext)
  const { profileData, dobData, getProfile } = useContext(ProfileContext)

  const [aboutOpen, setAboutOpen] = useState(false)

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const formattedDOB = (val) => {
    if (val !== null) {
      if (val.dob !== undefined && val.dob !== null) {
        let splitDOB = val.dob !== null && val.dob.split('/')
        let newDOB = splitDOB[0] + ' ' + months[parseInt(splitDOB[1]) - 1] + ' ' + splitDOB[2]
        return newDOB
      }
    }
    return '-'
  }

  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [snackProps, setSnackProps] = useState({
    severity: 'success',
    message: 'Your save was successful.',
  })

  // Function for saved snackbar
  const [openSnack, setOpenSnack] = useState(false)
  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }

  // settings menu logic
  const [openSetting, setOpenSetting] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpenSetting((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpenSetting(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenSetting(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(openSetting)
  useEffect(() => {
    if (prevOpen.current === true && openSetting === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = openSetting
  }, [openSetting])
  // end settings menu logic

  // Logout
  const handleSignOut = (event) => {
    setLoading(true)
    handleClose(event)
    clearCookies()
    window.open('/bootcamp', '_self')
  }

  const [photo, setPhoto] = useState(null)
  const [files, setFiles] = useState([])

  const handleImageModal = () => {
    setOpenDialog(false)
    setPhoto(null)
    setFiles([])
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    maxFiles: 0,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
      const extension = getFileExtension(acceptedFiles[0].name)
      const fileName = generateFileName(auth.userId, 'document', extension)
      Object.defineProperty(acceptedFiles[0], 'name', {
        writable: true,
        value: fileName,
      })
      setPhoto(acceptedFiles[0])
    },
  })

  const uploadPhoto = async () => {
    setLoading(true)
    try {
      const results = await signUrl({
        bucket: process.env.REACT_APP_AWS_BUCKET,
        key: 'profile_photo/' + photo.name,
      })
      const awsRequest = axios.create()
      delete awsRequest.defaults.headers.common['Authorization']
      await awsRequest.put(results.data.url, photo, {
        headers: {
          'Content-Type': photo.type,
        },
      })
      await jobseekerImage({ profile_image: photo.name })
      await getProfile()
      handleImageModal()
      setSnackProps({
        severity: 'success',
        message: 'Your upload was successful.',
      })
      setAuthData()
      setOpenSnack(true)
    } catch (e) {
      console.log(e)
      setSnackProps({
        severity: 'error',
        message: 'Upload error, please try again!',
      })
      setOpenSnack(true)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteModal = (e) => {
    setOpenDelete(!openDelete)
    handleClose(e)
  }

  return (
    <div className="bg-adx-gray">
      {loading && <LoadingScreen />}

      {profileData && (
        <>
          <HeaderEdu />
          <CustomHelmet title="Profile | adnexio EDU" url="https://adnexio.jobs/bootcamp/profile" />

          <Snackbar
            open={openSnack}
            autoHideDuration={3500}
            onClose={closeSnack}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={closeSnack} severity={snackProps.severity} variant="filled">
              {snackProps.message}
            </Alert>
          </Snackbar>

          <div className="h-auto lg:flex">
            <div className="sticky top-0 z-10 lg:flex lg:h-screen lg:w-6/12 lg:flex-col lg:pt-32 lg:pb-10">
              <div className="bg-adx-gray lg:relative">
                <div className="flex items-center justify-around p-4 lg:flex-col lg:rounded-none lg:bg-transparent lg:p-0">
                  <div className="absolute hidden -top-20 right-10 lg:block">
                    <Tooltip title="Open settings" placement="top" arrow>
                      <IconButton onClick={handleToggle} ref={anchorRef} size="large">
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                    <Popper
                      open={openSetting}
                      anchorEl={anchorRef.current}
                      transition
                      disablePortal
                      placement="top-end"
                      className="w-96"
                      sx={mui.popper}
                    >
                      {({ TransitionProps }) => (
                        <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                          <div className="text-xs rounded-md shadow-md outline-none bg-adx-white font-poppins ">
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList autoFocusItem={openSetting} onKeyDown={handleListKeyDown}>
                                <div className="flex items-center justify-between px-6 rounded-md outline-none bg-adx-darkGreen">
                                  <div className="text-base font-normal text-white outline-none">
                                    Settings
                                  </div>
                                  <IconButton onClick={handleClose} size="large">
                                    <CloseRoundedIcon fontSize="small" sx={mui.closeBtn} />
                                  </IconButton>
                                </div>
                                <Link to="/bootcamp/contact-us">
                                  <MenuItem sx={mui.settingsBtn} onClick={handleClose} divider>
                                    <div>Contact Us</div>
                                    <ChevronRightRoundedIcon
                                      style={{ color: 'gray' }}
                                      fontSize="small"
                                    />
                                  </MenuItem>
                                </Link>
                                <MenuItem sx={mui.settingsBtn} onClick={handleDeleteModal} divider>
                                  <div>Delete my account</div>
                                  <ChevronRightRoundedIcon
                                    style={{ color: 'gray' }}
                                    fontSize="small"
                                  />
                                </MenuItem>
                                <MenuItem sx={mui.deleteBtn} onClick={handleSignOut}>
                                  <div>Sign out</div>
                                  <ChevronRightRoundedIcon
                                    style={{ color: 'red' }}
                                    fontSize="small"
                                  />
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </div>
                        </Grow>
                      )}
                    </Popper>
                  </div>

                  <div>
                    {profileData.profile_image_url !== null &&
                      profileData.profile_image_url !== undefined && (
                        <Tooltip
                          title="Upload new photo"
                          placement="top"
                          arrow
                          onClick={() => setOpenDialog(!openDialog)}
                        >
                          <div>
                            <div className="relative w-32 lg:w-auto" style={{ zIndex: 0 }}>
                              <img
                                src={profileData.profile_image_url}
                                alt="profile_image"
                                className="object-cover rounded-full cursor-pointer h-28 w-28 lg:h-60 lg:w-60"
                              />
                            </div>
                          </div>
                        </Tooltip>
                      )}
                    {(profileData.profile_image_url === null ||
                      profileData.profile_image_url === undefined) && (
                      <Tooltip
                        title="Upload new photo"
                        placement="top"
                        arrow
                        onClick={() => setOpenDialog(!openDialog)}
                      >
                        <div className="relative w-32 lg:w-auto">
                          <ErrorOutlineIcon className="absolute text-red-500 -right-4 lg:right-0" />
                          <img
                            src={DefaultProfile}
                            alt="profile_image"
                            className="object-cover w-32 h-32 mt-10 ml-3 rounded-full cursor-pointer lg:ml-0 lg:mt-0 lg:h-60 lg:w-60"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  <Dialog onClose={handleImageModal} open={openDialog}>
                    <div className="px-2 py-0 lg:py-0 lg:px-10">
                      <DialogTitle>Upload new photo</DialogTitle>
                      <div {...getRootProps()} className="flex items-center justify-center p-4">
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <div className="flex items-center justify-center object-cover w-32 h-32 rounded-full cursor-pointer bg-adx-darkerGray text-adx-darkGray lg:h-60 lg:w-60">
                            <div>Drop here</div>
                          </div>
                        ) : !files.length ? (
                          <div className="flex flex-col items-center justify-center object-cover w-32 h-32 rounded-full cursor-pointer bg-adx-darkGray text-adx-darkerGray lg:h-60 lg:w-60">
                            <CameraAltIcon />
                          </div>
                        ) : (
                          <>
                            <input {...getInputProps()} />
                            <img
                              src={files[0].preview}
                              alt="profile_image"
                              className="object-cover w-32 h-32 rounded-full shadow-lg cursor-pointer lg:h-60 lg:w-60"
                            />
                          </>
                        )}
                      </div>
                      <div className="flex items-center justify-center p-8 pt-2 space-x-4">
                        {!files.length ? (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button sx={mui.imageBtn} color="primary" variant="contained">
                              Add
                            </Button>
                          </div>
                        ) : (
                          <Button
                            sx={mui.imageBtn}
                            onClick={uploadPhoto}
                            color="primary"
                            variant="contained"
                          >
                            Upload
                          </Button>
                        )}
                        <Button sx={mui.imageBtn} onClick={handleImageModal} variant="outlined">
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Dialog>

                  <div className="text-sm text-black lg:my-5 lg:max-w-md lg:text-center lg:text-lg lg:font-semibold">
                    {profileData.name}
                  </div>
                </div>

                <DeleteAccount
                  setOpenDelete={setOpenDelete}
                  openDelete={openDelete}
                  loading={loading}
                  setLoading={setLoading}
                />
              </div>
            </div>

            <div className="h-full bg-adx-white lg:w-7/12">
              <div className="px-3 pb-10 lg:min-h-screen lg:px-28 lg:pt-10">
                <div className="max-w-lg p-6 pt-20 mx-auto space-y-10 text-sm lg:max-w-2xl lg:py-6">
                  {aboutOpen && (
                    <AboutMeForm
                      aboutOpen={aboutOpen}
                      setAboutOpen={setAboutOpen}
                      profileData={profileData}
                      newDOB={formattedDOB(dobData)}
                      dobData={dobData} // Somehow address is in dobData
                      setOpenSnack={setOpenSnack}
                      setSnackProps={setSnackProps}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  )}

                  {!aboutOpen && (
                    <div>
                      <div className="mb-[48px] flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="mr-2 text-xl font-semibold">About Me</div>
                        </div>
                        <IconButton
                          style={{ outline: 'none' }}
                          onClick={() => setAboutOpen(!aboutOpen)}
                          size="large"
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      </div>
                      <div>
                        <div className="mb-[16px] font-semibold">{profileData.name}</div>
                        <div className="flex flex-col gap-[8px]">
                          <div className="flex gap-[16px]">
                            <div className="w-[102px] text-adx-darkerGray">Birth Date</div>
                            <div>{formattedDOB(dobData)}</div>
                          </div>
                          <div className="flex gap-[16px]">
                            <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">
                              Phone
                            </div>
                            <div>
                              {profileData.contact_number !== null &&
                              profileData.contact_number !== '-'
                                ? profileData.contact_number
                                : '-'}
                            </div>
                          </div>
                          <div className="flex gap-[16px]">
                            <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">
                              Email
                            </div>
                            <div>{profileData.email ?? '-'}</div>
                          </div>
                          <div className="flex gap-[16px]">
                            <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">
                              Home Address
                            </div>
                            <div>{dobData.address ?? '-'}</div>
                          </div>
                          <div className="flex gap-[16px]">
                            <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">
                              Postal Code
                            </div>
                            <div>{dobData.postal_code ?? '-'}</div>
                          </div>
                          <div className="flex gap-[16px]">
                            <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">
                              State
                            </div>
                            <div>{dobData.state ?? '-'}</div>
                          </div>
                          <div className="flex gap-[16px]">
                            <div className="w-[102px] whitespace-nowrap text-adx-darkerGray">
                              Country
                            </div>
                            <div>{dobData.country ?? '-'}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  )
}

export default EDUProfile
