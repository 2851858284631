import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { CourseCardContext } from 'contexts/CourseCardContext'
import { postCourseLog } from 'services/api/course_advertisment'
import StatusChipApplication from './StatusChipApplication'
import Bugsnag from '@bugsnag/js'

const CourseCard = (props) => {
  const navigate = useNavigate()
  const { courseID, setCourseID, setSelectedCourse } = useContext(CourseCardContext)

  async function showCourseDetail() {
    if (window.innerWidth >= 1024) {
      setCourseID(props.item.id)
      setSelectedCourse(props.item)
      if (!('nex_edu_course_id' in props.item)) {
        try {
          const data = { nex_edu_course_id: props.item.id }
          await postCourseLog(data)
        } catch (e) {
          Bugsnag.notify(e)
        }
      }
    } else {
      if ('nex_edu_course_id' in props.item) {
        navigate(`/course-application/${props.item.id}`)
      } else {
        navigate(`/courses/${props.item.id}`)
      }
    }
  }
  let re = /(?:\.([^.]+))?$/

  return (
    <div
      className="relative flex w-full px-6 py-6 mx-auto mb-4 cursor-pointer border-xl rounded-xl bg-adx-white"
      onClick={showCourseDetail}
    >
      <div className="w-1/3 h-auto lg:w-1/4 place-items-center justify-self-center ">
        {props.item.id === courseID && (
          <div
            className="absolute top-0 left-0 rounded-tl-[12px] rounded-bl-[12px] hidden h-full w-2 lg:block lg:bg-adx-darkGreen"
          />
        )}
        {props.item.course_logo === null || re.exec(props.item.course_logo)[1] === 'pdf' ? (
          <div className="flex items-center justify-center w-20 h-20 text-3xl text-black rounded-full shadow bg-adx-white">
            {props.item.course_title.charAt(0)}
          </div>
        ) : (
          <img
            src={props.item.course_logo}
            alt="course_logo"
            className="object-cover w-20 h-20 rounded-full shadow"
          />
        )}
      </div>

      <div className="flex flex-col w-2/3 lg:w-3/4 lg:flex-row lg:justify-between">
        <div className={props.item?.countdown > 0 ? 'w-full lg:w-3/4' : 'w-full'}>
          <p className="w-5/6 font-semibold capitalize truncate text-adx-darkGreen">
            {props.item.course_title}
          </p>
          <p className="w-5/6 mb-2 text-sm font-semibold truncate">by {props.item.trainer_name}</p>
          <p className="w-5/6 mb-1 text-sm truncate">{props.item.course_setting}</p>
        </div>
        {
          props.item?.countdown > 0 && <div className='absolute flex flex-col items-start justify-center right-6 bottom-6 lg:items-end'>
            <StatusChipApplication applicationStatusId={{
              textStatus: props.item?.countdown > 1 ? `${props.item?.countdown} days left` : `1 day left`
            }} />
          </div>
        }
      </div>
    </div>
  )
}

export default CourseCard
