import { createContext, useState, useEffect, useContext } from "react"
import { authContext } from "contexts/AuthContext"
import { notificationList } from "services/api/notification"
import Bugsnag from "@bugsnag/js"

export const NotificationContext = createContext()

const NotificationContextProvider = (props) => {
  const { auth } = useContext(authContext)

  const [notificationData, setNotificationData] = useState(null)
  const [eduNotification, setEduNotification] = useState(null)

  const getNotification = async (props) => {
    try {
      if (props) {
        const results = await notificationList(props)
        setEduNotification(results.data)
      } else {
        const results = await notificationList()
        setNotificationData(results.data)
      }
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  useEffect(() => {
    if (auth.isAuth) {
      getNotification()
      getNotification({ isEdu: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuth])

  return (
    <NotificationContext.Provider
      value={{
        notificationData,
        eduNotification,
        getNotification,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  )
}

export default NotificationContextProvider
