import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

import { EmployerCardContext } from 'contexts/EmployerCardContext'

const mui = {
  iconButton1: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
}

// Get image file extension
let re = /(?:\.([^.]+))?$/

const EmployerCardApplication = (props) => {
  const navigate = useNavigate()
  const { jobID, setJobID, setSelectedEmployer } = useContext(EmployerCardContext)

  function showJobDetail() {
    if (window.innerWidth >= 1024) {
      setJobID(props.item.id)
      setSelectedEmployer(props.item)
    } else {
      if (props.tab === 'application') {
        navigate(`/job-application/${props.item.id}`)
      } else if (props.tab === 'result') {
        navigate(`/job-result/${props.item.id}`)
      } else if (props.tab === 'interview') {
        navigate(`/job-interview/${props.item.id}`)
      }
    }
  }

  return (
    <div
      className="border-xl relative mx-auto mb-4 grid w-full cursor-pointer grid-cols-3 rounded-xl bg-adx-white py-2"
      onClick={() => {
        showJobDetail()
      }}
    >
      {props.item.id === jobID && (
        <div
          className="absolute top-0 left-0 h-full w-3 lg:bg-adx-darkGreen"
          style={{ borderTopLeftRadius: 'inherit', borderBottomLeftRadius: 'inherit' }}
        ></div>
      )}
      {props.item.id === jobID && <div className="absolute top-0 left-2 h-full w-3 bg-white"></div>}
      <div className="grid place-items-center justify-self-center ">
        {props.item.company_logo === null || re.exec(props.item.company_logo)[1] === 'pdf' ? (
          <div className="flex h-20 w-20 items-center justify-center rounded-full bg-adx-white text-3xl text-black shadow">
            {props.item.company_name.charAt(0)}
          </div>
        ) : (
          <img
            src={props.item.company_logo}
            alt="company_logo"
            className="h-20 w-20 rounded-full object-scale-down shadow"
          />
        )}
      </div>

      <div className="col-span-2">
        <p className="w-5/6 truncate font-bold capitalize text-adx-darkGreen">
          {props.item.position_title}
        </p>
        <p className="w-5/6 truncate text-sm capitalize">{props.item.company_name}</p>
        <hr className="my-2  w-5/6" />
        {props.tab === 'application' && (
          <p className="w-5/6 truncate font-bold capitalize text-adx-darkGreen">
            Application status
          </p>
        )}
        {props.tab === 'application' && (
          <p className="w-5/6  truncate text-sm">{props.item.application_status}</p>
        )}
        {props.tab === 'result' && (
          <p className="w-5/6 truncate font-bold capitalize text-adx-darkGreen">Result</p>
        )}
        {props.tab === 'result' && (
          <p
            className={`w-5/6  truncate text-sm 
                ${
                  [5].includes(props.item.job_application_status_id)
                    ? 'text-adx-darkGreen'
                    : [6, 8].includes(props.item.job_application_status_id)
                    ? 'text-red-700'
                    : [7].includes(props.item.job_application_status_id)
                    ? 'text-adx-yellow'
                    : [10].includes(props.item.job_application_status_id)
                    ? 'text-adx-yellow'
                    : null
                }
                `}
          >
            {props.item.job_application_status_id === 5
              ? 'Job offered'
              : props.item.job_application_status_id === 6
              ? 'Rejected'
              : props.item.job_application_status_id === 7
              ? 'No Response'
              : props.item.job_application_status_id === 8
              ? 'Declined'
              : props.item.job_application_status_id === 10
              ? 'Reject Interview'
              : null}
          </p>
        )}
        {props.tab === 'interview' && (
          <div className="w-5/6  truncate text-xs ">
            <span className="mb-2 lg:mb-0 lg:mr-2">Schedule</span>
            <div className="lg:inline">
              <CalendarTodayIcon className="mb-1 scale-75 transform lg:mb-0 lg:mr-2	" />
              <span className="mb-1 lg:mb-0 lg:mr-2">
                {moment(props.item.date).format(' DD/MM/YYYY')}
              </span>
            </div>
            <AccessTimeIcon className="mb-1 scale-75 transform lg:mb-0 lg:mr-2" />
            {moment(props.item.interview_time, 'hh:mm:ss').format('LT')}
          </div>
        )}
        {props.tab === 'interview' && (
          <p className="w-5/6  truncate text-xs">
            <span className="mr-2">Status</span>
            <span
              className={`${
                props.item.interview_status_id === 5
                  ? 'text-adx-darkGreen'
                  : [6, 8].includes(props.item.interview_status_id)
                  ? 'text-red-700'
                  : 'text-adx-yellow'
              }`}
            >
              {props.item.interview_status}
            </span>
          </p>
        )}
      </div>

      {props.showArrow && (
        <IconButton sx={mui.iconButton1} size="large">
          <ChevronRightIcon />
        </IconButton>
      )}
    </div>
  )
}

export default EmployerCardApplication
