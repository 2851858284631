import axios, { getData } from "../../../lib/axios"

export const jobMatchLists = async (page) => {
    return axios.get(`/job_seekers/job_match_lists?page=${page}`).then(getData)
}

export const jobMatchListsV2 = async (page) => {
    return axios.get(`v2/job_seekers/job_match_lists?page=${page}`).then(getData)
}

export const rawJobMatch = async () => {
    return axios.get("/job_seekers/raw_job_match_lists").then(getData)
}

export const verifiedJobMatch = async (id) => {
    return axios.put(`/job_seekers/job_match_lists/${id}`).then(getData)
}

