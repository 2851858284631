import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import StartPage from '../components/StartPage'
import QuestionsPage from '../components/questionsPage'

const TestQuestions = () => {
    const navigate = useNavigate()
    const [start, setStart] = useState(false)

    return (
        <div className='relative flex flex-col items-center min-h-screen bg-adx-defaultBackground text-adx-darkText'>
            {/* Header */}
            <div className='sticky top-0 z-10 flex items-center justify-between p-6 w-full border-b-[1px] bg-adx-white border-adx-addBackground font-medium'>
                <p>Work personality test</p>
                <CloseIcon onClick={() => navigate("/personality-test")} className='cursor-pointer' />
            </div>

            {/* Question component */}
            {!start ? (
                <StartPage setStart={setStart} />
            ) : (
                <QuestionsPage/>
            )}
        </div>
    )
}

export default TestQuestions