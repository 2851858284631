import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import Footer from 'components/dumb/Footer'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton from '@mui/material/IconButton'
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import { Link, useNavigate } from 'react-router-dom'
import { getCurrentEnrolment } from 'services/api/current_enrolment'
import { courseAdvertisements } from 'services/api/course_advertisment'
import * as Scroll from 'react-scroll'
import Loader from 'react-loader-spinner'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import ClassDetailsModal from '../components/ClassDetailsModal'
import ShareSocialMedia from 'components/smart/ShareSocialMedia'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import ProgressBar from 'components/smart/ProgressBar'

const mui = {
  viewBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#1B8379',
    color: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#1B8379',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  makePaymentBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#FCD94E',
    color: '#1B8379',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  iconStyle: {
    color: '#FFFFFF',
  },
  myClassBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    background: 'white',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  myClassBtnActive: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#E2EAE9',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
}

const EDUMyClasses = () => {
  const navigate = useNavigate()
  const [animateIconBool, setAnimateIconBool] = useState(true)
  const [dataArray, setDataArray] = useState([])
  const [variedHeight, setVariedHeight] = useState(0)
  const [showTnCModal, setShowTnCModal] = useState(false)
  const [shareCopied, setShareCopied] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [courseData, setCourseData] = useState(null)
  var scroll = Scroll.animateScroll

  // Icon Rotation
  const animatedClock = 'transform rotate-180 transition duration-1000'
  const animatedAntiClock = 'transform rotate-0 transition duration-1000'

  // Drawer Translation
  const drawerHidden = 'lg:transform lg:-translate-x-full lg:transition lg:duration-1000'
  const drawerDisplayed = 'lg:transform lg:translate-x-0 lg:transition lg:duration-1000'

  const heightRef = useRef(null)

  const handleShareClose = (value) => {
    setShareOpen(false)
    setShareCopied(false)
  }

  const handleClickOpen = (share_url) => {
    setShareOpen(true)
    setShowTnCModal(false)
  }

  const handlePress = () => {
    scroll.scrollToTop()
    setAnimateIconBool(!animateIconBool)
  }

  const getEnrolment = async () => {
    setLoading(true)
    try {
      let currentEnrolment = await getCurrentEnrolment()
      setDataArray(currentEnrolment.data)
    } catch (error) {
      setDataArray([])
    } finally {
      setLoading(false)
    }
  }

  const handleClickTnC = async (courseID) => {
    try {
      let response = await courseAdvertisements('get', null, courseID)
      setCourseData(response.data)
    } catch (error) {
      setCourseData(null)
    } finally {
      setShowTnCModal(true)
    }
  }

  const onCloseTnCModal = () => {
    setShowTnCModal(false)
    setCourseData(null)
  }

  const listenerResize = () => {
    if (heightRef.current && heightRef.current.offsetHeight !== variedHeight) {
      setVariedHeight(heightRef.current.offsetHeight)
    }
  }

  useEffect(() => {
    getEnrolment()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', listenerResize)

    return () => {
      window.removeEventListener('resize', listenerResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    listenerResize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heightRef.current])

  return (
    <div className="bg-adx-gray">
      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}
      <HeaderEdu page="my-classes" />

      <div className="relative grid h-full min-h-[calc(100vh-80px)] grid-cols-5 bg-adx-gray">
        {animateIconBool && (
          <div
            className={`hidden lg:block lg:h-full lg:w-full lg:bg-white ${animateIconBool ? drawerDisplayed : drawerHidden
              }`}
          >
            <div className="flex justify-center w-full mt-9">
              <Button variant="contained" sx={mui.myClassBtnActive}>
                <div>
                  <DesktopWindowsIcon className="mr-2" />
                  My Classes
                </div>
                <ArrowForwardIosIcon className="ml-2" />
              </Button>
            </div>
            <div className="flex justify-center w-full mt-9">
              <Button
                variant="contained"
                sx={mui.myClassBtn}
                onClick={() => navigate('/upskill-payment')}
              >
                <div>
                  <AccountBalanceWalletIcon className="mr-2" />
                  Payment
                </div>
                <ArrowForwardIosIcon className="ml-2 opacity-0" />
              </Button>
            </div>
          </div>
        )}
        {/* Animation to space the column with the left side bar */}
        {dataArray.length !== 0 ? (
          <div
            className={`px-12 pt-9 pb-96 ${animateIconBool ? 'col-span-5 lg:col-span-4' : 'col-span-5'
              } flex-1`}
          >
            <div className="mb-4 ml-2.5 text-xl font-semibold text-adx-lightBlack">My Classes</div>
            {/* Animation to space the column to the bottom */}
            <div className={`grid grid-cols-1`}>
              {dataArray.map((mapProps, mapIndex) => {
                let numerator = mapProps.completed_sessions ? mapProps.completed_sessions : 0
                let denominator = mapProps.total_sessions ? mapProps.total_sessions : 0
                let rawProgression = (numerator / denominator) * 100
                let progression = Math.round(rawProgression)
                return (
                  <div
                    className="flex my-4 overflow-hidden bg-white rounded-lg shadow-xl cursor-pointer"
                    onClick={() => handleClickTnC(mapProps.nex_edu_course_id)}
                    key={mapIndex}
                  >
                    <div
                      className="relative hidden bg-black lg:block"
                      style={{
                        height: '100%',
                        // If need the max height, just uncomment below
                        // maxHeight: variedHeight
                      }}
                    >
                      {/* Check whether there's background image url */}
                      {mapProps?.background_photo_url ? (
                        <img
                          src={mapProps?.background_photo_url}
                          alt={mapProps?.course_title}
                          className="object-cover h-full w-52"
                        />
                      ) : (
                        <img
                          src={
                            'https://images.unsplash.com/photo-1546410531-bb4caa6b424d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80'
                          }
                          alt={'Default'}
                          className="object-cover h-full w-52"
                        />
                      )}
                      <div className="absolute w-16 h-16 overflow-hidden bg-black border-2 border-white rounded-full top-5 -right-8">
                        <img
                          src={mapProps?.course_logo}
                          alt={mapProps?.course_title}
                          className="object-cover h-full"
                        />
                      </div>
                    </div>
                    <div
                      className="flex flex-col justify-between w-full py-6 pl-6 pr-6 lg:pl-16"
                      key={mapProps.id}
                      ref={heightRef}
                    >
                      <div className="flex flex-col items-start lg:flex-row lg:items-center lg:justify-between">
                        <div className="text-2xl font-semibold underline text-adx-lightBlack">
                          {mapProps.course_title}
                        </div>
                        <div className="items-center px-5 my-4 rounded-full bg-adx-softerGreen lg:my-0">
                          {/* Need new data */}
                          <div className="text-sm font-normal text-adx-darkText">
                            {denominator} sessions
                          </div>
                        </div>
                      </div>
                      {/* Need new data */}
                      <div className="text-base font-normal text-adx-textGray ">
                        {mapProps.trainer_name ? `by ${mapProps.trainer_name}` : 'by adnexio'}
                      </div>
                      <div className="flex flex-col items-end justify-between mt-5 lg:flex-row">
                        {/* Need new data */}
                        {mapProps.enrolment_confirmed && !mapProps.course_barred ? (
                          <div className="w-full lg:my-0">
                            <div className="mb-2 text-sm font-normal text-adx-darkText">
                              {mapProps.next_session
                                ? `Next up: ${mapProps.next_session}`
                                : 'Next up: TBC'}
                            </div>
                            <div className="flex items-center w-full space-x-6">
                              <ProgressBar
                                value={progression}
                                sx={{
                                  height: 16,
                                  flex: 1,
                                }}
                              />
                              <div className="font-medium text-adx-darkGreen">
                                {progression ? `${progression}%` : '0%'}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="w-full">
                            <div className="text-sm font-normal text-adx-error">
                              {mapProps.barred_message}
                            </div>
                            <div className="text-sm font-normal text-adx-darkText">
                              If you believe that this action was an error, please{' '}
                              <span className="text-blue-500 underline">
                                <Link to="/bootcamp/contact-us">contact us</Link>
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="flex flex-col items-end mt-5 lg:ml-15 lg:mt-0 lg:w-4/12 lg:pl-8">
                          {mapProps.enrolment_confirmed && !mapProps.course_barred ? (
                            <Button
                              variant="contained"
                              fullWidth
                              sx={mui.viewBtn}
                              onClick={() =>
                                navigate(`/bootcamp/my-classes/${mapProps.id}`, {
                                  ...mapProps,
                                })
                              }
                            >
                              {progression > 0 ? 'Continue' : 'Start'}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              fullWidth
                              sx={mui.makePaymentBtn}
                              onClick={() =>
                                navigate(
                                  `/payment-summary/${mapProps?.nex_edu_payment_schedule_id}`
                                )
                              }
                            >
                              Make Payment
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div
            className={`px-12 pt-36 pb-72 ${animateIconBool ? 'col-span-5 lg:col-span-4' : 'col-span-5'
              } flex flex-1 flex-col items-center justify-center`}
          >
            <div className="text-xl font-semibold text-center text-adx-darkBlack lg:text-3xl">
              No classes scheduled.
            </div>
            <div className="mt-5 mb-10 text-sm text-center font-regular text-adx-textGray lg:text-xl">
              To start learning, select and apply for a course today!
            </div>
            <div className="flex flex-col space-y-5 lg:flex-row lg:space-y-0 lg:space-x-10">
              <Link to="/courses">
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  sx={{ '&:hover': { backgroundColor: '#1B8379' } }}
                >
                  Browse Courses
                </Button>
              </Link>
              <Link to="/bootcamp/profile">
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  color="secondary"
                  sx={{ color: '#1b8379', '&:hover': { backgroundColor: '#FCD94E' } }}
                >
                  View Profile
                </Button>
              </Link>
            </div>
          </div>
        )}
        {/* Absolute arrow button */}
        <div
          className={`absolute bottom-8 left-16 hidden h-10 w-10 items-center justify-center rounded-full bg-adx-darkGreen lg:flex ${animateIconBool ? animatedClock : animatedAntiClock
            }`}
        >
          <IconButton onClick={handlePress} size="large">
            <ArrowForwardIosIcon sx={mui.iconStyle} />
          </IconButton>
        </div>
      </div>
      {/* FOOTER */}

      <Footer />

      <ClassDetailsModal
        sx={mui}
        onCloseTnCModal={onCloseTnCModal}
        showTnCModal={showTnCModal}
        handleClickOpen={handleClickOpen}
        courseData={courseData}
      />

      <ShareSocialMedia
        jobAdvertisement={courseData}
        handleClose={handleShareClose}
        open={shareOpen}
        edu={true}
        copied={shareCopied}
        setCopied={setShareCopied}
      />
    </div>
  )
}

export default EDUMyClasses
