import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import AssignmentChip from './AssignmentChip'
import CommentCard from './CommentCard'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import EventIcon from '@mui/icons-material/Event'
import GitHubIcon from '@mui/icons-material/GitHub'
import CommentIcon from '@mui/icons-material/Comment'
import LanguageIcon from '@mui/icons-material/Language'

import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { makeStyles } from '@mui/styles'

import moment from 'moment'

const useStyles = makeStyles({
  applyModalSecondButton: {
    color: '#FCD94E',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    width: '150px',
    margin: '0 10px',
  },
  largeIcon: {
    fontSize: '5rem',
  },
  tableCell: {
    padding: 6,
  },
  tableCellNoBorderBottom: {
    borderBottom: 'none',
    padding: '8px 16px',
  },
  tableHead: {
    backgroundColor: '#E2F2F0',
    borderRadius: '50px !important',
    '& .MuiTableRow-head': {
      // backgroundColor:"pink",
      borderRadius: '50px !important',
    },
  },
  btn: {
    width: '100%',
    textTransform: 'capitalize',
    boxShadow: 'none',
  },

  firstBtn: {
    backgroundColor: '#EAEAEA',
    color: 'black',
  },

  returnToDashboard: {
    backgroundColor: '#FCD94E',
    color: '#1B8379',
  },
  paymentTab: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    background: 'white',
    color: '#808080',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      color: '#1B8379',
      boxShadow: 'none',
    },
    '&:focus': {
      background: '#E2EAE9',
      color: '#1B8379',
      boxShadow: 'none',
    },
  },

  activePaymentTab: {
    background: '#E2EAE9',
    color: '#1B8379',
  },

  // backgroundColor: "#FCD94E",
  // width:"100%",
})

const AssignmentModal = ({ open, onClose, data }) => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState('comment')

  const onCloseModal = () => {
    onClose()
    setCurrentTab('comment')
  }

  return (
    <Dialog open={open} onClose={onCloseModal} fullWidth={true} maxWidth="md">
      <div>
        <div className="flex h-full items-center justify-between p-4">
          <p className="font-poppins text-lg font-bold text-adx-darkGreen">{data.project_name}</p>
          <div className="flex items-center">
            <AssignmentChip id={data.submission_type} />
            <IconButton onClick={onCloseModal} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={`${classes.tableCellNoBorderBottom}`}>
                <p className="font-poppins">
                  <EventIcon className="mr-2" /> Submitted On :
                </p>
              </TableCell>
              <TableCell className={`${classes.tableCellNoBorderBottom}`}>
                <p className="font-poppins text-sm italic text-adx-darkerGray">
                  {moment(data.last_submitted_at).format('DD/MM/YYYY HH:MM A')}
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCellNoBorderBottom}`}>
                <p className="font-poppins">
                  <GitHubIcon className="mr-2" /> GitHub Repository URL :
                </p>
              </TableCell>
              <TableCell className={`${classes.tableCellNoBorderBottom}`}>
                <a
                  href={data.last_repo_link}
                  target="_blank"
                  without
                  rel="noreferrer"
                  className="block font-poppins text-sm italic text-blue-500"
                >
                  {data.last_repo_link}
                </a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.tableCellNoBorderBottom}`}>
                <p className="font-poppins">
                  <LanguageIcon className="mr-2" /> Submission URL :
                </p>
              </TableCell>
              <TableCell className={`${classes.tableCellNoBorderBottom}`}>
                <a
                  href={data.last_submission_url}
                  target="_blank"
                  without
                  rel="noreferrer"
                  className="font-poppins text-sm italic text-blue-500"
                >
                  {data.last_submission_url}
                </a>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="flex gap-4 py-2 px-4">
          <Button
            variant="contained"
            className={`${classes.paymentTab} ${
              currentTab === 'comment' && classes.activePaymentTab
            }`}
            onClick={() => setCurrentTab('comment')}
          >
            Comment
          </Button>
          <Button
            variant="contained"
            className={`${classes.paymentTab} ${
              currentTab === 'historyLog' && classes.activePaymentTab
            }`}
            onClick={() => setCurrentTab('historyLog')}
          >
            History Log
          </Button>
        </div>
        <div className="h-96  px-4">
          {currentTab === 'comment' ? (
            data.comments?.length !== 0 ? (
              <div className="h-full w-full">
                {data.comments?.map((item) => (
                  <CommentCard item={item} />
                ))}
              </div>
            ) : (
              <div className="grid h-full w-full place-items-center">
                <div className="grid place-items-center">
                  <CommentIcon />
                  <p>No comments yet</p>
                </div>
              </div>
            )
          ) : currentTab === 'historyLog' ? (
            <p>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={`${classes.tableCellNoBorderBottom}`} width="15%">
                      Date
                    </TableCell>
                    <TableCell
                      className={`${classes.tableCellNoBorderBottom}`}
                      width="15%"
                      align="left"
                    >
                      Time
                    </TableCell>
                    <TableCell
                      className={`${classes.tableCellNoBorderBottom}`}
                      width="70"
                      align="left"
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="">
                  {data.history_log?.map((item, index) => (
                    <TableRow>
                      <TableCell className={`${classes.tableCellNoBorderBottom}`} width="15%">
                        {moment(item).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell
                        className={`${classes.tableCellNoBorderBottom}`}
                        width="15%"
                        align="left"
                      >
                        {moment(item).format('HH:MM A')}
                      </TableCell>
                      <TableCell
                        className={`${classes.tableCellNoBorderBottom}`}
                        width="70%"
                        align="left"
                      >
                        Assignment submitted
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </p>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
}

export default AssignmentModal
