/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import practice from 'assets/images/video-interview-practice.png'
import CustomHelmet from 'components/smart/CustomHelmet'
import HeaderInterview from 'components/smart/Header/view/HeaderInterview'
import { Box, Button, LinearProgress } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import TimerIcon from '@mui/icons-material/Timer'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import VideoRecording from './VideoRecording'
import { useNavigate } from 'react-router-dom'

const mui = {
  btnContained: {
    fontWeight: '400',
    fontSize: '14px',
    width: '100%',
    height: '50px',
  },
  btnContainedFull: {
    fontWeight: '400',
    fontSize: '14px',
    width: '100%',
    height: '50px',
  },
  btnOutline: {
    fontWeight: '400',
    fontSize: '14px',
    width: '100%',
    height: '50px',
  },
}

/**
 * This function renders a video interview practice component.
 *
 * @return {JSX.Element} The JSX representation of the video interview practice component.
 */
function VideoInterviewPractice() {
  const navigate = useNavigate()

  const [state, setState] = useState({
    videoStart: false,
    continueState: false,
    startInterview: false,
    changeLayer: false,
    closeLayer: false,
    nextQuestion: false,
    finalLayer: false,
    interviewQuestions: [],
    questionNumber: 0,
  })
  const [countdown, setCountdown] = useState()

  /**
   * Returns a Timer component that displays the time left in minutes and seconds.
   *
   * @param {number} timeLeft - The time left in seconds.
   * @return {JSX.Element} - A JSX element that displays the time left in minutes and seconds.
   */
  const Timer = ({ timeLeft }) => {
    const minuteInterval = Math.floor((timeLeft / 60) % 60)
    const secondsInterval = Math.floor(timeLeft % 60)

    const addZeroesIfSingleInteger = (n) => {
      return (n < 10 ? '0' : '') + n
    }

    return (
      <p className={`w-[60px] ${countdown > 10 ? '' : 'text-adx-error'}`}>
        0{minuteInterval}:{addZeroesIfSingleInteger(secondsInterval)}
      </p>
    )
  }

  /**
   * Asynchronously generates an array of 5 random interview questions from a
   * pre-defined list of questions. The interview questions are then stored in
   * the component's state in the interviewQuestions field.
   *
   * @return {void} No return value.
   */
  const loadQuestion = async () => {
    let questionsArray = [
      'How do you usually spend your weekend?',
      'Can you tell me a little about yourself?',
      'What is your favourite movie and why?',
      'How would your friends describe you?',
      'What do you like to do outside of work?',
      'How do you want to improve yourself in the next year?',
      'What are your long term career goals?',
      "Give examples of ideas you've had or implemented.",
      'List five words that describe your character.',
      'Why did you choose your major?',
      'What are the qualities of a good leader?',
      'Who are your heroes?',
      'What is your greatest achievement outside of work?',
      'Can you tell me about a difficult work situation and how you overcame it?',
      "What's the most important thing you learned in school?",
      'What is your greatest fear?',
      'Who has impacted you most in your career and how?',
    ]
    let randomArray = []
    let B = 5
    let N = 0
    for (let i = 0; i < 5; i = i + 1) {
      let num = Math.floor(Math.random() * questionsArray.length - N)
      N = Math.min(N + 1, B)
      let questions = questionsArray.splice(num, 1)
      randomArray.push(questions[0])
    }
    setState({ ...state, interviewQuestions: randomArray })
  }

  useEffect(() => {
    loadQuestion()
  }, [])

  useEffect(() => {
    let interval = null

    if (countdown) {
      interval = setInterval(() => {
        setCountdown((secondsTemp) => secondsTemp - 1)
      }, 1000)
    }

    if (countdown === 0) {
      clearInterval(interval)
    }

    return () => {
      clearInterval(interval)
    }
  }, [countdown])

  return (
    <>
      <Box className="flex flex-col h-screen ">
        <CustomHelmet
          title="Video Interview | adnexio.jobs"
          url="https://adnexio.jobs/video-interview"
        />
        <HeaderInterview />
        <Box className="flex flex-row h-full bg-adx-defaultBackground sm:items-center sm:justify-center">
          {state.continueState ? (
            // Component to start the practice
            <Box className="flex flex-col w-full bg-adx-white lg:my-5 lg:grid lg:max-w-7xl lg:grid-cols-5 lg:flex-row">
              {/* Left Column */}
              <VideoRecording
                setParentState={setState}
                parentState={state}
                parentCountdown={countdown}
                parentSetCountdown={setCountdown}
              />
              {/* Right Column */}
              <Box
                className={`flex h-full flex-col items-center gap-5 lg:col-span-2 lg:items-center lg:justify-center ${
                  !state.videoStart ? 'lg:space-y-80' : 'lg:space-y-80'
                }`}
              >
                {/* Control the component on top right */}
                {!state.videoStart ? (
                  <Box className="flex flex-col items-center justify-center gap-3 px-5 mt-5 text-center lg:w-full lg:items-start lg:text-start">
                    <p className="text-2xl font-medium text-adx-darkText">Getting ready...</p>
                    <p className="text-sm font-normal text-adx-darkText">
                      Your session will be ready in just a few moment
                    </p>
                  </Box>
                ) : !state.changeLayer ? (
                  <Box className="flex flex-col gap-3 px-5 mt-5 text-center lg:w-full lg:text-start">
                    <p className="text-2xl font-medium text-adx-darkText">
                      Start your recording once you are ready
                    </p>
                    <p className="text-sm font-normal text-adx-darkText">
                      You will have one minute to answer each of the five questions, for a total of
                      five minutes.
                    </p>
                    <p className="text-sm font-normal text-adx-darkText">Goodluck!</p>
                  </Box>
                ) : !state.finalLayer ? (
                  <Box className="flex flex-col gap-3 px-5 mt-5 text-center lg:w-full lg:text-start">
                    <p className="text-sm">Question {state.questionNumber + 1} out of 5</p>
                    <p className="text-2xl font-medium">
                      {state.interviewQuestions[state.questionNumber]}
                    </p>
                  </Box>
                ) : (
                  <Box className="flex flex-col gap-3 px-5 mt-5 text-center lg:w-full lg:text-start">
                    <p className="text-2xl font-medium text-adx-darkText">
                      Create your online video interview now
                    </p>
                    <p className="text-sm font-normal text-adx-darkText">
                      Feel confident already? Create your video interview now or you can keep on
                      practice.
                    </p>
                  </Box>
                )}

                {/* Control the component on bottom right */}
                {!state.startInterview ? (
                  <Box className="w-full px-5">
                    <Button
                      sx={mui.btnContained}
                      disabled={!state.videoStart}
                      variant="contained"
                      disableElevation
                      startIcon={
                        <FiberManualRecordIcon
                          sx={{
                            color: state.videoStart ? 'red' : 'gray',
                            width: '12px',
                            height: '12px',
                            backgroundColor: 'white',
                            borderRadius: '100%',
                          }}
                        />
                      }
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          startInterview: true,
                          changeLayer: true,
                        }))
                        setCountdown(65)
                      }}
                    >
                      Start Recording
                    </Button>
                  </Box>
                ) : !state.closeLayer ? (
                  <Box className="flex h-[50px] w-full flex-col justify-center gap-3 px-5 text-center lg:text-start">
                    <p className="text-sm font-medium text-adx-lightGreen">
                      Starting your video...
                    </p>
                    <LinearProgress color="primary" />
                  </Box>
                ) : (
                  <Box className="flex w-full gap-2 px-5">
                    {!state.finalLayer && (
                      <>
                        <Box className="flex w-[85px] items-center justify-center">
                          <TimerIcon sx={{ color: countdown > 10 ? 'blue' : 'red' }} />
                          <Timer timeLeft={countdown} />
                        </Box>
                      </>
                    )}

                    {state.questionNumber !== state.interviewQuestions.length - 1 ? (
                      <Button
                        sx={mui.btnOutline}
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            questionNumber: prevState.questionNumber + 1,
                            nextQuestion: !prevState.nextQuestion,
                          }))
                          setCountdown(65)
                        }}
                        endIcon={<ArrowForwardIcon />}
                        variant="outlined"
                        disableElevation
                      >
                        Next Question
                      </Button>
                    ) : !state.finalLayer ? (
                      <Button
                        sx={mui.btnContained}
                        onClick={() => {
                          setCountdown(null)
                        }}
                        startIcon={
                          <VideocamOffIcon
                            sx={{
                              color: 'white',
                            }}
                          />
                        }
                        variant="contained"
                        disableElevation
                      >
                        Finish Recording
                      </Button>
                    ) : (
                      <>
                        <Box className="flex flex-col w-full gap-3">
                          <Button
                            sx={mui.btnContainedFull}
                            onClick={() => window.location.reload()}
                            variant="outlined"
                            disableElevation
                          >
                            Practice again
                          </Button>
                          <Button
                            sx={mui.btnContainedFull}
                            onClick={() => navigate('/video-interview/create')}
                            variant="contained"
                            disableElevation
                          >
                            Create now
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            // Component before starting the practice
            <Box className="flex flex-col items-center justify-center h-full max-w-5xl gap-5 px-5 bg-adx-white">
              <img
                src={practice}
                alt="practice"
                className="object-fill object-center sm:max-w-sm lg:max-w-md"
              />
              <Box className="flex flex-col max-w-5xl gap-3 sm:max-w-xl lg:max-w-2xl xl:max-w-3xl">
                <p className="mx-auto text-xl font-medium text-center text-adx-black lg:text-2xl">
                  Let’s practice
                </p>
                <p className="flex text-sm font-normal text-center text-adx-darkerGray">
                  You will be practicing 5 interview questions related to your personal and
                  professional background. Approach the exercise with confidence and you're ready to
                  begin!
                </p>
              </Box>
              <Button
                sx={mui.btnContained}
                variant="contained"
                disableElevation
                onClick={() => setState({ ...state, continueState: true })}
              >
                Continue
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default VideoInterviewPractice
