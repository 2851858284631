import axios, { getData } from "../../../lib/axios"

export const jobSaved = async (method, data, id) => {
    let requestType = method
    let url = (id) ? `/job_seekers/save_job_advertisements_list/${id}` : `job_seekers/save_job_advertisements_list/`
    return axios({
        method: requestType,
        url: url,
        data: data
    }).then(getData)
}

