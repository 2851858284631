import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form, useField, Field } from 'formik'
import * as Yup from 'yup'
import InputMask from 'react-input-mask'

import { myAccountUpdate } from 'services/api/authentication'
import { profile } from 'services/api/profile'
import { ProfileContext } from 'contexts/ProfileContext'
import { authContext } from 'contexts/AuthContext'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'

const mui = {
  inputCSS: {
    fontSize: '14px',
    '& input::placeholder': {
      fontSize: '14px',
    },
  },
  labelCSS: {
    fontSize: '14px',
  },
}

const MyTextField = ({ placeholder, label, InputProps, shrink, InputLabelProps, ...props }) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return (
    <TextField
      variant="standard"
      placeholder={placeholder}
      label={label}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      {...field}
      helperText={errorText}
      error={!!errorText}
      shrink={shrink}
    />
  )
}

const MyMaskField = ({ placeholder, label, InputProps, shrink, InputLabelProps, ...props }) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return (
    <InputMask mask="999-99999999999999" maskChar={null} {...field}>
      {(inputProps) => {
        return (
          <TextField
            variant="standard"
            placeholder={placeholder}
            label={label}
            InputProps={InputProps}
            InputLabelProps={InputLabelProps}
            {...inputProps}
            helperText={errorText}
            error={!!errorText}
            shrink={shrink}
            type="tel"
          />
        )
      }}
    </InputMask>
  )
}

const MyDatePicker = (props) => {
  const { label, name, InputProps, ...rest } = props
  return (
    <Field name={name}>
      {({ form, field, meta }) => {
        const { setFieldValue } = form
        const { value } = field
        const errorText = meta.error && meta.touched ? meta.error : ''
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={label}
              onChange={(val) => setFieldValue(name, val)}
              value={value}
              inputFormat={'dd/MM/yyyy'}
              disableFuture
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params?.InputProps,
                      ...InputProps,
                    }}
                    error={!!errorText}
                    helperText={errorText}
                    variant="standard"
                    {...rest}
                  />
                )
              }}
            />
          </LocalizationProvider>
        )
      }}
    </Field>
  )
}

const AboutMeForm = ({
  aboutOpen,
  setAboutOpen,
  profileData,
  newDOB,
  dobData,
  setOpenSnack,
  setSnackProps,
  loading,
  setLoading,
}) => {
  const { getProfile, getDob } = useContext(ProfileContext)
  const { setAuthData } = useContext(authContext)

  const [initialValues, setInitialValues] = useState({
    name: '',
    dob: null,
    phone: '',
    email: '',
    address: '',
    postal_code: '',
    state: '',
    country: '',
  })

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() - 1)

  const validationSchema = Yup.object({
    name: Yup.string().required('Enter your name'),
    dob: Yup.date()
      .typeError('Enter your birth date')
      .max(tomorrow, "Birth date must be before today's date")
      .required(),
    phone: Yup.string()
      .typeError('Enter your phone number')
      .required('Enter your phone number')
      .min(11, 'Phone number must be at least 10 digits')
      .max(18),
  })

  const updateProfile = async (val, setErrors) => {
    let phoneNumber = val.phone.split('-')
    newDOB = new Date(val.dob)

    let MyDOB =
      ('0' + newDOB.getDate()).slice(-2) +
      '/' +
      ('0' + (newDOB.getMonth() + 1)).slice(-2) +
      '/' +
      newDOB.getFullYear()

    const { address, postal_code, state, country } = val

    try {
      setOpenSnack(false)
      setLoading(true)
      await myAccountUpdate({ name: val.name })
      await profile({
        contact_operator: phoneNumber[0],
        contact_no: phoneNumber[1],
        dob: MyDOB,
        address,
        postal_code,
        state,
        country,
      })
      await getProfile()
      await getDob()
      await setAuthData()
      setSnackProps({
        severity: 'success',
        message: 'Your save was successful.',
      })
      setOpenSnack(true)
    } catch (e) {
      if (e.data.errors) {
        if ('contact_operator' in e.data.errors) {
          setErrors({ phone: e.data.errors['contact_operator'] })
        } else if ('contact_no' in e.data.errors) {
          setErrors({ phone: e.data.errors['contact_no'] })
        } else {
          setErrors(e.data.errors)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  window.scroll(0, 0)

  useEffect(() => {
    setInitialValues({
      name: profileData.name,
      dob: newDOB,
      phone: profileData.contact_number,
      email: profileData.email,
      address: dobData.address,
      postal_code: dobData.postal_code,
      state: dobData.state,
      country: dobData.country,
    })
  }, [
    profileData.name,
    newDOB,
    profileData.contact_number,
    profileData.email,
    dobData.address,
    dobData.postal_code,
    dobData.state,
    dobData.country,
  ])

  return (
    <div>
      <div className="mb-6 flex items-center justify-between">
        <div className="font-semibold">About Me</div>
        <Button
          color="primary"
          startIcon={<ChevronLeftRoundedIcon />}
          onClick={() => setAboutOpen(!aboutOpen)}
          style={{
            textTransform: 'none',
            fontFamily: 'Poppins',
            color: 'gray',
            outline: 'none',
          }}
        >
          Back
        </Button>
      </div>

      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(data, { setErrors }) => {
          updateProfile(data, setErrors)
        }}
      >
        {({ dirty }) => (
          <Form autoComplete="off">
            <div className="flex flex-col space-y-6 pl-2 lg:w-4/5">
              <MyTextField
                label="Name"
                name="name"
                InputProps={{ sx: mui.inputCSS }}
                InputLabelProps={{ sx: mui.labelCSS }}
              />
              <MyDatePicker
                name="dob"
                label="Birth Date"
                InputProps={{ sx: mui.inputCSS }}
                InputLabelProps={{ sx: mui.labelCSS }}
              />
              <MyMaskField
                label="Phone"
                name="phone"
                InputProps={{ sx: mui.inputCSS }}
                InputLabelProps={{ sx: mui.labelCSS }}
              />
              <TextField
                variant="standard"
                disabled
                label="Email"
                name="email"
                defaultValue={profileData.email}
                InputProps={{ sx: mui.inputCSS }}
                InputLabelProps={{ sx: mui.labelCSS }}
              />
              <MyTextField
                label="Home Address"
                name="address"
                InputProps={{ sx: mui.inputCSS }}
                InputLabelProps={{ sx: mui.labelCSS }}
              />
              <div className="flex justify-between gap-4">
                <MyTextField
                  label="Postal Code"
                  name="postal_code"
                  fullWidth
                  InputProps={{ sx: mui.inputCSS }}
                  InputLabelProps={{ sx: mui.labelCSS }}
                />
                <MyTextField
                  label="State"
                  name="state"
                  InputProps={{ sx: mui.inputCSS }}
                  InputLabelProps={{ sx: mui.labelCSS }}
                />
              </div>
              <MyTextField
                label="Country"
                name="country"
                InputProps={{ sx: mui.inputCSS }}
                InputLabelProps={{ sx: mui.labelCSS }}
              />
            </div>
            <div className="relative mt-8 flex items-center justify-center">
              <Button
                disabled={!dirty || loading}
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  textTransform: 'none',
                  fontWeight: '400',
                  fontSize: '13px',
                  padding: '5px 20px',
                  boxShadow: 'none',
                }}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AboutMeForm
