import axios, { getData } from "lib/axios"

export const courseApplications = async (
  method = "get",
  data = null,
  id = null,
  page = 1
) => {
  let url = id
    ? `/job_seekers/edu_applications/${id}`
    : `/job_seekers/edu_applications?page=${page}`
  return axios({
    method: method,
    url: url,
    data: data,
  }).then(getData)
}

export const selfAppliedCourse = async (data) => {
  return axios.post("/job_seekers/edu_applications/apply", data).then(getData)
}

export const selfJoinWaitlist = async (data) => {
  return axios
    .post("/job_seekers/edu_applications/waitlist", data)
    .then(getData)
}

export const selfWithdrawApplication = async (courseID) => {
  return axios
    .put(`/job_seekers/edu_applications/withdraw/${courseID}`)
    .then(getData)
}

export const confirmCourseParticipation = async (id) => {
  return axios.put(`/job_seekers/edu_applications/confirm/${id}`).then(getData)
}
