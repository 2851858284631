import { Button, IconButton } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { mobile_new_header, new_header } from '../content/new_header'
import Menu from '../components/Menu'
import { useContext } from 'react'
import { authContext } from 'contexts/AuthContext'
import { useState } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import SettingsModal from 'components/smart/SettingsModal'
import DeleteAccount from 'pages/profile/components/DeleteAccount'
import { ReactComponent as AdnexioJobs } from '../../../../assets/logos/adnexio-jobs.svg'
import NotificationButton from '../components/NotificationButton'
import ProfileButton from '../components/ProfileButton'
import Loader from 'react-loader-spinner'

const mui = {
  menuIconBtn: {
    color: 'white',
    '&:focus': {
      outline: 'none',
    },
  },
  menuIcon: {
    fontSize: '30px',
    color: '#1B8379',
  },
}

const NewHeader = () => {
	const { auth } = useContext(authContext)
	const isLoggedIn = auth.isAuth
	const [openMobileMenu, setOpenMobileMenu] = useState(false)
	const [openSetting, setOpenSetting] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleMenu = () => {
		if (openMobileMenu) {
			setOpenMobileMenu(false)
			document.body.style.overflow = 'unset'
		} else if (!openMobileMenu) {
			setOpenMobileMenu(true)
		}
	}

	const handleSettings = () => {
		setOpenSetting(true)
		setOpenMobileMenu(false)
	}
	
	return (
		<>
			{loading === true && (
				<>
					<div
						style={{
						position: 'fixed',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						zIndex: '999999999',
						}}
					>
						<Loader type="Oval" color="#09b7b7" height={55} width={55} />
					</div>
					<div
						style={{
						position: 'fixed',
						zIndex: '999999',
						width: '100vw',
						height: '100vh',
						background: 'black',
						opacity: '0.7',
						}}
					/>
				</>
			)}

			<div className="relative">
				{openMobileMenu && (
					<div onClick={handleMenu} className="fixed z-20 w-screen h-screen lg:hidden bg-black/70" />
				)}

				<div className="relative z-20 flex h-[75px] items-center justify-between bg-white px-2 text-sm font-medium lg:px-[60px]">
					<div className="lg:hidden">
					{!openMobileMenu && (
						<IconButton sx={mui.menuIconBtn} onClick={handleMenu} size="large">
						<MenuRoundedIcon sx={mui.menuIcon} />
						</IconButton>
					)}

					{openMobileMenu && (
						<IconButton sx={mui.menuIconBtn} onClick={handleMenu} size="large">
						<CloseRoundedIcon sx={mui.menuIcon} />
						</IconButton>
					)}
					</div>
					<div className="flex h-full">
					<Link to={'/'} className="flex h-full items-center px-[10.5px]">
						<AdnexioJobs />
					</Link>
					<div className="hidden h-full gap-6 ml-10 lg:flex">
						{new_header.map((item, index) => (
							<React.Fragment key={index}>
								{item.link ? (
								<Link
									to={item.link}
									className="flex h-full items-center px-[10.5px] capitalize border-y-[2px] border-adx-white hover:border-b-adx-darkGreen hover:text-adx-darkGreen"
								>
									<p>{item.name}</p>
								</Link>
								) : (
								<Menu key={index} panelComponent={item.menuComponent} fullWidth={item.fullWidth}>
									{item.name}
								</Menu>
								)}
							</React.Fragment>
						))}
					</div>
					</div>

					<div className="flex items-center mr-2">
					{!isLoggedIn ? (
						<div className="flex gap-[10px]">
						<div className="hidden lg:block">
							<Button className="whitespace-nowrap" href="/signup" variant="outlined">
							Sign up
							</Button>
						</div>
						<div>
							<Button className="whitespace-nowrap" href="/signin" variant="contained">
							Log in
							</Button>
						</div>
						</div>
					) : (
						<>
						<NotificationButton setOpenMobileMenu={setOpenMobileMenu} />
						<ProfileButton />
						</>
					)}
					</div>
				</div>

				{openMobileMenu && (
					<div className="absolute z-50 flex flex-col w-full gap-1 px-6 pt-1 pb-6 text-sm capitalize shadow-2xl bg-adx-defaultBackground lg:hidden">
						{mobile_new_header.map((item, index) => (
							<React.Fragment key={index}>
								{item.link ? (
									<a href={item.link} className="py-2 hover:text-adx-darkGreen">
									{item.name}
									</a>
								) : (
									<Popover>
										{({ open }) => (
											<>
												<Popover.Button
													className={`${
													open ? 'text-adx-darkGreen' : ''
													} flex w-full items-center justify-between py-2 hover:text-adx-darkGreen`}
												>
													<p className="capitalize">{item.name}</p>
													<ExpandMoreIcon className={`${open ? 'rotate-180' : ''}`} />
												</Popover.Button>
												<Transition
													as={Fragment}
													enter="transition ease-out duration-100"
													enterFrom="opacity-0 -translate-y-1"
													enterTo="opacity-100 translate-y-0"
													leave="transition ease-in duration-150"
													leaveFrom="opacity-100 translate-y-0"
													leaveTo="opacity-0 -translate-y-1"
												>
													<Popover.Panel className={`ml-6 mt-1 flex flex-col gap-1`}>
													{item.list.map((child, index) => (
														<a
														key={index}
														href={child.link}
														className="py-2 hover:text-adx-darkGreen"
														>
														{child.name}
														</a>
													))}
													</Popover.Panel>
												</Transition>
											</>
										)}
									</Popover>
								)}
							</React.Fragment>
						))}
						{isLoggedIn && (
							<button onClick={handleSettings} className="py-2 text-left hover:text-adx-darkGreen">
								Settings
							</button>
						)}
					</div>
				)}

				<SettingsModal
					openSetting={openSetting}
					setOpenSetting={setOpenSetting}
					loading={loading}
					setLoading={setLoading}
					setOpenDelete={setOpenDelete}
					openDelete={openDelete}
				/>
				<DeleteAccount
					setOpenDelete={setOpenDelete}
					openDelete={openDelete}
					loading={loading}
					setLoading={setLoading}
				/>
			</div>
		</>
	)
}

export default NewHeader
