import { useState, useEffect, useContext } from 'react'

import { Formik, Form, Field, useField } from 'formik'
import * as Yup from 'yup'

import { ProfileContext } from 'contexts/ProfileContext'
import { authContext } from 'contexts/AuthContext'
import { qualifications, academicFields, grades, countries } from 'services/api/dropdown_list'
import { educations } from 'services/api/profile'

import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const CustomMenuItem = withStyles({
  root: {
    fontFamily: 'Poppins',
    fontSize: '14px',
  },
})(MenuItem)

const mui = {
  inputCSS: {
    fontSize: '14px',
    '& input::placeholder': {
      fontSize: '14px',
    },
  },
  selectCSS: {
    fontSize: '14px',
  },
  labelCSS: {
    fontSize: '14px',
  },
}

const MyTextField = ({
  placeholder,
  label,
  InputProps,
  InputLabelProps,
  className,
  rows,
  rowsMax,
  multiline,
  type,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return (
    <TextField
      variant="standard"
      placeholder={placeholder}
      label={label}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      className={className}
      {...field}
      helperText={errorText}
      error={!!errorText}
      multiline={multiline}
      rows={rows}
      maxRows={rowsMax}
      type={type}
      disabled={disabled}
    />
  )
}

const MyDatePicker = (props) => {
  const { label, name, InputProps, ...rest } = props
  return (
    <Field name={name}>
      {({ form, field, meta }) => {
        const { setFieldValue } = form
        const { value } = field
        const errorText = meta.error && meta.touched ? meta.error : ''
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={label}
              onChange={(val) => setFieldValue(name, val)}
              value={value}
              inputFormat={'yyyy'}
              views={['year']}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params?.InputProps,
                      ...InputProps,
                    }}
                    error={!!errorText}
                    helperText={errorText}
                    variant="standard"
                    fullWidth
                    {...rest}
                  />
                )
              }}
            />
          </LocalizationProvider>
        )
      }}
    </Field>
  )
}

const EducationForm = ({
  educationOpen,
  setEducationOpen,
  aboutOpen,
  setAboutOpen,
  setOpenSnack,
  educationID,
  loading,
  setLoading,
  setSnackProps,
}) => {
  const { getEducation } = useContext(ProfileContext)
  const { setAuthData } = useContext(authContext)

  const [qualificationList, setQualificationList] = useState([])
  const [academicFieldList, setAcademicFieldList] = useState([])
  const [courseList, setCourseList] = useState([])
  const [gradeList, setGradeList] = useState([])
  const [countriesList, setCountriesList] = useState([])
  const [universityList, setUniversityList] = useState([])
  const [academicFieldListFilter, setAcademicFieldListFilter] = useState([])
  const [gradeListFilter, setGradeListFilter] = useState([])

  const [initialValues, setInitialValues] = useState({
    qualification: '',
    fieldOfStudy: '',
    major: '',
    country: '',
    university: '',
    grade: '',
    cgpa: '',
    startYear: null,
    endYear: null,
  })

  const validationSchema = Yup.object({
    qualification: Yup.string().required('Select your qualification'),
    fieldOfStudy: Yup.string().required('Select your field of study'),
    major: Yup.string().required('Select your major'),
    country: Yup.string().required('Select your university location'),
    university: Yup.string().required('School or university is required'),
    grade: Yup.string().required('Select your grade'),
    cgpa: Yup.number()
      .when('grade', {
        is: (grade) => grade === 'CGPA',
        then: Yup.number()
          .typeError('Value must be a number')
          .max(4.0, 'CGPA must be less than or equal to 4')
          .required('Enter your pointer'),
      })
      .when('grade', {
        is: (grade) => grade !== 'CGPA',
        then: Yup.number().nullable(true),
      }),
    startYear: Yup.date()
      .typeError('Value must be a date')
      .max(
        new Date().getFullYear(),
        `Start year field must be earlier than ${new Date().getFullYear()}`
      )
      .required('Enter your start year')
      .nullable(),
    endYear: Yup.date()
      .typeError('Value must be a date')
      .min(Yup.ref('startYear'), 'End year cannot be before start year')
      .required('Enter your end year')
      .nullable(),
  })

  const getDropdownValue = async () => {
    try {
      let resQualifications = await qualifications()
      setQualificationList(resQualifications.data)

      let resAcademicField = await academicFields()
      setAcademicFieldList(resAcademicField.data)

      let resGrade = await grades()
      setGradeList(resGrade.data)

      let resCountries = await countries()
      setCountriesList(resCountries.data)
    } catch (e) {
      console.log(e)
    }
  }

  const submitEducation = async (values, setErrors) => {
    values.start_year = values.startYear.getFullYear()
    values.end_year = values.endYear.getFullYear()
    values.academic_field = values.fieldOfStudy
    values.course = values.major
    values.qualification_id = values.qualification

    if (educationID === null) {
      try {
        setOpenSnack(false)
        setLoading(true)
        await educations('post', values, null)
        await getEducation()
        await setAuthData()
        setSnackProps({
          severity: 'success',
          message: 'Your save was successful.',
        })
        setOpenSnack(true)
      } catch (e) {
        if (e.status === 403) {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        } else if (e.status === 422) {
          const errors = e.data.errors
          const errorsData = {}
          Object.keys(errors).forEach((item, index) => {
            errorsData[item] = Object.values(errors)[index][0]
          })
          setErrors(errorsData)
        }
      } finally {
        setLoading(false)
        setEducationOpen(!educationOpen)
      }
    } else if (educationID !== null) {
      try {
        setOpenSnack(false)
        setLoading(true)
        await educations('put', values, educationID)
        await getEducation()
        await setAuthData()
        setSnackProps({
          severity: 'success',
          message: 'Your save was successful.',
        })
        setOpenSnack(true)
      } catch (e) {
        if (e.status === 403) {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        } else if (e.status === 422) {
          const errors = e.data.errors
          const errorsData = {}
          Object.keys(errors).forEach((item, index) => {
            errorsData[item] = Object.values(errors)[index][0]
          })
          setErrors(errorsData)
        }
      } finally {
        setLoading(false)
        setEducationOpen(!educationOpen)
      }
    }
  }

  const deleteEducation = async () => {
    setLoading(true)
    try {
      await educations('delete', null, educationID)
      await getEducation()
      window.open('/profile', '_self')
    } catch (e) {
      setSnackProps({
        severity: 'error',
        message: e.data.message,
      })
      setLoading(false)
      setOpenSnack(true)
    }
  }

  const loaderHandler = () => {
    setLoading(false)
  }

  const getUserEducation = async (id) => {
    try {
      let res = await educations('get', null, id)

      academicFieldList.length !== 0 &&
        setCourseList(
          academicFieldList.find((item) => item.academic_field === res.data.academic_field).courses
        )
      countriesList.length !== 0 &&
        setUniversityList(countriesList.find((item) => item.name === res.data.country).universities)

      let yearStart = new Date()
      let yearEnd = new Date()

      setInitialValues({
        qualification: res.data.qualification_id,
        fieldOfStudy: res.data.academic_field,
        major: res.data.course,
        country: res.data.country,
        university: res.data.university,
        grade: res.data.grade,
        cgpa: res.data.grade !== 'CGPA' ? '' : res.data.cgpa,
        startYear: new Date(yearStart.setFullYear(res.data.start_year)),
        endYear: new Date(yearEnd.setFullYear(res.data.end_year)),
      })
    } catch (e) {
      console.log(e)
    } finally {
      setTimeout(loaderHandler, 2000)
    }
  }

  useEffect(() => {
    getDropdownValue()
    if (educationID) {
      setLoading(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (educationID && academicFieldList.length !== 0 && countriesList !== 0) {
      getUserEducation(educationID)
      handleQualificationChange(initialValues.qualification)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [academicFieldList, countriesList])

  window.scroll(0, 0)

  const handleQualificationChange = (value) => {
    const schoolFieldStudyId = [17, 18, 19]
    if ([1, 2].includes(value)) {
      setAcademicFieldListFilter(
        [...academicFieldList].filter((item) => schoolFieldStudyId.includes(item.id))
      )
    } else {
      setAcademicFieldListFilter(
        [...academicFieldList].filter((item) => !schoolFieldStudyId.includes(item.id))
      )
    }
    if ([1].includes(value)) {
      const schoolGradeId = [11, 12]
      setGradeListFilter([...gradeList].filter((item) => schoolGradeId.includes(item.id)))
    } else {
      setGradeListFilter([...gradeList])
    }
    if (value === '') {
      setAcademicFieldListFilter([])
      setGradeListFilter([])
      setCourseList([])
    }
  }

  const handleFieldOfStudyChange = (value) => {
    if (value === '') {
      setCourseList([])
    } else {
      setCourseList(academicFieldList.find((course) => course.academic_field === value).courses)
    }
  }

  return (
    <div>
      <div className="mb-2 flex items-center justify-between">
        <div className="font-semibold">Education</div>
        <Button
          color="primary"
          startIcon={<ChevronLeftRoundedIcon />}
          onClick={() => setEducationOpen(!educationOpen)}
          style={{
            textTransform: 'none',
            fontFamily: 'Poppins',
            color: 'gray',
            outline: 'none',
          }}
        >
          Back
        </Button>
      </div>

      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(data, { setErrors }) => {
          submitEducation(data, setErrors)
        }}
      >
        {({ values, errors, touched, dirty, handleChange, setFieldValue, setTouched }) => (
          <Form autoComplete="off">
            <div className="flex flex-col space-y-5 pl-2">
              <FormControl
                variant="standard"
                className="lg:w-4/5"
                error={touched.qualification && Boolean(errors.qualification)}
              >
                <InputLabel sx={mui.labelCSS}>Qualification</InputLabel>
                <Select
                  variant="standard"
                  sx={mui.selectCSS}
                  name="qualification"
                  value={values.qualification}
                  onChange={async (e) => {
                    setFieldValue('qualification', e.target.value, false)
                    // Change from school to uni
                    if (![1, 2].includes(e.target.value) && [1, 2].includes(values.qualification)) {
                      setFieldValue('university', '', false)
                      setTouched(['university'], false)
                      setFieldValue('fieldOfStudy', '', false)
                      setFieldValue('major', '', false)
                      setCourseList([])
                    }
                    // Change from uni to school
                    else if (
                      [1, 2].includes(e.target.value) &&
                      ![1, 2].includes(values.qualification)
                    ) {
                      setFieldValue('university', '', false)
                      setTouched(['university'], false)
                      setFieldValue('fieldOfStudy', '', false)
                      setFieldValue('major', '', false)
                      setCourseList([])
                    }
                    // Change from spm to higher
                    if (![1].includes(e.target.value) && [1].includes(values.qualification)) {
                      setFieldValue('grade', '', false)
                    } else if (
                      [1].includes(e.target.value) &&
                      ![1].includes(values.qualification)
                    ) {
                      setFieldValue('grade', '', false)
                    }
                    handleQualificationChange(e.target.value)
                  }}
                  defaultValue=""
                >
                  <CustomMenuItem value={''}>
                    <em style={{ color: 'rgba(0,0,0,0.54)' }}>None</em>
                  </CustomMenuItem>
                  {qualificationList.map((item) => (
                    <CustomMenuItem key={item.id} value={item.id}>
                      {item.qualification_name}
                    </CustomMenuItem>
                  ))}
                </Select>
                {touched.qualification && errors.qualification && (
                  <FormHelperText>{touched.qualification && errors.qualification}</FormHelperText>
                )}
              </FormControl>

              <FormControl
                variant="standard"
                className="lg:w-4/5"
                error={touched.fieldOfStudy && Boolean(errors.fieldOfStudy)}
              >
                <InputLabel sx={mui.labelCSS}>Field of Study</InputLabel>
                <Select
                  variant="standard"
                  sx={mui.selectCSS}
                  name="fieldOfStudy"
                  value={values.fieldOfStudy}
                  onChange={(e) => {
                    handleChange(e)
                    setFieldValue('major', '', false)
                    handleFieldOfStudyChange(e.target.value)
                  }}
                  defaultValue=""
                >
                  <CustomMenuItem value={''}>
                    <em style={{ color: 'rgba(0,0,0,0.54)' }}>None</em>
                  </CustomMenuItem>
                  {academicFieldListFilter.map((item) => (
                    <CustomMenuItem key={item.id} value={item.academic_field}>
                      {item.academic_field}
                    </CustomMenuItem>
                  ))}
                </Select>
                {touched.fieldOfStudy && errors.fieldOfStudy && (
                  <FormHelperText>{touched.fieldOfStudy && errors.fieldOfStudy}</FormHelperText>
                )}
              </FormControl>

              <FormControl
                variant="standard"
                className="lg:w-4/5"
                error={touched.major && Boolean(errors.major)}
              >
                <InputLabel sx={mui.labelCSS}>Major</InputLabel>
                <Select
                  variant="standard"
                  sx={mui.selectCSS}
                  name="major"
                  value={values.major}
                  onChange={handleChange}
                  defaultValue=""
                >
                  <CustomMenuItem value={''}>
                    <em style={{ color: 'rgba(0,0,0,0.54)' }}>None</em>
                  </CustomMenuItem>
                  {courseList.map((item) => (
                    <CustomMenuItem key={item.id} value={item.course}>
                      {item.course}
                    </CustomMenuItem>
                  ))}
                </Select>
                {touched.major && errors.major && (
                  <FormHelperText>{touched.major && errors.major}</FormHelperText>
                )}
              </FormControl>

              <FormControl
                variant="standard"
                className="lg:w-4/5"
                error={touched.country && Boolean(errors.country)}
              >
                <InputLabel sx={mui.labelCSS}>Country</InputLabel>
                <Select
                  variant="standard"
                  sx={mui.selectCSS}
                  name="country"
                  value={values.country}
                  onChange={(e) => {
                    handleChange(e)
                    setUniversityList(
                      countriesList.find((university) => university.name === e.target.value)
                        .universities
                    )
                  }}
                  defaultValue=""
                >
                  <CustomMenuItem value={''}>
                    <em style={{ color: 'rgba(0,0,0,0.54)' }}>None</em>
                  </CustomMenuItem>
                  {countriesList.map((item) => (
                    <CustomMenuItem key={item.id} value={item.name}>
                      {item.name}
                    </CustomMenuItem>
                  ))}
                </Select>
                {touched.country && errors.country && (
                  <FormHelperText>{touched.country && errors.country}</FormHelperText>
                )}
              </FormControl>

              {[1, 2].includes(values.qualification) && (
                <MyTextField
                  label="School"
                  name="university"
                  InputProps={{ sx: mui.inputCSS }}
                  InputLabelProps={{ sx: mui.labelCSS }}
                  className="lg:w-4/5"
                />
              )}

              {![1, 2].includes(values.qualification) && (
                <FormControl
                  variant="standard"
                  className="lg:w-4/5"
                  error={touched.university && Boolean(errors.university)}
                >
                  <InputLabel sx={mui.labelCSS}>University</InputLabel>
                  <Select
                    variant="standard"
                    sx={mui.selectCSS}
                    name="university"
                    value={values.university}
                    onChange={handleChange}
                    defaultValue=""
                  >
                    <CustomMenuItem value={''}>
                      <em style={{ color: 'rgba(0,0,0,0.54)' }}>None</em>
                    </CustomMenuItem>
                    {universityList.map((item) => (
                      <CustomMenuItem key={item.id} value={item.university_name}>
                        {item.university_name}
                      </CustomMenuItem>
                    ))}
                  </Select>
                  {touched.university && errors.university && (
                    <FormHelperText>{touched.university && errors.university}</FormHelperText>
                  )}
                </FormControl>
              )}

              <FormControl
                variant="standard"
                className="lg:w-4/5"
                error={touched.grade && Boolean(errors.grade)}
              >
                <InputLabel sx={mui.labelCSS}>Grade</InputLabel>
                <Select
                  variant="standard"
                  sx={mui.selectCSS}
                  name="grade"
                  value={values.grade}
                  onChange={(e) => {
                    handleChange(e)
                    if (e.target.value !== 'CGPA') setFieldValue('cgpa', null, false)
                  }}
                  defaultValue=""
                >
                  <CustomMenuItem value={''}>
                    <em style={{ color: 'rgba(0,0,0,0.54)' }}>None</em>
                  </CustomMenuItem>
                  {gradeListFilter.map((item) => (
                    <CustomMenuItem key={item.id} value={item.grade_name}>
                      {item.grade_detail}
                    </CustomMenuItem>
                  ))}
                </Select>
                {touched.grade && errors.grade && (
                  <FormHelperText>{touched.grade && errors.grade}</FormHelperText>
                )}
              </FormControl>

              {![1].includes(values.qualification) && values.grade === 'CGPA' && (
                <MyTextField
                  label="Pointer"
                  name="cgpa"
                  InputProps={{ sx: mui.inputCSS }}
                  InputLabelProps={{ sx: mui.labelCSS }}
                  className="w-1/2 lg:w-1/3"
                  disabled={values.grade !== 'CGPA' ? true : false}
                />
              )}

              <div className="flex space-x-5 outline-none lg:w-4/5 lg:space-x-10">
                <MyDatePicker
                  name="startYear"
                  label="Start Year"
                  InputProps={{ sx: mui.inputCSS }}
                  InputLabelProps={{ sx: mui.labelCSS }}
                />
                <MyDatePicker
                  name="endYear"
                  label="End Year"
                  InputProps={{ sx: mui.inputCSS }}
                  InputLabelProps={{ sx: mui.labelCSS }}
                />
              </div>
            </div>
            <div className="mt-8 flex justify-center space-x-5">
              <Button
                disabled={!dirty || loading}
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => setAboutOpen(aboutOpen)}
                style={{
                  textTransform: 'none',
                  fontWeight: '400',
                  fontSize: '13px',
                  padding: '5px 20px',
                  boxShadow: 'none',
                }}
              >
                Save
              </Button>
              {educationID && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={deleteEducation}
                  sx={{
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '13px',
                    padding: '5px 20px',
                    boxShadow: 'none',
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EducationForm
