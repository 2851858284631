import { Button } from '@mui/material'
import NewHeader from 'components/smart/Header/view/NewHeader'
import React from 'react'
import answerImg from '../../personality_test/assets/answer-img.png'
import questionImg from '../../personality_test/assets/question-img.png'
import grassIcon from '../../personality_test/assets/grass-icon.svg'
import peopleIcon from '../../personality_test/assets/people-icon.svg'
import shieldIcon from '../../personality_test/assets/shield-icon.svg'
import videoIvImg from '../../personality_test/assets/video-interview-2.png'
import videoIvMobile from '../../personality_test/assets/video-interview-mobile.png'

const PersonalityTest = () => {
  return (
    <>
      <NewHeader />

      {/* Landing page */}
      <div className="text-adx-darkText">
        <div className="flex flex-col-reverse justify-between gap-10 bg-gradient-to-tr from-[#B6A4EF] via-white to-adx-softGreen px-5 py-10 md:flex-row md:gap-20 md:px-[60px] md:py-[80px]">
          <div className="flex flex-col gap-5 md:w-1/2">
            <p className="text-2xl font-semibold md:text-[34px]">
              Unlock your work personality insights
            </p>
            <p className="text-sm">
              Assess your personality at work by taking this 10-minute work personality test with
              us. This test measures 5 different dimensions of your work personality.
            </p>
            <Button href="/personality-test/start" variant="contained" className="w-fit px-5">
              Get started
            </Button>
          </div>
          <div className="relative h-[250px] md:h-[300px] md:w-1/2">
            <img
              src={questionImg}
              alt="Question"
              className=" absolute w-[80%] md:right-[20%] md:w-auto"
            />
            <img
              src={answerImg}
              alt="Answer"
              className="absolute bottom-0 right-0 z-10 w-[80%] md:w-auto"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-12 py-[80px] px-5 md:px-0">
          <p className="text-center text-2xl font-semibold md:text-[34px]">Why personality test?</p>
          <div className="flex flex-col gap-5 lg:flex-row">
            <div className="relative aspect-square h-[250px] w-[300px] rounded-[20px] bg-adx-defaultBackground p-[30px]">
              <p className="text-xl font-medium">Get to know yourself better</p>
              <img src={peopleIcon} alt="People icon" className="absolute bottom-0 right-[30px]" />
            </div>
            <div className="relative aspect-square h-[250px] w-[300px] rounded-[20px] bg-adx-defaultBackground p-[30px]">
              <p className="text-xl font-medium">Build confidence at your workplace</p>
              <img src={shieldIcon} alt="Shield icon" className="absolute bottom-0 right-[30px]" />
            </div>
            <div className="relative aspect-square h-[250px] w-[300px] rounded-[20px] bg-adx-defaultBackground p-[30px]">
              <p className="text-xl font-medium">Find a workplace compatible to your personality</p>
              <img src={grassIcon} alt="Grass icon" className="absolute bottom-0 right-[30px]" />
            </div>
          </div>
        </div>
        <div className="px-5 pt-5 pb-10 md:px-[60px] md:pb-[60px]">
          <div className="flex flex-col-reverse justify-between rounded-2xl bg-adx-defaultBackground px-5 pb-10 md:flex-row md:p-[60px]">
            <div className="flex flex-col md:w-1/2">
              <p className="mb-5 text-2xl font-semibold md:text-[34px]">
                Explore <br className="md:hidden" /> video interview
              </p>
              <p className="mb-10 text-sm">
                Record a 5 minutes video interview with NEX, our AI Career Coach and NEX would match
                you with the best jobs for you.
              </p>
              <Button href="/video-interview" variant="contained" className="w-fit px-5">
                Learn more
              </Button>
            </div>
            <img
              src={videoIvImg}
              alt="Video interview"
              className="-m-[60px] hidden object-contain md:block"
            />
            <img
              src={videoIvMobile}
              alt="Video interview"
              className="mt-4 mb-[35px] object-contain md:hidden"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PersonalityTest
