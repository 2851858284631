import axios, { getData } from "lib/axios"

export const notificationList = async (props) => {
  if (props) {
    return axios.get(`notification/lists/edu_notification`).then(getData)
  } else {
    return axios.get(`notification/lists`).then(getData)
  }
}

export const notificationRead = async (id) => {
  return axios.get(`notification/read/${id}`).then(getData)
}
