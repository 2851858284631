import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import HRDHeader from '../components/HRDHeader'
import HRDPending from '../components/HRDPending'
import HRDEligibleSuccess from '../components/HRDEligibleSuccess'
import HRDEligibleFail from '../components/HRDEligibleFail'
import HRDGrantApproved from '../components/HRDGrantApproved'
import HRDGrantRejected from '../components/HRDGrantRejected'
import { useEffect } from 'react'
import { getHrdfStatus } from 'services/api/hrd'
import { useState } from 'react'
import Bugsnag from '@bugsnag/js'
import HRDCourseFull from '../components/HRDCourseFull'
import Loader from 'react-loader-spinner'

const HRDStatus = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [status, setStatus] = useState()
  const [loading, setLoading] = useState(false)

  const fetchApi = async () => {
    try {
      setLoading(true)
      const res = await getHrdfStatus(state?.nex_edu_payment_schedule_id)
      setStatus(res)
    } catch (error) {
      Bugsnag.notify(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!state?.data) {
      fetchApi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.nex_edu_payment_schedule_id])
  if (!state) {
    return <Navigate to="/courses" />
  }
  return (
    <div className="flex min-h-screen flex-col bg-adx-defaultBackground">
      {loading && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}
      <HRDHeader handleClose={() => navigate('/courses')} />
      <div className="mx-auto flex lg:min-w-[700px] max-w-3xl flex-1 flex-col justify-between bg-adx-white p-[20px] md:p-[50px] text-center ">
        {state?.data ? (
          <HRDCourseFull handleClose={() => navigate('/courses')} />
        ) : status?.hrdf_application_status_id === 1 ? (
          <HRDPending handleClose={() => navigate('/courses')} />
        ) : status?.hrdf_application_status_id === 2 ? (
          <HRDEligibleSuccess nex_edu_payment_schedule_id={state?.nex_edu_payment_schedule_id} />
        ) : status?.hrdf_application_status_id === 3 ? (
          <HRDEligibleFail nex_edu_payment_schedule_id={state?.nex_edu_payment_schedule_id} />
        ) : status?.hrdf_application_status_id === 4 ? (
          <HRDGrantApproved nex_edu_payment_schedule_id={state?.nex_edu_payment_schedule_id} />
        ) : status?.hrdf_application_status_id === 5 ? (
          <HRDGrantRejected nex_edu_payment_schedule_id={state?.nex_edu_payment_schedule_id} />
        ) : null}
      </div>
    </div>
  )
}

export default HRDStatus
