import { useState } from 'react'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import Slide from '@mui/material/Slide'
import IconButton from '@mui/material/IconButton'
import JobCard from './JobCard'

const mui = {
  jobBtn: {
    backgroundColor: '#1B8379',
    color: 'white',
    '&:hover': {
      background: '#1B8379',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    zIndex: '10',
  },
  disabledBtn: {
    backgroundColor: '#E2E2E2 !important',
    color: 'white !important',
  },
}

function Arrow(props) {
  const { direction, clickFunction, index } = props
  const icon = direction === 'left' ? <ChevronLeftRoundedIcon /> : <ChevronRightRoundedIcon />

  let jobLength = props.jobList !== undefined && props.jobList.length

  let disabled = false
  if (direction === 'left' && index === 0) {
    disabled = true
  } else if (direction === 'right' && index === Math.ceil(jobLength / 3) - 1) {
    disabled = true
  }

  return (
    <IconButton
      onClick={clickFunction}
      color="error"
      size="small"
      disabled={disabled}
      sx={!disabled ? mui.jobBtn : { ...mui.jobBtn, ...mui.disabledBtn }}
    >
      {icon}
    </IconButton>
  )
}

const DesktopView = ({ jobList }) => {
  let jobListChunk = []

  var i,
    j,
    temparray,
    chunk = 3
  for (i = 0, j = jobList.length; i < j; i += chunk) {
    temparray = jobList.slice(i, i + chunk)
    // DO NOT DELETE THIS COMMENT! -syazwan-
    // if (temparray.length === 3) {
    jobListChunk.push(temparray)
    // }
  }

  const [index, setIndex] = useState(0)
  const content = jobListChunk[index]
  const numSlides = jobListChunk.length

  const [slideIn, setSlideIn] = useState(true)
  const [slideDirection, setSlideDirection] = useState('up')

  const onArrowClick = (direction) => {
    const increment = direction === 'left' ? -1 : 1
    const newIndex = (index + increment + numSlides) % numSlides

    const oppDirection = direction === 'left' ? 'right' : 'left'
    setSlideDirection(direction)
    setSlideIn(false)

    setTimeout(() => {
      setIndex(newIndex)
      setSlideDirection(oppDirection)
      setSlideIn(true)
    }, 550)
  }

  return (
    <div className="mx-auto hidden max-w-7xl lg:block ">
      {jobList !== undefined && jobList.length !== 0 && (
        <div className="flex items-center justify-center space-x-4 p-4">
          <Arrow direction="left" clickFunction={() => onArrowClick('left')} index={index} />
          <div className="overflow-hidden  p-3">
            <Slide in={slideIn} direction={slideDirection} timeout={500}>
              <div className="flex space-x-6">
                {content.map((job) => (
                  <JobCard job={job} key={job.id} />
                ))}
              </div>
            </Slide>
          </div>
          <Arrow
            direction="right"
            clickFunction={() => onArrowClick('right')}
            index={index}
            jobList={jobList}
          />
        </div>
      )}
    </div>
  )
}

export default DesktopView
