import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loader-spinner'

import SocialMediaButton from '../../../../../components/smart/SocialMediaButton'
import Button from '@mui/material/Button'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { registerJobseeker } from '../../../../../services/api/registration/index'
import { ReactComponent as Check } from '../assets/check.svg'

import '../styles/styles.css'
import Footer from 'components/dumb/Footer'
import { clearCookies } from 'services/helper/clearCookies'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import EduRegistrationBanner from 'modules/edu/components/EduRegistrationBanner/view/EduRegistrationBanner'
import { Box, Typography } from '@mui/material'
import Bugsnag from '@bugsnag/js'

const EDUSignUp = () => {
  const [authError, setAuthError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const apiRegisterEmployer = async (values, actions) => {
    try {
      setLoading(true)
      const response = await registerJobseeker(values)
      if (response.data.access_token) {
        setModalOpen(true)
        clearCookies()
      }
    } catch (e) {
      if (e.status === 422) {
        const errors = e.data.errors
        Object.keys(errors).forEach(function (key) {
          actions.setFieldError(key, errors[key][0])
        })
      } else {
        Bugsnag.notify(e)
        setErrorMessage(
          'An unexpected error occurred. Please retry or contact us at support@adnexio.jobs for support.'
        )
        setAuthError(true)
      }
    }
    setLoading(false)
  }
  const initialValues = {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    agree_status: false,
    redirect: 'edu',
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Your full name is required.'),
    email: Yup.string().email().required('The email field is required.'),
    password: Yup.string().required('The password field is required.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match!')
      .required('The password field is required.'),
    agree_status: Yup.boolean()
      .oneOf([true], 'You must agree to the terms and conditions before registering!')
      .required('You must agree to the terms and conditions before registering!'),
  })

  return (
    <div>
      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}
      <div className="fixed top-0 z-50 block w-full lg:hidden">
        <HeaderEdu page="disableBottom" loginMode={true} />
      </div>
      <div
        id="anchor"
        className="border-box mx-8 box-border h-auto pt-20 pb-4 lg:mx-0 lg:grid lg:min-h-screen lg:grid-cols-12 lg:pb-0 lg:pt-0 "
      >
        <div className="hidden  flex-col lg:col-span-6 lg:flex lg:h-full lg:bg-adx-darkGreen">
          <EduRegistrationBanner />
        </div>
        <div className="col-span-6 h-full lg:flex lg:flex-col lg:text-center">
          <div className="relative justify-center lg:mx-auto lg:flex lg:h-full lg:w-full lg:max-w-md lg:flex-col ">
            <p className="mt-4 block text-xl text-adx-darkGreen lg:mt-0 lg:hidden ">
              Create an account
            </p>
            <p className="hidden text-3xl lg:mb-4 lg:block lg:font-bold">Sign Up</p>
            <p className="mb-4 block text-adx-darkerGray lg:mb-4">
              Already a member ?{' '}
              <Link className="text-adx-darkGreen" to="/bootcamp/signin">
                Login
              </Link>
            </p>
            <div className="mb-4 grid gap-y-2 lg:mb-4">
              {['facebook', 'google', 'linkedin', 'apple'].map((item) => (
                <SocialMediaButton type="signup" socmed={item} redirect={'edu'} key={item} />
              ))}
            </div>
            <p className="mb-2 text-center text-adx-darkerGray lg:mb-4">or create an account</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                apiRegisterEmployer(values, actions)
              }}
            >
              {({ handleChange, handleSubmit }) => (
                <Form>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    className="my-1 w-full rounded bg-adx-darkGray px-2 py-3 text-sm"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="mt-1 mb-2 text-left text-xs text-red-400"
                  />

                  <Field
                    type="text"
                    name="email"
                    placeholder="Email"
                    className="my-1 w-full rounded bg-adx-darkGray px-2 py-3 text-sm"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="mt-1 mb-2 text-left text-xs text-red-400"
                  />

                  <Field
                    autoComplete="off"
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="my-1 w-full rounded bg-adx-darkGray px-2 py-3 text-sm"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="mt-1 mb-2 text-left text-xs text-red-400"
                  />

                  <Field
                    autoComplete="off"
                    type="password"
                    name="password_confirmation"
                    placeholder="Confirm Password"
                    className="my-1 w-full rounded bg-adx-darkGray px-2 py-3 text-sm"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="password_confirmation"
                    component="div"
                    className="mt-1 mb-4 text-left text-xs text-red-400"
                  />

                  <label className="mt-2 flex items-center">
                    <div className="relative mr-2 inline-block w-10 select-none align-middle transition duration-1000 ease-in">
                      <Field
                        type="checkbox"
                        name="agree_status"
                        id="agree_status"
                        className="toggle-checkbox absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 bg-white focus:outline-none"
                      />
                      <label
                        htmlFor="agree_status"
                        className="toggle-label block h-6 cursor-pointer overflow-hidden rounded-full bg-gray-300"
                      ></label>
                    </div>
                    <div className="text-xs">
                      I agree to the{' '}
                      <Link className="font-semibold hover:underline" to="/terms-conditions">
                        Terms and Conditions.
                      </Link>
                    </div>
                  </label>
                  <ErrorMessage
                    name="agree_status"
                    component="div"
                    className="mt-1 mb-4 text-left text-xs text-red-400"
                  />

                  {authError && (
                    <p className="text-left text-xs font-semibold text-red-400">{errorMessage}</p>
                  )}

                  <div className="mt-2 text-center">
                    <Button
                      type="submit"
                      onSubmit={handleSubmit}
                      sx={{
                        backgroundColor: '#1B8379',
                        textTransform: 'capitalize',
                        padding: '6px 20px',
                        boxShadow: 'none',
                        fontWeight: 400,
                      }}
                      variant="contained"
                    >
                      Sign Up
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="mt-8 mb-6">
              <div className="text-xs">
                By signing in you agree to our{' '}
                <Link className="font-semibold hover:underline" to="/terms-conditions">
                  Terms and Conditions,{' '}
                </Link>
                <Link className="font-semibold hover:underline" to="/privacy-policy">
                  Data Policy and Cookies Policy.
                </Link>
              </div>
            </div>
          </div>
        </div>

        {modalOpen && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                width: '100vw',
                height: '100vh',
                backgroundColor: '#1B8379',
                opacity: 0.7,
              }}
              onClick={() => setModalOpen(false)}
            ></Box>
            <Box
              sx={{
                display: 'flex',
                width: `calc(100vw - (35%/2))`,
                maxWidth: '400px',
                backgroundColor: '#FFFFFF',
                position: 'absolute',
                borderRadius: 4,
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Check className="mx-auto mb-4 h-32 w-32" />
              <Typography variant="h6" sx={{ color: '#1B8379', fontWeight: 'bold' }}>
                Success
              </Typography>
              <Typography variant="body" sx={{ mb: 2, textAlign: 'center' }}>
                Please click on the verification link sent to your email to continue with the
                registration process.
              </Typography>
              <Link to="/bootcamp/signin">
                <Typography
                  variant="body"
                  sx={{ cursor: 'pointer', color: '#1B8379', fontWeight: 'bold' }}
                >
                  Click Here To Log In
                </Typography>
              </Link>
            </Box>
          </Box>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default EDUSignUp
