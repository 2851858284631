import { Error } from "@mui/icons-material"

const TabScore = ({ scoreData }) => {
    if (!scoreData) {
        return (
            <div className='py-4 max-w-2xl mx-auto space-y-5'>
                <div className='flex flex-col justify-center items-center bg-adx-gray rounded-lg p-2 py-4 mx-5 my-3'>
                    <Error sx={{
                        color: 'error.main',
                        fontSize: '3rem',
                    }} />
                    <div className='text-base font-semibold text-adx-darkText my-3'>You haven't completed your profile</div>
                    <div className='text-sm font-normal text-adx-darkText'>Please complete your profile to get the latest score!</div>
                </div>
            </div>
        )
    }
    return (
        <div className="py-4 max-w-2xl mx-auto space-y-5">
            <div className="flex items-start bg-adx-gray rounded-lg p-2 py-4 mx-5 my-3">
                <div className="mr-4">
                    <div className="border-black border-2 p-2 w-12 h-12 rounded-full font-bold text-lg flex justify-center items-center">{scoreData.academic.score.toFixed(1)}</div>
                </div>
                <div>
                    <div className="font-semibold text-sm mb-1">Academic</div>
                    <div className="font-light text-xs">{scoreData.academic.description}</div>
                </div>
            </div>
            <div className="flex items-start bg-adx-gray rounded-lg p-2 py-4 mx-5 my-3">
                <div className="mr-4">
                    <div className="border-black border-2 p-2 w-12 h-12 rounded-full font-bold text-lg flex justify-center items-center">{scoreData.experience.score.toFixed(1)}</div>
                </div>
                <div>
                    <div className="font-semibold text-sm mb-1">Experience</div>
                    <div className="font-light text-xs">{scoreData.experience.description}</div>
                </div>
            </div>
            <div className="flex items-start bg-adx-gray rounded-lg p-2 py-4 mx-5 my-3">
                <div className="mr-4">
                    <div className="border-black border-2 p-2 w-12 h-12 rounded-full font-bold text-lg flex justify-center items-center">{scoreData.extracurricular.score.toFixed(1)}</div>
                </div>
                <div>
                    <div className="font-semibold text-sm mb-1">Extracurricular</div>
                    <div className="font-light text-xs">{scoreData.extracurricular.description}</div>
                </div>
            </div>
            <div className="flex items-start bg-adx-gray rounded-lg p-2 py-4 mx-5 my-3">
                <div className="mr-4">
                    <div className="border-black border-2 p-2 w-12 h-12 rounded-full font-bold text-lg flex justify-center items-center">{scoreData.interview_score.score.toFixed(1)}</div>
                </div>
                <div>
                    <div className="font-semibold text-sm mb-1">Interview</div>
                    <div className="font-light text-xs">{scoreData.interview_score.description}</div>
                </div>
            </div>
            <div className="flex items-start bg-adx-gray rounded-lg p-2 py-4 mx-5 my-3">
                <div className="mr-4">
                    <div className="border-black border-2 p-2 w-12 h-12 rounded-full font-bold text-lg flex justify-center items-center">{scoreData.leadership.score.toFixed(1)}</div>
                </div>
                <div>
                    <div className="font-semibold text-sm mb-1">Leadership</div>
                    <div className="font-light text-xs">{scoreData.leadership.description}</div>
                </div>
            </div>
        </div>
    )
}

export default TabScore
