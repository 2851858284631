export const test_questions = [
    {
        factor: 1,
        max: 12,
        questions: [
            {
                id: 1,
                question: 'I like working with flexible timelines.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 2,
                question: 'I am more comfortable working remotely, from home or another location.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 3,
                question: 'I work better when I have a flexible schedule.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 4,
                question: 'I prefer a flexible and informal work environment.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 5,
                question: 'I am motivated by financial rewards.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 6,
                question: 'I feel more comfortable working in-person, at an office or workplace.',
                options: [
                    { id: 1, option: "This defines me very well", value: 2 },
                    { id: 2, option: "This is partially me", value: 1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: -1 },
                    { id: 5, option: "Absolutely not me", value: -2 },
                ],
            },
        ]
    },
    {
        factor: 2,
        max: 12,
        questions: [
            {
                id: 7,
                question: 'At work, I value growth and challenges.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 8,
                question: 'I am driven by my own personal growth.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 9,
                question: 'My career goals are to make a difference in my field.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 10,
                question: 'When giving feedback, I am direct and honest.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 11,
                question: 'I prefer working with a team that provides support and encouragement.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 12,
                question: 'I value innovation and change.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
        ]
    },
    {
        factor: 3,
        max: 12,
        questions: [
            {
                id: 13,
                question: 'I actively participate as a team member when collaborating with others.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 14,
                question: 'I cope with stress by seeking solutions and taking action.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 15,
                question: 'In a team setting, I am more likely to speak up and share my ideas.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 16,
                question: 'When collaborating with others, I take the lead and delegate tasks.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 17,
                question: 'I have a preference for collaborating with a team when engaged in a project.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 18,
                question: 'I am driven by recognition.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
        ]
    },
    {
        factor: 4,
        max: 12,
        questions: [
            {
                id: 19,
                question: 'I prefer working in an environment that is stable and predictable.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 20,
                question: 'I feel more at ease when I follow established procedures.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 21,
                question: 'In my job, I value stability and security.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 22,
                question: 'When mediating conflicts, I am objective and focused on the issue.',
                options: [
                    { id: 1, option: "This defines me very well", value: 2 },
                    { id: 2, option: "This is partially me", value: 1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: -1 },
                    { id: 5, option: "Absolutely not me", value: -2 },
                ],
            },
            {
                id: 23,
                question: 'I am more productive when I have a fixed routine.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 24,
                question: 'I prefer a job that has a manageable and predictable workload.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
        ]
    },
    {
        factor: 5,
        max: 8,
        questions: [
            {
                id: 25,
                question: 'My career goals are to advance to a high-level position.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 26,
                question: 'I am highly ambitious.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 27,
                question: 'I prefer to work in a competitive environment.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
            {
                id: 28,
                question: 'When faced with multiple tasks, I prioritise based on deadlines and importance.',
                options: [
                    { id: 1, option: "This defines me very well", value: -2 },
                    { id: 2, option: "This is partially me", value: -1 },
                    { id: 3, option: "I'm neutral", value: 0 },
                    { id: 4, option: "This cannot be me", value: 1 },
                    { id: 5, option: "Absolutely not me", value: 2 },
                ],
            },
        ]
    },
]