import { Box, IconButton } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useEffect, useState } from 'react'

const widthSpan = 100

//How to use:
//1. Indicate a refId as the carousel will know which container to control

const AdxCarousel = ({ children, infinite, timer, stopOnManual, indicatorColor, refId }) => {
  let interval
  const [sliderPosition, setSliderPosition] = useState(0)
  const [autoAdvance, setAutoAdvance] = useState(timer !== undefined)

  // disable because not mobile friendly //////////////////////////////////
  // const [mouseClicked, setMouseClicked] = useState(false)
  // const [touched, setTouched] = useState(false)
  // const [touchStartPosition, setTouchStartPosition] = useState(0)
  // const [touchEndPosition, setTouchEndPosition] = useState(0)
  // const [swiped, setSwiped] = useState(false)
  // const [mouseStartPosition, setMouseStartPosition] = useState(0)
  // const [mouseEndPosition, setMouseEndPosition] = useState(0)
  // const [mouseSwiped, setMouseSwiped] = useState(false)
  // disable because not mobile friendly //////////////////////////////////

  //Timer for autoplay
  const manageTimer = () => {
    clearInterval(interval)
    if (stopOnManual) {
      setAutoAdvance(false)
    }
  }
  //Left side logic for carousel
  const prevSlideHandler = () => {
    let newPosition = sliderPosition
    if (newPosition > 0) {
      newPosition = newPosition - 1
    } else if (infinite) {
      newPosition = children?.length - 1 || 0
    }
    translateFullSlides(newPosition)
    setSliderPosition(newPosition)
  }
  //Right side logic for carousel
  const nextSlideHandler = () => {
    let newPosition = sliderPosition
    if (newPosition < children.length - 1) {
      newPosition = newPosition + 1
    } else if (infinite) {
      newPosition = 0
    }
    translateFullSlides(newPosition)
    setSliderPosition(newPosition)
  }
  //Onclick Handler
  const prevClickHandler = () => {
    manageTimer()
    prevSlideHandler()
  }
  const nextClickHandler = () => {
    manageTimer()
    nextSlideHandler()
  }

  //Translating Pages
  const translateFullSlides = (newPosition) => {
    let toTranslate = -widthSpan * newPosition
    for (var i = 0; i < (children.length || 1); i++) {
      let elem = document.getElementById(`${refId}` + i)
      elem.style.transform = `translateX(` + toTranslate + `%)`
    }
  }

  const jumpToSlideHandler = (newPosition) => {
    manageTimer()
    setSliderPosition(newPosition)
    translateFullSlides(newPosition)
  }

  // disable because not mobile friendly //////////////////////////////////

  // const translatePartialSlides = (toTranslate) => {
  //   let currentTranslation = -sliderPosition * widthSpan
  //   let totalTranslation = currentTranslation + toTranslate
  //   for (var i = 0; i < (children?.length || 1); i++) {
  //     let elem = document.getElementById(`${refId}` + i)
  //     elem.style.transform = `translateX(` + totalTranslation + `%)`
  //   }
  // }

  //Touch Swiping
  // const touchStartHandler = (e) => {
  //   manageTimer()
  //   setTouchStartPosition(e.targetTouches[0].clientX)
  //   setTouchEndPosition(e.targetTouches[0].clientX)
  //   setTouched(true)
  // }
  // const touchMoveHandler = (e) => {
  //   setTouchEndPosition(e.targetTouches[0].clientX)
  //   const frameWidth = document.getElementById(refId).offsetWidth
  //   const translateDist = ((touchEndPosition - touchStartPosition) / frameWidth) * 100
  //   translatePartialSlides(translateDist)
  //   if (touched === true) {
  //     setSwiped(true)
  //   }
  // }
  // const touchEndHandler = (e) => {
  //   if (swiped) {
  //     if (touchStartPosition - touchEndPosition > 75) {
  //       nextSlideHandler()
  //     } else if (touchStartPosition - touchEndPosition < 75) {
  //       prevSlideHandler()
  //     } else {
  //       jumpToSlideHandler(sliderPosition)
  //     }
  //   }
  //   setTouched(false)
  //   setSwiped(false)
  // }

  // //Mouse Swiping
  // const mouseStartHandler = (e) => {
  //   manageTimer()
  //   e.preventDefault()
  //   setMouseStartPosition(e.clientX)
  //   setMouseEndPosition(e.clientX)
  //   setMouseClicked(true)
  // }
  // const mouseMoveHandler = (e) => {
  //   e.preventDefault()
  //   var frameWidth = document.getElementById(refId).offsetWidth
  //   if (mouseClicked) {
  //     setMouseEndPosition(e.clientX)
  //     let translateDist = ((mouseEndPosition - mouseStartPosition) / frameWidth) * 100
  //     translatePartialSlides(translateDist)
  //     setMouseSwiped(true)
  //   }
  // }
  // const mouseEndHandler = (e) => {
  //   if (mouseSwiped) {
  //     if (mouseStartPosition - mouseEndPosition > 100) {
  //       nextSlideHandler()
  //     } else if (mouseStartPosition - mouseEndPosition < -100) {
  //       prevSlideHandler()
  //     } else {
  //       jumpToSlideHandler(sliderPosition)
  //     }
  //   }
  //   setMouseSwiped(false)
  //   setMouseClicked(false)
  // }
  // disable because not mobile friendly //////////////////////////////////

  //Frame for displayed children
  const displayItems = children.map((child, index) => (
    <Box
      className="classes-CarouselItem"
      key={index}
      id={`${refId}` + index}
      sx={{
        width: '100%',
        display: 'inline-block',
        verticalAlign: 'top',
        whiteSpace: 'normal',
        transition: 'all 500ms ease-in-out',
      }}
    >
      {child}
    </Box>
  ))

  //Indicators
  const positionIndicators = children.map((child, index) => {
    let currentItem = sliderPosition === index
    return (
      <Box
        key={index}
        className="h-3 w-3"
        sx={{
          boxSizing: 'border-box',
          border: '1px solid #E2E2E2',
          borderRadius: '50%',
          margin: '2px',
          '&:hover': {
            backgroundColor: 'black',
          },
          '&:active': {
            backgroundColor: '#E2E2E2',
          },
          backgroundColor: currentItem ? indicatorColor || '#8A8A8A' : '#E2E2E2',
        }}
        onClick={() => jumpToSlideHandler(index)}
      />
    )
  })

  //For autoplay on load
  useEffect(() => {
    // disable because not mobile friendly //////////////////////////////////
    // if (autoAdvance && !mouseClicked && !touched) {
    if (autoAdvance) {
      // eslint-disable-next-line
      interval = setInterval(() => {
        nextSlideHandler()
      }, timer)
    }
    return () => {
      clearInterval(interval)
    }
  })

  return (
    <Box>
      <Box
        className="classes-container"
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          verticalAlign: 'middle',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Box
          className="classes-LeftArrow"
          sx={{ position: 'absolute', left: '1%', paddingY: 1, zIndex: 2 }}
          onClick={prevClickHandler}
        >
          <IconButton
            sx={{
              color: '#1B8379',
              opacity: 1,
              '&:focus': {
                outline: 'none',
              },
              '&:hover': {
                opacity: 1,
              },
            }}
            size="large"
          >
            <ChevronLeftIcon sx={{ fontSize: '30px' }} />
          </IconButton>
        </Box>
        <Box
          className="classes-DisplayFrame"
          id={refId}
          // disable because not mobile friendly //////////////////////////////////
          // onTouchStart={(e) => touchStartHandler(e)}
          // onTouchMove={(e) => touchMoveHandler(e)}
          // onTouchEnd={(e) => touchEndHandler(e)}
          // onMouseDown={(e) => mouseStartHandler(e)}
          // onMouseMove={(e) => mouseMoveHandler(e)}
          // onMouseUp={(e) => mouseEndHandler(e)}
          // onMouseLeave={(e) => mouseEndHandler(e)}
          // disable because not mobile friendly //////////////////////////////////
          sx={{
            width: '100vw',
            margin: 'auto',
            textAlign: 'center',
            overflowY: 'hidden',
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            zIndex: 1,
          }}
        >
          {displayItems}
        </Box>
        <Box
          className="classes-RightArrow"
          sx={{ position: 'absolute', right: '1%', paddingY: 1, zIndex: 2 }}
          onClick={nextClickHandler}
        >
          <IconButton
            sx={{
              color: '#1B8379',
              opacity: 1,
              '&:focus': {
                outline: 'none',
              },
              '&:hover': {
                opacity: 1,
              },
            }}
            size="large"
          >
            <ChevronRightIcon sx={{ fontSize: '30px' }} />
          </IconButton>
        </Box>
      </Box>
      <Box
        className="classes-Navigation cursor-pointer pt-3"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          userSelect: 'none',
        }}
      >
        {positionIndicators}
      </Box>
    </Box>
  )
}

export default AdxCarousel
