import { useState, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack' //https://github.com/wojtekmaj/react-pdf/wiki/Known-issues
import Loader from 'react-loader-spinner'
import Bugsnag from '@bugsnag/js'

import PublishIcon from '@mui/icons-material/Publish'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import useMediaQuery from '@mui/material/useMediaQuery'

import { generateFileName, getFileExtension } from 'services/helper/index'
import { signUrl } from 'services/api/storage/index'
import { storeCv } from 'services/api/profile/index'

import { authContext } from 'contexts/AuthContext'
import { ProfileContext } from 'contexts/ProfileContext'
import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'

import apple_store_badges from 'pages/registration/assets/app-store-badge.png'
import google_play_badges from 'pages/registration/assets/google-play-badge.png'
import adnexio_logo_white from 'assets/logos/adnexio-logo-white-v2-aug.png'

const mui = {
  btnText: {
    width: '100%',
    textTransform: 'none',
  },
  btnContained: {
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '13px',
    width: '100%',
    marginBottom: '10px',
  },
  btnText2: {
    textTransform: 'none',
  },
}

const UploadCV = () => {
  const navigate = useNavigate()

  const { auth, setAuthData } = useContext(authContext)
  const { getCv } = useContext(ProfileContext)

  const [loading, setLoading] = useState(false)

  const [error, setError] = useState({
    showError: false,
    textError: null,
  })

  const [isCvPicked, setIsCvPicked] = useState(false)
  const [selectedCv, setSelectedCv] = useState(null)

  const inputFile = useRef(null)

  const closeSnackInvitation = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setError({
      ...error,
      showError: false,
    })
  }

  const uploadCv = async () => {
    try {
      setLoading(true)

      const extension = getFileExtension(selectedCv.name)
      const fileName = generateFileName(auth.userId, 'document', extension.toLowerCase())

      const results = await signUrl({
        bucket: process.env.REACT_APP_AWS_BUCKET,
        key: 'cv_upload/' + fileName,
      })
      const awsRequest = axios.create()
      delete awsRequest.defaults.headers.common['Authorization']
      await awsRequest.put(results.data.url, selectedCv, {
        headers: {
          'Content-Type': selectedCv.type,
        },
      })
      await storeCv({ filename: fileName })
      await setAuthData()
      await getCv()
      navigate('/complete')
    } catch (e) {
      Bugsnag.notify(e)
    } finally {
      setLoading(false)
    }
  }

  const handleCvUpload = (e) => {
    if (e.target.files[0].type !== 'application/pdf') {
      setError({
        showError: true,
        textError: 'Only pdf formatted file is accepted.',
      })

      return
    }

    if (e.target.files[0].size > 2000000) {
      setError({
        showError: true,
        textError: 'File size must be less than 2MB',
      })

      return
    }

    setIsCvPicked(true)
    setSelectedCv(e.target.files[0])
  }

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  const lg = useMediaQuery('(min-width:1280px)')
  const md = useMediaQuery('(min-width:400px)')
  const sm = useMediaQuery('(min-width:300px)')

  const pdfWidth = () => {
    if (lg) {
      return 320
    } else if (md) {
      return 300
    } else if (sm) {
      return 200
    } else {
      return 100
    }
  }

  return (
    <div>
      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}

      <Snackbar
        open={error.showError}
        autoHideDuration={3500}
        onClose={closeSnackInvitation}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={closeSnackInvitation} severity="error" variant="filled">
          {error.textError}
        </Alert>
      </Snackbar>
      <div className="fixed top-0 z-50 block w-full lg:hidden">
        {/* <Header page="disableBottom" loginMode={true} /> */}
        <NewHeader/>
      </div>
      <div>
        <div
          id="anchor"
          className="h-screen pt-20 pb-4 mx-8 lg:mx-0 lg:grid lg:grid-cols-12 lg:pt-0 lg:pb-0 "
        >
          <div className="flex-col hidden lg:col-span-6 lg:flex lg:h-full lg:bg-adx-darkGreen">
            <img className="w-48 mt-12 ml-4 h-9" src={adnexio_logo_white} alt="adnexio logo" />
            <div className="flex flex-col items-center justify-center h-full">
              <div className="lg:mb-24 lg:text-7xl lg:text-adx-yellow">
                <p className="font-bold text-left">
                  WELCOME
                  <br />
                  TO ADNEXIO
                </p>
                <p className="text-lg text-white">
                  Sign up with a simple two step registration process.
                </p>
              </div>

              <div className="text-center">
                <p className="mb-2 text-white">For better experience</p>
                <div className="flex justify-center gap-x-2">
                  <img
                    className="rounded-lg cursor-pointer h-11 w-36"
                    src={apple_store_badges}
                    alt="apple badge"
                    onClick={() => appDownload('apple')}
                  />
                  <img
                    className="rounded-lg cursor-pointer h-11 w-36"
                    src={google_play_badges}
                    alt="google badge"
                    onClick={() => appDownload('android')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between h-full col-span-6 text-center lg:justify-start lg:py-12">
            <div className="flex flex-col">
              <div className="flex items-center w-auto mx-auto text-center">
                <div className="inline-block w-20 cursor-pointer rounded-full bg-adx-darkGray py-0.5 text-center  text-sm text-white lg:w-24 lg:px-4 lg:py-2   lg:text-sm">
                  Step 1
                </div>
                <div className="flex items-center">
                  <div className="block  h-0.5 w-8 bg-adx-darkGray text-gray-300"></div>
                  <div className="hidden h-0.5  w-8 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-8 bg-adx-darkGray text-gray-300 lg:block"></div>
                </div>
                <div className="inline-block w-20 cursor-pointer rounded-full bg-adx-darkGreen py-0.5 text-center  text-sm text-white lg:w-24 lg:px-4 lg:py-2   lg:text-sm">
                  Step 2
                </div>
                <div className="flex items-center">
                  <div className="block  h-0.5 w-2 bg-adx-darkGray text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-darkGray text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-darkGray text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-darkGray text-gray-300"></div>
                  <div className="block  h-0.5 w-2 bg-adx-white text-gray-300"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-darkGray text-gray-300 lg:block"></div>
                  <div className="hidden h-0.5  w-2 bg-adx-white text-gray-300 lg:block"></div>
                  <div className="block w-3 h-3 text-gray-300 rounded-full bg-adx-darkGray"></div>
                </div>
              </div>
              <div className="w-full mx-auto mt-4 lg:mt-20 lg:w-80 xl:w-96">
                <p className="text-sm text-left text-adx-darkGreen lg:text-lg">
                  Build your profile
                </p>
                <p className="mb-2 text-xs text-left lg:text-md text-adx-darkGreen">
                  With your CV, we can get to know you
                </p>

                <>
                  {!isCvPicked && (
                    <div
                      className="h-64 my-4 bg-gray-200 rounded cursor-pointer xl:h-80"
                      onClick={() => {
                        inputFile.current.click()
                      }}
                    >
                      <div className="flex flex-col items-center justify-center h-full text-gray-500">
                        <PublishIcon />
                        <p className="text-xs">
                          Acceptable format <br /> .pdf
                        </p>
                      </div>
                      <input
                        type="file"
                        hidden
                        accept=".pdf"
                        ref={inputFile}
                        onChange={handleCvUpload}
                      />
                    </div>
                  )}

                  {isCvPicked && (
                    <div className="my-4 flex min-h-[256px] items-center justify-center rounded bg-gray-200 p-2">
                      <Document file={selectedCv}>
                        <Page pageNumber={1} renderAnnotationLayer={false} width={pdfWidth()} />
                      </Document>
                    </div>
                  )}
                </>
              </div>
            </div>
            <div className="text-center lg:mx-auto lg:w-80">
              <p className="mb-4 text-xs text-adx-darkGreen">
                Showcase your experiences to potential employers.
              </p>
              <Button
                variant="contained"
                sx={mui.btnContained}
                onChange={handleCvUpload}
                component="label"
              >
                {!isCvPicked ? 'Add CV' : 'Change CV'}
                <input type="file" hidden accept=".pdf" />
              </Button>

              {!isCvPicked ? (
                <Button
                  onClick={() => {
                    navigate('/complete')
                  }}
                  sx={mui.btnText}
                >
                  Skip
                </Button>
              ) : (
                <div className="flex justify-between">
                  <Button
                    onClick={() => {
                      navigate('/complete')
                    }}
                    sx={mui.btnText2}
                  >
                    Skip
                  </Button>
                  <Button
                    onClick={() => {
                      uploadCv()
                    }}
                    sx={mui.btnText2}
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadCV
