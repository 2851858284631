import axios, { getData } from "lib/axios"

export const eduPaymentDashboard = async () => {
  return axios.get("/nex_edu_payment/dashboard").then(getData)
}

export const eduPaymentSummary = async (id) => {
  return axios.get(`/nex_edu_payment/summary/${id}`).then(getData)
}

export const eduPaymentInvoice = async (nex_edu_current_enrolment_id, payment_method) => {
  return axios.get(`/adx_edu_payment/invoice/${nex_edu_current_enrolment_id}?payment_method=${payment_method}`, { responseType: 'blob' })
}

export const eduPaymentReceipt = async (orderId) => {
  return axios.get(`/adx_edu_payment/receipt/${orderId}`, { responseType: 'blob' })
}

export const eduMakePayment = async (data) => {
  return axios.post(`/nex_edu_payment/orders`, data).then(getData)
}

export const eduPaymenStatus = async (status, orderId) => {
  return axios.get(`/edu_payment_status/${status}/${orderId}`).then(getData)
}

export const eduOrderHistory = async (id) => {
  return axios.get(`/order_history/nex_edu_payment`).then(getData)
}
