import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import adnexio_logo_white from '../../../../../assets/logos/adnexio-logo-white-edu-v2-aug.png'
import signUpBanner from '../assets/signUpBanner.png'
import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'

const EduRegistrationBanner = () => {
  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }
  return (
    <>
      <Link to="/bootcamp">
        <img className="mt-8 ml-16 w-48" src={adnexio_logo_white} alt="logo" />
      </Link>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={signUpBanner} style={{ height: '500px' }} alt="banner" />
        </Box>
        <Box sx={{ width: '100%', height: '100%', mt: 2 }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p className="mb-2 text-white">For better experience</p>{' '}
          </Box>
          <Box
            sx={{
              width: '100%',

              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              columnGap: 1,
            }}
          >
            <img
              className="h-11 w-36 cursor-pointer rounded-lg"
              src={apple_store_badges}
              alt="appstore"
              onClick={() => appDownload('apple')}
            />
            <img
              className="h-11 w-36 cursor-pointer rounded-lg"
              src={google_play_badges}
              alt="playstore"
              onClick={() => appDownload('android')}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default EduRegistrationBanner
