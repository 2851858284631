import React, { useState, useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { format } from 'date-fns'

import Alert from '@mui/material/Alert'
import Pagination from '@mui/material/Pagination'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { authContext } from 'contexts/AuthContext'
import InputBase from '@mui/material/InputBase'
import Snackbar from '@mui/material/Snackbar'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ShareIcon from '@mui/icons-material/Share'
import SearchIcon from '@mui/icons-material/Search'

import CustomHelmet from 'components/smart/CustomHelmet'
import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import Footer from 'components/dumb/Footer'
import ShareSocialMedia from 'components/smart/ShareSocialMedia'
import EmployerCard from '../components/EmployerCard'

import SearchFilter from 'pages/jobads/components/SearchFilter'

import { EmployerCardContext } from 'contexts/EmployerCardContext'

import { selfApplied, matchApplied } from 'services/api/job_application'
import { qualifications } from 'services/api/dropdown_list'
import { jobMatchListsV2 } from 'services/api/job_match_list'
import { jobSaved } from 'services/api/job_saved'
import { jobAdvertisements } from 'services/api/job_advertisement'

import background from '../assets/background.jpg'
import { ButtonBase } from '@mui/material'

import Apple from 'assets/images/app-store-badge.png'
import Android from 'assets/images/google-play-badge.png'

const GOOGLE_PLAY = process.env.REACT_APP_GOOGLE_PLAY_URL
const APP_STORE = process.env.REACT_APP_APPLE_STORE_URL

const mui = {
  tab: {
    textTransform: 'capitalize',
  },
  btnText: {
    color: '#1B8379',
    textTransform: 'capitalize',
  },
  btnTextSeeMore: {
    textTransform: 'capitalize',
  },
  btnContained: {
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    fontSize: '12px',
  },
  ShareIconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  pagination: {
    justifyContent: 'center',
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
  },
  interviewnBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '500',
    background: '#FCD94E',
    color: '#1B8379',
    boxShadow: 'none',
    padding: '5px 20px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
  },
  searchIcon: {
    color: '#959595',
    fontSize: '27px',
  },
  searchBar: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#959595',
  },
  appBtn: {
    outline: 'none',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
}

const JobAds = () => {
  const { auth } = useContext(authContext)
  let isLoggedIn = auth.isAuth
  let score = auth.score

  const params = new URL(window.location.href).searchParams
  const paramSearch = params.get('search')
  const paramLocation = params.get('location')
  const paramSalaryMin = params.get('salary')?.split(',')[0]
  const paramSalaryMax = params.get('salary')?.split(',')[1]
  const paramQualitfication = params.get('qualification')

  const navigate = useNavigate()
  const searchInput = window.location.search.substr(8)
  const { jobID, setJobID, selectedEmployer } = useContext(EmployerCardContext)
  const [value, setValue] = useState(1)
  const [jobAdvertisementsLists, setJobAdvertisementsLists] = useState([])
  const [jobAdvertisementSetting, setJobAdvertisementsListsSetting] = useState()
  const [qualificationName, setQualificationName] = useState('')
  const [matchingLists, setMatchingLists] = useState([])
  const [jobSavedLists, setJobSavedLists] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)
  const [snackProps, setSnackProps] = useState({
    severity: 'info',
    message: '',
  })
  const [matchBool, setMatchBool] = useState(false)

  const [jobAdvetisementPage, setjobAdvetisementPage] = useState(1)
  const [jobAdvertisementsListsShuffle, setJobAdvertisementsListsShuffle] = useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleClickAllTab = () => {
    setJobID(null)
  }
  const handleClickRecommendationTab = () => {
    setJobID(null)
    setValue(0)
  }

  const handleCloseButton = () => {
    setJobID(null)
  }

  const getJobAdvertisements = async (jobAdvetisementPage) => {
    setLoading(true)
    try {
      const response = await jobAdvertisements('get', null, null, jobAdvetisementPage)
      setJobAdvertisementsListsSetting(response.data)
      setJobAdvertisementsLists(response.data.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const getRandomJobAdvertisements = async (jobAdvetisementPage) => {
    try {
      const response = await jobAdvertisements('get', null, null, jobAdvetisementPage)
      setJobAdvertisementsListsShuffle(shuffle(response.data.data))
    } catch (e) {
      console.log(e)
    }
  }

  const getJobMatchList = async () => {
    try {
      const results = await jobMatchListsV2()
      setMatchingLists(results.data.data)
    } catch (e) {
      if (e.status === 404) {
        setMatchingLists([])
      } else {
        console.log(e)
      }
    }
  }

  const getJobSaved = async () => {
    try {
      let res = await jobSaved('get', null, null)
      setJobSavedLists(res.data)
    } catch (e) {
      console.log(e)
    }
  }

  const getQualificationList = async () => {
    try {
      const res = await qualifications()
      setQualificationName(
        res.data.find((item) => item.id === Number(paramQualitfication))?.qualification_name
      )
    } catch (e) {
      console.log(e)
    }
  }

  const filterJobAdvertisementList = async (jobAdvetisementPage) => {
    const paramsObject = {
      position_title: paramSearch,
      work_location: paramLocation,
      min_salary: Number(paramSalaryMin),
      max_salary: Number(paramSalaryMax),
      qualification_id: Number(paramQualitfication),
    }
    setLoading(true)
    try {
      const results = await jobAdvertisements('post', paramsObject, null, jobAdvetisementPage)
      setJobAdvertisementsListsSetting(results.data)
      setJobAdvertisementsLists(results.data.data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const processSelfApplied = async (data) => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        const results = await selfApplied(data)
        setSnackProps({
          severity: 'success',
          message: results.message,
        })
        setOpenSnack(true)
      } catch (e) {
        console.log(e)

        if (e.status === 422) {
          setSnackProps({
            severity: 'error',
            message: e.data.errors.job_advertisement_id[0],
          })
          setOpenSnack(true)
        } else if (e.status === 400) {
          setSnackProps({
            severity: 'error',
            message: 'Your score is not suitable for this job',
          })
          setOpenSnack(true)
        } else {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        }
      }
      setLoading(false)
    } else {
      window.location.href = '/signin'
    }
  }

  const processMatchApplied = async (data) => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        const results = await matchApplied(data)
        setSnackProps({
          severity: 'success',
          message: results.message,
        })
        setOpenSnack(true)
      } catch (e) {
        console.log(e)

        if (e.status === 422) {
          setSnackProps({
            severity: 'error',
            message: e.data.errors.job_advertisement_id[0],
          })
          setOpenSnack(true)
        } else if (e.status === 400) {
          setSnackProps({
            severity: 'error',
            message: 'Your score is not suitable for this job',
          })
          await setOpenSnack(true)
        } else {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        }
      }
      setLoading(false)
    } else {
      window.location.href = '/signin'
    }
  }

  const processJobSaved = async (id) => {
    try {
      await jobSaved('get', null, id)
      if (searchInput.length) {
        filterJobAdvertisementList()
        getJobSaved()
        getJobMatchList()
      } else {
        getJobMatchList()
        getJobSaved()
      }
      getQualificationList()
    } catch (e) {
      console.log(e.data.message)
    }
  }

  const removedJobSaved = async (id) => {
    try {
      var savedJobid = jobSavedLists.find((item) => item.job_advertisement_id === id).id
      await jobSaved('delete', null, savedJobid)
      if (searchInput.length) {
        filterJobAdvertisementList()
        getJobSaved()
        getJobMatchList()
      } else {
        getJobMatchList()
        getJobSaved()
      }
      getQualificationList()
    } catch (e) {
      alert(e.data.message)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    setCopied(false)
  }

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }

  const handlePaginationAll = async (e, page) => {
    setjobAdvetisementPage(page)
    await getJobAdvertisements(page)
    window.scrollTo(0, 0)
  }
  const handlePaginationFilter = async (e, page) => {
    setjobAdvetisementPage(page)
    await filterJobAdvertisementList(page)
    window.scrollTo(0, 0)
  }

  //Shuffle
  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }
    return array
  }

  useEffect(() => {
    getJobSaved()
    getJobMatchList()
    if (searchInput.length) {
      filterJobAdvertisementList(jobAdvetisementPage)
    } else {
      getJobAdvertisements(jobAdvetisementPage)
      getRandomJobAdvertisements()
    }
    getQualificationList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get image file extension
  let re = /(?:\.([^.]+))?$/

  return (
    <div>
      <CustomHelmet title="Jobs | adnexio.jobs" url="https://adnexio.jobs/jobs" />

      <div
        className={`${
          ((!value && !isLoggedIn) || !jobAdvertisementsLists.length) && 'h-screen'
        } flex min-h-screen flex-col bg-adx-gray lg:h-auto `}
      >
        {loading === true && (
          <>
            <div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: '999999999',
              }}
            >
              <Loader type="Oval" color="#09b7b7" height={55} width={55} />
            </div>
            <div
              style={{
                position: 'fixed',
                zIndex: '15',
                width: '100vw',
                height: '100vh',
                background: 'black',
                opacity: '0.7',
              }}
            ></div>
          </>
        )}

        <Snackbar
          open={openSnack}
          autoHideDuration={3500}
          onClose={closeSnack}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={closeSnack} severity={snackProps.severity} variant="filled">
            {snackProps.message}
          </Alert>
        </Snackbar>

        <NewHeader/>
        {/* <div className="z-10">
          <Header page="jobs" />
        </div> */}
        
        <div className='relative z-10 lg:p-10 bg-adx-darkGreen'>
          {isLoggedIn ? (
            <div className='flex items-center justify-center px-6 pt-3 pb-6'>
              <div className="max-w-lg mx-autop lg:mx-0">
                <div className="pl-3 text-base font-semibold text-adx-white lg:text-2xl">
                  Video Interview
                </div>
                <div className="pl-3 mt-2 text-xs font-light text-adx-white lg:text-base">
                  Complete a simple 5 minute interview with our AI Coach, NEX, to improve your score
                  and get better job matches.
                </div>
                <div className="pl-3 mt-4">
                  <Button variant="contained" sx={mui.interviewnBtn}>
                    <Link to="/video-interview">Start Interview</Link>
                  </Button>
                </div>
              </div>
              <div className="max-w-lg ml-6 lg:ml-0">
                <div className="flex items-center justify-center lg:space-x-10">
                  <div className="hidden w-1/2 p-4 text-sm font-light text-center text-white rounded-xl bg-adx-lightGreen lg:block">
                    {`Your score is ${
                      score !== 0 ? score.toFixed(1) : score
                    }. Improve your score by downloading the adnexio mobile app for a better chance of getting hired`}
                    .
                  </div>
                  <div className="flex flex-col items-center justify-center w-24 h-24 text-white border-2 border-white rounded-full lg:h-32 lg:w-32 lg:border-4">
                    <div className="text-3xl font-bold lg:text-5xl">
                      {score !== 0 ? score.toFixed(1) : score}
                    </div>
                    <div className="font-light lg:text-xl">Score</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='items-center px-6 pt-3 pb-6 lg:flex lg:justify-around'>
              <div className="max-w-lg mx-auto lg:mx-0">
                <div className="pl-3 text-lg font-semibold text-adx-white lg:text-2xl">
                  Find a job <span className="font-extralight">you will love</span>
                </div>
                <form
                  className="p-1 mt-5 rounded-4xl bg-adx-white lg:mb-0 lg:min-w-440 lg:max-w-md lg:py-2"
                  method="GET"
                  action="/jobs"
                  autoComplete="off"
                >
                  <SearchIcon className="mx-2" sx={mui.searchIcon} />
                  <InputBase
                    className="w-80percent"
                    sx={mui.searchBar}
                    placeholder="Search"
                    name="search"
                  />
                </form>
              </div>
              <div className="hidden lg:block">
                <div className="hidden lg:flex lg:flex-col lg:items-center lg:space-y-5">
                  <div className="text-2xl font-semibold text-adx-white">For a better experience</div>
                  <div className="space-x-4">
                    <ButtonBase sx={mui.appBtn} href={APP_STORE} target="_blank">
                      <div>
                        <img src={Apple} alt="apple" className="w-40 rounded-md" />
                      </div>
                    </ButtonBase>
                    <ButtonBase sx={mui.appBtn} href={GOOGLE_PLAY} target="_blank">
                      <div>
                        <img src={Android} alt="android" className="w-40 rounded-md" />
                      </div>
                    </ButtonBase>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        

        <ShareSocialMedia
          jobAdvertisement={selectedEmployer}
          handleClose={handleClose}
          open={open}
          setCopied={setCopied}
          copied={copied}
        />

        <div className="relative flex-1">
          <div
            className={`hidden lg:absolute lg:grid lg:min-h-screen lg:w-full lg:grid-cols-2 ${
              jobID && 'lg:h-full'
            }`}
          >
            <div className="bg-adx-gray" style={{ zIndex: -100 }}></div>
            {!jobID ? (
              <div
                className="relative -mt-12 text-white bg-cover"
                style={{ backgroundImage: `url(${background})` }}
              >
                <div className="absolute w-full h-full bg-black opacity-60" />
                <div className="absolute grid w-full h-full text-center right place-items-center">
                  <div className="w-1/2 mx-auto">
                    <p className="text-2xl">
                      "The only way to do great work is to love what you do. If you haven't found it
                      yet, keep looking."
                    </p>
                    <p className="mt-4 text-lg font-medium">Steve Jobs</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative w-full h-full bg-white" />
            )}
          </div>

          <div className="h-full lg:mx-auto lg:flex lg:min-h-screen lg:max-w-screen-2xl ">
            <div className="flex flex-col min-h-full lg:w-full lg:justify-self-center lg:px-16 lg:pb-8 lg:pt-4 ">
              {isLoggedIn && (
                <div className="px-4 lg:mx-0">
                  <div className="pl-3 mt-2 text-lg font-semibold lg:text-2xl">
                    Find a job you will love
                  </div>
                  <form
                    className="p-1 mt-2 rounded-4xl bg-adx-white lg:mt-5 lg:mb-0 lg:min-w-440 lg:py-2"
                    method="GET"
                    action="/jobs"
                    autoComplete="off"
                  >
                    <SearchIcon sx={mui.searchIcon} className="mx-2" />
                    <InputBase
                      sx={mui.searchBar}
                      className="w-80percent"
                      placeholder="Search"
                      name="search"
                    />
                  </form>
                </div>
              )}

              {!searchInput.length ? (
                <div className="relative px-4">
                  <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab
                      label="Recommended"
                      sx={mui.tab}
                      onClick={() => handleClickRecommendationTab()}
                      style={{ borderBottom: '2px solid #E2E2E2' }}
                    />
                    <Tab
                      label="All"
                      sx={mui.tab}
                      onClick={() => handleClickAllTab()}
                      style={{ borderBottom: '2px solid #E2E2E2' }}
                    />
                  </Tabs>
                </div>
              ) : (
                <div className="px-4">
                  <div className="mt-2 flex items-center justify-between py-0.5 text-adx-darkGreen lg:mt-0">
                    <p className="flex w-80percent">
                      <span className="block w-auto font-bold truncate max-w-50percent">
                        {`${paramSearch} `}{' '}
                      </span>{' '}
                      <span className="inline-block mx-1">|</span>
                      {jobAdvertisementSetting ? jobAdvertisementSetting?.total : 0} job
                      {(!jobAdvertisementSetting || jobAdvertisementSetting?.total > 1) && 's'}{' '}
                      found
                    </p>
                    <SearchFilter paramSearch={paramSearch} />
                  </div>
                  <span className="block h-0.5 w-full bg-adx-darkGreen "></span>
                  {(paramLocation || paramQualitfication || paramSalaryMin || paramSalaryMax) && (
                    <div
                      className="items-center justify-between inline-block w-full mt-1 text-xs "
                      style={{ padding: '1px 0px' }}
                    >
                      {paramLocation && (
                        <span className="inline-block truncate max-w-33percent ">
                          {paramLocation.replaceAll(',', ', ')}{' '}
                        </span>
                      )}
                      {paramLocation && <span className="inline-block mx-1 truncate">|</span>}
                      {paramQualitfication && (
                        <span className="inline-block truncate max-w-33percent">
                          {qualificationName}
                        </span>
                      )}
                      {paramQualitfication && <span className="inline-block mx-1 truncate">|</span>}
                      {paramSalaryMin && (
                        <span className="inline-block truncate max-w-33percent">
                          RM{paramSalaryMin} - RM{paramSalaryMax}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}

              {!searchInput.length &&
                (value === 0 && !isLoggedIn ? (
                  <div className="flex items-center justify-center flex-1 px-4 text-center">
                    <div className="px-4 lg:px-8">
                      <p className="text-md text-adx-darkerGray lg:text-xl">
                        Your dream job is only sign-in away
                      </p>
                      <p className="mb-4 text-sm lg:text-md text-adx-darkerGray">
                        Login to view personalised job recommendations from NEX, our AI career coach
                      </p>
                      <div>
                        <Button
                          sx={mui.btnContained}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            navigate('/signin')
                          }}
                        >
                          Login
                        </Button>
                      </div>
                      <div>
                        <Button
                          sx={mui.btnText}
                          onClick={() => {
                            navigate('/signup')
                          }}
                        >
                          Sign Up
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : value === 0 && isLoggedIn ? (
                  <div className="px-2 mt-4 overflow-auto ">
                    <div className="px-4 pb-2 rounded-xl bg-adx-darkGray ">
                      <div className="flex items-center justify-between py-2">
                        <span className="font-bold">Matched jobs </span>
                        <Button
                          sx={mui.btnTextSeeMore}
                          onClick={() => navigate('/track')}
                          endIcon={<ChevronRightIcon />}
                        >
                          See More
                        </Button>
                      </div>
                      <div>
                        {matchingLists.length ? (
                          matchingLists.slice(0, 3).map((item) => (
                            <EmployerCard
                              isSaved={jobSavedLists
                                .map((job) => job.job_advertisement_id)
                                .includes(item.job_advertisement_id)}
                              key={item.id}
                              item={item}
                              matched={true}
                              handleMatchBool={() => setMatchBool(true)}
                              processJobSaved={() => processJobSaved(item.job_advertisement_id)}
                              removedJobSaved={() => {
                                removedJobSaved(item.job_advertisement_id)
                              }}
                            />
                          ))
                        ) : (
                          <div className="grid h-32 text-base place-items-center text-adx-darkGreen">
                            <span>You have no job match available</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="px-4 mt-4 rounded-xl lg:pt-4">
                      {jobAdvertisementsListsShuffle.map((item) => (
                        <EmployerCard
                          isSaved={jobSavedLists
                            .map((job) => job.job_advertisement_id)
                            .includes(item.id)}
                          key={item.id}
                          item={item}
                          matched={false}
                          handleMatchBool={() => setMatchBool(false)}
                          processJobSaved={() => processJobSaved(item.id)}
                          removedJobSaved={() => {
                            removedJobSaved(item.id)
                          }}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="px-2 mt-4 overflow-auto">
                    <div className="px-4 rounded-xl lg:pt-4">
                      {jobAdvertisementsLists.map((item) => (
                        <EmployerCard
                          isSaved={jobSavedLists
                            .map((job) => job.job_advertisement_id)
                            .includes(item.id)}
                          key={item.id}
                          item={item}
                          matched={false}
                          handleMatchBool={() => setMatchBool(false)}
                          processJobSaved={() => processJobSaved(item.id)}
                          removedJobSaved={() => {
                            removedJobSaved(item.id)
                          }}
                        />
                      ))}
                      <div className="mt-4 mb-8">
                        <Pagination
                          shape="rounded"
                          sx={mui.pagination}
                          siblingCount={0}
                          boundaryCount={1}
                          showFirstButton
                          showLastButton
                          color="primary"
                          count={jobAdvertisementSetting?.last_page}
                          onChange={(e, page) => {
                            handlePaginationAll(e, page)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}

              {!loading && searchInput.length && !jobAdvertisementsLists.length ? (
                <div className="flex items-center justify-center flex-1 text-center">
                  <div className="px-4 lg:px-32">
                    <p className="whitespace-pre-wrap text-md text-adx-darkGreen lg:text-xl">
                      <span>{`We couldn’t find any jobs listed under “${paramSearch}”`} </span>
                      {paramLocation && <span>{`at ${paramLocation.replaceAll(',', ', ')}`} </span>}
                      {paramQualitfication && <span>{`with a ${qualificationName}`} </span>}
                      {paramSalaryMin && (
                        <span>{`with salary ranges from RM${paramSalaryMin} to RM${paramSalaryMax}`}</span>
                      )}
                    </p>
                  </div>
                </div>
              ) : !loading && searchInput.length && jobAdvertisementsLists.length ? (
                <div className="px-2 mt-4 overflow-auto">
                  <div className="rounded-xl lg:px-4 lg:pt-4">
                    {jobAdvertisementsLists.map((item) => (
                      <EmployerCard
                        isSaved={jobSavedLists
                          .map((job) => job.job_advertisement_id)
                          .includes(item.id)}
                        key={item.id}
                        item={item}
                        matched={false}
                        handleMatchBool={() => setMatchBool(false)}
                        processJobSaved={() => processJobSaved(item.id)}
                        removedJobSaved={() => {
                          removedJobSaved(item.id)
                        }}
                      />
                    ))}
                  </div>
                  <div className="mt-4 mb-8">
                    <Pagination
                      shape="rounded"
                      sx={mui.pagination}
                      siblingCount={0}
                      showFirstButton
                      showLastButton
                      boundaryCount={1}
                      color="primary"
                      count={jobAdvertisementSetting?.last_page}
                      onChange={(e, page) => {
                        handlePaginationFilter(e, page)
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>

            {(value === 1 && jobID) ||
            (searchInput.length && jobID) ||
            (value === 0 && isLoggedIn && jobID) ? (
              <div className="flex-col hidden overflow-hidden lg:sticky lg:top-0 lg:flex lg:h-full lg:w-full lg:justify-self-center lg:px-16 lg:pb-8 lg:pt-4">
                <div className="lg:flex lg:h-12 lg:items-center lg:justify-between">
                  <Button
                    sx={mui.btnText}
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => handleCloseButton()}
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={mui.btnContained}
                    onClick={() => {
                      if (matchBool) {
                        processMatchApplied({
                          user_employer_id: selectedEmployer.user_id,
                          job_advertisement_id:
                            'job_advertisement_id' in selectedEmployer
                              ? selectedEmployer.job_advertisement_id
                              : selectedEmployer.id,
                        })
                        filterJobAdvertisementList()
                      } else {
                        processSelfApplied({
                          user_employer_id: selectedEmployer.user_id,
                          job_advertisement_id:
                            'job_advertisement_id' in selectedEmployer
                              ? selectedEmployer.job_advertisement_id
                              : selectedEmployer.id,
                        })
                        filterJobAdvertisementList()
                      }
                    }}
                    disabled={selectedEmployer.applied}
                  >
                    {' '}
                    {!selectedEmployer.applied ? 'Apply Now' : 'Applied'}
                  </Button>
                </div>
                <div className="px-4 py-4 overflow-auto h-minusTab rounded-xl bg-adx-gray lg:mt-2">
                  <div className="relative grid w-full grid-cols-12 py-6 mx-auto mb-4 rounded-md shadow border-xl bg-adx-white">
                    <div className="grid col-span-3 place-items-center justify-self-center">
                      {selectedEmployer.company_logo === null ||
                      re.exec(selectedEmployer.company_logo)[1] === 'pdf' ? (
                        <div className="flex items-center justify-center w-20 h-20 text-3xl text-black rounded-full shadow bg-adx-white">
                          {selectedEmployer.company_name.charAt(0)}
                        </div>
                      ) : (
                        <img
                          src={selectedEmployer.company_logo}
                          alt="company_logo"
                          className="object-scale-down w-20 h-20 rounded-full shadow"
                        />
                      )}
                    </div>
                    <div className="flex flex-col justify-center col-span-9">
                      <p className="w-5/6 text-sm font-semibold capitalize text-adx-darkGreen lg:text-lg">
                        {selectedEmployer.position_title}
                      </p>
                      <p className="w-5/6 text-xs font-semibold capitalize lg:text-sm">
                        {selectedEmployer.company_name}
                      </p>
                    </div>
                    <IconButton
                      onClick={() => handleClickOpen()}
                      sx={mui.ShareIconButton}
                      size="large"
                    >
                      <ShareIcon />
                    </IconButton>
                  </div>
                  <div className="my-2 mt-5 space-y-1 px-7">
                    <p className="text-xs font-semibold lg:text-sm">Job Details</p>
                    <p className="w-5/6 text-xs lg:text-sm">
                      RM{selectedEmployer.min_salary} - RM
                      {selectedEmployer.max_salary}
                    </p>
                    <p className="w-5/6 text-xs lg:text-sm">
                      {selectedEmployer.city}, {selectedEmployer.state}
                    </p>
                  </div>
                  <hr className="mx-4 my-5 border-1 border-adx-darkerGray" />

                  <div className="my-2 space-y-2 px-7">
                    <p className="text-xs font-semibold lg:text-sm">Job Description</p>
                    <p
                      className="text-sm text-justify whitespace-pre-line"
                      style={{ lineHeight: '1.55rem' }}
                    >
                      {selectedEmployer.job_description}
                    </p>
                  </div>
                  <hr className="mx-4 my-5 border-1 border-adx-darkerGray" />

                  <div className="my-2 mb-8 space-y-2 px-7">
                    <p className="text-xs font-semibold lg:text-sm">Job Requirements</p>
                    <p
                      className="text-sm text-justify whitespace-pre-line"
                      style={{ lineHeight: '1.55rem' }}
                    >
                      {selectedEmployer.job_requirement}
                    </p>
                  </div>

                  <hr className="mx-4 my-5 border-1 border-adx-darkerGray" />
                  <div className="my-2 mb-8 space-y-2 px-7">
                    <p className="text-xs font-semibold lg:text-sm">Published at</p>
                    <p
                      className="text-sm text-justify whitespace-pre-line"
                      style={{ lineHeight: '1.55rem' }}
                    >
                      {!!selectedEmployer.published_at
                        ? format(new Date(selectedEmployer.published_at), 'dd MMMM yyyy')
                        : 'Data not available'}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="hidden overflow-hidden lg:block lg:h-full lg:w-full lg:justify-self-center lg:px-16 lg:pb-8 lg:pt-4"></div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default JobAds
