import axios, { getData } from 'lib/axios'

export const login = async (data) => {
  return axios.post('/login', data).then(getData)
}

export const signout = async (data) => {
  return axios.get('/sign-out', data).then(getData)
}

export const myAccount = async () => {
  return axios.get('/my_accounts').then(getData)
}

export const myAccountUpdate = async (data) => {
  return axios.put('/my_accounts', data).then(getData)
}

export const forgotPassword = async (data) => {
  return axios.post('/forgot-password', data).then(getData)
}

export const resetPassword = async (data) => {
  return axios.post('/reset-password', data).then(getData)
}

export const changePassword = async (data) => {
  return axios.post('/change-password', data).then(getData)
}

export const socialLogin = async (platform, accessToken) => {
  return axios
    .get(`/social_authentication/${platform}`, {
      headers: {
        Token: accessToken,
      },
    })
    .then(getData)
}

export const socialAuthentication = async (platform, redirect = '') => {
  return axios.get(`/web_social_authentication/${platform}?redirect=${redirect}`).then(getData)
}

export const profile = async (data) => {
  return axios.put('/profiles', data).then(getData)
}

export const removeAccount = async (data) => {
  return axios.post('/delete_account_jobseekers', data).then(getData)
}
