import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import Footer from 'components/dumb/Footer'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Link } from 'react-router-dom'
import CustomHelmet from 'components/smart/CustomHelmet'

const Setting = ({ children, title }) => {
  window.scroll(0, 0)

  return (
    <div className="">
      <CustomHelmet
        title={`${title} | adnexio.jobs`}
        description={`${title}. Find the latest Vacancies in Malaysia. Malaysia's Best Job Portal.`}
      />

      {/* <Header page={'disableBottom'} /> */}
      <NewHeader />

      <div className="lg:flex">
        <div className="sticky top-0 z-10 flex flex-col items-center justify-center bg-adx-gray lg:h-screen lg:w-6/12">
          <div>
            <div className="p-6 text-xl font-semibold text-adx-darkerGray lg:mb-20 lg:-mt-10 lg:p-0 lg:text-3xl">
              {title}
            </div>
            <div className="hidden lg:block lg:min-w-350">
              <div className="mb-6 text-xl font-semibold text-adx-darkerGray">Read more</div>
              <div className="space-y-4 text-sm ">
                <Link to="/nex" className="flex justify-between">
                  <div className={title === "Who's NEX" && 'font-semibold text-adx-darkGreen'}>
                    Who's NEX
                  </div>
                  <ChevronRightIcon fontSize="small" />
                </Link>
                <hr />
                <Link to="/faq" className="flex justify-between">
                  <div className={title === 'FAQ' && 'font-semibold text-adx-darkGreen'}>
                    Frequently asked questions (FAQ)
                  </div>
                  <ChevronRightIcon fontSize="small" />
                </Link>
                <hr />
                <Link to="/terms-conditions" className="flex justify-between">
                  <div
                    className={
                      title === 'Terms and Conditions' && 'font-semibold text-adx-darkGreen'
                    }
                  >
                    Terms and Conditions
                  </div>
                  <ChevronRightIcon fontSize="small" />
                </Link>
                <hr />
                <Link to="/privacy-policy" className="flex justify-between">
                  <div className={title === 'Privacy Policy' && 'font-semibold text-adx-darkGreen'}>
                    Privacy Policy
                  </div>
                  <ChevronRightIcon fontSize="small" />
                </Link>
                <hr />
                <Link to="/contact-us" className="flex justify-between">
                  <div className={title === 'Contact Us' && 'font-semibold text-adx-darkGreen'}>
                    Contact Us
                  </div>
                  <ChevronRightIcon fontSize="small" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center bg-adx-white lg:w-7/12">{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default Setting
