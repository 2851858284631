import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import Loader from 'react-loader-spinner'
import * as Yup from 'yup'
import Bugsnag from '@bugsnag/js'

import { login } from 'services/api/authentication'

import Button from '@mui/material/Button'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import Footer from 'components/dumb/Footer'
import SocialMediaButton from 'components/smart/SocialMediaButton'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import EduRegistrationBanner from 'modules/edu/components/EduRegistrationBanner/view/EduRegistrationBanner'
import signUpBanner from '../assets/signUpBanner.png'

const EDUSignIn = ({ data }) => {
  let location = useLocation()
  let historyData = location.state
  const navigate = useNavigate()

  const [authError, setAuthError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState)
  }

  const [open, setOpen] = useState(false)
  const handleClose = (event, reason) => {
    if ((reason === 'clickaway') | (reason === 'backdropClick') | (reason === 'escapeKeyDown')) {
      return
    }
    setOpen(false)
  }

  const handleNavigate = () => {
    navigate('/contact-us')
  }

  useEffect(() => {
    if (data !== undefined && data?.status === 'fail') {
      setOpen(true)
    }
  }, [data])

  const apiLogin = async (values, actions) => {
    setLoading(true)
    setAuthError(false)
    try {
      await login(values)
      if (historyData?.from === 'courses/') {
        window.location.href = `/courses/${historyData.id}`
      } else if (historyData?.from === 'courses') {
        window.location.href = `/courses`
      } else if (historyData?.from === 'edu') {
        window.location.href = `/bootcamp`
      } else {
        return (window.location.href = '/bootcamp')
      }
    } catch (e) {
      if (e.status === 401) {
        if (
          e.data.message ===
          'This email was used to create account with banned previously. Please proceed to sign in with banned'
        ) {
          setErrorMessage('Your account has been blocked.')
        } else {
          setErrorMessage(e.data.message)
        }
        setAuthError(true)
      } else if (e.status === 422) {
        const errors = e.data.errors
        Object.keys(errors).forEach(function (key) {
          actions.setFieldError(key, errors[key][0])
        })
      } else {
        Bugsnag.notify(e)
        setErrorMessage(
          'An unexpected error occurred. Please retry or contact us at support@adnexio.jobs for support.'
        )
        setAuthError(true)
      }
      setLoading(false)
    }
  }

  const initialValues = {
    verified: '',
    email: '',
    password: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Email must be a valid email').required('Please fill out this field'),
    password: Yup.string().required('Please fill out this field'),
  })

  return (
    <div>
      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}

      <div className="fixed top-0 z-50 block w-full lg:hidden">
        <HeaderEdu page="disableBottom" loginMode={true} />
      </div>

      <div className="flex h-screen flex-col items-center justify-between bg-adx-darkGreen py-20 text-white lg:hidden">
        <p className="opacity-0">.</p>
        <img src={signUpBanner} alt="banner" className="w-2/3" />

        <LinkScroll to="anchor" spy={true} smooth={true} duration={500}>
          <div className="flex h-20 w-20 cursor-pointer flex-col items-center justify-center rounded-full border border-white">
            <ArrowDownwardIcon />
            <span>Login</span>
          </div>
        </LinkScroll>
      </div>
      <div id="anchor" className="mx-8 h-screen pt-20 lg:mx-0 lg:grid lg:grid-cols-12 lg:pt-0 ">
        <div className="hidden  flex-col lg:col-span-6 lg:flex  lg:h-full lg:bg-adx-darkGreen">
          <EduRegistrationBanner />
        </div>
        <div className="col-span-6 h-full lg:flex lg:flex-col lg:text-center">
          <div className="justify-center lg:mx-auto lg:flex lg:h-full lg:w-full lg:max-w-md lg:flex-col">
            <p className="mt-4 block text-xl text-adx-darkGreen lg:mt-0 lg:hidden ">Welcome back</p>
            <p className="hidden text-3xl lg:mb-4 lg:block lg:font-bold">Login</p>

            <div className="mb-4  block text-adx-darkerGray lg:mb-4">
              Not a member yet?{' '}
              <Link to="/bootcamp/signup" className="font-medium text-adx-darkGreen">
                Sign Up
              </Link>
            </div>

            <p className="mb-2 block text-center text-adx-darkerGray lg:mb-4 lg:hidden">
              Login Below
            </p>
            <div className="mb-4 grid gap-y-3">
              {['facebook', 'google', 'linkedin', 'apple'].map((item) => (
                <SocialMediaButton key={item} type="signin" redirect={'bootcamp'} socmed={item} />
              ))}
            </div>
            <p className="mb-2 text-center text-sm text-adx-darkerGray lg:mb-4">
              or login manually
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => apiLogin(values)}
            >
              {() => (
                <Form autoComplete="off">
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    className="my-1 w-full rounded bg-adx-darkGray px-2 py-3 text-sm"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="mt-1 mb-4 text-left text-xs text-red-400"
                  />

                  <div className="relative">
                    <Field name="password">
                      {({ field }) => (
                        <>
                          {showPassword ? (
                            <VisibilityOffIcon
                              className="absolute top-3.5 right-3 h-full w-6 cursor-pointer text-gray-400"
                              onClick={togglePasswordVisibility}
                            />
                          ) : (
                            <VisibilityIcon
                              className="absolute top-3.5 right-3 h-full w-6 cursor-pointer text-gray-400"
                              onClick={togglePasswordVisibility}
                            />
                          )}
                          <input
                            {...field}
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            className="my-1 w-full rounded bg-adx-darkGray px-2 py-3 text-sm"
                          />
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="mt-1 mb-4 text-left text-sm text-red-400"
                    />
                  </div>

                  {authError && (
                    <p className="mt-1 text-left text-xs text-red-400">{errorMessage}</p>
                  )}

                  <div className="my-2 flex justify-end text-sm text-adx-darkGreen">
                    <Link className="" to="/forgot-password">
                      Forgot password?
                    </Link>
                  </div>

                  <div className="text-center">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{
                        backgroundColor: '#1B8379',
                        textTransform: 'capitalize',
                        padding: '6px 20px',
                        boxShadow: 'none',
                        fontWeight: 400,
                      }}
                    >
                      Log In
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">Login Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data?.value?.replaceAll('_', ' ')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
          <Button onClick={handleNavigate}>Contact Us</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EDUSignIn
