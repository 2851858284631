import React from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import { ReactComponent as DeleteImage } from 'assets/images/delete-account.svg'
import { removeAccount } from 'services/api/authentication/index'
import { clearCookies } from 'services/helper/clearCookies'

const initialValues = {
  delete: '',
}

const validationSchema = Yup.object({
  delete: Yup.mixed()
    .oneOf(['DELETE'], 'Please type DELETE to confirm your account deletion')
    .required('Please type DELETE to confirm your account deletion'),
})

const MyTextField = ({ placeholder, label, InputProps, shrink, InputLabelProps, ...props }) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return (
    <TextField
      variant="standard"
      placeholder={placeholder}
      label={label}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      {...field}
      helperText={errorText}
      error={!!errorText}
      shrink={shrink}
      fullWidth
    />
  )
}

const useStyles = makeStyles((theme) => ({
  inputCSS: {
    fontSize: '14px',
  },
  labelCSS: {
    fontSize: '14px',
  },
}))

const DeleteAccount = ({ setOpenDelete, openDelete, loading, setLoading }) => {
  const classes = useStyles()

  const submitDelete = async (data, setErrors) => {
    setLoading(true)
    try {
      await removeAccount(data)
      clearCookies()
      window.open('/', '_self')
    } catch (e) {
      setErrors(e.data.errors)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog onClose={() => setOpenDelete(!openDelete)} open={openDelete}>
      <div className="space-y-2 p-8 font-poppins text-sm">
        <div className="flex justify-center">
          <DeleteImage className="h-36 w-auto" />
        </div>
        <div className="py-4 text-4xl font-semibold">We’re sorry to see you go</div>
        <div>
          Be advised, account deletion is final. There will be no way to restore your account.
        </div>
        <div>
          Please type <span className="font-semibold">DELETE</span> and click{' '}
          <span className="font-semibold text-red-500">Delete Account</span> to confirm.
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, { setErrors }) => {
            submitDelete(data, setErrors)
          }}
        >
          {({ dirty }) => (
            <Form autoComplete="off">
              <div className="flex flex-col space-y-6 pl-2">
                <MyTextField
                  label="DELETE"
                  name="delete"
                  InputProps={{ className: classes.inputCSS }}
                  InputLabelProps={{ className: classes.labelCSS }}
                />
              </div>
              <div className="relative mt-8 flex items-center justify-center space-x-6">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '13px',
                    boxShadow: 'none',
                  }}
                  onClick={() => setOpenDelete(!openDelete)}
                >
                  Go Back
                </Button>
                <Button
                  disabled={!dirty || loading}
                  type="submit"
                  variant="outlined"
                  color="error"
                  style={{
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '13px',
                    boxShadow: 'none',
                  }}
                >
                  Delete Account
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  )
}

export default DeleteAccount
