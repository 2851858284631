import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import moment from 'moment'

import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { makeStyles } from '@mui/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

import failCopyWriting from '../constant/failCopyWriting.json'
import successCopyWriting from '../constant/successCopyWriting.json'
import { eduPaymenStatus, eduPaymentReceipt } from 'services/api/course_payment/index'
import CustomHelmet from 'components/smart/CustomHelmet'
import AdnexioEduV2 from 'assets/logos/adnexio-logo-edu-v2-aug.png'
import Bugsnag from '@bugsnag/js'
import { CircularProgress } from '@mui/material'

const useStyles = makeStyles({
  applyModalSecondButton: {
    color: '#FCD94E',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    width: '150px',
    margin: '0 10px',
  },
  largeIcon: {
    fontSize: '5rem',
  },
  tableCell: {
    padding: 6,
  },
  tableCellNoBorderBottom: {
    borderBottom: 'none',
  },
  btn: {
    width: '100%',
    textTransform: 'capitalize',
    boxShadow: 'none',
  },

  firstBtn: {
    backgroundColor: '#EAEAEA',
    color: 'black',
  },

  returnToDashboard: {
    backgroundColor: '#FCD94E',
    color: '#1B8379',
  },
})

const PaymentStatus = () => {
  const classes = useStyles()
  const { status, orderId } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [copyWriting, setCopyWriting] = useState({})
  const [paymentStatusData, setPaymentStatusData] = useState({})

  const paymentSuccess =
    status === 'success' ? true : status === 'fail' || status === 'error' ? false : null

  const getEduPaymentStatus = async (status, orderId) => {
    try {
      setLoading(true)
      const res = await eduPaymenStatus(status, orderId)
      setPaymentStatusData(res.data)
    } catch (e) {
      if (e.status === 400) {
        alert(e.data.message)
      }
      if (e.status === 500) {
        alert('Order Id is invalid')
      }
    } finally {
      setLoading(false)
    }
  }

  const determineCopyWriting = () => {
    if (paymentSuccess) {
      setCopyWriting(successCopyWriting)
    } else {
      setCopyWriting(failCopyWriting)
    }
  }

  useEffect(() => {
    handleRedirect(status)
    determineCopyWriting(status)
    getEduPaymentStatus(status, orderId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFirstButton = async () => {
    if (paymentSuccess) {
      setDownloadLoading(true)
      try {
        const res = await eduPaymentReceipt(orderId)
        const receiptName = res.headers['content-disposition'].substring(21)
        const element = document.createElement('a')
        const file = new Blob([res.data], {
          type: res.headers["content-type"],
        })
        element.href = URL.createObjectURL(file)
        element.download = `${receiptName}`
        document.body.appendChild(element)
        element.click()
      } catch (error) {
        if (error.status === 400) {
          alert(error.data.message)
        }
        if (error.status === 500) {
          alert('Order Id is invalid')
        }
        Bugsnag.notify(error)
      } finally {
        setDownloadLoading(false)
      }
    } else {
      window.print()
    }
  }
  const handleReturnToDashboardButtonMobileView = () => {
    if (status === 'success') {
      navigate('/courses')
    } else {
      navigate(`/course-application/${paymentStatusData.nex_edu_application_id}`)
    }
  }

  const handleReturnToDashboardButtonDesktopView = () => {
    navigate('/upskill-payment')
  }

  const handleRedirect = () => {
    const isStatusValid = ['success', 'fail', 'error'].includes(status)
    if (!isStatusValid) {
      navigate('/upskill-payment')
    }
  }
  return (
    <div className="min-h-screen px-4 py-12 bg-adx-gray ">
      <CustomHelmet title="Payment Status | adnexio EDU" />

      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}

      <div className="max-w-3xl mx-auto">
        <img src={AdnexioEduV2} className="h-10 mx-auto mb-12" alt="adnexio_edu" />
        <div className="flex-1 pb-4 bg-white rounded-xl">
          <div className="py-4 text-center">
            {paymentSuccess ? (
              <CheckCircleIcon
                sx={{
                  fontSize: '5rem',
                  color: 'primary.main',
                }}
              />
            ) : (
              <CancelIcon
                sx={{
                  fontSize: '5rem',
                  color: '#E94C4C',
                }}
              />
            )}
          </div>
          <div className="w-4/5 mx-auto ">
            <p
              className={`mb-4 text-center text-xl font-bold lg:text-2xl ${paymentSuccess ? 'text-adx-darkGreen' : 'text-red-500'
                }`}
            >
              {copyWriting.header}
            </p>
            <p className="mb-4 text-sm text-center break-words text-adx-darkerGray lg:text-base">
              {copyWriting.title} {paymentSuccess && paymentStatusData.email}
            </p>
            <hr className="my-2" />
            <div className="mb-16">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell
                      width="30%"
                      className={`${classes.tableCell} ${classes.tableCellNoBorderBottom}`}
                    >
                      <span className="text-adx-darkerGray">Order Id</span>
                    </TableCell>
                    <TableCell
                      width="70%"
                      className={`${classes.tableCell} ${classes.tableCellNoBorderBottom}`}
                    >
                      <span className="text-adx-darkerGray">{orderId}</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      width="30%"
                      className={`${classes.tableCell} ${classes.tableCellNoBorderBottom}`}
                    >
                      <span className="text-adx-darkerGray">Course name</span>
                    </TableCell>
                    <TableCell
                      width="70%"
                      className={`${classes.tableCell} ${classes.tableCellNoBorderBottom}`}
                    >
                      <span className="text-adx-darkerGray">{paymentStatusData.course_title}</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      width="30%"
                      className={`${classes.tableCell} ${classes.tableCellNoBorderBottom}`}
                    >
                      <span className="text-adx-darkerGray">Amount Paid</span>
                    </TableCell>
                    <TableCell
                      width="70%"
                      className={`${classes.tableCell} ${classes.tableCellNoBorderBottom}`}
                    >
                      <span className="text-adx-darkerGray">
                        {' '}
                        {paymentStatusData.currency_code + " " + paymentStatusData.amount_paid}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      width="30%"
                      className={`${classes.tableCell} ${classes.tableCellNoBorderBottom}`}
                    >
                      <span className="text-adx-darkerGray">Date</span>
                    </TableCell>
                    <TableCell
                      width="70%"
                      className={`${classes.tableCell} ${classes.tableCellNoBorderBottom}`}
                    >
                      <span className="text-adx-darkerGray">
                        {' '}
                        {moment(paymentStatusData.date).format('Do MMMM YYYY')}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {!paymentSuccess && (
                <p className="mt-4 text-sm text-adx-darkerGray lg:text-base">
                  Kindly check your bank account and if funds have been deducted, please notify your
                  bank immediately
                </p>
              )}
              {/* <hr className="my-2" /> */}
            </div>

            <div className="mb-4">
              <p className="text-sm text-center text-adx-darkerGray lg:text-base">
                Need help?{' '}
                <Link
                  to="/bootcamp/contact-us"
                  target="_blank"
                  className="text-blue-500 underline cursor-pointer"
                >
                  Contact us
                </Link>{' '}
                for any query or visit our{' '}
                <Link to="/faq" target="_blank" className="text-blue-500 underline cursor-pointer">
                  FAQ
                </Link>
              </p>
            </div>
            <div className="grid gap-4 lg:grid-cols-3">
              <div className="bg-red-50">
                <Button
                  onClick={() => handleFirstButton()}
                  disabled={downloadLoading}
                  variant="contained"
                  sx={{
                    boxShadow: 'none',
                    width: '100%',
                    backgroundColor: '#EAEAEA',
                    color: 'black',
                    ':hover': {
                      color: 'white'
                    }
                  }}
                >
                  {downloadLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : paymentSuccess ? 'Download Receipt' : 'Print'}
                </Button>
              </div>
              <div className="hidden lg:col-span-2 lg:block">
                <Button
                  onClick={() => handleReturnToDashboardButtonDesktopView()}
                  variant="contained"
                  sx={{
                    boxShadow: 'none',
                    width: '100%',
                    backgroundColor: 'secondary.main',
                    color: 'primary.main',
                    ":hover": {
                      color: 'white'
                    }
                  }}
                >
                  Return To Dashboard
                </Button>
              </div>
              <div className="block lg:col-span-2 lg:hidden">
                <Button
                  onClick={() => handleReturnToDashboardButtonMobileView()}
                  variant="contained"
                  sx={{
                    boxShadow: 'none',
                    width: '100%',
                    backgroundColor: 'secondary.main',
                    color: 'primary.main',
                  }}
                >
                  {status === 'success' ? 'Return to Courses' : 'Return'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentStatus
