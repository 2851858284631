import axios, { getData } from "lib/axios"

export const courseAdvertisements = async (
  method = "get",
  data = null,
  id = null,
  page = 1,
  country,
) => {
  const checkCurrency = (country) => {
    switch (country) {
      case "MY":
        return "MYR";
      
      case "SG":
        return "SGD";

      default:
        return null;
    }
  }

  const baseURL = "/job_seekers/edu_course_list";
  const url = id ? `${baseURL}/${id}` : baseURL;
  const params = id ? null : { page, exclusive_country: country, currency: checkCurrency(country) };
  
  return axios({
    method,
    url,
    data,
    params,
  }).then(getData)
}

export const postCourseLog = async (data) => {
  return axios.post(`/job_seekers/edu_course_list/log/`, data).then(getData)
}
