import React from 'react'
import Loader from 'react-loader-spinner'

const LoadingScreen = () => {
  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '999999999',
        }}
      >
        <Loader type="Oval" color="#09b7b7" height={55} width={55} />
      </div>
      <div
        style={{
          position: 'fixed',
          zIndex: '15',
          width: '100vw',
          height: '100vh',
          background: 'black',
          opacity: '0.7',
        }}
      ></div>
    </>
  )
}

export default LoadingScreen