import Setting from 'pages/setting/components/Setting'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const TermsConditions = () => {
  return (
    <Setting title={'Terms and Conditions'}>
      <div className="my-2 max-w-2xl space-y-10 py-4 px-10 text-sm lg:my-10">
        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">INTRODUCTION</div>
          <div>
            You are required to read this page carefully. It contains the terms and conditions
            (hereinafter as "Terms and Conditions") governing your access to and use of the{' '}
            <b>adnexio</b> services and websites, whether under full or partial control of{' '}
            <b>adnexio</b>(collectively as "Sites") and <b>adnexio</b> 's online and/or mobile
            services and websites, including any <b>adnexio</b> mobile application (collectively as
            "<b>adnexio</b> apps"), provided by Agensi Pekerjaan Adnexio Sdn Bhd and/or one of its
            associated companies. If you do not accept these Terms and Conditions or you do not meet
            or comply with their provisions, you may not use the Sites. These Terms and Conditions
            are effective as of the date of the launch of the <b>adnexio</b> website.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">AGREEMENT</div>
          <div>
            Each time you access or use <b>adnexio</b> apps, regardless of where it is downloaded
            from, and any software provided by or on behalf of <b>adnexio</b> on or in connection
            with such Sites:
          </div>
          <ul className="ml-6 list-outside list-disc space-y-1">
            <li>
              you represent that you have read and understood all policies on <b>adnexio</b>'s apps
              and Sites; and
            </li>
            <li>you hereby agree to the Terms and Conditions stated herein and therein.</li>
          </ul>
          <div>
            All references to "we", "<b>adnexio</b>", "Agensi Pekerjaan Adnexio Sdn Bhd" refer to
            the same applicable entity a set forth above.
          </div>
          <div>
            The Terms and Conditions (as may be amended from time to time by <b>adnexio</b> form a
            binding agreement (the "Agreement") between you and <b>adnexio</b>. Your access to or
            use of the <b>adnexio</b> services indicates your acceptance of these Terms and
            Conditions. You are agreeing to use the Sites at your own risk.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">DEFINITIONS</div>

          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>adnexio</b> Materials
                  </TableCell>
                  <TableCell>
                    Any materials, methodologies, implementation plans or other intellectual
                    property used during the provision of <b>adnexio</b> services.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Site
                  </TableCell>
                  <TableCell>
                    Any website under <b>adnexio</b>'s control, whether partial or otherwise and
                    includes such Site's Content, <b>adnexio</b> Materials and Services (as
                    applicable in each context).
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Content
                  </TableCell>
                  <TableCell>
                    <b>adnexio</b>'s web pages, web forms, programming (including software code used
                    on the Sites and in the Services, including (i) tools, kits, and object
                    libraries, (ii) all third-party or open source code embedded therein, and (iii)
                    any upgrades, updates, releases, fixes, enhancements or modifications to the
                    foregoing), graphics, images, design (color combinations and page layout), text,
                    information, data, resumes stored in various commercial databases operated and
                    licensed by <b>adnexio</b> data submitted via the Sites by Users and other
                    content made available through the Sites by <b>adnexio</b>.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Document
                  </TableCell>
                  <TableCell>Any posting to a Site, whether job or resume.</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Employer
                  </TableCell>
                  <TableCell>
                    A person or entity that is accessing a Site to post a job or utilizing the
                    Services for any reason related to the purpose of seeking candidates for
                    employment.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Employer Materials
                  </TableCell>
                  <TableCell>
                    Any brochures, emails, sample job postings, web site content, career fair
                    material, audio, videos, photographs, logos, trademarks, service marks, domain
                    names, documents or other materials provided by Employer, if any, for use in
                    connection with the Services.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Job Seeker
                  </TableCell>
                  <TableCell>
                    A User who is accessing a Site seeking career advancement, seeking career
                    advice, seeking jobs or employment or accessing the Site in any other capacity
                    except as an Employer.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Services
                  </TableCell>
                  <TableCell>
                    Any services provided by <b>adnexio</b> or its agents described herein and
                    specified more fully in relevant places.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>Any individual or entity that uses any aspect of the Sites.</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    You or you
                  </TableCell>
                  <TableCell>
                    The person (or the entity on behalf of whom you are acting) that is agreeing to
                    these Terms and Conditions.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            INTELLECTUAL PROPERTY RIGHTS
          </div>
          <div>
            The Sites, the <b>adnexio</b> Materials and all rights, title and interest in and to the
            Sites and <b>adnexio</b> Materials are the sole property of <b>adnexio</b> or its
            licensors, and are protected by Malaysia and foreign copyright, trademark and other
            laws. Except for the limited licenses expressly granted to you in these Terms and
            Conditions, <b>adnexio</b> reserves for itself and its licensors all other rights, title
            and interest. Without limitation on the foregoing, you may not reproduce, modify,
            display, sell, or distribute the Content or <b>adnexio</b> Materials, or use them in any
            other way for public or commercial purpose. Notwithstanding anything to the contrary
            contained herein, this prohibition includes: (a) copying or adapting the HTML code used
            to generate web pages on the Sites; (b) using or attempting to use engines, manual or
            automated software, tools, devices, agents, scripts, robots or other means, devices,
            mechanisms or processes (including, but not limited to, browsers, spiders, robots,
            avatars or intelligent agents) to navigate, search, access, "scrape," "crawl," or
            "spider" any web pages or any Services provided on the Sites other than the search
            engine and search agents available from <b>adnexio</b> on such <b>adnexio</b> Sites and
            other than generally available third party web browsers (e.g., Internet Explorer,
            Firefox, Safari); and (c) aggregating, copying or duplicating in any manner any of the
            Content or information available from any of the <b>adnexio</b> Sites, without the
            express written consent of <b>adnexio</b>. The use of the Content on any other web site
            or in a networked computer environment for any purpose is strictly prohibited. The{' '}
            <b>adnexio</b> Materials are not considered to be works for hire and you may duplicate
            such <b>adnexio</b> Materials only for the purposes outlined in the Service Activation
            Agreement. The <b>adnexio</b> design logo and certain other names or logos are service
            marks or trademarks of <b>adnexio</b>, and all related product and service names, design
            marks and slogans are the service marks or trademarks of <b>adnexio</b>. In addition,
            the "look" and "feel" of the Sites (including color combinations, button shapes, layout,
            design and all other graphical elements) are also protected by <b>adnexio</b>'s
            trademarks, service marks, and copyrights. Any code that <b>adnexio</b> creates to
            generate or display the Content or the pages making up the Sites is also protected by{' '}
            <b>adnexio</b>
            's copyright. You must retain all copyright, trademark, service mark and other
            proprietary notices contained on the Content or <b>adnexio</b> Materials on any
            authorized copy you make of the Content or <b>adnexio</b> Materials. All other product
            and service marks contained on the Sites are the trademarks of their respective owners.
          </div>
        </div>

        <div className="space-y-6">
          <div className="space-y-3">
            <div className="text-xl font-semibold text-adx-darkGreen">TERMS OF USE</div>
            <div>
              <u>General use</u>
            </div>
            <div>
              The Sites are intended for individuals seeking career enhancement, career advice, and
              employment and for employers or recruiters seeking candidates for employment. You may
              use the Sites only for lawful purposes within the stated context of <b>adnexio</b>'s
              intended and acceptable use of the Sites.<b>adnexio</b> is the sole interpreter of the
              Sites' intended and acceptable use.
            </div>
          </div>

          <div className="space-y-3">
            <div>
              <u>License to use by Users who are Job Seekers</u>
            </div>
            <div>
              <b>adnexio</b> hereby grants you a limited, terminable, non-exclusive right to access
              and use the Sites only for your personal use seeking employment opportunities, career
              enhancement, and career advice for yourself. This authorizes you to view and download
              a single copy of the material on the Sites solely for your personal, non-commercial
              use. You agree that you are solely responsible for the content of any Document you
              post to a Site and any consequences arising from such posting. Your use of the Sites
              is a privilege. <b>adnexio</b> reserves the right to suspend or terminate that
              privilege for any reason at any time, in its sole discretion.
            </div>
          </div>

          <div className="space-y-3">
            <div>
              <u>License to use by Users who are Employers</u>
            </div>
            <div>
              <b>adnexio</b> hereby grants you a limited, terminable, non-exclusive right to access
              and use the Sites only for your internal business use seeking candidates for
              employment. This authorizes you to view and download a single copy of the material on
              the Sites solely for your personal use directly related to searching for and
              recruiting job prospects. <b>adnexio</b> also grants you a limited, terminable,
              non-exclusive license to use the <b>adnexio</b> Materials and Services for your
              internal use only. You may not sell, transfer or assign any of the Services or your
              rights to any of the Services provided by <b>adnexio</b> to any third party without
              the express written authorization of <b>adnexio</b>. You agree that you are solely
              responsible for the content of any Document you post to a Site and any consequences
              arising from such posting. <b>adnexio</b> reserves the right to suspend or terminate
              your access and use at any time if <b>adnexio</b> determines that you are in breach of
              these Terms and Conditions.
            </div>
          </div>

          <div className="space-y-3">
            <div>
              <u>Employer Material</u>
            </div>
            <div>
              Employer represents, warrants, and covenants that any Employer Materials provided by
              Employer for use in connection with the Services will not violate any laws or
              regulations or third-party proprietary rights, including, without limitation,
              copyright, trademark, obscenity, rights of publicity or privacy, and defamation laws.
              Employer hereby grants <b>adnexio</b> a non-exclusive, worldwide, royalty-free license
              to use the Employer Materials and to hyperlink to Employer's website in connection
              with the Services.
            </div>
          </div>

          <div className="space-y-3">
            <div>
              <u>Use of Data</u>
            </div>
            <div>
              You understand and agree that <b>adnexio</b> owns and has the right to collect,
              extract, compile, synthesize, and analyze data, which includes aggregate and
              unaggregated data. <b>adnexio</b> may use such data for any lawful business purpose
              without a duty of accounting to you. "Aggregate Data" means de-identified aggregated
              data or information regarding Job Seekers' educational or career history (including,
              by way of example and not limitation, aggregate data relating to Job Seekers'
              occupation, location, salary, education and experience).
            </div>
          </div>

          <div className="space-y-3">
            <div>
              <u>User submission</u>
            </div>
            <div>
              <b>adnexio</b> welcomes your comments regarding the Services and the Sites and
              appreciates hearing from you. Please note, however, that <b>adnexio</b>does not accept
              or consider creative ideas, suggestions, inventions or materials other than those
              which it has specifically requested. If you submit feedback on the Services, please be
              specific in your comments and do not submit creative ideas, inventions, suggestions,
              or materials. If, despite this notice, you send <b>adnexio</b> creative suggestions,
              ideas, drawings, concepts, inventions, or other information (a "User Submission"), you
              understand and agree that the User Submission shall become the property of{' '}
              <b>adnexio</b>. User Submission and any elements contained in User Submissions, shall
              not be subject to any obligation of confidentiality on <b>adnexio</b>'s part, and{' '}
              <b>adnexio</b> will not be liable for any use or disclosure of any User Submission.{' '}
              <b>adnexio</b> shall exclusively own all now known or later discovered rights to the
              User Submission and shall be entitled to unrestricted use of the User Submission for
              any purpose whatsoever, commercial or otherwise, without compensation to you or any
              other person.
            </div>
          </div>

          <div className="space-y-3">
            <div>
              <u>Third Party Providers</u>
            </div>
            <div>
              You acknowledge and agree that <b>adnexio</b> may provide the Services using third
              party providers, including subcontractors and consultants (the "Third Party
              Providers"). You agree that, as between <b>adnexio</b> and its Third Party Providers,{' '}
              <b>adnexio</b> will have sole responsibility for handling all billing and contract
              negotiations.
            </div>
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            DISCLAIMERS AND LIMITATIONS ON adnexio'S LIABILITY
          </div>
          <div>
            <b>adnexio</b> assumes no responsibility for Documents posted by Users and no
            responsibility for the activities, omissions or other conduct of Users. <b>adnexio</b>{' '}
            acts as a portal for the online distribution and publication of User submitted
            information and has no obligation to screen communications or information in advance and
            is not responsible for screening or monitoring Documents posted by Users. If notified by
            a User of a Document which allegedly does not conform to these Terms and Conditions,{' '}
            <b>adnexio</b> may investigate the allegation and determine in good faith and in its
            sole discretion whether to remove or request the removal of such Document.{' '}
            <b>adnexio</b> has no liability or responsibility to Users for performance or
            non-performance of such activities. <b>adnexio</b> may take any action with respect to
            User submitted information that it deems necessary or appropriate, in its sole
            discretion.
          </div>
          <div>
            Nothing on the Sites shall be considered an endorsement, representation or warranty with
            respect to any User or third party, whether in regards to its web site, products,
            services, hiring, experience, employment or recruiting practices, or otherwise.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">WARRANTY DISCLAIMERS</div>
          <div>
            (a) THE SITES ARE PROVIDED ON AN 'AS IS' BASIS WITHOUT ANY WARRANTIES OF ANY KIND,
            EXPRESS OR IMPLIED. <b>adnexio</b>, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS
            ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, TITLE,
            NON-INFRINGEMENT OF THIRD PARTIES' RIGHTS, AND FITNESS FOR A PARTICULAR PURPOSE.{' '}
            <b>adnexio</b> MAKES NO WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, OR
            TIMELINESS OF THE SITES.
          </div>
          <div>(b) WITHOUT LIMITATION ON THE FOREGOING:</div>
          <div>
            (i) <b>adnexio</b> DOES NOT WARRANT THAT THE SITES WILL OPERATE ERROR-FREE OR THAT THE
            SITES AND THEIR SERVERS ARE FREE OF COMPUTER VIRUSES OR OTHER HARMFUL MECHANISMS. IF
            YOUR USE OF THE SITES RESULTS DIRECTLY OR INDIRECTLY IN THE NEED FOR SERVICING OR
            REPLACING EQUIPMENT OR DATA, <b>adnexio</b> IS NOT RESPONSIBLE FOR THOSE COSTS.
          </div>
          <div>
            (ii) <b>adnexio</b> makes no representations or guarantees regarding the truthfulness,
            accuracy, LEGALITY, completeness, timeliness or reliability of any Documents posted by
            Users, or of any other form of communication engaged in by Users. Documents may contain
            inaccuracies or typographical errors. You agree that any reliance on Documents posted by
            Users, or on any other form of communication with Users, will be at your own risk.
          </div>
          <div>
            (iii) <b>adnexio</b> makes no representations or guarantees regarding the Content of the
            Sites, including, but not limited to, broken links, inaccuracies or typographical
            errors.
          </div>
          <div>
            (iv) <b>adnexio</b> MAKES NO REPRESENTATIONS OR GUARANTEES REGARDING THE EFFECTIVENESS
            OF THE SERVICES OR TIMELINESS OF THE SERVICES IN MEETING YOUR EMPLOYMENT OBJECTIVES.{' '}
            <b>adnexio</b>DOES NOT GUARANTEE THAT THE SERVICES WILL RESULT IN CANDIDATES BEING
            HIRED, POSITIONS BEING FILLED OR EMPLOYEES BEING RETAINED, AND IS NOT RESPONSIBLE OR
            LIABLE FOR ANY BUSINESS, EMPLOYMENT, HIRING AND/OR SALARY DECISIONS, FOR WHATEVER REASON
            MADE, MADE BY YOU.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            DAMAGE LIMITATIONS, ALLOCATIONS OF LIABILITY AND EQUITABLE RELIEF
          </div>
          <div>
            (a) THE SITES ARE PROVIDED ON AN 'AS IS' BASIS WITHOUT ANY WARRANTIES OF ANY KIND,
            EXPRESS OR IMPLIED. <b>adnexio</b>, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS
            ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, TITLE,
            NON-INFRINGEMENT OF THIRD PARTIES' RIGHTS, AND FITNESS FOR A PARTICULAR PURPOSE.{' '}
            <b>adnexio</b> MAKES NO WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, OR
            TIMELINESS OF THE SITES.
          </div>
          <div>
            (b) IN NO EVENT SHALL <b>adnexio</b> (OR ANY OF ITS OFFICERS, DIRECTORS, SHAREHOLDERS,
            EMPLOYEES, SUBSIDIARIES, AFFILIATES, AGENTS OR ADVERTISERS), BE LIABLE FOR ANY
            NON-DIRECT DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND
            CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA, LOST
            EMPLOYMENT OPPORTUNITY, OR BUSINESS INTERRUPTION) RESULTING FROM OR ARISING UNDER OR IN
            CONNECTION WITH SERVICES OR THE USE OR ACCESS TO, OR THE INABILITY TO USE OR ACCESS, THE
            SITES AND/OR ANY DOCUMENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL
            THEORY, AND WHETHER OR NOT <b>adnexio</b> IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </div>
          <div>
            (c) BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE LIMITATIONS SET FORTH IN THE
            PRECEDING PARAGRAPH MAY NOT APPLY TO YOU.
          </div>
          <div>
            (d) Due to the nature of this Agreement, in addition to money damages, you agree that{' '}
            <b>adnexio</b> will be entitled to equitable relief upon a breach of this agreement by
            you.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">USER AUTHENTICATION</div>
          <div>
            Because User authentication on the Internet is difficult, <b>adnexio</b> cannot and does
            not confirm that each User is who they claim to be, although <b>adnexio</b> undertakes
            to authenticate Users on a reasonable endeavour basis. Because <b>adnexio</b> does not
            and cannot be involved in User-to-User dealings or control the behavior of participants
            on the Sites, in the event that you have a dispute with one or more Users, you release{' '}
            <b>adnexio</b> from claims, demands, and damages (actual and consequential and direct
            and indirect) of every kind and nature, known and unknown, suspected and unsuspected,
            disclosed and undisclosed, arising out of or in any way connected with such disputes.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">LINKS TO OTHER SITES</div>
          <div>
            <b>adnexio</b>'s Sites contains links to third party web sites. These links are provided
            solely as a convenience to you and not as an endorsement by <b>adnexio</b> of the
            contents on such third-party web sites. <b>adnexio</b> is not responsible for the
            content of linked third-party sites and does not make any representations regarding the
            content or accuracy of materials on such third-party web sites. If you decide to access
            linked third-party web sites, you do so at your own risk.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">
            AMENDMENTS TO THIS AGREEMENT AND CHANGES TO SITES
          </div>
          <div>
            <b>adnexio</b> may revise these Terms and Conditions at any time by updating this page
            and other related pages that publishes the Agreement. Changes will be binding on you on
            the date they are posted on the Sites (or as otherwise stated in the any notice of such
            changes). Any use of a Site will be considered acceptance by you of the then-current
            Terms and Conditions (including any exhibits thereto) contained on such Site. If at any
            time you find the Terms and Conditions unacceptable, you may not use the applicable Site
            any longer. Any new or different terms supplied by you are specifically rejected by{' '}
            <b>adnexio</b> unless <b>adnexio</b> agrees to them in a signed writing specifically
            including those new or different terms. <b>adnexio</b> may change the Sites at any time.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">INDEMNITY</div>
          <div>
            When you register on any of the <b>adnexio</b> Sites or in connection with the Services,
            you will be asked to create an account and provide <b>adnexio</b> with certain
            information, including but not limited to, a valid email address ("User Information").
            You acknowledge and agree that you have no ownership rights in your account.
          </div>
          <div>
            All User Information will be used in accordance with the terms of <b>adnexio</b>'s
            Privacy Policy. Please note, as set forth in the Privacy Policy, that <b>adnexio</b> may
            collect certain User Information and may contact Users periodically in accordance with
            the terms of the Privacy Policy. In addition, <b>adnexio</b> reserves the right to
            comply, in its sole discretion, with legal requirements, requests from law enforcement
            agencies or requests from government entities, even to the extent that such compliance
            may require disclosure of certain User Information. In addition, third parties may
            retain cached copies of User Information.
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">GENERAL</div>
          <div>
            <b>adnexio</b> contact information is listed on the Sites. <b>adnexio</b> makes no
            claims that the Content is appropriate or may be downloaded outside of Malaysia. Access
            to the Content may not be legal by certain persons or in certain countries, and such
            persons have no right to access or use the Sites. If you access <b>adnexio</b> from
            outside of Malaysia, you do so at your own risk and are responsible for compliance with
            the laws of your jurisdiction. This Agreement and your Service Activation Agreement, if
            you have one, are governed by the laws of Malaysia, without respect to its conflict of
            laws principles. Jurisdiction for any claims arising under this Agreement or your
            Service Activation Agreement shall lie exclusively with the courts of Malaysia. The sole
            relationship between you and <b>adnexio</b> is that of independent contractors. If any
            provision of this Agreement is found to be invalid by any court having competent
            jurisdiction, the invalidity of all or part of a provision shall not affect the validity
            of the remaining parts and provisions of this Agreement, which shall remain in full
            force and effect. All provisions of this Agreement shall survive termination except
            those granting access or use to the Sites, and you shall cease all your use and access
            thereof immediately. You may not assign or transfer your obligations under this
            Agreement. No waiver of any term of this Agreement shall be deemed a further or
            continuing waiver of such term or any other term. Except as expressly provided by{' '}
            <b>adnexio</b> in a particular "Legal Notice," or material on particular web pages of
            the Sites, this Agreement and your Service Activation Agreement where applicable,
            constitute the entire agreement between you and <b>adnexio</b>.
          </div>
        </div>

        <div className="space-y-10">
          <div className="space-y-3">
            <div className="text-xl font-semibold text-adx-darkGreen">
              RULES REGARDING POSTING, CONDUCT, AND SECURITY
            </div>
            <div>
              <b>Posting Rules</b>
            </div>
            <div>
              (a) You may not post or upload any Document to a Site that contains: (i) URLs or links
              to web sites other than to recruitment related pages on your company web site (to
              advertise your company or web site, see our Advertising Info page.); (ii) copyrighted
              material (unless you own the copyright or have the owner's permission to post the
              copyrighted material); (iii) trade secrets (unless you own them or have the owner's
              permission to post them); (iv) material that infringes on or misappropriates any other
              intellectual property rights, or violates the privacy or publicity rights of others;
              (v) irrelevant keywords or white text keywords (including any words embedded in the
              Document and hidden from the User); (vi) anything that is discriminatory, sexually
              explicit, obscene, libellous, defamatory, threatening, harassing, abusive, or hateful;
              or (vii) anything that is embarrassing or offensive to another person or entity.
            </div>
            <div>
              (b) You may not use a Document(s) to: (i) impersonate another person, living or dead;
              (ii) post false, inaccurate or misleading information, opinions or notices (commercial
              or otherwise) or chain letters; (iii) post advertisements, or solicitations of
              business (including, but not limited to, email processors, any pyramid scheme or "club
              membership").
            </div>
            <div>
              (c) Your Document(s) must contain sufficient detail to convey clearly to the User the
              nature and requirements of the job opportunity, or, in the case of Users seeking
              employment, your qualifications as a candidate for employment. Documents that
              encourage the User to "email for more details" are not permitted.
            </div>
            <div>
              (d) In limited circumstances, at <b>adnexio</b>'s sole discretion, <b>adnexio</b>{' '}
              allows non-traditional job postings such as education/training, business
              opportunities, franchise, and multi-level marketing opportunities to be posted on the
              Sites. These non-traditional job postings may require an investment of time and/or
              money by the User seeking the position. <b>adnexio</b> reserves the right, in its sole
              discretion, to move, modify, or remove entirely a non-traditional job posting if{' '}
              <b>adnexio</b> deems such job posting to conflict with the best interests of its Users
              or detract from the User experience.
            </div>
            <div>
              (e) Résumé postings must contain the accurate résumé of a living individual seeking
              employment on a full-time, part-time, or contractual basis on his or her own behalf.
            </div>
            <div>
              (f) <b>adnexio</b> is under no obligation to monitor the Documents posted on the
              Sites, but it may monitor Documents at random. Documents found to violate the above
              Posting Rules may be removed at <b>adnexio</b>'s sole discretion.
            </div>
          </div>

          <div className="space-y-3">
            <div>
              <b>Conduct Rules</b>
            </div>
            <div>
              (a) You may not respond to postings by other Users in any manner or for any purpose
              other than that which is expected (i.e., to apply for the job or to initiate further
              discussion with the candidate). Communications soliciting the Employer's business are
              prohibited.
            </div>
            <div>(b) You may not send unsolicited commercial email to Users.</div>
            <div>
              (c) Protect your password. Your <b>adnexio</b> account and any related Services
              accessed through such account are to be accessed and used solely by you. Upon your
              successful registration for an <b>adnexio</b> account, you will be provided with a
              unique password that will permit you to access the account. You may not provide your
              password or otherwise permit access to your <b>adnexio</b> account to any third party.
              You are responsible for maintaining the confidentiality of your information and
              password. You agree to protect and keep your password confidential, to change your
              password on a regular basis, and to maintain appropriate and regularly updated malware
              scanning and cleaning tools on your network and individual computer(s).You are
              responsible for all uses of your account, whether or not authorized by you. If others
              use your password to post inappropriate material on the Sites, you risk losing your
              access to the Sites. You agree to notify <b>adnexio</b> immediately of any
              unauthorized use of your account or password.
            </div>
            <div>(d) Report inappropriate postings or conduct to us in any available methods.</div>
            <div>
              (e) You may not delete or revise any material posted by any other person or entity.
            </div>
            <div>
              (f) If at any time <b>adnexio</b> comes to the understanding that you: (i) misled{' '}
              <b>adnexio</b> regarding your business practices and/or services, or (ii) purchased
              services that do not represent your precise business, <b>adnexio</b> reserves the
              right to terminate your Agreement.
            </div>
          </div>

          <div className="space-y-3">
            <div>
              <b>Security Rules</b>
            </div>
            <div>
              (a) Users are prohibited from violating or attempting to violate the security of the
              Sites, including, without limitation: (i) accessing data not intended for such User or
              logging into a server or account which the User is not authorized to access; (ii)
              attempting to probe, scan or test the vulnerability of a system or network or to
              breach security or authentication measures without proper authorization; (iii)
              attempting to interfere with service to any User, host or network, including, without
              limitation, via means of submitting a virus to the Sites, overloading, "flooding",
              "mail bombing" or "crashing"; (iv) sending unsolicited e-mail, including promotions
              and/or advertising of products or services; (v) forging any TCP/IP packet header or
              any part of the header information in any e-mail or newsgroup posting.
            </div>
            <div>
              (b) Violation of these Security Rules may result in civil or criminal liability.{' '}
              <b>adnexio</b> will investigate occurrences which may involve such violations and may
              involve, and cooperate with, law enforcement authorities in prosecuting Users who are
              involved in such violations.
            </div>
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-xl font-semibold text-adx-darkGreen">COPYRIGHT COMPLAINTS</div>
          <div>
            <b>adnexio</b> respects the intellectual property of others. It is <b>adnexio</b>'s
            policy to respond to claims of copyright and other intellectual property infringement.{' '}
            <b>adnexio</b> will promptly process and investigate notices of alleged infringement and
            will take appropriate actions applicable intellectual property laws. Upon receipt of any
            such notices, <b>adnexio</b> may act expeditiously to remove or disable access to any
            material claimed to be infringing or claimed to be the subject of infringing activity
            and may act expeditiously to remove or disable access to any reference or link to
            material or activity that is claimed to be infringing. <b>adnexio</b> will terminate
            access for Users who are repeat infringers.
          </div>
        </div>
      </div>
    </Setting>
  )
}

export default TermsConditions
