import { useState, useContext, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { scroller, Element } from 'react-scroll'
import moment from 'moment'

import Pagination from '@mui/material/Pagination'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Grow from '@mui/material/Grow'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ShareIcon from '@mui/icons-material/Share'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { authContext } from 'contexts/AuthContext'
import { CourseCardContext } from 'contexts/CourseCardContext'
import { courseAdvertisements } from 'services/api/course_advertisment'
import {
  courseApplications,
  selfAppliedCourse,
  selfWithdrawApplication,
} from 'services/api/course_application'

import EDUAlertModal from 'modules/edu/components/AlertModal'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import CustomHelmet from 'components/smart/CustomHelmet'
import Footer from 'components/dumb/Footer'
import CourseCard from '../components/CourseCard'
import StatusChipApplication from '../components/StatusChipApplication'
import LoadingScreen from '../components/LoadingScreen'

import background from '../assets/background.jpg'
import not_found from '../assets/not_found.png'
import ShareSocialMedia from 'components/smart/ShareSocialMedia'
import { Box } from '@mui/material'

import hrdLogo from '../../hrd/assets/hrd-logo-1.svg'
import Bugsnag from '@bugsnag/js'
import { getHrdfStatus } from 'services/api/hrd'
import { format } from 'date-fns'
import { IpContext } from 'contexts/IpContext'

const mui = {
  btnText: {
    color: '#1B8379',
    textTransform: 'capitalize',
  },
  btnContained: {
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    fontSize: '12px',
  },
  ShareIconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  btnCourseStructure: {
    width: '100%',
    fontSize: 16,
  },
  pagination: {
    justifyContent: 'center',
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
  },
  accordionBox: {
    border: 'none',
    boxShadow: 'none',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
  },
  accordionTitle: {
    border: 'solid #D9D9D9 1px',
    marginTop: 0,
    borderRadius: '4px',
  },
  accordionDescription: {
    backgroundColor: '#F5F5F5',
  },
  disableAccordion: {
    '&.Mui-disabled': {
      backgroundColor: 'white',
      opacity: 1,
    },
  },
  disableAccordionSummary: {
    opacity: '1 !important',
  },
  btnEmail: {
    padding: '8px 8px',
    fontSize: '12px',
    background: '#FFFFFF',
    color: '#1B8379',
    borderRadius: '50%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#1B8379',
    boxShadow: 'none',
    marginRight: '17px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  btnAdnexioGig: {
    background: '#FCD94E',
    color: '#1B8379',
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    fontSize: '12px',
    '&:hover': {
      background: '#FCD94E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  btnAdnexioOutline: {
    background: '#FFFFFF',
    color: '#1B8379',
    textTransform: 'capitalize',
    padding: '8px 18px',
    boxShadow: 'none',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#1B8379',
    fontSize: '12px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  popperTwo: {
    '& .MuiList-padding': {
      padding: '10px 10px 10px 10px',
    },
  },
  settingsEDUBtn: {
    fontSize: '14px !important',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const CourseAds = () => {
  const { auth } = useContext(authContext)
  const { isAuth: isLoggedIn, profileComplete } = auth
  const { ipCountry } = useContext(IpContext)

  const params = new URL(window.location.href).searchParams
  const paramSearch = params.get('search')

  const navigate = useNavigate()
  const searchInput = window.location.search.substr(8)

  const { courseID, setCourseID, selectedCourse, setSelectedCourse } = useContext(CourseCardContext)

  const [openShare, setOpenShare] = useState(false)
  const [copied, setCopied] = useState(false)
  const handleOpen = () => {
    setOpenShare(true)
  }
  const handleClose = () => {
    setOpenShare(false)
    setCopied(false)
  }

  const [value, setValue] = useState(0)
  const [courseAdvertisementsLists, setCourseAdvertisementsLists] = useState([])
  const [courseAdvertisementsListsSetting, setCourseAdvertisementsListsSetting] = useState()
  const [courseApplicationLists, setCourseApplicationLists] = useState([])
  const [courseApplicationListsSetting, setCourseApplicationSetting] = useState([])

  const [loading, setLoading] = useState(false)

  const [courseAdvetisementPage, setCourseAdvetisementPage] = useState(1)
  const [courseApplicationPage, setCourseApplicationPage] = useState(1)
  const [hrdfStatus, setHRDFStatus] = useState({})

  const [openApplyModal, setOpenApplyModal] = useState(false)
  const [modalProp, setApplyModalProp] = useState({
    success: null,
    title: '',
    body: '',
    button: '',
  })

  const [expandedPanel, setExpandedPanel] = useState(false)
  const [anchorWaitlistPopper, setAnchorWaitlistPopper] = useState(null)
  const open = Boolean(anchorWaitlistPopper)

  // to close the popper for withdrawal of awaiting list
  const handleDismissPopperModal = () => {
    if (open) {
      setAnchorWaitlistPopper(null)
    }
    setAnchorWaitlistPopper(null)
  }

  // Remove Waitlist API Call
  const handleRemoveWaitlist = async (valueID) => {
    try {
      await selfWithdrawApplication(valueID)
      await getCourseAdvertisements(courseAdvetisementPage, ipCountry)
      await getCourseApplicationList(courseApplicationPage)
      setCourseID(null)
    } catch (error) {
      Bugsnag.notify(error)
      if (error.status === 400) {
        setApplyModalProp({
          success: false,
          title: 'Oh Snap!',
          body: 'Complete your full profile before applying for a course!',
          button: 'Go to Profile',
        })
        setOpenApplyModal(true)
      } else if (error.status === 422) {
        const errors = error.data.errors
        setApplyModalProp({
          success: false,
          title: 'Oh Snap!',
          body: Object.values(errors)[0],
          button: 'Close',
        })
        setOpenApplyModal(true)
      } else {
        setApplyModalProp({
          success: false,
          title: `Oh Snap! ${error.status}`,
          body: JSON.stringify(error.data, null, 2),
          button: 'Close',
        })
        setOpenApplyModal(true)
      }
    }
  }

  // handle onClick menu for waitlist popper
  const handlePageDifference = (pageValue) => {
    if (pageValue === 0) {
      handleRemoveWaitlist(selectedCourse?.nex_edu_application_id)
    } else if (pageValue === 1) {
      handleRemoveWaitlist(selectedCourse?.id)
    }
  }

  //handle close course ads details on the right side
  const handleCloseButton = () => {
    setCourseID(null)
  }

  // get list of course advertisements
  const getCourseAdvertisements = async (courseAdvetisementPage, country) => {
    setLoading(true)
    try {
      const response = await courseAdvertisements(
        'get',
        null,
        null,
        courseAdvetisementPage,
        country
      )
      setCourseAdvertisementsListsSetting(response.data)
      setCourseAdvertisementsLists(response.data.data)
    } catch (e) {
      setCourseAdvertisementsLists([])
    } finally {
      setLoading(false)
    }
  }

  // handle close successful application modal
  const onCloseAlertModal = async () => {
    setOpenApplyModal(false)
    setApplyModalProp({
      success: null,
      title: '',
      body: '',
      button: '',
    })

    await getCourseAdvertisements(courseAdvetisementPage, ipCountry)
    await getCourseApplicationList(courseApplicationPage)
    setCourseID(null)
  }

  // handle first button on succesful application modal
  const handleApplyModalButton = async () => {
    if (modalProp.button === 'Close' || modalProp.button === 'Pay Later') {
      setOpenApplyModal(false)
      setApplyModalProp({
        success: null,
        title: '',
        body: '',
        button: '',
      })
      await getCourseAdvertisements(courseAdvetisementPage, ipCountry)
      await getCourseApplicationList(courseApplicationPage)
      setCourseID(null)
    } else if (modalProp.button === 'Go to Profile') {
      navigate('/profile')
    }
  }

  // handle second button on successful application modal
  const handleApplyModalSecondButton = () => {
    if (modalProp.secondButton === 'View My Class') {
      navigate('/bootcamp/my-classes')
    } else if (modalProp.secondButton === 'Make Payment') {
      navigate(`payment-summary/${selectedCourse.nex_edu_payment_schedule_id}`)
    }
  }

  // handle pagination on all tabs
  const handlePaginationAll = async (e, page) => {
    setCourseAdvetisementPage(page)
    await getCourseAdvertisements(page, ipCountry)
    window.scrollTo(0, 0)
  }

  // handle pagination on application tabs
  const handlePaginationApplicationAll = async (e, page) => {
    setCourseApplicationPage(page)
    await getCourseApplicationList(page)
    window.scrollTo(0, 0)
  }

  // get list of application list
  const getCourseApplicationList = async (courseApplicationPage) => {
    try {
      const results = await courseApplications('get', null, null, courseApplicationPage)
      setCourseApplicationSetting(results.data)
      setCourseApplicationLists(results.data.data)
    } catch (e) {
      if (e.status === 404) {
        setCourseApplicationLists([])
      }
    }
  }

  // handle navigate to make payment
  const handleMakePaymentButton = async (res) => {
    if (selectedCourse?.nex_edu_payment_schedule_id || res) {
      navigate(`/payment-summary/${selectedCourse?.nex_edu_payment_schedule_id || res}`)
    } else {
      setApplyModalProp({
        success: false,
        title: 'Oh Snap!',
        body: 'Some errors occured! Please contact us for further information', //TBC
        button: 'Close',
      })
      setOpenApplyModal(true)
    }
  }

  const handleHRDFNavigation = (res) => {
    if (selectedCourse?.nex_edu_payment_schedule_id || res) {
      // CHECK IF USER HAS APPLIED FOR HRDF ELIGIBLITY OR NOT
      if (hrdfStatus?.hrdf_application_status_id) {
        navigate('/hrd/status', {
          state: {
            nex_edu_payment_schedule_id: selectedCourse?.nex_edu_payment_schedule_id || res,
            data: selectedCourse?.spots_remaining < 1 ? selectedCourse : null,
          },
        })
      } else {
        navigate('/hrd/introduction', { state: selectedCourse?.nex_edu_payment_schedule_id || res })
      }
    } else {
      setApplyModalProp({
        success: false,
        title: 'Oh Snap!',
        body: 'Some errors occured! Please contact us for further information', //TBC
        button: 'Close',
      })
      setOpenApplyModal(true)
    }
  }

  const handleBuyNowHrdf = (res) => {
    if (selectedCourse?.nex_edu_payment_schedule_id || res) {
      navigate(`/payment-options/${selectedCourse?.nex_edu_payment_schedule_id || res}`, {
        state: selectedCourse?.nex_edu_payment_schedule_id || res,
      })
    } else {
      setApplyModalProp({
        success: false,
        title: 'Oh Snap!',
        body: 'Some errors occured! Please contact us for further information', //TBC
        button: 'Close',
      })
      setOpenApplyModal(true)
    }
  }

  const handlePurchaseCourse = async (e) => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        await selfAppliedCourse({
          trainer_id: selectedCourse.trainer_id,
          nex_edu_course_id: selectedCourse.id,
          nex_edu_course_intake_id: selectedCourse?.nex_edu_course_intakes[0]?.id,
        }).then((res) => {
          setSelectedCourse(res.data)
          if (res.data?.is_hrdf) {
            if (e && e.target.id === 'buyNow') {
              navigate(`/payment-options/${res.data.nex_edu_payment_schedule_id}`, {
                state: res.data.nex_edu_payment_schedule_id,
              })
            } else {
              handleHRDFNavigation(res.data.nex_edu_payment_schedule_id)
            }
          } else {
            handleMakePaymentButton(res.data.nex_edu_payment_schedule_id)
          }
        })
        await getCourseAdvertisements(courseAdvetisementPage, ipCountry)
        await getCourseApplicationList(courseApplicationPage)
      } catch (e) {
        Bugsnag.notify(e)
        if (e.status === 400) {
          setApplyModalProp({
            success: false,
            title: 'Oh Snap!',
            body: 'Complete your full profile before applying for a course!',
            button: 'Go to Profile',
          })
          setOpenApplyModal(true)
        } else if (e.status === 422) {
          const errors = e.data.errors
          setApplyModalProp({
            success: false,
            title: 'Oh Snap!',
            body: Object.values(errors)[0],
            button: 'Close',
          })
          setOpenApplyModal(true)
        }
        setLoading(false)
      }
    } else {
      navigate('/signin', { state: { from: 'courses' } })
    }
  }

  const handleClickScrollUpButton = () => {
    scroller.scrollTo('topCourseDetail', {
      containerId: 'courseDetail',
      offset: -100,
    })
  }

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }

  const fetchHRDFStatus = async () => {
    try {
      const res = await getHrdfStatus(selectedCourse?.nex_edu_payment_schedule_id)
      setHRDFStatus(res)
    } catch (error) {
      Bugsnag.notify(error)
    }
  }

  useEffect(() => {
    if (selectedCourse?.nex_edu_payment_schedule_id) {
      if (selectedCourse?.is_hrdf) {
        fetchHRDFStatus()
      } else {
        setHRDFStatus({})
      }
    } else {
      setHRDFStatus({})
    }
    // eslint-disable-next-line
  }, [selectedCourse])

  useEffect(() => {
    getCourseAdvertisements(courseAdvetisementPage, ipCountry)
  }, [courseAdvetisementPage, ipCountry])

  useEffect(() => {
    getCourseApplicationList(courseApplicationPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseApplicationPage])

  // display motivational quote (right section) on mount
  useEffect(() => {
    handleCloseButton()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // scroll up on the right panel upon any changes in courseId
  useEffect(() => {
    handleClickScrollUpButton()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseID])

  // Get image file extension
  let re = /(?:\.([^.]+))?$/

  const handleNavigateEdu = () => {
    window.open(`${process.env.REACT_APP_EDU_URL}/course/${selectedCourse?.id}`)
  }

  return (
    <div>
      <CustomHelmet title="Courses | adnexio EDU" url="https://adnexio.jobs/courses" />

      <div>
        <div className="flex min-h-screen flex-col bg-adx-gray lg:h-auto">
          {/* loading screen */}
          {loading && <LoadingScreen />}

          <div className="fixed z-10 w-full">
            <HeaderEdu page={'courses'} />
          </div>

          {/* Application alert modal */}
          <EDUAlertModal
            onClose={() => {
              onCloseAlertModal()
            }}
            onClickButton={() => {
              handleApplyModalButton()
            }}
            onClickSecondButton={() => {
              handleApplyModalSecondButton()
            }}
            open={openApplyModal}
            modalProp={modalProp}
          />

          {/* Joined Waitlist Popper to remove from waitlist */}
          <Popper
            open={open}
            anchorEl={anchorWaitlistPopper}
            transition
            placement="bottom-end"
            className="z-50 w-4/12 min-w-min max-w-sm rounded-xl shadow-xl lg:w-auto lg:max-w-sm"
            sx={mui.popperTwo}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                <div className="rounded-xl bg-white shadow-xl">
                  <ClickAwayListener onClickAway={handleDismissPopperModal}>
                    <MenuList autoFocusItem={open}>
                      <MenuItem onClick={() => handlePageDifference(value)} sx={mui.settingsEDUBtn}>
                        Remove from waitlist
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </div>
              </Grow>
            )}
          </Popper>

          <div className="relative flex-1" style={{ marginTop: isLoggedIn ? 80 : 70 }}>
            {/* background image on the right side of screen */}
            <div
              className={`hidden lg:fixed lg:grid lg:min-h-screen lg:w-full lg:grid-cols-2 ${
                courseID && 'lg:h-full'
              }`}
            >
              <div className="bg-adx-gray" style={{ zIndex: -100 }}></div>
              {!courseID ? (
                <div
                  className="relative -mt-12 bg-cover text-white"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <div className="absolute h-full w-full bg-black opacity-60" />
                  <div className="right absolute grid h-full w-full place-items-center text-center">
                    <div className="mx-auto w-1/2">
                      <p className="text-2xl">
                        "Education is not the learning of facts, but the training of minds to
                        think."
                      </p>
                      <p className="mt-4 text-lg font-medium">Albert Einstein</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="relative h-full w-full bg-white" />
              )}
            </div>

            <div className="h-full lg:mx-auto lg:flex lg:min-h-screen lg:max-w-screen-2xl ">
              {/* left section */}
              <div className="flex min-h-full flex-col lg:w-full lg:justify-self-center lg:px-16 lg:pb-8 lg:pt-4 ">
                {
                  //Display All Courses Card when NOT search Card
                  value === 0 && !searchInput.length && courseAdvertisementsLists.length ? (
                    <div className="relative overflow-auto px-2">
                      <div className="rounded-xl px-4 pt-4">
                        {courseAdvertisementsLists.map((item) => (
                          <CourseCard key={item.id} item={item} value={value} />
                        ))}
                        <div className="mt-4 mb-8">
                          {courseAdvertisementsListsSetting?.last_page > 1 && (
                            <Pagination
                              shape="rounded"
                              sx={mui.pagination}
                              siblingCount={0}
                              boundaryCount={1}
                              showFirstButton
                              showLastButton
                              color="primary"
                              count={courseAdvertisementsListsSetting?.last_page}
                              onChange={(e, page) => {
                                handlePaginationAll(e, page)
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : //Copywrting No course Ads yet
                  value === 0 && !searchInput.length && !courseAdvertisementsLists.length ? (
                    <div className="relative flex h-screen items-center justify-center text-center lg:flex-1">
                      <div className="px-4 lg:px-28">
                        <div className="text-md relative -top-20 whitespace-pre-wrap text-adx-darkGreen lg:text-xl">
                          <img
                            src={not_found}
                            width={150}
                            alt="no course available"
                            className="mx-auto mb-4"
                          />
                          <p className="mb-2 font-semibold">There are no courses available yet.</p>
                        </div>
                      </div>
                    </div>
                  ) : //Display Copywriting when Courses Searched but NOT found
                  !loading &&
                    value === 0 &&
                    searchInput.length &&
                    !courseAdvertisementsLists.length ? (
                    <div className="relative flex h-screen items-center justify-center text-center lg:flex-1">
                      <div className="px-4 lg:px-28">
                        <p className="text-md relative -top-20 whitespace-pre-wrap text-adx-darkGreen lg:text-xl">
                          <img
                            src={not_found}
                            width={150}
                            alt="no course found"
                            className="mx-auto mb-4"
                          />
                          <p className="font-semibold">{`“${paramSearch}” not found`} </p>
                          <p>{`Make sure you've used the right keywords and search terms to find the course you're looking for!`}</p>
                        </p>
                      </div>
                    </div>
                  ) : value === 1 && !isLoggedIn ? (
                    <div className="relative flex h-screen items-center justify-center px-4 text-center lg:flex-1">
                      <div className="px-4 lg:px-8">
                        <p className="text-md text-adx-darkerGray lg:text-xl">
                          Your upskilling journey is only one step away
                        </p>
                        <p className="lg:text-md mb-4 text-sm text-adx-darkerGray">
                          Login to view our available masterclasses and crash courses
                        </p>
                        <div>
                          <Button
                            sx={mui.btnContained}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              navigate('/signin', { state: { from: 'courses' } })
                            }}
                          >
                            Login{' '}
                          </Button>
                        </div>
                        <div>
                          <Button
                            sx={mui.btnText}
                            onClick={() => {
                              navigate('/signup')
                            }}
                          >
                            Sign Up{' '}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : // Display All Applied Courses if select APPLICATION tab and login
                  value === 1 && isLoggedIn && courseApplicationLists.length ? (
                    <div className="relative overflow-auto px-2 ">
                      <div className="rounded-xl px-4 pt-4">
                        {courseApplicationLists.map((item) => (
                          <CourseCard key={item.id} item={item} value={value} />
                        ))}
                      </div>
                      <div className="mt-4 mb-8">
                        {courseApplicationListsSetting?.last_page > 1 && (
                          <Pagination
                            shape="rounded"
                            sx={mui.pagination}
                            siblingCount={0}
                            showFirstButton
                            showLastButton
                            boundaryCount={1}
                            color="primary"
                            count={courseApplicationListsSetting?.last_page}
                            onChange={(e, page) => {
                              handlePaginationApplicationAll(e, page)
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ) : // Display Copywriting if login but no applied any courses
                  value === 1 && isLoggedIn && !courseApplicationLists.length ? (
                    <div className="relative flex h-screen items-center justify-center text-center lg:flex-1">
                      <div className="px-4 lg:px-28">
                        <div className="text-md relative -top-20 whitespace-pre-wrap text-adx-darkGreen lg:text-xl">
                          <img
                            src={not_found}
                            width={150}
                            alt="no course applied found"
                            className="mx-auto mb-4"
                          />
                          <p className="mb-2 font-semibold">
                            {`You have not apply for any courses yet`}{' '}
                          </p>
                          <div>
                            <Button
                              sx={mui.btnContained}
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setValue(0)
                              }}
                            >
                              View available course{' '}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
              </div>

              {/* Course ads details */}
              {(value === 1 && courseID) ||
              (searchInput.length && courseID) ||
              (value === 0 && courseID) ? (
                <div className="hidden flex-col overflow-hidden lg:sticky lg:top-20 lg:flex lg:h-full lg:w-full lg:justify-self-center lg:px-16 lg:pb-8 lg:pt-4">
                  <div className="lg:sticky lg:flex lg:h-12 lg:items-center lg:justify-between ">
                    <Button
                      sx={mui.btnText}
                      startIcon={<ChevronLeftIcon />}
                      onClick={handleCloseButton}
                    >
                      Close
                    </Button>
                    {/* button status on the right side section */}
                    <div className="flex">
                      <IconButton
                        sx={mui.btnEmail}
                        onClick={() => navigate('/bootcamp/contact-us')}
                        size="large"
                      >
                        <MailOutlineIcon />
                      </IconButton>
                      {selectedCourse?.course_type === 'COURSE' ? (
                        <Button
                          id="buyNow"
                          color="primary"
                          variant="contained"
                          sx={mui.btnContained}
                          onClick={handleNavigateEdu}
                          disabled={!isLoggedIn || !profileComplete}
                        >
                          Buy Now
                        </Button>
                      ) : !selectedCourse?.nex_edu_application_status_id ? (
                        <div className="flex items-center">
                          {selectedCourse.spots_remaining > 0 ? (
                            <Button
                              id="buyNow"
                              color="primary"
                              variant="contained"
                              sx={mui.btnContained}
                              onClick={handlePurchaseCourse}
                              disabled={!isLoggedIn || !profileComplete}
                            >
                              Buy Now
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              sx={mui.btnAdnexioGig}
                              disabled
                            >
                              Full
                            </Button>
                          )}
                        </div>
                      ) : selectedCourse?.spots_remaining > 0 ? (
                        [1, 2, 4, 5].includes(selectedCourse?.nex_edu_application_status_id) ? (
                          <div className="flex items-center">
                            <Button
                              color="primary"
                              variant="contained"
                              sx={mui.btnContained}
                              onClick={
                                selectedCourse?.is_hrdf
                                  ? () =>
                                      handleBuyNowHrdf(selectedCourse?.nex_edu_payment_schedule_id)
                                  : () =>
                                      handleMakePaymentButton(
                                        selectedCourse?.nex_edu_payment_schedule_id
                                      )
                              }
                              disabled={!isLoggedIn || !profileComplete}
                            >
                              Buy now
                            </Button>
                          </div>
                        ) : (
                          [3].includes(selectedCourse?.nex_edu_application_status_id) && (
                            <div className="flex items-center">
                              <Button
                                color="primary"
                                variant="contained"
                                sx={mui.btnContained}
                                disabled
                              >
                                Purchased
                              </Button>
                            </div>
                          )
                        )
                      ) : (
                        <div className="flex items-center">
                          <Button
                            color="primary"
                            variant="contained"
                            sx={mui.btnAdnexioGig}
                            disabled
                          >
                            full
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>

                  {((isLoggedIn && !profileComplete) || !isLoggedIn) && (
                    <div
                      className="rounded-sm p-2"
                      style={{
                        backgroundColor: '#FC4E4E14',
                        color: 'rgba(0, 0, 0, 0.87)',
                      }}
                    >
                      <div className="flex items-center">
                        <ErrorOutlineIcon className="mr-2 text-red-500 " />
                        <p className="text-xs text-red-500 lg:text-sm">
                          {isLoggedIn && !profileComplete
                            ? 'Please complete your phone number and birth date before applying for a course.'
                            : 'Please login to your adnexio account to apply for this course.'}
                        </p>
                      </div>
                      <Link
                        to={{
                          pathname:
                            isLoggedIn && !profileComplete ? '/profile' : '/bootcamp/signin',
                          state: { from: 'edu' },
                        }}
                      >
                        <p className="ml-8 text-xs text-blue-500 underline lg:text-sm">
                          {isLoggedIn && !profileComplete ? 'Go to Profile' : 'Login Now'}
                        </p>
                      </Link>
                    </div>
                  )}

                  <Element
                    className="overflow-auto rounded-xl bg-adx-gray pt-4 lg:mt-2 lg:max-h-detailSection"
                    id="courseDetail"
                  >
                    <div className="px-4">
                      {/* course summary */}
                      <div
                        className="border-xl relative mx-auto mb-4 grid w-full grid-cols-12 rounded-md bg-adx-white px-4 py-6 shadow"
                        id="topCourseDetail"
                      >
                        <div className="col-span-3 grid place-items-center justify-self-center">
                          {selectedCourse.course_logo === null ||
                          re.exec(selectedCourse.course_logo)[1] === 'pdf' ? (
                            <div className="flex h-20 w-20 items-center justify-center rounded-full bg-adx-white text-3xl text-black shadow">
                              {selectedCourse.course_title.charAt(0)}
                            </div>
                          ) : (
                            <img
                              src={selectedCourse.course_logo}
                              alt="course_logo"
                              className="h-20 w-20 rounded-full object-cover shadow"
                            />
                          )}
                        </div>
                        <div className="col-span-9 flex flex-col justify-center">
                          <div className="flex items-center justify-between">
                            <p className="w-5/6 text-sm font-semibold capitalize text-adx-darkGreen lg:text-lg">
                              {selectedCourse.course_title}
                            </p>
                            {value === 0 && (
                              <>
                                <Box onClick={handleOpen}>
                                  <IconButton sx={mui.ShareIconButton} size="large">
                                    <ShareIcon />
                                  </IconButton>
                                </Box>

                                <ShareSocialMedia
                                  jobAdvertisement={selectedCourse}
                                  handleClose={handleClose}
                                  open={openShare}
                                  setCopied={setCopied}
                                  copied={copied}
                                  edu={true}
                                />
                              </>
                            )}
                          </div>
                          <div className="mt-1 flex w-full items-center justify-between pr-8">
                            <p className="text-xs font-semibold lg:text-sm">
                              by {selectedCourse.trainer_name}
                            </p>

                            {value === 1 && selectedCourse?.nex_edu_application_status_id && (
                              <StatusChipApplication
                                applicationStatusId={selectedCourse?.nex_edu_application_status_id}
                              />
                            )}
                          </div>
                        </div>

                        <div className="col-span-12">
                          <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />
                        </div>
                        <div className="col-span-12 grid grid-cols-4 gap-2 px-4">
                          <div className="w-full justify-self-center ">
                            <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                              <p className="text-sm font-medium text-adx-darkGreen">Spots</p>
                              <p className="text-center text-sm font-medium">
                                {selectedCourse?.course_type === 'COURSE'
                                  ? 'Unlimited'
                                  : selectedCourse.total_spots}
                              </p>
                            </div>
                          </div>
                          <div className="w-full justify-self-center">
                            <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                              <p className="text-sm font-medium text-adx-darkGreen">Duration</p>
                              <p className="text-center text-sm font-medium">
                                {selectedCourse.course_length}
                              </p>
                            </div>
                          </div>
                          <div className="w-full justify-self-center">
                            <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                              <p className="text-sm font-medium text-adx-darkGreen">Class</p>
                              <p className="text-center text-sm font-medium">
                                {selectedCourse?.course_type === 'COURSE'
                                  ? 'Online'
                                  : selectedCourse.course_setting}
                              </p>
                            </div>
                          </div>
                          <div className="w-full justify-self-center ">
                            <div className="flex w-full flex-col items-center justify-center rounded-md bg-adx-lightTonedGreen py-1">
                              <p className="text-sm font-medium text-adx-darkGreen">Mode</p>
                              <p className="text-center text-sm font-medium">
                                {selectedCourse?.course_type === 'COURSE'
                                  ? 'Part Time'
                                  : selectedCourse.course_mode}
                              </p>
                            </div>
                          </div>
                        </div>
                        {selectedCourse?.is_hrdf ? (
                          <div className="col-span-12 mt-5 flex flex-col space-y-[20px] rounded-[4.5px] bg-adx-gray p-[20px]">
                            <div className=" flex flex-col items-center space-y-4 text-center md:flex-row md:space-y-0 md:space-x-[14px] md:text-left">
                              <img alt="hrd logo" src={hrdLogo} className="w-[60px]" />
                              {hrdfStatus && Object.keys(hrdfStatus).length > 1 ? (
                                <div className="flex flex-col space-y-[4px]">
                                  <p className="text-sm font-medium lg:text-base">
                                    HRD Corp Incentive
                                  </p>
                                  <p className="text-sm lg:text-base">
                                    You have sent your application on{' '}
                                    {format(new Date(hrdfStatus?.created_at), 'dd/MM/yyyy')}
                                  </p>
                                </div>
                              ) : (
                                <p className="text-sm lg:text-base">
                                  This course is eligible for HRD Corp Claimable Course. Apply now
                                  to receive a comprehensive reimbursement of your training costs.
                                  Enhance your professional development and upskill with this
                                  opportunity.{' '}
                                  <span
                                    onClick={() => {
                                      window.open(
                                        'https://hrdcorp.gov.my/hrd-corp-claimable-course-2/'
                                      )
                                    }}
                                    className="cursor-pointer text-adx-darkGreen underline"
                                  >
                                    Learn more
                                  </span>
                                </p>
                              )}
                            </div>
                            <Button
                              variant="contained"
                              disabled={
                                !isLoggedIn ||
                                !profileComplete ||
                                (!hrdfStatus?.hrdf_application_status_id &&
                                  selectedCourse.spots_remaining < 1) ||
                                [3].includes(selectedCourse?.nex_edu_application_status_id)
                              }
                              onClick={
                                !selectedCourse?.nex_edu_application_status_id
                                  ? () => handlePurchaseCourse()
                                  : () =>
                                      handleHRDFNavigation(
                                        selectedCourse.nex_edu_payment_schedule_id
                                      )
                              }
                              sx={{
                                backgroundColor: '#FCD94E',
                                width: '100%',
                                color: '#1B8379',
                                boxShadow: 'none',
                                minHeight: '45px',
                                '&:hover': {
                                  color: '#FCD94E',
                                },
                              }}
                            >
                              {[3].includes(selectedCourse?.nex_edu_application_status_id)
                                ? 'Purchased'
                                : !hrdfStatus?.hrdf_application_status_id &&
                                  selectedCourse.spots_remaining < 1
                                ? 'Full'
                                : hrdfStatus && Object.keys(hrdfStatus).length > 1
                                ? 'View status'
                                : 'Apply HRD Corp incentive'}
                            </Button>
                          </div>
                        ) : (
                          <div className="col-span-12 mt-5 px-4">
                            {selectedCourse?.course_type === 'COURSE' ? (
                              <Button
                                onClick={handleNavigateEdu}
                                color="primary"
                                variant="contained"
                                sx={{ ...mui.btnContained, width: '100%' }}
                                disabled={!isLoggedIn || !profileComplete}
                              >
                                Buy Now
                              </Button>
                            ) : (
                              <Button
                                onClick={
                                  !selectedCourse?.nex_edu_application_status_id
                                    ? () => handlePurchaseCourse()
                                    : [1, 2, 4, 5].includes(
                                        selectedCourse?.nex_edu_application_status_id
                                      )
                                    ? () =>
                                        handleMakePaymentButton(
                                          selectedCourse.nex_edu_payment_schedule_id
                                        )
                                    : null
                                }
                                color="primary"
                                variant="contained"
                                sx={{ ...mui.btnContained, width: '100%' }}
                                disabled={
                                  !isLoggedIn ||
                                  !profileComplete ||
                                  selectedCourse?.spots_remaining < 1 ||
                                  [3].includes(selectedCourse?.nex_edu_application_status_id)
                                }
                              >
                                {selectedCourse?.spots_remaining < 1
                                  ? 'Full'
                                  : !selectedCourse?.nex_edu_application_status_id ||
                                    [1, 2, 4, 5].includes(
                                      selectedCourse?.nex_edu_application_status_id
                                    )
                                  ? selectedCourse?.lump_sum_discount === '0'
                                    ? 'Buy Now'
                                    : `Click here to get ${selectedCourse?.currency_code}${selectedCourse?.lump_sum_discount} OFF`
                                  : [3].includes(selectedCourse?.nex_edu_application_status_id) &&
                                    'Purchased'}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                      {selectedCourse?.nex_edu_course_testimonials && (
                        <div className="mt-5 mb-8 space-y-1">
                          <video
                            className="h-full w-full p-0"
                            controls
                            key={1}
                            controlsList="nodownload"
                          >
                            <source src={selectedCourse?.nex_edu_course_testimonials} />
                          </video>
                        </div>
                      )}
                      {selectedCourse?.course_type !== 'COURSE' && (
                        <>
                          <div className="mt-5 mb-2 space-y-1 px-7">
                            <p className="text-xs font-semibold lg:text-sm">Application Guide</p>
                            <p
                              className="whitespace-pre-line text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              1. Sign up/Login to adnexio accounts.
                              <br />
                              2. Complete your profile with phone number and birth date.
                              <br />
                              3. Click “Buy Now” to purchase the course.
                              <br />
                              4. Make payment to confirm your enrollment and gain access to the
                              course.
                            </p>
                            <br />
                            <p
                              className="whitespace-pre-line text-justify text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              <span className="text-blue-500 underline">
                                <Link to="/bootcamp/contact-us">Contact us</Link>
                              </span>{' '}
                              if you have any further questions!
                            </p>
                          </div>
                          <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />
                        </>
                      )}
                      <div className="mt-5 mb-2 space-y-1 px-7">
                        <p className="text-xs font-semibold lg:text-sm">Description</p>
                        <p
                          className="whitespace-pre-line text-justify text-sm"
                          style={{ lineHeight: '1.55rem' }}
                        >
                          {selectedCourse.course_description}
                        </p>
                        {selectedCourse?.course_type !== 'COURSE' &&
                          selectedCourse?.class_schedule && (
                            <div>
                              <p className="text-xs font-semibold lg:text-sm">Class Schedule</p>
                              <p
                                className="whitespace-pre-line text-sm"
                                style={{ lineHeight: '1.55rem' }}
                              >
                                {selectedCourse?.class_schedule}
                              </p>
                            </div>
                          )}
                        {selectedCourse?.course_type !== 'COURSE' &&
                          selectedCourse?.grad_requirement && (
                            <div>
                              <p className="text-xs font-semibold lg:text-sm">
                                {selectedCourse.course_title} - Graduation Requirement
                              </p>
                              <p
                                className="whitespace-pre-line text-sm"
                                style={{ lineHeight: '1.55rem' }}
                              >
                                {selectedCourse?.grad_requirement}
                              </p>
                            </div>
                          )}
                      </div>
                      <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                      <div className="my-2 mb-8 space-y-2 px-7">
                        {selectedCourse?.course_type !== 'COURSE' ? (
                          <>
                            <p className="text-xs font-semibold lg:text-sm">Benefits</p>
                            <p
                              className="whitespace-pre-line text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              {selectedCourse.course_benefits}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="text-xs font-semibold lg:text-sm">Learning objectives</p>
                            <ul className="ml-4 list-outside list-disc space-y-1 text-sm ">
                              {selectedCourse?.objectives?.map((objective, index) => (
                                <li key={index}>{objective}</li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                      <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                      <div className="my-2 mb-8 space-y-2 px-7">
                        <p className="text-xs font-semibold lg:text-sm">Requirements</p>
                        <p
                          className="whitespace-pre-line text-sm"
                          style={{ lineHeight: '1.55rem' }}
                        >
                          {selectedCourse?.course_type !== 'COURSE' ? (
                            selectedCourse.course_requirements
                          ) : (
                            <ul className="ml-4 list-outside list-disc space-y-1 text-sm ">
                              {selectedCourse.course_requirements
                                ?.split('\n')
                                ?.map((requirement, index) => (
                                  <li key={index}>{requirement}</li>
                                ))}
                            </ul>
                          )}
                        </p>
                      </div>
                      {selectedCourse?.course_type !== 'COURSE' && (
                        <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />
                      )}

                      {selectedCourse?.course_type !== 'COURSE' && (
                        <>
                          <div className="my-2 mb-8 space-y-2 px-7">
                            <p className="text-xs font-semibold lg:text-sm">Career Paths</p>
                            <p
                              className="whitespace-pre-line text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              {selectedCourse.course_careers}
                            </p>
                          </div>
                          <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                          <div className="my-2 mb-8 space-y-2 px-7">
                            <p className="text-xs font-semibold lg:text-sm">Teaching Method</p>
                            <p
                              className="whitespace-pre-line text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              {selectedCourse.course_teaching_method}
                            </p>
                          </div>
                          <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                          <div className="my-2 mb-8 space-y-2 px-7">
                            <p className="text-xs font-semibold lg:text-sm">Intake</p>
                            {selectedCourse.nex_edu_course_intakes?.map((item, index) => {
                              const start_date = moment(item.start_date).format('DD MMMM YYYY')
                              return (
                                <p
                                  key={index}
                                  className="whitespace-pre-line text-justify text-sm"
                                  style={{ lineHeight: '1.55rem' }}
                                >
                                  {start_date}
                                </p>
                              )
                            })}
                            <p
                              className="whitespace-pre-line text-justify text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              More intakes to open in the future!
                            </p>
                          </div>
                          <hr className="border-1 mx-4 my-5 border-adx-darkerGray" />

                          <div className="my-2 mb-8 space-y-2 px-7">
                            <p className="text-xs font-semibold lg:text-sm">Length of Course</p>
                            <p
                              className="whitespace-pre-line text-justify text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              {selectedCourse.course_length}
                            </p>
                          </div>
                          {/* <hr className="mx-4 my-5 border-1 border-adx-darkerGray" />

                          <div className="my-2 mb-8 space-y-2 px-7">
                            <p className="text-xs font-semibold lg:text-sm">Fee</p>
                            <p
                              className="text-xs text-justify whitespace-pre-line lg:text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              {`Total Fee: ${selectedCourse?.currency_code}${selectedCourse.course_fee}, with 2 payment plan options:`}
                            </p>
                            <p
                              className="text-xs text-justify whitespace-pre-line lg:text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              {`i) ${selectedCourse?.currency_code}${
                                parseInt(selectedCourse.course_fee) -
                                selectedCourse.lump_sum_discount
                              } for a one-off payment`}
                            </p>
                            <p
                              className="text-xs text-justify whitespace-pre-line lg:text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              {`ii) ${selectedCourse?.currency_code}${parseInt(
                                selectedCourse.course_fee / selectedCourse.num_of_installments
                              )} x ${selectedCourse.num_of_installments} via installments`}
                            </p>
                            <p
                              className="text-xs text-justify whitespace-pre-line lg:text-sm"
                              style={{ lineHeight: '1.55rem' }}
                            >
                              Prices include 8% SST
                            </p>
                          </div> */}

                          {selectedCourse?.tnc_link && (
                            <>
                              <hr className="border-1 mx-2 my-5 border-adx-darkerGray lg:mx-4" />
                              <div className="my-2 mb-8 space-y-2 px-2 lg:px-7">
                                <p className="text-xs font-semibold lg:text-sm">
                                  Terms and Conditions
                                </p>
                                <p
                                  className="whitespace-pre-line text-justify text-sm"
                                  style={{ lineHeight: '1.55rem' }}
                                >
                                  Read your full{' '}
                                  <span
                                    className="cursor-pointer text-blue-500"
                                    onClick={() => window.open(selectedCourse?.tnc_link)}
                                  >
                                    Terms & Conditions
                                  </span>{' '}
                                  here
                                </p>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <div className="bg-white pt-16 pb-12">
                      <div className="" id="lessonPlanContainer">
                        <p className="mb-4 text-xs font-semibold lg:text-lg">Browse Lesson Plan</p>
                        {selectedCourse?.nex_edu_course_topics?.map((item, index) => (
                          <Accordion
                            key={index}
                            sx={{ ...mui.accordionBox, ...mui.disableAccordion }}
                            expanded={expandedPanel === index}
                            onChange={handleAccordionChange(index)}
                          >
                            <AccordionSummary
                              sx={{ ...mui.accordionTitle, ...mui.disableAccordionSummary }}
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <p className="text-xs font-semibold lg:text-sm">{item.topic_title}</p>
                            </AccordionSummary>
                            <AccordionDetails sx={mui.accordionDescription}>
                              <p
                                className="whitespace-pre-line text-xs lg:text-sm"
                                style={{ lineHeight: '1.55rem' }}
                              >
                                {item.topic_description}
                              </p>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                    </div>
                  </Element>
                </div>
              ) : (
                <div className="hidden overflow-hidden lg:block lg:h-full lg:w-full lg:justify-self-center lg:px-16 lg:pb-8 lg:pt-4" />
              )}
            </div>
          </div>
        </div>
        <div className="relative">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default CourseAds
