import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'

import { EmployerCardContext } from 'contexts/EmployerCardContext'
import { authContext } from 'contexts/AuthContext'

const mui = {
  iconButton1: {
    position: 'absolute',
    top: 0,
    right: 0,
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
}

const EmployerCard = (props) => {
  const { isSaved } = props

  const navigate = useNavigate()

  const { jobID, setJobID, setSelectedEmployer } = useContext(EmployerCardContext)
  const { auth } = useContext(authContext)
  let isLoggedIn = auth.isAuth

  function showJobDetail() {
    if (window.innerWidth >= 1024) {
      setJobID(props.item.id)
      setSelectedEmployer(props.item)
    } else {
      if (props.matched) {
        navigate(`/jobs/${props.item.job_advertisement_id}`, {
          matched: props.matched,
        })
      } else {
        navigate(`/jobs/${props.item.job_advertisement_id}`)
      }
    }
  }

  const handleBookmark = (e) => {
    e.stopPropagation()
    if (isSaved) {
      props.removedJobSaved()
    } else if (!isSaved) {
      props.processJobSaved()
    }
  }

  // Get image file extension
  let re = /(?:\.([^.]+))?$/

  return (
    <div
      className="border-xl relative mx-auto mb-4 grid w-full cursor-pointer grid-cols-12 rounded-xl bg-adx-white py-6"
      onClick={() => {
        showJobDetail()
      }}
    >
      {props.item.id === jobID && (
        <div
          className="absolute top-0 left-0 hidden h-full w-3 lg:block lg:bg-adx-darkGreen"
          style={{
            borderTopLeftRadius: 'inherit',
            borderBottomLeftRadius: 'inherit',
          }}
        ></div>
      )}
      {props.item.id === jobID && (
        <div className="absolute top-0 left-2 hidden h-full w-3 bg-white lg:block"></div>
      )}
      <div className="col-span-4 grid place-items-center justify-self-center lg:col-span-3">
        {props.item.company_logo === null || re.exec(props.item.company_logo)[1] === 'pdf' ? (
          <div className="flex h-20 w-20 items-center justify-center rounded-full bg-adx-white text-3xl text-black shadow">
            {props.item.company_name.charAt(0)}
          </div>
        ) : (
          <img
            src={props.item.company_logo}
            alt="company_logo"
            className="h-20 w-20 rounded-full object-scale-down shadow"
          />
        )}
      </div>

      <div className="col-span-8 lg:col-span-9">
        <p className="w-5/6 truncate font-bold capitalize text-adx-darkGreen">
          {props.item.position_title}
        </p>
        <p className="w-5/6 truncate text-sm capitalize">{props.item.company_name}</p>
        <p className="w-5/6  truncate text-sm">
          RM{props.item.min_salary} - RM{props.item.max_salary}
        </p>
        <p className="w-5/6  truncate text-sm">
          {props.item.city}, {props.item.state}
        </p>
      </div>
      <IconButton sx={mui.iconButton1} onClick={handleBookmark} size="large">
        {isLoggedIn && isSaved ? (
          <BookmarkIcon className={'text-adx-yellow'} />
        ) : isLoggedIn && !isSaved ? (
          <BookmarkBorderIcon />
        ) : (
          <ChevronRightIcon />
        )}
      </IconButton>
    </div>
  )
}

export default EmployerCard
