import { Navigate, useLocation, useNavigate } from "react-router-dom"
import HRDHeader from "../components/HRDHeader"
import { Button, Drawer } from "@mui/material"
import { Form, Formik } from "formik"
import * as yup from 'yup'
import TextField from "components/smart/TextField"
import { useState } from "react"
import { Close } from "@mui/icons-material"
import { useContext } from "react"
import { ProfileContext } from "contexts/ProfileContext"
import { submitHRDApplication } from "services/api/hrd"
import Bugsnag from "@bugsnag/js"
import { useEffect } from "react"

const HRDFormPage = () => {
  const navigate = useNavigate()
  const { profileData } = useContext(ProfileContext)
  const { state: nex_edu_payment_schedule_id } = useLocation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const handleOpen = () => {
    setOpenDrawer(true)
  }
  const handleClose = () => {
    setOpenDrawer(false)
  }
  const validationSchema = yup.object().shape({
    name: yup.string().required('Full name is required'),
    phone: yup.string().required('Phone number is required'),
    email: yup.string().email('Please use correct email format').required('Email is required'),
    company_name: yup.string().required('Full name is required'),
    ssm_number: yup.string().matches(/^[0-9]+$/, 'Field must be an integer number').required('Company SSM number is required') // TO CHECK IF SSM ONLY REQUIRES NUMBER OR ALPHANUMERIC
  })
  const handleSubmission = async (values) => {
    const { company_name, ssm_number } = values
    let data = { company_name, ssm_number } //TO CONFIRM WITH AIMI IF NAME EMAIL AND NUMBER IS NOT REQUIRED
    try {
      await submitHRDApplication(nex_edu_payment_schedule_id, data).then(() => navigate('/hrd/status', {
        state: {
          nex_edu_payment_schedule_id,
          data: null
        }
      }))
    } catch (error) {
      Bugsnag.notify(error)
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [nex_edu_payment_schedule_id])
  if (!nex_edu_payment_schedule_id) {
    return <Navigate to='/courses' />
  }
  return (
    <div className={`h-screen bg-adx-defaultBackground overflow-y-auto flex flex-col ${openDrawer && 'overflow-hidden'}`}>
      <HRDHeader handleClose={() => navigate('/courses')} />
      <Formik
        enableReinitialize
        initialValues={{
          name: profileData?.name,
          phone: profileData?.contact_number,
          email: profileData?.email,
          company_name: '',
          ssm_number: ''
        }}
        onSubmit={handleSubmission}
        validationSchema={validationSchema}
      >
        {
          ({ values, touched, errors, handleChange, handleSubmit }) => (
            <Form>
              <div className="mx-auto bg-adx-white max-w-3xl flex flex-col space-y-[40px] p-[30px] ">
                <div className="flex flex-col space-y-[10px]">
                  <h3 className="font-medium text-[20px] leading-[calc(20px * 1.5)]">Fill in the form</h3>
                  {/* <p className="text-adx-darkerGray">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                </div>
                <div className=" flex flex-col space-y-[15px]">
                  <p className="text-adx-darkerGray">STUDENT DETAILS</p>
                  <TextField
                    label='Name'
                    name='name'
                    disabled
                    onChange={handleChange}
                    value={values?.name}
                    isErrorShown={touched?.name && errors?.name}
                    errorMessage={touched?.name && errors?.name}
                  />
                  <TextField
                    label='Phone number'
                    name='phone'
                    disabled
                    onChange={handleChange}
                    value={values?.phone}
                    isErrorShown={touched?.phone && errors?.phone}
                    errorMessage={touched?.phone && errors?.phone}
                  />
                  <TextField
                    label='Email'
                    name='email'
                    disabled
                    onChange={handleChange}
                    value={values?.email}
                    isErrorShown={touched?.email && errors?.email}
                    errorMessage={touched?.email && errors?.email}
                  />
                </div>
                <div className=" flex flex-col space-y-[15px]">
                  <p className="text-adx-darkerGray">COMPANY DETAILS</p>
                  <TextField
                    label='Company’s name'
                    required
                    name='company_name'
                    onChange={handleChange}
                    value={values?.company_name}
                    isErrorShown={touched?.company_name && errors?.company_name}
                    errorMessage={touched?.company_name && errors?.company_name}
                  />
                  <TextField
                    label='Company’s SSM registration number'
                    required
                    name='ssm_number'
                    onChange={handleChange}
                    value={values?.ssm_number}
                    isErrorShown={touched?.ssm_number && errors?.ssm_number}
                    errorMessage={touched?.ssm_number && errors?.ssm_number}
                  />
                </div>
                <div className="pt-[15px] pb-[20px] space-y-[6px]">
                  <Button onClick={handleSubmit} variant='contained' fullWidth sx={{ minHeight: '45px', mb: '10px' }} color='primary'>Submit</Button>
                  <p className="text-adx-darkerGray text-center">By clicking submit, you agree to our <span onClick={handleOpen} className="underline cursor-pointer text-adx-darkGreen">Terms and conditions</span></p>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleClose}
      >
        <div className="min-h-[100vh] box-border w-[100%] md:w-[50vw] lg:w-[40vw]">
          <div className="flex items-center justify-between py-[15px] px-[30px] border-b border-adx-gray">
            <h4 className="font-medium text-[16px] leading-[calc(16px * 1.5)]">Terms and conditions</h4>
            <Close sx={{ cursor: 'pointer' }} onClick={handleClose} />
          </div>
          <div className="p-[30px] flex flex-col space-y-[30px]">
            <h2 className="font-medium text-[24px] leading-[calc(24px * 1.5)]">HRD Corp claimable course terms and conditions</h2>
            <p className="whitespace pre-line">
              adnexioedu provides an online education service on the adnexioedu website. By registering on the Site or by visiting, browsing, or using the adnexioedu Service in any way, you (“user” or “you”) accept these Terms of Use (the “Terms”), which forms a binding agreement between you and adnexioedu.<br /><br />

              THESE TERMS SET FORTH LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SERVICE. BY ACCESSING OR USING THE SITE OR SERVICE, YOU ARE ACCEPTING THESE TERMS ON YOUR OWN BEHALF OR ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY. IF YOU ARE ENTERING THESE TERMS ON BEHALF OF A COMPANY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS, IN WHICH CASE THE TERMS “YOU” OR “USER” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES.
              <br /><br />

              <strong>1. 	ACCOUNT</strong><br /><br />

              1.1	Account Creation<br /><br />

              In order to use certain features of the Service, you must register for an account (“Account”) and provide certain information about yourself. You represent and warrant that: (a) all registration information you submit is truthful and accurate, and (b) you will maintain the accuracy of such information. You agree that all account information you provide is governed by our Privacy Policy and you consent to all actions we take with respect to your information consistent with our Privacy Policy. Our Privacy Policy forms a part of these Terms. You may delete your Account at any time, for any reason, by following the instructions on the Service. adnexioedu may suspend or terminate your Account in accordance with Section 6.
              <br /><br />
              1.2	Account Responsibilities <br /><br />

              You are responsible for maintaining the confidentiality of your Account login information and for all activities that occur under your Account. You agree to immediately notify adnexioedu of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. adnexioedu cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.
              <br /><br />
              <strong>2. 	adnexioedu PLANS AND PURCHASE TERMS</strong >
              <br /><br />
              The features and price of your access to the Service is determined by the Course and account type you register for, purchase, renew, or change into (each, a “Plan”)—to review the features and price associated with adnexioedu’s Courses, please visit <span onClick={() => window.open('https://adnexio.jobs/courses')} className="underline cursor-pointer text-adx-darkGreen">https://adnexio.jobs/courses</span>
              <br /><br />
              <strong>3. 	USAGE OF THE SERVICE</strong>
              <br /><br />
              3.1	Restrictions
              <br /><br />
              The License granted is subject to the following restrictions: (a) you will not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Service, in whole or in part, or any content displayed on the Service; (b) you will not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Service; (c) you will not access or use the Service in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein or specifically enabled as a feature of the Service, no part of the Service may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. All copyright and other proprietary notices on the Service (or on any content displayed on the Service) must be retained on all copies thereof.
              <br /><br />
              3.2 	Limits
              <br /><br />
              We reserve the right to monitor use of the Service and to deactivate or terminate your rights to use the Service (including your Account) if your usage behavior exceeds normal limits, as determined in our sole discretion.
              <br /><br />
              3.3	Modification
              <br /><br />
              adnexioedu reserves the right, at any time without notice or liability, to change or eliminate any content or feature or restrict the use of any portion of the Service. Your only right with respect to any dissatisfaction is to cease use of the Service. Unless otherwise indicated, any future release, update, or other addition to functionality of the Service will be subject to these Terms.
              <br /><br />
              3.4	Availability of Service
              <br /><br />
              You recognize that the traffic of data through the Internet may cause delays during your use of or access to the Service, and accordingly, you agree not to hold us liable or make any claims against us for delays that are ordinary in the course of Internet use. You further acknowledge and accept that the Service may not be available due to such delays, delays caused by our upgrading, modification, or standard maintenance of the Service, or any other delays outside of our control.
              <br /><br />
              3.5 	No Support or Maintenance
              <br /><br />
              You acknowledge and agree that adnexioedu will have no obligation to provide you with any support or maintenance in connection with the Service.
              <br /><br />
              <strong>4. 	PAYMENT TERMS</strong>
              <br /><br />
              4.1	Payment
              <br /><br />
              The term of payment will be specified in each Course(s)’ Term of Enrolment which will be distributed to you upon registration of the Course(s).
              <br /><br />
              4.2 	Third-Party Payment Processors
              <br /><br />
              You agree to pay us, through our payment processors, all charges at the prices then in effect for any purchase in accordance with the applicable payment terms presented to you at the time of purchase. You agree to make payment using the payment method you provide when you sign up for a Course(s). We reserve the right to correct, or to instruct our payment processor or financing partner to correct, any errors or mistakes, even if payment has already been requested or received.
              <br /><br />
              <strong>5. 	TERMINATION AND CANCELLATION </strong>
              <br /><br />
              5.1 	Termination
              <br /><br />
              adnexio reserves the right to terminate your enrollment from the Course and prevent your access to the Course if, in our assessment:
              <br /><br />
              5.1.1. you have breached any of these Terms including the terms related to unauthorized dissemination of the Course materials; and
              <br /><br />
              5.1.2. you willfully and persistently neglect the Course lessons, any assigned prepwork or coursework, tests, etc to an extent that there is no possibility of you satisfactorily completing the course.
              <br /><br />
              If we terminate your enrollment for the above reasons, or for reasons of your withdrawal from the course, we will not be able to provide you with a refund.
              <br /><br />
              <strong>  6. 	INTELLECTUAL PROPERTY</strong>
              <br /><br />
              6.1 	Ownership
              <br /><br />
              Excluding any User Content that you may provide, you acknowledge that all intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Service and its content, including without limitation any statistical analyses on your data and information resulting from your or your users’ use of the Service (“Statistical Data”) are owned by adnexioedu or adnexioedu’s trainer(s) (“Proprietary Materials”).
              <br /><br />
              6.2 	Rights
              <br /><br />
              We represent, warrant, and covenant that: (i) we have and will have all rights, titles, licenses, intellectual property, permissions and approvals necessary in connection with our performance under these Terms to grant you the License granted hereunder; and (ii) neither the Service, nor the provision or utilization thereof as contemplated under these Terms, will infringe, violate, trespass or in any manner contravene or breach or constitute the unauthorized use or misappropriation of any intellectual property of any third party, provided that this clause will not apply to the extent any such infringement, violation, trespass or contravention, breach, or unauthorized use or misappropriation of any intellectual property is caused by a combination of the Service with User Content or any materials, software, devices, or other things that are not under adnexioedu’s control.
              <br /><br />
              <strong>7. 	USER CONTENT</strong>
              <br /><br />
              7.1 	User Content
              <br /><br />
              User Content means any information and content that a user submits to, or uses with, the Service (e.g., content in the user’s profile or postings). You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that personally identifies you or any third party. You hereby represent and warrant that your User Content does not and will not violate the Restrictions in Section 8.3. You may not represent or imply to others that your User Content is in any way provided, sponsored or endorsed by adnexioedu. adnexioedu is not obligated to backup any User Content, and your User Content may be deleted at any time without prior notice. You are solely responsible
              for creating and maintaining your own backup copies of your User Content if you desire.
              <br /><br />
              7.2 	License
              <br /><br />
              You hereby grant (and represent, warrant and covenant that you have the right to grant) adnexioedu an irrevocable (except where prohibited by law), non-exclusive, royalty-free and fully-paid-up, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use and exploit your User Content, and to grant sublicenses of the foregoing rights, in connection with the Service through any medium, whether alone or in combination with other content or materials, in any manner and by any means, method or technology, whether now known or hereafter created. You hereby irrevocably waive (and agree to cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Content.
              <br /><br />
              7.3 	Restrictions
              <br /><br />
              7.3.1	You will not use the Service to collect, upload, transmit, display, or distribute any User Content (i) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual or is otherwise objectionable; (iii) that is harmful to minors in any way; or (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.
              <br /><br />
              7.3.2	In addition, you agree not to: (i) upload, transmit, or distribute to or through the Service any computer viruses, worms, or any software intended to damage or alter a computer system or data; (ii) send through the Service unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (iii) use the Service to harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Service, or violate the regulations, policies or procedures of such networks; (v) attempt to gain unauthorized access to the Service (or to other computer systems or networks connected to or used together with the Service), whether through password mining or any other means; (vi) harass or interfere with any other user’s use and enjoyment of the Service; or (vi) use software or automated agents or scripts to produce multiple accounts on the Service, or to generate automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Service (provided, however, that we conditionally grant to the operators of public search engines revocable permission to use spiders to copy materials from the Service for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials, subject to the parameters set forth in our robots.txt file).
              <br /><br />
              7.3.3	You also acknowledge and agree that the Service is not intended to hold any Sensitive Information. You represent and warrant that you will not use our Service to transmit, upload, collect, manage, or otherwise process any Sensitive Information. WE WILL NOT BE LIABLE FOR ANY DAMAGES THAT MAY RESULT FROM YOUR USE OF THE SERVICE IN TRANSMITTING, COLLECTING, MANAGING, OR PROCESSING ANY SENSITIVE INFORMATION. “Sensitive Information” means any passwords, credit card or debit card information, personal financial account information, personal health information, social security numbers, passport numbers, driver’s license numbers, employment records, physical or mental health condition or information, or other laws, regulations, or industry standards designed to protect similar information.
              <br /><br />
              7.4 	Enforcement
              <br /><br />
              We reserve the right (but have no obligation) to review any User Content, and to investigate and/or take appropriate action against you in our sole discretion if you violate any Restrictions or any other provision of these Terms. Such action may include removing or modifying your User Content, terminating your Account in accordance with Section 6, and/or reporting you to law enforcement authorities.
              <br /><br />
              7.5 	Feedback
              <br /><br />
              If you provide adnexioedu with any feedback or suggestions regarding the Service (“Feedback”), you hereby assign to adnexioedu all rights in such Feedback and agree that adnexioedu will have the right to use and fully exploit such Feedback and related information in any manner it deems appropriate. adnexioedu will treat any Feedback you provide to adnexioedu as non-confidential and non-proprietary. You agree that you will not submit to adnexioedu any information or ideas that you consider to be confidential or proprietary.
              <br /><br />
              <strong>         8. 	DISCLAIMERS, LIABILITY AND INDEMNIFICATION </strong>
              <br /><br />
              8.1 	Disclaimers and Liability
              <br /><br />
              adnexioedu will not be liable for any failure to carry out, or delay in carrying out, the Course where such delay or failure is caused by events beyond adnexio's reasonable control. Such circumstances include, but are not limited to, natural disasters or acts of God; acts of terrorism; labour disputes or stoppages; war; government acts or orders; epidemics, pandemics or outbreak of communicable disease; quarantines; national or regional emergencies; third party service provider (including but not limited to web hosting, cloud services, domain name, and messaging service providers) breakdowns or unscheduled downtimes; or any other cause, whether similar in kind to the foregoing or otherwise including acts of God, war, terrorism, cyber-attacks, industrial disputes, fire, adverse weather, pandemics, epidemics or disruption resulting from pandemics or epidemics, and national emergencies. In such circumstances, adnexio will take reasonable and proportionate steps to minimize any adverse impact on you.
              <br /><br />
              8.2 	Indemnification
              <br /><br />
              If you behave in a way that gets us in legal trouble, we may exercise legal recourse against you. You agree to indemnify and hold harmless adnexio, our affiliated companies, their officers, directors, suppliers, partners, and agents from and against any third-party claims, demands, losses, damages, or expenses (including reasonable legal fees) arising from (a) the manner of your enrollment and participation in the Course; (b) your violation of these Terms; or (c) your violation of any rights of a third party. Your indemnification obligation will  survive the termination of these Terms and the conclusion of the Course.
              <br /><br />
              <strong>9. 	DATA PROTECTION AND PRIVACY </strong>
              <br /><br />
              9.1 	Personal Data Collection
              <br /><br />
              We collect your personal data for the purposes of managing Student registration and participation in the Course(s). For more information on why we collect your personal data, what personal data we collect, and how we manage it, please view adnexio’s Privacy Policy here.
              <br /><br />

              <strong>10. 	GOVERNING LAW</strong>
              <br /><br />
              These Terms shall be interpreted in accordance with the laws of Malaysia. Jurisdiction and the venue shall be solely within Malaysia.
              <br /><br />
              If you have any questions on these Terms, please reach out to us at <span onClick={() => window.open('https://adnexio.jobs/contact-us')} className="underline cursor-pointer text-adx-darkGreen">https://adnexio.jobs/contact-us.</span>
            </p>
          </div>
        </div>
      </Drawer>
    </div >
  )
}
export default HRDFormPage