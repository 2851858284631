import React from 'react'
import { createRoot } from 'react-dom/client'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ErrorView from './pages/error/view/ErrorView'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import common_my from './lang/my/common.json'
import common_en from './lang/en/common.json'

const langLocalStorage = localStorage.getItem('lang')

Bugsnag.start({
  apiKey: '47061a0ce2be0420b818227b2c9e6ea6',
  plugins: [new BugsnagPluginReact()],
})

i18next.init({
  interpolation: { escapeValue: false },
  lng: langLocalStorage || 'en',
  resources: {
    en: {
      common: common_en,
    },
    my: {
      common: common_my,
    },
  },
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ErrorBoundary FallbackComponent={ErrorView}>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </ErrorBoundary>
)

reportWebVitals()
