import React from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const EDUAlertModal = ({ onClose, open, modalProp, onClickButton, onClickSecondButton }) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" PaperComponent={Box}>
      <div className="item-center relative my-16 flex h-64 flex-col justify-between rounded-lg bg-white py-8 px-0 lg:w-96">
        <div
          className="absolute top-0 left-1/2 grid z-[1500000000000] h-20 w-20 -translate-x-1/2 -translate-y-1/2 transform place-items-center rounded-full bg-gray-200"
        >
          {modalProp.success ? (
            <CheckCircleIcon sx={{
              color: 'primary.main',
              fontSize: '5rem',
            }}
            />
          ) : (
            <ErrorIcon sx={{
              color: 'error.main',
              fontSize: '5rem',
            }}
            />
          )}
        </div>
        <p
          className={`mt-2 text-center text-4xl ${modalProp.success ? 'text-adx-darkGreen' : 'text-red-500'
            }`}
        >
          {modalProp.title}
        </p>
        <p className="my-2 mx-auto w-3/5 text-center">{modalProp.body}</p>
        <div className="flex justify-center">
          <Button onClick={onClickButton} sx={{
            color: 'primary',
            backgroundColor: 'secondary',
            width: '150px',
            margin: '0 10px',
          }}>
            {modalProp.button}
          </Button>
          {modalProp?.secondButton && (
            <Button
              onClick={() => onClickSecondButton()}
              sx={{
                color: 'secondary',
                backgroundColor: 'primary',
                width: '150px',
                margin: '0 10px',
              }}
            >
              {modalProp.secondButton}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default EDUAlertModal
