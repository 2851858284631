import axios, { getData } from "lib/axios"

export const getCurrentEnrolment = async (courseID) => {
  if (courseID) {
    return axios.get(`/job_seekers/edu_my_classes/${courseID}`).then(getData)
  } else {
    return axios.get("/job_seekers/edu_my_classes").then(getData)
  }
}

export const getCourseSessions = async (courseSessionID) => {
  return axios
    .get(`/job_seekers/course_sessions/${courseSessionID}`)
    .then(getData)
}

export const updateAttendance = async (courseSessionId) => {
  return axios.put(`edu_attend/${courseSessionId}`).then(getData)
}
