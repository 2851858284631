import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Loader from 'react-loader-spinner'

import SocialMediaButton from '../../../components/smart/SocialMediaButton'
import Button from '@mui/material/Button'

import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'
import adnexio_logo_white from 'assets/logos/adnexio-logo-white-v2-aug.png'
import banner_text from '../assets/banner_text.png'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { registerJobseeker } from '../../../services/api/registration/index'
import { ReactComponent as Check } from '../assets/check.svg'
import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'

import '../styles/styles.css'
import Footer from 'components/dumb/Footer'
import { clearCookies } from 'services/helper/clearCookies'

const ER_URL = process.env.REACT_APP_ER_URL

const SignUp = () => {
  const [authError, setAuthError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  let location = useLocation()
  let historyData = location.state

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  const apiRegisterEmployer = async (values, actions) => {
    try {
      setLoading(true)
      const response = await registerJobseeker(values)
      if (response.data.access_token) {
        setModalOpen(true)
        clearCookies()
      }
    } catch (e) {
      if (e.status === 422) {
        const errors = e.data.errors
        Object.keys(errors).forEach(function (key) {
          actions.setFieldError(key, errors[key][0])
        })
      } else {
        setErrorMessage(
          'An unexpected error occurred. Please retry or contact us at support@adnexio.jobs for support.'
        )
        setAuthError(true)
      }
    }
    setLoading(false)
  }
  const initialValues = {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    agree_status: false,
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Your full name is required.'),
    email: Yup.string().email().required('The email field is required.'),
    password: Yup.string().required('The password field is required.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match!')
      .required('The password field is required.'),
    agree_status: Yup.boolean()
      .oneOf([true], 'You must agree to the terms and conditions before registering!')
      .required('You must agree to the terms and conditions before registering!'),
  })

  return (
    <div>
      {loading === true && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '999999999',
            }}
          >
            <Loader type="Oval" color="#09b7b7" height={55} width={55} />
          </div>
          <div
            style={{
              position: 'fixed',
              zIndex: '5',
              width: '100vw',
              height: '100vh',
              background: 'black',
              opacity: '0.7',
            }}
          ></div>
        </>
      )}
      <div className="fixed top-0 z-50 block w-full lg:hidden">
        {/* <Header page="disableBottom" loginMode={true} /> */}
        <NewHeader/>
      </div>
      <div
        id="anchor"
        className="box-border h-auto pt-20 pb-4 mx-8 border-box lg:mx-0 lg:grid lg:min-h-screen lg:grid-cols-12 lg:pb-0 lg:pt-0 "
      >
        <div className="flex-col hidden lg:col-span-6 lg:flex lg:h-full lg:bg-adx-darkGreen">
          {historyData?.from === 'edu' || historyData?.from === 'courses' ? (
            <Link to="/bootcamp">
              <img className="w-48 mt-8 ml-16" src={adnexio_logo_white} alt="logo" />
            </Link>
          ) : (
            <Link to="/">
              <img className="w-48 mt-8 ml-16" src={adnexio_logo_white} alt="logo" />
            </Link>
          )}
          <div className="flex flex-col items-center justify-center h-full">
            <div className="lg:mb-24 lg:text-7xl lg:text-adx-yellow">
              <img src={banner_text} alt="banner" />
            </div>
            <div className="text-center">
              <p className="mb-2 text-white">For better experience</p>
              <div className="flex justify-center gap-x-2">
                <img
                  className="rounded-lg cursor-pointer h-11 w-36"
                  src={apple_store_badges}
                  alt="appstore"
                  onClick={() => appDownload('apple')}
                />
                <img
                  className="rounded-lg cursor-pointer h-11 w-36"
                  src={google_play_badges}
                  alt="playstore"
                  onClick={() => appDownload('android')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-full col-span-6 lg:flex lg:flex-col lg:text-center">
          <div className="items-center hidden lg:mx-auto lg:mt-12 lg:flex lg:w-full lg:max-w-md lg:flex-row lg:justify-between lg:text-xl">
            {historyData?.from !== 'edu' && (
              <div className="items-center hidden lg:mx-auto lg:mt-12 lg:flex lg:w-full lg:max-w-md lg:flex-row lg:justify-between lg:text-xl">
                <div className="font-semibold select-none text-adx-darkGreen">For Jobseeker</div>
                <div>|</div>
                <a href={ER_URL}>For Employer</a>
              </div>
            )}
          </div>

          <div className="relative justify-center lg:mx-auto lg:flex lg:h-full lg:w-full lg:max-w-md lg:flex-col ">
            <p className="block mt-4 text-xl text-adx-darkGreen lg:mt-0 lg:hidden ">
              Create an account
            </p>
            <p className="hidden text-3xl lg:mb-4 lg:block lg:font-bold">
              {historyData?.from !== 'edu' && <span>Jobseeker</span>} Sign Up
            </p>
            <p className="block mb-4 text-adx-darkerGray lg:mb-4">
              Already a member ?{' '}
              <Link className="text-adx-darkGreen" to="/signin">
                Login
              </Link>
            </p>
            <div className="grid mb-4 gap-y-2 lg:mb-4">
              {['facebook', 'google', 'linkedin', 'apple'].map((item) => (
                <SocialMediaButton type="signup" socmed={item} key={item} />
              ))}
            </div>
            <p className="mb-2 text-center text-adx-darkerGray lg:mb-4">or create an account</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                apiRegisterEmployer(values, actions)
              }}
            >
              {({ handleChange, handleSubmit }) => (
                <Form>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    className="w-full px-2 py-3 my-1 text-sm rounded bg-adx-darkGray"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="mt-1 mb-2 text-xs text-left text-red-400"
                  />

                  <Field
                    type="text"
                    name="email"
                    placeholder="Email"
                    className="w-full px-2 py-3 my-1 text-sm rounded bg-adx-darkGray"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="mt-1 mb-2 text-xs text-left text-red-400"
                  />

                  <Field
                    autoComplete="off"
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="w-full px-2 py-3 my-1 text-sm rounded bg-adx-darkGray"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="mt-1 mb-2 text-xs text-left text-red-400"
                  />

                  <Field
                    autoComplete="off"
                    type="password"
                    name="password_confirmation"
                    placeholder="Confirm Password"
                    className="w-full px-2 py-3 my-1 text-sm rounded bg-adx-darkGray"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="password_confirmation"
                    component="div"
                    className="mt-1 mb-4 text-xs text-left text-red-400"
                  />

                  <label className="flex items-center mt-2">
                    <div className="relative inline-block w-10 mr-2 align-middle transition duration-1000 ease-in select-none">
                      <Field
                        type="checkbox"
                        name="agree_status"
                        id="agree_status"
                        className="absolute block w-6 h-6 bg-white border-4 rounded-full appearance-none cursor-pointer toggle-checkbox focus:outline-none"
                      />
                      <label
                        htmlFor="agree_status"
                        className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer toggle-label"
                      ></label>
                    </div>
                    <div className="text-xs">
                      I agree to the{' '}
                      <Link className="font-semibold hover:underline" to="/terms-conditions">
                        Terms and Conditions.
                      </Link>
                    </div>
                  </label>
                  <ErrorMessage
                    name="agree_status"
                    component="div"
                    className="mt-1 mb-4 text-xs text-left text-red-400"
                  />

                  {authError && (
                    <p className="text-xs font-semibold text-left text-red-400">{errorMessage}</p>
                  )}

                  <div className="mt-2 text-center">
                    <Button
                      type="submit"
                      onSubmit={handleSubmit}
                      sx={{
                        backgroundColor: '#1B8379',
                        textTransform: 'capitalize',
                        padding: '6px 20px',
                        boxShadow: 'none',
                        fontWeight: 400,
                      }}
                      variant="contained"
                    >
                      Sign Up
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="mt-8 mb-6">
              <div className="text-xs">
                By signing in you agree to our{' '}
                <Link className="font-semibold hover:underline" to="/terms-conditions">
                  Terms and Conditions,{' '}
                </Link>
                <Link className="font-semibold hover:underline" to="/privacy-policy">
                  Data Policy and Cookies Policy.
                </Link>
              </div>
            </div>
          </div>
        </div>

        {modalOpen && (
          <div className="fixed top-0 left-0 w-full h-full">
            <div
              className="absolute w-full h-full bg-adx-darkGreen opacity-70"
              onClick={() => setModalOpen(false)}
            ></div>
            <div className="absolute w-4/5 px-8 py-8 text-center transform -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 left-1/2 rounded-xl lg:max-w-md">
              <Check className="w-32 h-32 mx-auto mb-4" />
              <div className="mb-4 text-xl font-bold text-adx-darkGreen">Success</div>
              <div className="">
                {' '}
                Please click on the verification link sent to your email to continue with the
                registration process.
              </div>
              {historyData?.from === 'edu' || historyData?.from === 'courses' ? (
                <Link to="/bootcamp">
                  <div className="mt-2 font-bold cursor-pointer text-adx-darkGreen">
                    {' '}
                    Back to Homepage
                  </div>
                </Link>
              ) : (
                <Link to="/">
                  <div className="mt-2 font-bold cursor-pointer text-adx-darkGreen">
                    {' '}
                    Back to Homepage
                  </div>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default SignUp
