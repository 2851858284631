import React, { createContext, useEffect, useState } from 'react'
import { myAccount } from 'services/api/authentication'
import Cookies from 'universal-cookie'
import { clearCookies } from 'services/helper/clearCookies'

const cookies = new Cookies()
export const authContext = createContext({})

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    loading: true,
    isAuth: false,
    profilePhoto: null,
    cv: null,
    userId: null,
    userType: null,
    cvStatus: null,
    score: null,
  })

  const setAuthData = async () => {
    // If Token Already Expired Logout User
    if (cookies.get('access_token')) {
      if (cookies.get('token_expired') < Date.now() / 1000) {
        clearCookies()
        setAuth({ loading: false, isAuth: false })
      } else {
        try {
          const results = await myAccount()
          // If Account is Employer Logout User
          if (results.data.account_type === 'employer') {
            clearCookies()
            setAuth({ loading: false, isAuth: false })
          } else if (results.data.account_type === 'jobseeker') {
            // Check either profile is complete or not to apply for courses
            const { about_me } = results.data.profile_status
            const profileCompleteArray = [about_me]
            const profileCompleteArrayValues = Object.values(profileCompleteArray)
            const profileComplete = profileCompleteArrayValues.every((item) => item === 1)

            setAuth({
              loading: false,
              isAuth: true,
              profilePhoto: results.data.profile_image_url,
              cv: true,
              cvStatus: results.data.profile_status.cv,
              photoStatus: results.data.profile_status.photo,
              userId: results.data.id,
              profilePhotoName: results.data.profile_image,
              profileStatus: results.data.profile_status.about_me,
              userType: results.data.account_type,
              score: results.data.score_weight,
              profileComplete,
            })
          }
        } catch (e) {
          setAuth({ loading: false, isAuth: false })
        }
      }
    } else {
      setAuth({ loading: false, isAuth: false })
    }
  }

  const logoutUser = () => {
    setAuth({
      loading: false,
      isAuth: false,
      profilePhoto: null,
      cv: null,
      userId: null,
      userType: null,
      cvStatus: null,
      score: null,
    })
  }

  useEffect(() => {
    setAuthData()
  }, [auth.isAuth])

  return (
    <authContext.Provider value={{ auth, setAuthData, logoutUser }}>
      {children}
    </authContext.Provider>
  )
}

export default AuthProvider
