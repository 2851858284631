import React from 'react'
import AssignmentChip from './AssignmentChip'
import EventIcon from '@mui/icons-material/Event'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  viewSubmitBtn: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    textTransform: 'none',
    fontWeight: '500',
    background: '#FCD94E',
    color: '#1B8379',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '5px 20px',
    '&:hover': {
      background: '#FCCE4E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    width: '95px',
  },
}))

const AssignmentCard = ({ item, onClick }) => {
  const classes = useStyles()

  return (
    <div className="mb-4 flex items-center justify-between  bg-white">
      <div className="col-span-11 p-4">
        <p className="mb-4 truncate text-xl font-bold text-adx-darkGreen">{item.project_name}</p>
        <div className="flex ">
          <div className="mr-4 flex">
            <AssignmentChip id={item.submission_type} />
          </div>
          <div className="flex items-center">
            <EventIcon className="mr-2" />{' '}
            <p className="text-sm italic text-adx-darkerGray">
              {moment(item.last_submitted_at).format('DD/MM/YYYY HH:MM A')}
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-1 mr-4 grid place-items-center">
        <Button variant="contained" className={classes.viewSubmitBtn} onClick={onClick}>
          View
        </Button>
      </div>
    </div>
  )
}

export default AssignmentCard
