import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import Footer from 'components/dumb/Footer'
import RecentJobCarousel from '../components/RecentJobCarousel'
import { Link } from 'react-router-dom'

import Button from '@mui/material/Button'
import ButtonBase from '@mui/material/ButtonBase'

import Apple from 'assets/images/app-store-badge.png'
import Android from 'assets/images/google-play-badge.png'

import PosterTwo from '../assets/poster_two.png'
import PortfolioBgGreen from '../assets/portfolio_bg_green.png'
import FeatAi from '../assets/feat_ai.png'
import FeatInterview from '../assets/feat_interview.png'
import FeatSocial from '../assets/feat_social.png'

import '../styles/styles.css'

import PosterOne from '../assets/poster_one.png'
import { InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const GOOGLE_PLAY = process.env.REACT_APP_GOOGLE_PLAY_URL
const APP_STORE = process.env.REACT_APP_APPLE_STORE_URL

const mui = {
  signUpBtnTwo: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    textTransform: 'none',
    fontWeight: '300',
    background: '#1B8379',
    color: '#FFFFFF',
    borderRadius: '5px',
    boxShadow: 'none',
    padding: '5px 20px',
    '&:hover': {
      background: '#1B8379',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  appBtn: {
    outline: 'none',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  searchIcon: {
    color: '#959595',
    fontSize: '27px',
  },
}

const LandingPage = () => {
  return (
    <div className="bg-adx-gray">
      <NewHeader/>
      {/* <Header/> */}
      <div className='lg:p-10 bg-adx-darkGreen'>
        <div className='flex items-center px-6 pt-3 pb-6 lg:justify-around'>
          <div className="max-w-2xl mx-auto lg:mx-0">
            <div className="text-lg font-semibold text-adx-white lg:text-4xl">
              A Better Platform For Better Jobs
            </div>
            <div className="text-sm font-extralight text-adx-white lg:mt-2 lg:text-lg">
              Find your dream job with our AI-based career guidance modules. The next phase of
              your career begins here.
            </div>
            <form
              className="p-1 mt-5 rounded-4xl bg-adx-white lg:mb-8 lg:max-w-md lg:py-2"
              method="GET"
              action="/jobs"
              autoComplete="off"
            >
              <SearchIcon className="mx-2" sx={mui.searchIcon} />
              <InputBase
                className="w-80percent"
                sx={mui.searchBar}
                placeholder="Search"
                name="search"
              />
            </form>
            <div className="hidden lg:flex lg:items-center lg:space-x-4">
              <div className="text-lg font-semibold text-adx-white">For a better experience</div>
              <ButtonBase sx={mui.appBtn} href={APP_STORE} target="_blank">
                <div>
                  <img src={Apple} alt="apple" className="rounded-md w-28" />
                </div>
              </ButtonBase>
              <ButtonBase sx={mui.appBtn} href={GOOGLE_PLAY} target="_blank">
                <div>
                  <img src={Android} alt="android" className="rounded-md w-28" />
                </div>
              </ButtonBase>
            </div>
          </div>
          <div className="hidden lg:block">
            <img src={PosterOne} alt="poster_one" />
          </div>
        </div>
      </div>

      <RecentJobCarousel />

      {/* ADS SIGN UP */}
      <div className="bg-adx-gray font-poppins">
        <div className="flex flex-col items-center justify-center px-10 py-12 mx-auto space-x-4 max-w-7xl lg:flex-row lg:space-x-32">
          <div className="flex flex-col items-center max-w-md px-2 mb-8 space-y-3 lg:mb-0 lg:w-96">
            <div className="">
              <img src={PosterTwo} alt="poster_two" />
            </div>
            <div className="text-base text-justify">
              Our machine-learning driven system supports you through every step of your
              professional journey.
            </div>
          </div>

          <div className="flex flex-col items-center justify-center px-6 py-10 w-52 rounded-2xl bg-adx-darkGray">
            <div className="text-base text-center">Ready to be a part of adnexio?</div>
            <div className="mt-1 text-base font-bold text-center mb-7">Join us now</div>
            <div>
              <Button sx={mui.signUpBtnTwo} variant="contained">
                <Link to="/signup">Sign Up</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* ADS FEATURES */}
      <div
        style={{ backgroundImage: `url(${PortfolioBgGreen})` }}
        className="bg-fixed bg-center bg-cover font-poppins"
      >
        <div className="flex flex-col items-center justify-center pb-10 mx-auto max-w-7xl">
          <div className="px-5 py-10 space-y-2">
            <div className="text-lg font-semibold text-center text-adx-white lg:text-4xl">
              Why adnexio?
            </div>
            <div className="text-sm font-light text-center text-adx-white lg:max-w-xl lg:text-lg">
              {`How does adnexio support you & your career?`}
            </div>
          </div>
          <div className="space-y-5 lg:flex lg:space-y-0 lg:space-x-20">
            <div className="w-64 px-4 py-8 space-y-2 rounded-3xl bg-adx-yellow lg:w-72 lg:space-y-0 lg:px-6">
              <div className="flex justify-end">
                <img src={FeatAi} alt="feat_ai" />
              </div>
              <div className="text-2xl font-bold lg:pb-3 ">
                A.I. Job <br /> Matching
              </div>
              <div className="text-sm font-light">
                Get matched with the best jobs available for you, with an AI that takes into account
                your qualific- ations, skills, experience, and interview skills.
              </div>
            </div>
            <div className="w-64 px-4 py-8 space-y-2 rounded-3xl bg-adx-yellow lg:w-72 lg:space-y-0 lg:px-6">
              <div className="flex justify-end">
                <img src={FeatInterview} alt="feat_interview" />
              </div>
              <div className="text-2xl font-bold lg:pb-3">
                Video <br /> {`Interview & Score`}
              </div>
              <div className="text-sm font-light">
                Our holistic scoring system provides detailed, constructive feedback on your ranking
                as a candidate, and how you can make yourself more attractive to employers.
              </div>
            </div>
            <div className="w-64 px-4 py-8 space-y-2 rounded-3xl bg-adx-yellow lg:w-72 lg:space-y-0 lg:px-6">
              <div className="flex justify-end">
                <img src={FeatSocial} alt="feat_social" />
              </div>
              <div className="text-2xl font-bold lg:pb-3">
                Professional <br />
                Social Videos
              </div>
              <div className="text-sm font-light">{`Expand your professional network. Watch the fun & casual social videos by other jobseekers, and post fun videos of your own, to give employers a better sense of your personality.`}</div>
            </div>
          </div>
        </div>
      </div>

      {/* DOWNLOAD APPS */}
      <div className="flex flex-col items-center py-16 space-y-2 bg-adx-white font-poppins lg:py-20">
        <div className="mb-4 text-lg font-semibold lg:mb-6 lg:text-2xl">
          For a better experience
        </div>
        <div className="flex space-x-3 lg:space-x-8">
          <ButtonBase sx={mui.appBtn} href={APP_STORE} target="_blank">
            <div>
              <img src={Apple} alt="apple" className="rounded-md w-36 lg:w-52" />
            </div>
          </ButtonBase>
          <ButtonBase sx={mui.appBtn} href={GOOGLE_PLAY} target="_blank">
            <div>
              <img src={Android} alt="android" className="rounded-md w-36 lg:w-52" />
            </div>
          </ButtonBase>
        </div>
      </div>

      {/* FOOTER */}

      <Footer />
    </div>
  )
}

export default LandingPage
