import React, { useState, useRef, useEffect } from 'react'
import { Link as RRDLink, useNavigate } from 'react-router-dom'
import { Link as RSLink } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { isAndroid, isIOS } from 'react-device-detect'

import bg_gig from 'pages/gig/assets/bg_gig.jpg'
import bg_gig_why from 'pages/gig/assets/bg_gig_why.png'
import down_chevron from 'pages/gig/assets/down_chevron.png'
import photo_1 from 'pages/gig/assets/photo_1.svg'
import photo_2 from 'pages/gig/assets/photo_2.svg'
import photo_3 from 'pages/gig/assets/photo_3.svg'
import adnexio_gig_logo from 'pages/gig/assets/adnexio_gig_logo.png'
import adnexio_green_logo from 'pages/gig/assets/adnexio_green_logo.png'
import apple_store from 'assets/images/app-store-badge.png'
import play_store from 'assets/images/google-play-badge.png'

import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import ButtonBase from '@mui/material/ButtonBase'
import Select from '@mui/material/Select'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import CloseIcon from '@mui/icons-material/Close'

const GOOGLE_PLAY = process.env.REACT_APP_GOOGLE_PLAY_URL
const APP_STORE = process.env.REACT_APP_APPLE_STORE_URL

const useStyles = makeStyles({
  btn1: {
    backgroundColor: '#FCD94E',
    color: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 20,
    padding: '3px 20px',
  },
  btnSignUp: {
    textTransform: 'capitalize',
    fontSize: 16,
    color: '#ffffff',
    fontWeight: 'bold',
    padding: '7px 20px',
    fontFamily: 'Poppins',
    marginRight: 10,
    borderWidth: 2,
    borderColor: 'white',
    borderRadius: 100,
  },
  iconBtnDown: {
    position: 'absolute',
    bottom: 16,
    left: '50%',
    transform: `translate(${-50}%, ${0}px)`,
  },
  btnMenu: {
    textTransform: 'capitalize',
    borderRadius: 0,
    padding: '10px 10px',
    fontSize: 18,
    fontWeight: 300,
    '@media (max-width: 1280px)': {
      padding: '5px 20px',
    },
  },
  btnLangMobile: {
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 'semibold',
    border: 'black solid 1px',
    padding: '0px 10px',
  },
  btnListMobile: {
    padding: '4px 16px',
  },
  btnListTextMobile: {
    '& .MuiTypography-body1': {
      fontSize: 16,
    },
  },
  btnListMobileActive: {
    color: '#1B8379',
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
    },
  },
  btnSignUpMobile: {
    opacity: 0,
    marginRight: 8,
    backgroundColor: '#FCD94E',
    color: '#1B8379',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 'semibold',
    padding: '0px 5px',
    minWidth: 0,
    '& .MuiTypography-label': {
      padding: '0px 0px',
    },
  },
  btnMenuActive: {
    color: '#FCD94E',
  },
  btnMenuNotActive: {
    color: 'white',
  },
  selecLang: {
    backgroundColor: '#FCD94E',
    border: 'none',
    color: '#1B8379',
    padding: '7px 20px',
    borderRadius: 100,
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  footerLink: {
    color: '#CECECE',
  },
  menuIconBtn: {
    color: '#ffffff',
  },
})

const Gigs = () => {
  const node = useRef()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('common')
  const classes = useStyles()
  const langLocalStorage = localStorage.getItem('lang')
  const [showMenuMobile, setShowMenuMobile] = useState(false)

  const handleChangeLang = (event) => {
    i18n.changeLanguage(event.target.value)
    localStorage.setItem('lang', event.target.value)
  }

  const toggleLanguage = () => {
    const lang = localStorage.getItem('lang')
    if (lang === 'my') {
      i18n.changeLanguage('en')
      localStorage.setItem('lang', 'en')
    }
    if (lang === 'en') {
      i18n.changeLanguage('my')
      localStorage.setItem('lang', 'my')
    }
  }

  const whyUsData = [
    {
      photo: photo_1,
      title: t('featureCards._1.title'),
      text: t('featureCards._1.desc'),
    },
    {
      photo: photo_2,
      title: t('featureCards._2.title'),
      text: t('featureCards._2.desc'),
    },
    {
      photo: photo_3,
      title: t('featureCards._3.title'),
      text: t('featureCards._3.desc'),
    },
  ]

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const handleClick = (e) => {
    if (node.current?.contains(e.target)) {
      return
    }
    // outside click
    setShowMenuMobile(false)
  }

  const handleSignUpNow = () => {
    if (isAndroid) {
      window.open(GOOGLE_PLAY, '_blank')
    } else if (isIOS) {
      window.open(APP_STORE, '_blank')
    } else {
      window.open(GOOGLE_PLAY, '_blank')
    }
  }

  return (
    <div className="bg-gray-200">
      <div
        className="relative h-screen w-full bg-cover bg-gigMobilePosition bg-no-repeat lg:bg-gigDesktopPosition"
        style={{ backgroundImage: `url(${bg_gig})` }}
      >
        <div className="relative mx-auto flex h-full w-full max-w-7xl flex-col">
          <div className="hidden flex-row items-center justify-between py-8 text-white  lg:flex">
            <div className="flex w-full flex-col items-center justify-center lg:w-auto lg:flex-row lg:space-x-24">
              <img src={adnexio_gig_logo} alt="..." className="w-48" />
              <div className="flex lg:space-x-14">
                <Button
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_ER_URL}/gigs`
                  }}
                  className={`${classes.btnMenu} ${classes.btnMenuNotActive}`}
                >
                  {t('forEmployer')}
                </Button>
                <Button
                  onClick={() => {
                    navigate('/')
                  }}
                  className={`${classes.btnMenu} ${classes.btnMenuNotActive}`}
                >
                  Pro
                </Button>
              </div>
            </div>
            <div className="hidden lg:block">
              <div>
                <Select
                  variant="standard"
                  className={`border-2 border-solid  border-white text-white ${classes.selecLang}`}
                  onChange={handleChangeLang}
                  value={langLocalStorage || 'en'}
                  disableUnderline
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="my">Bahasa Melayu</MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div
            className={`absolute block w-full py-1 lg:hidden ${
              showMenuMobile && 'bg-adx-gigYellow'
            }`}
          >
            <div className={`flex items-center justify-between`}>
              <IconButton
                className={classes.menuIconBtn}
                onClick={() => setShowMenuMobile(!showMenuMobile)}
                size="large"
              >
                {!showMenuMobile ? (
                  <MenuRoundedIcon className={classes.menuIcon} />
                ) : (
                  <CloseIcon className="text-black" />
                )}
              </IconButton>
              <img
                src={!showMenuMobile ? adnexio_gig_logo : adnexio_green_logo}
                alt="..."
                className="w-32"
              />
              <Button
                className={`${classes.btnSignUpMobile}`}
                onClick={() => handleSignUpNow()}
                disabled
              >
                {t('btnSignUp')}
              </Button>
            </div>
            {showMenuMobile && (
              <div ref={node}>
                <List>
                  <ListItem
                    className={classes.btnListMobile}
                    button
                    onClick={() => {
                      window.location.href = `${process.env.REACT_APP_ER_URL}/gigs`
                    }}
                  >
                    <ListItemText
                      className={classes.btnListTextMobile}
                      primary={t('forEmployer')}
                    />
                  </ListItem>
                  <ListItem
                    className={classes.btnListMobile}
                    button
                    onClick={() => {
                      navigate('/')
                    }}
                  >
                    <ListItemText className={classes.btnListTextMobile} button primary="Pro" />
                  </ListItem>
                  <ListItem>
                    <Button
                      onClick={() => {
                        toggleLanguage()
                      }}
                      className={`${classes.btnLangMobile}`}
                    >
                      {t('lang')}
                    </Button>
                  </ListItem>
                </List>
              </div>
            )}
          </div>
          <div className="flex flex-1 items-end justify-center pb-16 lg:items-center lg:justify-start lg:p-0">
            <div className="lg:w-4/12">
              <div className="mb-4 lg:mb-12">
                <p className="text-center text-4xl leading-tight text-adx-gigYellow lg:text-left lg:text-5xl ">
                  {t('gigWork')}
                  <br /> <span className="font-bold text-adx-darkGreen">{t('madeSimple')}</span>
                </p>
                <p className="hidden text-xl leading-normal text-white lg:block">
                  {t('gigDesc')}
                  <span className="font-bold italic"> adnexio gigs!</span>{' '}
                </p>
              </div>
              {/* <div className ="text-center lg:text-left">
                                <Button variant="contained" className={classes.btn1} onClick={()=> handleSignUpNow()}>{t('joinUsNow')}</Button>
                            </div> */}
            </div>
          </div>
        </div>
        <RSLink
          className="absolute left-1/2 bottom-4 -translate-x-1/2 transform cursor-pointer"
          to="anchor"
          spy={true}
          smooth={true}
          duration={500}
        >
          {/* <KeyboardArrowDownIcon className="text-white text-3xl"/> */}
          <img src={down_chevron} alt="..." width={25} />
        </RSLink>
      </div>
      <div className="mx-auto  grid max-w-7xl grid-cols-1 gap-y-4 py-8 px-4 lg:grid-cols-12 lg:space-x-32 lg:py-40">
        <div className="lg:col-span-7 ">
          <p className="mb-8 text-center text-xl font-bold text-adx-darkGreen lg:text-left lg:text-3xl">
            {t('furtherValueProp._1')} <br className="lg:hidden" />{' '}
            <span className="text-black">{t('furtherValueProp._2')}</span>
          </p>
          <p className=" mb-4 text-justify text-base lg:mb-0 lg:text-2xl">
            {t('furtherValuePropDesc')}
          </p>
        </div>
        <div
          className="grid h-full w-full space-y-4 justify-self-center rounded-3xl py-4 lg:col-span-4"
          style={{ backgroundColor: '#CECECE' }}
        >
          <p className="text-center text-xl font-semibold text-adx-darkGreen lg:text-3xl">
            {t('signUpPropmt._1')}
          </p>
          <p className="text-center text-2xl">
            {t('signUpPropmt._2._1')}
            <br />
            {t('signUpPropmt._2._2')} <span className="font-bold">{t('signUpPropmt._2._3')}!</span>
          </p>
          <div className="flex justify-center space-x-4">
            <ButtonBase className={classes.appBtn} href={APP_STORE} target="_blank">
              <img
                src={apple_store}
                alt="..."
                className="w-36 justify-self-center rounded-md lg:w-36"
              />
            </ButtonBase>
            <ButtonBase className={classes.appBtn} href={GOOGLE_PLAY} target="_blank">
              <img
                src={play_store}
                alt="..."
                className="w-36 justify-self-center rounded-md lg:w-36"
              />
            </ButtonBase>
          </div>
        </div>
      </div>
      <div
        id="anchor"
        className="relative w-full bg-cover bg-no-repeat py-16"
        style={{ backgroundImage: `url(${bg_gig_why})` }}
      >
        <div className="mx-auto grid max-w-7xl space-y-4">
          <div>
            <p className="text-center text-2xl font-semibold text-white lg:text-3xl">
              {t('featuresHeader._1')} <span className="text-adx-gigYellow"> adnexio gigs</span>?
            </p>
            <p className="text-center text-base text-white lg:text-lg">{t('featuresDesc')}</p>
          </div>
          <div className="grid w-full grid-cols-1 space-y-4 lg:grid-cols-3 lg:space-y-0">
            {whyUsData.map((item, index) => {
              const { photo, title, text } = item
              return (
                <div
                  key={index}
                  className="w-64 space-y-2 justify-self-center rounded-3xl bg-adx-gigYellow px-4 py-10"
                >
                  <img src={photo} alt="..." className="mx-auto h-24 w-24" />
                  <p className="text-xl font-bold">{title}</p>
                  <p className="text-xs">{text}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="py-20 text-center lg:py-32">
        <p className="mb-4 text-center text-xl font-bold lg:text-3xl">{t('callToAction')}</p>
        <div className="flex w-full justify-center space-x-4">
          <ButtonBase className={classes.appBtn} href={APP_STORE} target="_blank">
            <img
              src={apple_store}
              alt="..."
              className="w-36 justify-self-center rounded-md lg:w-52"
            />
          </ButtonBase>
          <ButtonBase className={classes.appBtn} href={GOOGLE_PLAY} target="_blank">
            <img
              src={play_store}
              alt="..."
              className="w-36 justify-self-center rounded-md lg:w-52"
            />
          </ButtonBase>
        </div>
      </div>
      <div className="bg-black">
        <div className="mx-auto flex max-w-6xl flex-col items-center justify-center space-y-5 py-10 text-center text-sm text-adx-darkGray lg:flex-row lg:justify-between lg:px-10">
          <img src={adnexio_gig_logo} alt="..." className="w-48" />
          <div className="flex flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-20">
            <div className="space-y-2 lg:text-left">
              <div className="text-adx-gigYellow lg:text-xl">INFO</div>
              <div className="flex flex-col space-y-2 text-xs">
                <RRDLink to="/nex" className={classes.footerLink}>
                  Who's NEX?
                </RRDLink>
                <RRDLink to="/faq" className={classes.footerLink}>
                  FAQ
                </RRDLink>
                <RRDLink to="/terms-conditions" className={classes.footerLink}>
                  Terms and Conditions
                </RRDLink>
                <RRDLink to="/privacy-policy" className={classes.footerLink}>
                  Privacy Policy
                </RRDLink>
              </div>
            </div>
            <div className="space-y-2 lg:text-left">
              <div className="text-adx-gigYellow lg:text-xl">CONTACT</div>
              <div className="flex flex-col space-y-2 text-xs">
                <Link
                  href="https://www.facebook.com/adnexio.jobs"
                  target="_blank"
                  className={classes.footerLink}
                  underline="hover"
                >
                  Facebook
                </Link>
                <Link
                  href="https://twitter.com/adnexioJobs"
                  target="_blank"
                  className={classes.footerLink}
                  underline="hover"
                >
                  Twitter
                </Link>
                <Link
                  href="https://www.linkedin.com/company/adnexiojobs/"
                  target="_blank"
                  className={classes.footerLink}
                  underline="hover"
                >
                  LinkedIn
                </Link>
                <Link
                  href="https://www.instagram.com/adnexio.jobs/"
                  target="_blank"
                  className={classes.footerLink}
                  underline="hover"
                >
                  Instagram
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gigs
