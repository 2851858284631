import axios, { getData } from "lib/axios"

export const postSuggestion = async (data) => {
  return axios.post("/nex_edu_suggest", data).then(getData)
}

export const getCourseList = async (page, country) => {
  const checkCurrency = (country) => {
    switch (country) {
      case "MY":
        return "MYR"
      
      case "SG":
        return "SGD"

      default:
        return null;
    }
  }

  const params = { page, exclusive_country: country, currency: checkCurrency(country) }

  return axios.get("/job_seekers/edu_course_list", { params }).then(getData)
}