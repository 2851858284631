import { createContext, useState, useEffect, useContext } from 'react'
import { authContext } from 'contexts/AuthContext'
import { myAccount } from 'services/api/authentication'
import {
  scoreWeight,
  experiences,
  educations,
  extracurriculars,
  skills,
  retrieveCv,
  profile,
} from 'services/api/profile'
import Bugsnag from '@bugsnag/js'

export const ProfileContext = createContext()

const ProfileContextProvider = (props) => {
  const { auth } = useContext(authContext)
  const [profileData, setProfileData] = useState(null)
  const [scoreData, setScoreData] = useState(null)
  const [experienceData, setExperienceData] = useState(null)
  const [educationData, setEducationData] = useState(null)
  const [extracurricularData, setExtracurricularData] = useState(null)
  const [skillData, setSkillData] = useState(null)
  const [cvData, setCvData] = useState(null)
  const [dobData, setDobData] = useState(null)

  const getProfile = async () => {
    try {
      const results = await myAccount()
      setProfileData(results.data)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const getScore = async () => {
    try {
      const results = await scoreWeight()
      setScoreData(results.data)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const getExperience = async () => {
    try {
      const results = await experiences()
      setExperienceData(results.data)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const getEducation = async () => {
    try {
      const results = await educations()
      setEducationData(results.data)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const getExtracurricular = async () => {
    try {
      const results = await extracurriculars()
      setExtracurricularData(results.data)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const getSkill = async (method, data) => {
    try {
      const results = await skills(method, data)
      setSkillData(results.data)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const getCv = async () => {
    try {
      const results = await retrieveCv()
      setCvData(results.data)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const getDob = async () => {
    try {
      const results = await profile()
      setDobData(results.data)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  useEffect(() => {
    if (auth.isAuth) {
      getProfile()
      getScore()
      getExperience()
      getEducation()
      getExtracurricular()
      getSkill('get')
      getCv()
      getDob()
    } else {
      setProfileData(null)
      setScoreData(null)
      setExperienceData(null)
      setEducationData(null)
      setExtracurricularData(null)
      setSkillData(null)
      setCvData(null)
      setDobData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuth])

  return (
    <ProfileContext.Provider
      value={{
        profileData,
        scoreData,
        experienceData,
        educationData,
        extracurricularData,
        skillData,
        cvData,
        dobData,
        getProfile,
        getScore,
        getExperience,
        getEducation,
        getExtracurricular,
        getSkill,
        getCv,
        getDob,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  )
}

export default ProfileContextProvider
