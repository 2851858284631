import { useState, useEffect, useContext } from 'react'

import { Formik, Form, Field, useField } from 'formik'
import * as Yup from 'yup'

import { ProfileContext } from 'contexts/ProfileContext'
import { jobLevel, jobBackgrounds } from 'services/api/dropdown_list'
import { experiences } from 'services/api/profile'

import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'

const CustomMenuItem = withStyles({
  root: {
    fontFamily: 'Poppins',
    fontSize: '14px',
  },
})(MenuItem)

const mui = {
  inputCSS: {
    fontSize: '14px',
    '& input::placeholder': {
      fontSize: '14px',
    },
  },
  selectCSS: {
    fontSize: '14px',
  },
  labelCSS: {
    fontSize: '14px',
  },
  adornmentCSS: {
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
  },
  checkBoxLabel: {
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
    userSelect: 'none',
  },
  calendarIcon: {
    '& .MuiButtonBase-root': {
      outline: 'none',
    },
  },
}

const MyTextField = ({
  placeholder,
  label,
  InputProps,
  InputLabelProps,
  sx,
  className,
  rows,
  minRows,
  maxRows,
  multiline,
  type,
  ...props
}) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''
  return (
    <TextField
      variant="standard"
      placeholder={placeholder}
      label={label}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      sx={sx}
      {...field}
      helperText={errorText}
      error={!!errorText}
      multiline={multiline}
      rows={rows}
      minRows={minRows}
      maxRows={maxRows}
      type={type}
      className={className}
    />
  )
}

const MyCheckBox = ({ label, sx, checked, ...props }) => {
  const [field] = useField(props)
  return (
    <FormControlLabel
      {...field}
      label={label}
      sx={sx}
      control={<Checkbox color="primary" size="small" checked={checked} />}
    />
  )
}

const MyDatePicker = (props) => {
  const { label, name, disabled, InputProps, ...rest } = props
  return (
    <Field name={name}>
      {({ form, field, meta }) => {
        const { setFieldValue } = form
        const { value } = field
        const errorText = meta.error && meta.touched ? meta.error : ''
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={label}
              onChange={(val) => setFieldValue(name, val)}
              value={value}
              inputFormat={'MM/yyyy'}
              views={['year', 'month']}
              disabled={disabled}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params?.InputProps,
                      ...InputProps,
                    }}
                    error={!!errorText}
                    helperText={errorText}
                    variant="standard"
                    fullWidth
                    {...rest}
                  />
                )
              }}
            />
          </LocalizationProvider>
        )
      }}
    </Field>
  )
}

const ExperienceForm = ({
  experienceOpen,
  setExperienceOpen,
  aboutOpen,
  setAboutOpen,
  setOpenSnack,
  experienceID,
  loading,
  setLoading,
  setSnackProps,
}) => {
  const { getExperience } = useContext(ProfileContext)
  const [jobLevelList, setJobLevelList] = useState([])
  const [jobBackgroundList, setJobBackgroundList] = useState([])

  const [initialValues, setInitialValues] = useState({
    position: '',
    company_name: '',
    startDate: null,
    endDate: null,
    currentWork: false,
    job_level_id: '',
    job_description: '',
    job_background: '',
    salary: '',
  })

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)

  const validationSchema = Yup.object({
    position: Yup.string().required('Enter your position title'),
    company_name: Yup.string().required('Enter the company name'),
    startDate: Yup.date()
      .typeError('Invalid input')
      .max(tomorrow, "Start date cannot be after today's date")
      .required('Enter your start date')
      .nullable(),
    endDate: Yup.date()
      .typeError('Invalid input')
      .when('currentWork', {
        is: (currentWork) => {
          return !!currentWork ? false : true
        },
        then: Yup.date()
          .typeError('Invalid input')
          .min(Yup.ref('startDate'), 'End date cannot be before start date')
          .max(tomorrow, "End date cannot be after today's date")
          .required('Enter your end date')
          .nullable(),
      })
      .nullable(),
    currentWork: Yup.boolean(),
    job_level_id: Yup.string().required('Select your job level'),
    job_description: Yup.string().required('Enter your job description'),
    job_background: Yup.string().required('Select your job background'),
    salary: Yup.number().required('Enter your salary'),
  })

  const getJobLevel = async () => {
    try {
      let res = await jobLevel()
      setJobLevelList(res.data)
    } catch (e) {
      console.log(e)
    }
  }

  const getJobBackground = async () => {
    try {
      let res = await jobBackgrounds()
      setJobBackgroundList(res.data)
    } catch (e) {
      console.log(e)
    }
  }

  const submitExperience = async (values, setErrors) => {
    values.start_month = values.startDate.getMonth() + 1
    values.start_year = values.startDate.getFullYear()

    if (values.currentWork === true) {
      values.end_month = 'Present'
      values.end_year = 'Present'
    } else {
      values.end_month = values.endDate.getMonth() + 1
      values.end_year = values.endDate.getFullYear()
    }

    if (experienceID === null) {
      try {
        console.log('values', values)

        setOpenSnack(false)
        setLoading(true)
        await experiences('post', values, null)
        await getExperience()
        setSnackProps({
          severity: 'success',
          message: 'Your save was successful.',
        })
        setOpenSnack(true)
      } catch (e) {
        if (e.status === 422) {
          setErrors({
            ...e.data.errors,
            startDate: !!e.data.errors['start_month']
              ? e.data.errors['start_month']
              : e.data.errors['start_year'],
            endDate: !!e.data.errors['end_month']
              ? e.data.errors['end_month']
              : e.data.errors['end_year'],
          })
        } else {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        }
      } finally {
        setLoading(false)
        setExperienceOpen(!experienceOpen)
      }
    } else if (experienceID !== null) {
      try {
        setOpenSnack(false)
        setLoading(true)
        await experiences('put', values, experienceID)
        await getExperience()
        setSnackProps({
          severity: 'success',
          message: 'Your save was successful.',
        })
        setOpenSnack(true)
      } catch (e) {
        if (e.status === 422) {
          setErrors({
            ...e.data.errors,
            startDate: !!e.data.errors['start_month']
              ? e.data.errors['start_month']
              : e.data.errors['start_year'],
            endDate: !!e.data.errors['end_month']
              ? e.data.errors['end_month']
              : e.data.errors['end_year'],
          })
        } else {
          setSnackProps({
            severity: 'error',
            message: e.data.message,
          })
          setOpenSnack(true)
        }
      } finally {
        setLoading(false)
        setExperienceOpen(!experienceOpen)
      }
    }
  }

  const deleteExperience = async () => {
    setLoading(true)
    try {
      await experiences('delete', null, experienceID)
      await getExperience()
      window.open('/profile', '_self')
    } catch (e) {
      setSnackProps({
        severity: 'error',
        message: e.data.message,
      })
      setLoading(false)
      setOpenSnack(true)
    }
  }

  const loaderHandler = () => {
    setLoading(false)
  }

  const getUserExperience = async (id) => {
    try {
      let res = await experiences('get', null, id)

      setInitialValues({
        position: res.data.position,
        company_name: res.data.company_name,
        startDate: new Date(`${res.data.start_year}-${res.data.start_month}-01`),
        endDate:
          res.data.current_job === 1
            ? null
            : new Date(`${res.data.end_year}-${res.data.end_month}-01`),
        currentWork: res.data.current_job === 1 ? true : false,
        job_level_id: res.data.job_level_id,
        job_description: res.data.job_description,
        job_background: res.data.job_background,
        salary: res.data.salary,
      })
    } catch (e) {
      console.log(e)
    } finally {
      setTimeout(loaderHandler, 1000)
    }
  }

  useEffect(() => {
    getJobLevel()
    getJobBackground()
    if (experienceID) {
      setLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (experienceID && jobLevelList.length !== 0 && jobBackgroundList !== 0) {
      getUserExperience(experienceID)
    }
    return () => {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobLevelList, jobBackgroundList])

  window.scroll(0, 0)

  return (
    <div className="">
      <div className="mb-2 flex items-center justify-between">
        <div className="font-semibold">Work Experience</div>
        <Button
          color="primary"
          startIcon={<ChevronLeftRoundedIcon />}
          onClick={() => setExperienceOpen(!experienceOpen)}
          style={{
            textTransform: 'none',
            fontFamily: 'Poppins',
            color: 'gray',
            outline: 'none',
          }}
        >
          Back
        </Button>
      </div>

      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(data, { setErrors }) => {
          submitExperience(data, setErrors)
        }}
      >
        {({ values, errors, touched, dirty, handleChange }) => (
          <Form autoComplete="off">
            <div className="flex flex-col space-y-5 pl-2">
              <MyTextField
                label="Position Title"
                name="position"
                InputProps={{ sx: mui.inputCSS }}
                InputLabelProps={{ sx: mui.labelCSS }}
                className="lg:w-4/5"
              />

              <MyTextField
                label="Company Name"
                name="company_name"
                InputProps={{ sx: mui.inputCSS }}
                InputLabelProps={{ sx: mui.labelCSS }}
                className="lg:w-4/5"
              />

              <div className="flex space-x-5 outline-none lg:w-4/5 lg:space-x-10">
                <MyDatePicker
                  name="startDate"
                  label="Start Date"
                  InputProps={{ sx: mui.inputCSS }}
                  InputLabelProps={{ sx: mui.labelCSS }}
                />
                <MyDatePicker
                  name="endDate"
                  label="End Date"
                  InputProps={{ sx: mui.inputCSS }}
                  InputLabelProps={{ sx: mui.labelCSS }}
                  disabled={values.currentWork}
                />
              </div>

              <MyCheckBox
                name="currentWork"
                label="I am currently working here"
                sx={mui.checkBoxLabel}
                checked={values.currentWork}
              />

              <FormControl
                variant="standard"
                className="lg:w-4/5"
                error={touched.job_level_id && Boolean(errors.job_level_id)}
              >
                <InputLabel sx={mui.labelCSS}>Job Level</InputLabel>
                <Select
                  variant="standard"
                  sx={mui.selectCSS}
                  name="job_level_id"
                  value={values.job_level_id}
                  onChange={handleChange}
                  defaultValue=""
                >
                  <CustomMenuItem value={''}>
                    <em style={{ color: 'rgba(0,0,0,0.54)' }}>None</em>
                  </CustomMenuItem>
                  {jobLevelList.map((item) => (
                    <CustomMenuItem key={item.id} value={item.id}>
                      {item.level_name}
                    </CustomMenuItem>
                  ))}
                </Select>
                {touched.job_level_id && errors.job_level_id && (
                  <FormHelperText>{touched.job_level_id && errors.job_level_id}</FormHelperText>
                )}
              </FormControl>

              <MyTextField
                name="job_description"
                maxRows={'Infinity'}
                multiline={true}
                label="Job Description"
                InputProps={{ sx: mui.inputCSS }}
                InputLabelProps={{ sx: mui.labelCSS }}
                className="lg:w-4/5"
              />

              <FormControl
                variant="standard"
                className="lg:w-4/5"
                error={touched.job_background && Boolean(errors.job_background)}
              >
                <InputLabel sx={mui.labelCSS}>Job Background</InputLabel>
                <Select
                  variant="standard"
                  sx={mui.selectCSS}
                  name="job_background"
                  value={values.job_background}
                  onChange={handleChange}
                  defaultValue=""
                >
                  <CustomMenuItem value={''}>
                    <em style={{ color: 'rgba(0,0,0,0.54)' }}>None</em>
                  </CustomMenuItem>
                  {jobBackgroundList.map((item) => (
                    <CustomMenuItem key={item.id} value={item.job_background_name}>
                      {item.job_background_name}
                    </CustomMenuItem>
                  ))}
                </Select>
                {touched.job_background && errors.job_background && (
                  <FormHelperText>{touched.job_background && errors.job_background}</FormHelperText>
                )}
              </FormControl>

              <MyTextField
                label="Salary"
                type="number"
                name="salary"
                InputProps={{
                  sx: mui.inputCSS,
                  startAdornment: (
                    <InputAdornment sx={mui.adornmentCSS} position="start">
                      RM
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ sx: mui.labelCSS }}
                className="w-1/2 lg:w-1/3"
              />
            </div>
            <div className="mt-8 flex justify-center space-x-5">
              <Button
                disabled={!dirty || loading}
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => setAboutOpen(aboutOpen)}
                style={{
                  textTransform: 'none',
                  fontWeight: '400',
                  fontSize: '13px',
                  padding: '5px 20px',
                  boxShadow: 'none',
                }}
              >
                Save
              </Button>
              {experienceID && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={deleteExperience}
                  style={{
                    textTransform: 'none',
                    fontWeight: '400',
                    fontSize: '13px',
                    padding: '5px 20px',
                    boxShadow: 'none',
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ExperienceForm
