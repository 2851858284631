import { Badge, ButtonBase, ClickAwayListener, Grow, IconButton, MenuList, Popper } from '@mui/material'
import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useContext } from 'react'
import { NotificationContext } from 'contexts/NotificationContext'
import AdnexioWhite from 'assets/logos/adnexio-logo-white-v2-aug.png'
import { notificationRead } from 'services/api/notification'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'

const mui = {
    notiBtn: {
        textAlign: 'left',
        fontWeight: '300',
        outline: 'none',
        width: '100%',
        padding: '10px 0px',
        justifyContent: 'flex-start',
        '&:focus': {
          outline: 'none',
          boxShadow: 'none',
        },
        '&:hover': {
          backgroundColor: '#F9F9F9',
        },
    },
    notiIconBtn: {
        color: 'white',
            '&:focus': {
        outline: 'none',
        },
        padding: '5px',
        marginRight: '2px',
        '@media (min-width:1024px)': {
            marginRight: '20px',
        },
    },
    notiIcon: {
        fontSize: '25px',
        transform: 'rotate(15deg)',
        '@media (min-width:1024px)': {
            fontSize: '30px',
        },
        color: '#1B8379',
    },
    badgeCustom: {
        '& .MuiBadge-badge': {
          '@media (max-width:1024px)': {
            padding: '0px',
            fontSize: '10px',
          },
        },
    },
    popper: {
        '& .MuiList-padding': {
          padding: '10px 10px 0px 10px',
        },
    },
    notiCloseIconBtn: {
        color: 'white',
        '&:focus': {
        outline: 'none',
        },
    },
    notiCloseIcon: {
        color: 'white',
    },
    
}

const NotificationButton = ({ setOpenMobileMenu }) => {
    const { notificationData, getNotification } = useContext(NotificationContext)
    const [openNotification, setOpenNotification] = useState(false)
    const anchorRefNotification = useRef(null)

    const handleToggleNotification = () => {
        setOpenNotification((prevOpen) => !prevOpen)
        setOpenMobileMenu(false)
    }
    
      const handleCloseNotification = (event) => {
        if (anchorRefNotification.current && anchorRefNotification.current.contains(event.target)) {
            return
        }
        setOpenNotification(false)
    }
    
    const handleReadMessage = async (id) => {
        try {
            await notificationRead(id)
            await getNotification()
        } catch (e) {
            console.error(e)
        }
    }

    const handleListKeyDownNotification = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpenNotification(false)
        }
    }
    
    
    return (
        <>
            <IconButton
                sx={mui.notiIconBtn}
                onClick={handleToggleNotification}
                ref={anchorRefNotification}
                size="large"
            >
                <Badge
                badgeContent={notificationData !== null && notificationData.total}
                color="error"
                max={99}
                sx={mui.badgeCustom}
                >
                <NotificationsRoundedIcon sx={mui.notiIcon} />
                </Badge>
            </IconButton>
            <Popper
                open={openNotification}
                anchorEl={anchorRefNotification.current}
                transition
                disablePortal
                placement="bottom-end"
                className="z-50 w-10/12 max-w-sm lg:w-auto lg:min-w-400 lg:max-w-sm"
                sx={mui.popper}
            >
                {({ TransitionProps }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                    <div className="pb-4 text-xs rounded-lg shadow-md outline-none bg-adx-white font-poppins">
                    <ClickAwayListener onClickAway={handleCloseNotification}>
                        <MenuList
                        autoFocusItem={openNotification}
                        onKeyDown={handleListKeyDownNotification}
                        >
                        <div className="flex items-center justify-between px-4 mb-2 rounded-lg outline-none bg-adx-darkGreen">
                            <div className="text-base font-medium outline-none text-adx-white">
                                Notifications
                            </div>
                            <IconButton
                                sx={mui.notiCloseIconBtn}
                                onClick={handleCloseNotification}
                                size="large"
                            >
                                <CloseRoundedIcon fontSize="small" sx={mui.notiCloseIcon} />
                            </IconButton>
                        </div>
                        {notificationData !== null && notificationData.total === 0 && (
                            <div className="flex items-center justify-center px-4 pt-2 overflow-y-scroll font-light lg:no-scrollbar max-h-80 min-h-200 lg:overflow-y-auto lg:text-sm">
                            <div className="font-light">You are up to date</div>
                            </div>
                        )}
                        {notificationData !== null && notificationData.total !== 0 && (
                            <div className="px-4 pt-2 overflow-y-scroll font-light lg:no-scrollbar max-h-80 min-h-200 lg:overflow-y-auto lg:text-sm">
                            {notificationData.data.map((item, index) => (
                                <div key={item.id}>
                                <ButtonBase
                                    sx={mui.notiBtn}
                                    onClick={() => handleReadMessage(item.id)}
                                >
                                    {item.photo_url !== null && (
                                    <img
                                        src={item.photo_url}
                                        alt="profile"
                                        className="object-cover w-8 h-8 mr-3 rounded-full shadow-md lg:h-12 lg:w-12"
                                    />
                                    )}
                                    {item.photo_url === null && (
                                    <img
                                        src={AdnexioWhite}
                                        alt="profile"
                                        className="object-contain w-8 h-8 mr-3 rounded-full shadow-md bg-adx-darkGreen lg:h-12 lg:w-12"
                                    />
                                    )}
                                    <div className="flex flex-col">
                                    <div className="text-xs font-semibold">{item.title}</div>
                                    <div className="text-xs">
                                        {typeof item.message === 'string' && item.message}
                                    </div>
                                    </div>
                                </ButtonBase>
                                {notificationData.data.length !== index + 1 && <hr />}
                                </div>
                            ))}
                            </div>
                        )}
                        </MenuList>
                    </ClickAwayListener>
                    </div>
                </Grow>
                )}
            </Popper>
        </>
    )
}

export default NotificationButton