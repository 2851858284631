import React from 'react'

const StatusChipApplication = ({ applicationStatusId }) => {
  let textStatus
  let color
  let backgroundColor

  switch (applicationStatusId) {
    case 1:
      textStatus = 'Application under review' // Yellow
      color = '#CCAB02'
      backgroundColor = '#FCC84E29'
      break
    case 2:
      textStatus = 'To confirm participation' // Blue
      color = '#0FA6F5'
      backgroundColor = '#0088FF1C'
      break
    case 3:
      textStatus = 'Participation confirmed' // Green
      color = '#1B8379'
      backgroundColor = '#E2EAE9'
      break
    case 4:
      textStatus = 'Application is unsuccessful' // Red
      color = '#FC4E4E'
      backgroundColor = '#FC4E4E14'
      break
    case 5:
      textStatus = 'Waitlisted' // Blue
      color = '#8A8A8A'
      backgroundColor = '#E5E5E5'
      break
    default:
      textStatus = applicationStatusId?.textStatus || 'No status'
      color = applicationStatusId?.color || '#EB5757'
      backgroundColor = applicationStatusId?.backgroundColor || '#FDEEEE'
  }
  return (
    <p
      className="inline-block whitespace-nowrap rounded-full px-2 py-1 text-center text-xs font-semibold "
      style={{ color, backgroundColor }}
    >
      {textStatus}
    </p>
  )
}

export default StatusChipApplication