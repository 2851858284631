import { useState } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabProfile from './TabProfile'
import TabScore from './TabScore'
import TabVideo from './TabVideo'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <div className="font-poppins">{children}</div>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const mui = {
  tabButton: {
    fontFamily: 'Poppins',
    textTransform: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
}

const ProfileTab = ({
  profileData,
  scoreData,
  experienceData,
  educationData,
  extracurricularData,
  skillData,
  cvData,
  dobData,
  setOpenSnack,
  loading,
  setLoading,
  setSnackProps,
  historyData,
}) => {
  const theme = useTheme()
  const [value, setValue] = useState(historyData?.tabIndex || 0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <div className="bg-adx-white px-3 lg:min-h-screen lg:px-28 lg:py-10">
      <AppBar position="static" color="default" style={{ boxShadow: 'none', background: 'white' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Profile" sx={mui.tabButton} style={{ borderBottom: '2px solid #F5F5F5' }} />
          <Tab label="Score" sx={mui.tabButton} style={{ borderBottom: '2px solid #F5F5F5' }} />
          <Tab label="Videos" sx={mui.tabButton} style={{ borderBottom: '2px solid #F5F5F5' }} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <TabProfile
            profileData={profileData}
            experienceData={experienceData}
            educationData={educationData}
            extracurricularData={extracurricularData}
            skillData={skillData}
            cvData={cvData}
            dobData={dobData}
            setOpenSnack={setOpenSnack}
            loading={loading}
            setLoading={setLoading}
            setSnackProps={setSnackProps}
            historyData={historyData}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TabScore scoreData={scoreData} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <TabVideo />
        </TabPanel>
      </SwipeableViews>
    </div>
  )
}

export default ProfileTab
