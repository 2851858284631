import axios, { getData } from "lib/axios"

export const submitHRDApplication = async (nex_edu_payment_schedule_id, payload) => {
  return axios.post(`/job_seekers/nex_edu_payment_schedules/${nex_edu_payment_schedule_id}/hrdf_applications`, payload).then(getData)
}

export const getHrdfStatus = async (nex_edu_payment_schedule_id) => {
  const { data } = await axios.get(`/job_seekers/nex_edu_payment_schedules/${nex_edu_payment_schedule_id}/hrdf_applications`).then(getData)
  return data
}

export const getPaymentSummaryHrdf = async (nex_edu_payment_schedule_id) => {
  const { data } = await axios.get(`nex_edu_payment/summary/${nex_edu_payment_schedule_id}`).then(getData)
  return data
}

export const downloadQuotation = async (nexEduPaymentSchedule) => {
  return await axios.get(`job_seekers/nex_edu_payment_schedules/${nexEduPaymentSchedule}/hrdf_applications/quotation`, { responseType: 'blob' })
}