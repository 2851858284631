import { useState, useEffect } from 'react'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import * as Scroll from 'react-scroll'

import Button from '@mui/material/Button'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import IconButton from '@mui/material/IconButton'
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import Background from 'modules/edu/pages/classes_details/assets/background.png'
import { getCurrentEnrolment, updateAttendance } from 'services/api/current_enrolment'
import Footer from 'components/dumb/Footer'
import HeaderEdu from 'modules/edu/components/Header/view/HeaderEdu'
import { Error } from '@mui/icons-material'
import { Box, Skeleton, useMediaQuery } from '@mui/material'
import Bugsnag from '@bugsnag/js'

const mui = {
  iconStyle: {
    color: '#FFFFFF',
  },
  myClassBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    background: 'white',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  myClassBtnActive: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#E2EAE9',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  applicationBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 213,
    fontFamily: 'Poppins',
    fontSize: '18px',
    textTransform: 'none',
    fontWeight: '300',
    background: 'transparent',
    color: '#1B8379',
    borderRadius: '7px',
    boxShadow: 'none',
    padding: '4px 23px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  backBtn: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    textTransform: 'none',
    fontWeight: '300',
    background: 'transparent',
    color: '#6E6E6E',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '0px 0px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  attendBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#1B8379',
    color: '#FCD94E',
    borderRadius: '8px',
    boxShadow: 'none',
    padding: '7px 22px',
    '&:hover': {
      background: '#1B8379',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    width: '128px',
  },
  playZoomBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#E2EAE9',
    color: '#1B8379',
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: '#1B8379',
    boxShadow: 'none',
    padding: '3.5px 22px',
    '&:hover': {
      background: '#E2EAE9',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    width: '128px',
  },
  comingUpBtn: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    background: '#F3F3F3',
    color: '#D6D6D6',
    borderRadius: '8px',
    boxShadow: 'none',
    padding: '7px 22px',
    '&:hover': {
      background: '#F3F3F3',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    width: '128px',
  },
  viewSubmitBtn: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    textTransform: 'none',
    fontWeight: '500',
    background: '#FCD94E',
    color: '#1B8379',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '5px 20px',
    '&:hover': {
      background: '#FCCE4E',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    width: '95px',
  },
  accordionBox: {
    border: 'none',
    boxShadow: 'none',
  },
  disableAccordion: {
    '&.Mui-disabled': {
      backgroundColor: 'white',
      opacity: 1,
    },
  },
  disableAccordionSummary: {
    opacity: '1 !important',
  },
  boldedExpand: {
    backgroundColor: '#1B8379',
    color: '#FFFFFF',
    borderRadius: '12px',
  },
}

const EDUClassesDetails = () => {
  const [animateIconBool, setAnimateIconBool] = useState(true)
  const [courseSession, setCourseSession] = useState([])
  const [courseStatus, setCourseStatus] = useState({
    course_barred: false,
    barred_message: '',
  })
  const [courseHeader, setCourseHeader] = useState({
    title: '',
    description: '',
  })
  const [courseData, setCourseData] = useState(null)
  const [feedbackURL, setFeedbackURL] = useState(null)
  const [assignmentURL, setAssignmentURL] = useState(null)
  const [expandedPanel, setExpandedPanel] = useState(false)
  const [expandedReadingPanel, setExpandedReadingPanel] = useState(false)
  const [loading, setLoading] = useState(true)

  const param = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  let historyData = location.state
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  var scroll = Scroll.animateScroll

  // Icon Rotation
  const animatedClock = 'transform rotate-180 transition duration-1000'
  const animatedAntiClock = 'transform rotate-0 transition duration-1000'

  // Drawer Translation
  const drawerHidden = 'lg:transform lg:-translate-x-full lg:transition lg:duration-1000'
  const drawerDisplayed = 'lg:transform lg:translate-x-0 lg:transition lg:duration-1000'

  const handlePress = () => {
    setAnimateIconBool(!animateIconBool)
    scroll.scrollToTop()
  }
  const handleAttendZoom = (mapProps) => {
    navigate(`/bootcamp/my-classes/${courseData.id}/${mapProps.id}`, { ...mapProps })
  }
  const handleAttendance = async (courseSession) => {
    try {
      await updateAttendance(courseSession.id)
      window.open(courseSession.join_meeting_url, '_blank')
    } catch (err) {
      Bugsnag.notify(err)
    }
  }

  // For those who did not attend live session but watch recording sessions
  const handleAttendRecordingSession = async (courseSession) => {
    try {
      await updateAttendance(courseSession.id)
      navigate(`/playback/${courseData.id}/${courseSession.id}`, {
        ...courseSession,
      })
    } catch (error) {
      Bugsnag.notify(error)
    }

  }
  const handleGetMainSession = async (mainData) => {
    setLoading(true)
    try {
      setCourseData(mainData)
      setCourseHeader({
        ...courseHeader,
        title: mainData.course_title,
        description: mainData.course_description,
      })
      let response = await getCurrentEnrolment(mainData.id)
      setFeedbackURL(response.feedback_url)
      setCourseSession(response.data)
    } catch (error) {
      setCourseSession([])
    } finally {
      setLoading(false)
    }
  }
  const handleGetDetailedSession = async (sessionID) => {
    setLoading(true)
    await getCurrentEnrolment()
      .then(async (mainSession) => {
        let truth = mainSession.data.find((element) => {
          return element.id === parseInt(sessionID)
        })
        if (truth) {
          setCourseData(truth)
          setCourseHeader({
            ...courseHeader,
            title: truth.course_title,
            description: truth.course_description,
          })
          try {

            let response = await getCurrentEnrolment(truth.id)
            setAssignmentURL(response?.assignment_url)
            setFeedbackURL(response.feedback_url)
            setCourseSession(response.data)
            setCourseStatus({
              course_barred: response.course_barred,
              barred_message: response.barred_message,
            })
          } catch (error) {
            setCourseSession([])
            setCourseStatus({
              course_barred: false,
              barred_message: '',
            })
          }
        } else {
          window.location.href = '/course-not-found'
        }
      })
      .catch(() => {
        window.location.href = '/course-not-found'
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }
  useEffect(() => {
    if (historyData) {
      handleGetMainSession(historyData)
    } else {
      handleGetDetailedSession(param.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.id])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="bg-adx-gray">
      <HeaderEdu page="my-classes" />
      <div className="relative grid h-full grid-cols-5 bg-adx-gray">
        {animateIconBool ? (
          <div
            className={`hidden lg:block lg:h-full lg:w-full lg:bg-white ${animateIconBool ? drawerDisplayed : drawerHidden
              }`}
          >
            <div className="flex justify-center w-full mt-9">
              <Button variant="contained" sx={mui.myClassBtnActive} disableRipple={true}>
                <div>
                  <DesktopWindowsIcon className="mr-2" />
                  My Classes
                </div>
                <ArrowForwardIosIcon className="ml-2" />
              </Button>
            </div>
            <div className="flex justify-center w-full mt-9">
              <Link to="/upskill-payment">
                <Button
                  variant="contained"
                  sx={mui.myClassBtn}
                  onClick={() => navigate('/upskill-payment')}
                >
                  <div>
                    <AccountBalanceWalletIcon className="mr-2" />
                    Payment
                  </div>
                  <ArrowForwardIosIcon className="ml-2 opacity-0" />
                </Button>
              </Link>
            </div>
          </div>
        ) : null}
        {/* Animation to space the column with the left side bar */}
        <div
          className={`px-12 pt-9 pb-96 ${animateIconBool ? 'col-span-5 lg:col-span-4' : 'col-span-5'
            }`}
        >
          <Link to="/bootcamp/my-classes">
            <Button variant="contained" sx={mui.backBtn}>
              <ArrowBackIosIcon />
              Back
            </Button>
          </Link>
          {courseStatus?.course_barred ? (
            <div className="my-5 flex h-auto w-full flex-col items-center justify-center rounded-2xl bg-white p-[20px]">
              <Error
                sx={{
                  color: 'error.main',
                  fontSize: '5rem',
                }}
              />
              <div className="my-3 text-2xl font-semibold text-adx-lightBlack">
                Your account has been restricted
              </div>
              <div className="text-base font-normal text-adx-lightBlack">
                {courseStatus?.barred_message}
              </div>
              <Button
                variant="contained"
                sx={{ color: 'primary.main', backgroundColor: 'secondary.main', mt: 3 }}
                onClick={() => navigate('/upskill-payment')}
              >
                Make payment
              </Button>
            </div>
          ) : (
            <div>
              <div className="text-xl font-semibold mt-7 text-adx-lightBlack">
                {courseHeader.title}
              </div>
              {/* My progress, Submit assignment, Submit feedback space */}
              <Box
                sx={{
                  my: 6,
                  columnGap: 2,
                  width: '100%',
                  display: 'flex',
                  flexDirection: isLarge ? 'row' : 'column',
                  rowGap: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    columnGap: 4,
                    flexDirection: 'column',
                    rowGap: 2,
                    width: isLarge ? '60%' : '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: isLarge ? 'row' : 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderRadius: 4,
                      p: 3,
                      backgroundColor: '#FFF',
                      width: '100%',
                      boxShadow: '0px 2px 5px lightGray',
                    }}
                  >
                    <div className="mb-2 text-base font-semibold text-adx-alternateBlack lg:mb-0 lg:text-xl">
                      My progress
                    </div>
                    <Link to={`/bootcamp/my-progress/${param.id}`}>
                      <Button variant="contained" sx={mui.viewSubmitBtn}>
                        View
                      </Button>
                    </Link>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: isLarge ? 'row' : 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderRadius: 4,
                      p: 3,
                      width: '100%',
                      backgroundColor: '#FFF',
                      boxShadow: '0px 2px 5px lightGray',
                    }}
                  >
                    <div className="mb-2 text-base font-semibold text-adx-alternateBlack lg:mb-0 lg:text-xl">
                      Assessment
                    </div>
                    <Button
                      variant="contained"
                      sx={mui.viewSubmitBtn}
                      href={assignmentURL}
                      target="_blank"
                    >
                      Submit
                    </Button>
                  </Box>


                </Box>
                <Box sx={{ width: isLarge ? '40%' : '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      width: '100%',
                      borderRadius: 4,
                      p: 3,
                      alignItems: isLarge ? 'flex-start' : 'center',
                      justifyContent: 'center',
                      backgroundImage: `url(${Background})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      boxShadow: '0px 2px 5px lightGray',
                    }}
                  >
                    <div className="mb-2 text-base font-semibold text-adx-white lg:mb-0 lg:text-xl">
                      Leave your feedback
                    </div>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: isLarge ? 'row' : 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <div className="mb-2 text-sm font-normal text-center text-white whitespace-pre-wrap lg:mb-0 lg:text-left">
                        How are you finding {courseHeader.title + '\n'}Leave your feedback here!
                      </div>
                      <Button
                        variant="contained"
                        sx={mui.viewSubmitBtn}
                        href={feedbackURL}
                        target="_blank"
                        rel="noreferrer"
                        disabled={!feedbackURL}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* Animation to space the column to the bottom */}
              <Box sx={{ borderRadius: 10 }} className="my-14">
                {!loading ? (
                  <>
                    {courseSession.map((mapProps, index) => {
                      return (
                        <Accordion
                          key={index}
                          sx={{
                            ...mui.accordionBox,
                            borderTopRightRadius: index === 0 ? 20 : null,
                            borderTopLeftRadius: index === 0 ? 20 : null,
                            borderBottomRightRadius: index === courseSession.length - 1 ? 20 : null,
                            borderBottomLeftRadius: index === courseSession.length - 1 ? 20 : null,

                            ...mui.disableAccordion,
                          }}
                          expanded={expandedPanel === index}
                          onChange={handleAccordionChange(index)}
                        >
                          <AccordionSummary
                            sx={{
                              py: 2,
                              borderTopRightRadius: index === 0 ? 20 : null,
                              borderTopLeftRadius: index === 0 ? 20 : null,
                              borderBottomRightRadius:
                                index === courseSession.length - 1 ? 20 : null,
                              borderBottomLeftRadius:
                                index === courseSession.length - 1 ? 20 : null,

                              ...mui.disableAccordionSummary,
                            }}
                            expandIcon={
                              <ExpandMoreIcon sx={index === expandedPanel && mui.boldedExpand} />
                            }
                          >
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: isLarge ? 'row' : 'column',
                                alignItems: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',

                                  width: isLarge ? '50%' : '100%',
                                  alignItems: 'center',
                                  columnGap: 2,
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color: '#27AE60',
                                    opacity:
                                      !mapProps.wasCompleted ||
                                        mapProps.course_sessions.find(
                                          (object) => object.wasCompleted === false
                                        )
                                        ? 0
                                        : 1,
                                  }}
                                />
                                <Box
                                  className={`text-sm font-medium ${index === expandedPanel
                                    ? 'text-adx-darkGreen'
                                    : 'text-adx-darkText'
                                    }`}
                                >
                                  Week {mapProps.week_num} : {mapProps.topic_title}
                                </Box>
                                <div
                                  className={`absolute left-0 top-0 h-full w-1.5 ${index === expandedPanel && 'bg-adx-darkGreen'
                                    }`}
                                />
                              </Box>
                              <Box
                                sx={{
                                  width: '50%',
                                  display: isLarge ? 'block' : 'none',
                                }}
                                className={`${index === expandedPanel
                                  ? 'text-adx-darkGreen'
                                  : 'text-adx-textGray'
                                  } text-sm font-normal`}
                              >
                                {mapProps.start_date && mapProps.end_date
                                  ? moment(mapProps.start_date).format('D MMM yyyy') +
                                  ' - ' +
                                  moment(mapProps.end_date).format('D MMM yyyy')
                                  : 'TBC'}
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails sx={{ backgroundColor: '#F1F8F7' }}>
                            <Box
                              sx={{
                                width: '100%',
                              }}
                            >
                              {mapProps?.additional_materials?.length > 0 && (
                                <Box>
                                  <Box
                                    sx={{
                                      backgroundColor: '#FFF',
                                      width: '100%',
                                      height: '100%',
                                      p: 2,
                                      display: 'flex',
                                      flexDirection: 'row',
                                      cursor: 'pointer',
                                      borderRadius: 10,
                                      marginTop: 2,
                                      marginBottom: 2,
                                    }}
                                    onClick={() => setExpandedReadingPanel(!expandedReadingPanel)}
                                  >
                                    <Box
                                      sx={{ width: '100%' }}
                                      className={`${expandedReadingPanel
                                        ? 'text-adx-darkGreen'
                                        : 'text-adx-textGray'
                                        } text-center text-sm font-semibold`}
                                    >
                                      Reading Material
                                    </Box>
                                    <ExpandMoreIcon
                                      sx={{
                                        transition: '0.25s all ease-in-out',
                                        transform: expandedReadingPanel ? 'rotate(180deg)' : null,
                                        backgroundColor: expandedReadingPanel ? '#1B8379' : '#FFF',
                                        color: expandedReadingPanel ? '#FFFFFF' : '#00000',
                                        borderRadius: '12px',
                                      }}
                                    />
                                  </Box>
                                  {expandedReadingPanel && (
                                    <Box
                                      sx={{
                                        backgroundColor: '#FFF',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: 4,
                                        p: 1,
                                      }}
                                    >
                                      {mapProps?.additional_materials.map((item) => {
                                        return (
                                          <Box
                                            sx={{
                                              p: 1,
                                              width: '100%',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              flexDirection: isLarge ? 'row' : 'column',
                                              rowGap: 2,
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                width: '100%',
                                                textAlign: isLarge ? null : 'center',
                                              }}
                                              className={`text-sm font-normal text-black`}
                                            >
                                              {item?.name}
                                            </Box>
                                            <Box
                                              sx={{
                                                width: isLarge ? null : '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <Button
                                                variant={'contained'}
                                                sx={mui.attendBtn}
                                                href={item?.material_url}
                                                target="_blank"
                                              >
                                                Open
                                              </Button>
                                            </Box>
                                          </Box>
                                        )
                                      })}
                                    </Box>
                                  )}
                                </Box>
                              )}

                              {mapProps?.course_sessions.map((item) => (
                                <Box
                                  sx={{
                                    py: 2,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: isLarge ? 'row' : 'column',
                                    rowGap: 2,
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      width: isLarge ? '50%' : '100%',
                                      alignItems: 'center',
                                      columnGap: 2,
                                    }}
                                  >
                                    <CheckCircleIcon
                                      sx={{
                                        color: '#27AE60',
                                        opacity: !item.wasCompleted ? 0 : 1,
                                      }}
                                    />
                                    <Box
                                      className={`text-sm font-normal text-adx-darkText`}
                                    >
                                      {item.session_title}
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: isLarge ? 'calc(50% + 24px)' : '100%',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Box
                                      className={`whitespace-pre-line text-sm font-normal text-adx-textGray`}
                                    >
                                      {moment(item.start_date).format('D MMM yyyy, ') + 
                                        moment(item.start_time, 'HH:mm:ss').format('HH:mm') +
                                        ' - ' +
                                        moment(item.end_time, 'HH:mm:ss').format('HH:mm') +
                                        ' GMT+08'}
                                    </Box>
                                    <Box
                                      sx={{
                                        maxHeight: '38.5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {item.isJoinable && !item.wasCompleted ? (
                                        <Button
                                          variant={'contained'}
                                          sx={mui.attendBtn}
                                          rel="noreferrer"
                                          onClick={() => handleAttendance(item)}
                                        >
                                          Attend
                                        </Button>
                                      ) : (
                                        <Button
                                          variant={
                                            item.wasCompleted && !item.isJoinable
                                              ? 'outlined'
                                              : 'contained'
                                          }
                                          sx={
                                            item.isJoinable && !item.wasCompleted
                                              ? mui.attendBtn
                                              : item.wasCompleted
                                                ? item.recording_url
                                                  ? mui.playZoomBtn
                                                  : mui.comingUpBtn
                                                : mui.comingUpBtn
                                          }
                                          onClick={() =>
                                            item.isJoinable && !item.wasCompleted
                                              ? handleAttendZoom(item)
                                              : item.wasCompleted
                                                ? item.recording_url
                                                  ? handleAttendRecordingSession(item)
                                                  : null
                                                : null
                                          }
                                          disableRipple={
                                            !item.wasCompleted && !item.isJoinable ? true : false
                                          }
                                        >
                                          {item.isJoinable && !item.wasCompleted ? (
                                            'Attend'
                                          ) : item.wasCompleted ? (
                                            item.recording_url ? (
                                              <PlayArrowIcon />
                                            ) : (
                                              'Processing'
                                            )
                                          ) : (
                                            'Coming Up'
                                          )}
                                        </Button>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}{' '}
                  </>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                    <Skeleton variant="rectangular" width={'100%'} height={100} />
                    <Skeleton variant="rectangular" width={'100%'} height={100} />
                    <Skeleton variant="rectangular" width={'100%'} height={100} />
                  </Box>
                )}
              </Box>
            </div>
          )}
        </div>

        {/* Absolute arrow button */}
        <div
          className={`absolute bottom-8 left-16 hidden h-10 w-10 items-center justify-center rounded-full bg-adx-darkGreen lg:flex ${animateIconBool ? animatedClock : animatedAntiClock
            }`}
        >
          <IconButton onClick={handlePress} size="large">
            <ArrowForwardIosIcon sx={mui.iconStyle} />
          </IconButton>
        </div>
      </div>
      {/* FOOTER */}
      <Footer />
    </div>
  )
}

export default EDUClassesDetails
