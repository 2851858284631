import JobCard from './JobCard'

const MobileView = ({ jobList }) => {
  return (
    <div className="customJobScroll flex space-x-4 overflow-auto p-2 pl-5 lg:hidden lg:space-x-6">
      {jobList.map((job) => (
        <JobCard job={job} key={job.id} />
      ))}

      <div className="select-none text-adx-gray opacity-0">/</div>
    </div>
  )
}

export default MobileView
