import React from 'react'

const AssignmentChip = ({id}) => {

    let textStatus
    let color
    let backgroundColor

    switch(id){
        case "Coding" :
            textStatus = "Coding"
            color = "#B11AE6"
            backgroundColor = "#F7E7FD"
            break
        case "Individual" :
            textStatus = "Individual"
            color = "#F2994A"
            backgroundColor = "#FEF5ED"
            break
        default : 
            textStatus = "No Status"
            color = "gray"
            backgroundColor = "darkgray"
    }
    return(
        <div className ="rounded-md text-sm py-1 px-2" style={{color, backgroundColor}}>
            {textStatus}
        </div>
    )
}

export default AssignmentChip
