import { Box, InputBase, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'

const TextField = ({ label, isErrorShown, required = false, errorMessage, charLimit, inputProps, sx, ...rest }) => {
  const { disabled, value } = rest

  const { typography, palette } = useTheme()
  const [isFocus, setIsFocus] = useState(false)

  const defaultStyle = () => {
    return {
      fontSize: typography.body1,
      minHeight: 45,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      paddingX: 1.75,
      outline: 1,
      outlineStyle: 'solid',
      borderRadius: 1,
      outlineColor: !isErrorShown ? palette["light-gray-text"].main : palette["error"].main,
      "&.Mui-focused": {
        outline: 1,
        outlineStyle: 'solid',
        outlineColor: palette.primary.main
      },
      "&.Mui-disabled": {
        backgroundColor: palette["default-background"].main,
        outline: 1,
        color: palette['dark-text'].main,
        outlineStyle: 'solid',
        outlineColor: palette["gray-line"].main,
      },
      ...sx
    }
  }
  return (
    <Box>
      <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='flex-start'>
        {
          label && (
            <Typography sx={{ color: disabled ? 'light-gray-text.main' : isFocus ? 'primary.main' : isErrorShown ? 'error.main' : 'dark-text.main' }}>{label}{required && <span className='text-adx-error'>*</span>}</Typography>
          )
        }
        <InputBase
          fullWidth
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          inputProps={{ maxLength: charLimit, ...inputProps }}
          sx={{ marginTop: '6px', marginBottom: '6px', ...defaultStyle() }}
          {...rest}
        />
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          {
            isErrorShown ? (
              <Typography variant='body2' sx={{ color: 'error.main' }}>{errorMessage}</Typography>
            ) : null
          }
          {
            charLimit && !isErrorShown && (
              <Box display='flex' flexDirection='row' justifyContent='flex-end' alignItems='flex-end' sx={{ width: '100%' }} >
                <Typography variant='body' sx={{ color: disabled && 'light-gray-text.main' }}>{`${value?.length || 0}/${charLimit}`}</Typography>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  )
}

export default TextField