import SocialMediaButton from 'components/smart/SocialMediaButton'
import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import Button from '@mui/material/Button'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { login } from 'services/api/authentication'
import Loader from 'react-loader-spinner'

const LogIn = () => {
    const navigate = useNavigate()
    
    const [authError, setAuthError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
  
    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState)
    }
    
    const apiLogin = async (values, actions) => {
        setLoading(true)
        setAuthError(false)
        try {
            const res = await login(values)
            if (res.success) {
                return (window.location.href = '/personality-test/result')
            }
        } catch (e) {
            if (e.status === 401) {
                if (
                    e.data.message ===
                    'This email was used to create account with banned previously. Please proceed to sign in with banned'
                ) {
                    setErrorMessage('Your account has been blocked.')
                } else {
                    setErrorMessage(e.data.message)
                }
                setAuthError(true)
            } else if (e.status === 422) {
                const errors = e.data.errors
                Object.keys(errors).forEach(function (key) {
                    actions.setFieldError(key, errors[key][0])
                })
            } else {
                setErrorMessage(
                    'An unexpected error occurred. Please retry or contact us at support@adnexio.jobs for support.'
                )
                setAuthError(true)
            }
            setLoading(false)
        }
    }
      
    const initialValues = {
        verified: '',
        email: '',
        password: '',
    }
    
      const validationSchema = Yup.object({
        email: Yup.string().email('Email must be a valid email').required('Please fill out this field'),
        password: Yup.string().required('Please fill out this field'),
    })
    
    return (
        <div id='login' className='absolute bottom-0 w-full'>
            <div className='w-full h-[100px] bg-gradient-to-t from-adx-defaultBackground' />
            <div className='flex justify-center bg-adx-defaultBackground pt-[100px]'>
                <div className='px-6 pt-8 pb-6 bg-white rounded-[6.5px] max-w-[500px] w-full flex flex-col'>
                    <p className='text-xl font-medium text-center'>Unlock the extended results for FREE. <br /> Log in Now!</p>
                    <div className="flex justify-center gap-6 my-8">
                        {['facebook', 'google', 'linkedin', 'apple'].map((item) => (
                            <SocialMediaButton variant='round' key={item} type="signin" socmed={item} />
                        ))}
                    </div>
                    <div className='flex items-center gap-4 mb-6'>
                        <hr className='w-full' />
                        <p className='text-sm text-center whitespace-nowrap text-adx-darkerGray'>or login manually</p>
                        <hr className='w-full' />
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => apiLogin(values)}
                    >
                        {() => (
                            <Form autoComplete="off">
                                <Field
                                    type="text"
                                    name="email"
                                    placeholder="Email Address"
                                    className="w-full px-2 py-3 my-1 text-sm rounded bg-adx-darkGray"
                                />
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="mt-1 mb-4 text-sm text-left text-red-400"
                                />

                                <div className="relative">
                                    <Field name="password">
                                        {({ field }) => (
                                            <>
                                                {showPassword ? (
                                                    <VisibilityOffIcon
                                                        className="absolute top-3.5 right-3 h-full w-6 cursor-pointer text-gray-400"
                                                        onClick={togglePasswordVisibility}
                                                    />
                                                ) : (
                                                    <VisibilityIcon
                                                        className="absolute top-3.5 right-3 h-full w-6 cursor-pointer text-gray-400"
                                                        onClick={togglePasswordVisibility}
                                                    />
                                                )}
                                                <input
                                                    {...field}
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Password"
                                                    className="w-full px-2 py-3 my-1 text-sm rounded bg-adx-darkGray"
                                                />
                                            </>
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="mt-1 mb-4 text-sm text-left text-red-400"
                                    />
                                </div>

                                {authError && (
                                    <p className="mt-1 text-sm text-left text-red-400">{errorMessage}</p>
                                )}

                                <div className="flex justify-end my-2 text-sm text-adx-darkGreen">
                                    <Link className="" to="/forgot-password">
                                        Forgot password?
                                    </Link>
                                </div>

                                <Button
                                    fullWidth
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    size='large'
                                    sx={{
                                        backgroundColor: '#1B8379',
                                        textTransform: 'capitalize',
                                        padding: '6px 20px',
                                        boxShadow: 'none',
                                        fontWeight: 400,
                                        marginTop: '20px'
                                    }}
                                >
                                    {loading ? <Loader type="Oval" color="white" width={24} height={24} /> : 'Log In'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <p className='py-3 mt-2 text-sm text-center text-adx-darkerGray'>
                        Not a member yet?
                        {" "}
                        <span onClick={() => navigate("/signup")} className='font-semibold underline cursor-pointer text-adx-darkGreen'>
                            Sign up
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LogIn