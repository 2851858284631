import React from 'react'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

const factors = {
  0: { dominant: 'Freedom', recessive: 'Structured' },
  1: { dominant: 'Adventurous', recessive: 'Laidback' },
  2: { dominant: 'Teamwork', recessive: 'Independent' },
  3: { dominant: 'Systematic', recessive: 'Agile' },
  4: { dominant: 'Ambitious', recessive: 'Fulfilled' },
}

const PersonalityFactorCard = ({ index, data }) => {
  // percentage refers to recessive value
  const traitPercentage = data.score
  const traitName = data.factor.name
  const traitImage = data.factor.image
  const traitDescription = data.factor.long_description

  const personalityFactor = factors[index]
  const personalityType =
    traitPercentage === 50 ? 'balanced' : traitPercentage > 50 ? 'recessive' : 'dominant'

  // percentage refers to recessive value
  const recessiveValue = traitPercentage
  const dominantValue = 100 - traitPercentage

  return (
    <div className="w-full rounded-[4.5px] border-[1px] border-adx-addBackground bg-white">
      <div className="flex flex-col gap-8 p-6 sm:flex-row">
        <img src={traitImage} alt={traitName} className="h-[200px] rounded-[4.5px] object-cover" />
        <div className="flex flex-col gap-4">
          <p className="font-medium text-adx-darkText">{traitName}</p>
          <p className="text-justify text-sm text-adx-darkerGray">{traitDescription}</p>
        </div>
      </div>
      <div className="flex flex-col border-t-[1px] border-adx-addBackground p-6">
        <div className="relative mb-[23.5px] flex justify-between text-sm">
          <p
            className={
              personalityType === 'dominant'
                ? 'text-adx-darkerGray'
                : 'font-medium text-adx-darkGreen'
            }
          >
            {recessiveValue}% {personalityFactor.recessive}
          </p>
          <p
            className={
              personalityType === 'recessive'
                ? 'text-adx-darkerGray'
                : 'font-medium text-adx-darkGreen'
            }
          >
            {dominantValue}% {personalityFactor.dominant}
          </p>
          <p className="absolute top-0 w-full text-center font-medium">vs</p>
        </div>
        <div
          className={`
                        relative flex items-center
                        ${
                          personalityType === 'dominant'
                            ? 'justify-end'
                            : personalityType === 'balanced'
                            ? 'justify-center'
                            : ''
                        }
                    `}
        >
          <div
            className={`
                        flex h-[10px] w-full items-center rounded-[5px] transition-all duration-500
                        ${
                          personalityType === 'dominant'
                            ? 'bg-adx-darkGreen'
                            : personalityType === 'recessive'
                            ? 'bg-[#E2F9F8]'
                            : 'bg-adx-darkGreen'
                        }
                    `}
          >
            <div
              style={{ width: `${recessiveValue}%` }}
              className={`h-full rounded-l-[5px] transition-all duration-500 ${
                personalityType === 'dominant'
                  ? 'bg-[#E2F9F8]'
                  : personalityType === 'recessive'
                  ? 'bg-adx-darkGreen'
                  : 'bg-adx-darkGreen'
              }`}
            />
            <div
              className={`-ml-3 flex h-6 w-6 items-center justify-center rounded-full bg-adx-darkGreen text-white`}
            >
              <CheckRoundedIcon fontSize="small" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalityFactorCard
