import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import PrivateRoute from 'components/smart/PrivateRoute'

// Contexts
import AuthProvider from 'contexts/AuthContext'
import EmployerCardContextProvider from 'contexts/EmployerCardContext'
import RecentJobContextProvider from 'contexts/RecentJobContext'
import IpContextProvider from 'contexts/IpContext'
import ProfileContextProvider from 'contexts/ProfileContext'
import NotificationContextProvider from 'contexts/NotificationContext'
import CourseCardContextProvider from 'contexts/CourseCardContext'

//Components
import CustomHelmet from 'components/smart/CustomHelmet'

import LandingPage from 'pages/landing_page/view/LandingPage'
import NotFound from 'pages/not_found/view/NotFound'
import JobNotFound from 'pages/not_found/view/JobNotFound'
import CourseNotFound from 'pages/not_found/view/CourseNotFound'
import Profile from 'pages/profile/view/Profile'
import Track from 'pages/track/view/Track'
import VideoInterview from 'pages/video_interview/view/VideoInterview'

import PrivacyPolicy from 'pages/setting/view/PrivacyPolicy'
import TermsConditions from 'pages/setting/view/TermsConditions'
import ContactUs from 'pages/setting/view/ContactUs'
import Faq from 'pages/setting/view/Faq'
import Nex from 'pages/setting/view/Nex'

import JobAds from 'pages/jobads/views/JobAds'
import JobAdsDetails from 'pages/jobads_details/views/JobAdsDetails'
import JobApplicationDetails from './pages/job_application_details/views/JobApplicationDetails'
import JobResultDetails from './pages/job_result_details/views/JobResultDetails'
import JobInterviewDetails from './pages/job_interview_details/views/JobInterviewDetails'

import CourseAds from 'pages/courses_ads/views/CourseAds'
import CourseAdsDetails from 'pages/courses_ads_details/views/CourseAdsDetails'
import CourseApplicationDetails from 'pages/courses_application_details/views/CourseApplicationDetails'

import ExternalJobAdsDetails from 'pages/external_jobads_details/views/ExternalJobAdsDetails'

import SignIn from 'pages/sign_in/views/SignIn'
import SignUp from 'pages/registration/views/SignUp'
import UploadPhoto from 'pages/registration/views/UploadPhoto'
import UploadCV from 'pages/registration/views/UploadCV'
import Complete from 'pages/registration/views/Complete'
import ForgotPassword from 'pages/forgot_password/view/ForgotPassword'
import ResetPassword from 'pages/forgot_password/view/ResetPassword'
import RedirectSocialAuth from './components/smart/RedirectSocialAuth'

import Gigs from 'pages/gig/views/Gigs'

import MyProgress from 'pages/my_progress/view/MyProgress'
import Payment from 'pages/payment/view/Payment'
import PaymentSummary from 'pages/payment_summary/view/PaymentSummary'
import PaymentStatus from 'pages/payment_status/view/PaymentStatus'
import ZoomPlayback from 'pages/zoom_playback/view/ZoomPlayback'

// Set MUI global theme
import { ThemeProvider } from '@mui/material/styles'
import { muiTheme } from 'muiTheme'

// EDU Import
import EDULandingPage from 'modules/edu/pages/upskilling/view/EDULandingPage'
import EDUSignIn from 'modules/edu/pages/sign_in/views/EDUSignIn'
import EDUSignUp from 'modules/edu/pages/registration/views/EDUSignUp'
import EDUProfile from 'modules/edu/pages/profile/view/EDUProfile'
import EduRedirectSocialAuth from 'components/smart/EduRedirectSocialAuth'
import EDUMyClasses from 'modules/edu/pages/my_classes/view/EDUMyClasses'
import EDUClassesDetails from 'modules/edu/pages/classes_details/view/EDUClassesDetails'
import EDUContactUs from 'modules/edu/pages/setting/view/EDUContactUs'
import HRDIntro from 'pages/hrd/view/HRDIntro'
import HRDFormPage from 'pages/hrd/view/HRDFormPage'
import HRDStatus from 'pages/hrd/view/HRDStatus'
import PaymentOption from 'pages/hrd/view/PaymentOption'
import HRDPaymentSummary from 'pages/hrd/view/HRDPaymentSummary'
import VideoInterviewCreate from 'pages/video_interview/view/VideoInterviewCreate'
import VideoInterviewPractice from 'pages/video_interview/view/VideoInterviewPractice'
import PersonalityTest from 'pages/personality_test/view/PersonalityTest'
import TestQuestions from 'pages/personality_test/view/TestQuestions'
import TestResult from 'pages/personality_test/view/TestResult'

function App() {
  return (
    <>
      <CustomHelmet />
      <div className="font-poppins">
        <ThemeProvider theme={muiTheme}>
          <AuthProvider>
            <CourseCardContextProvider>
              <EmployerCardContextProvider>
                <RecentJobContextProvider>
                  <ProfileContextProvider>
                    <IpContextProvider>
                      <NotificationContextProvider>
                        <BrowserRouter>
                          <Routes>
                            {/*DEFAULT ROUTES */}
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="/signin" element={<SignIn />} />

                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/reset_password/:token" element={<ResetPassword />} />

                            {/* REDIRECT FAIL */}
                            <Route path="/redirect/:status/:value" element={<RedirectSocialAuth />} />
                            {/* REDIRECT SUCCESS */}
                            <Route
                              path="/redirect/:status/:value/:expired_at"
                              element={<RedirectSocialAuth />}
                            />

                            <Route path="/gigs" element={<Gigs />} />

                            <Route path="/jobs" element={<JobAds />} />
                            <Route path="/jobs/:id" element={<JobAdsDetails />} />
                            <Route path="/jobs/ext/:id" element={<ExternalJobAdsDetails />} />
                            <Route path="/job-application/:id" element={<JobApplicationDetails />} />
                            <Route path="/job-interview/:id" element={<JobInterviewDetails />} />
                            <Route path="/job-result/:id" element={<JobResultDetails />} />

                            <Route path="/courses" element={<CourseAds />} />
                            <Route path="/courses/:id" element={<CourseAdsDetails />} />

                            <Route path="/preview/:id" element={<JobAdsDetails />} />

                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/terms-conditions" element={<TermsConditions />} />
                            <Route path="/contact-us" element={<ContactUs />} />
                            <Route path="/faq" element={<Faq />} />
                            <Route path="/nex" element={<Nex />} />

                            <Route path="/job-not-found" element={<JobNotFound />} />
                            <Route path="/course-not-found" element={<CourseNotFound />} />

                            {/* Personality Test */}
                            <Route path="/personality-test" element={<PersonalityTest />} />
                            <Route path="/personality-test/start" element={<TestQuestions/>} />
                            <Route path="/personality-test/result" element={<TestResult/>} />

                            {/* PRIVATE */}
                            <Route element={<PrivateRoute redirectTo="/signin" />}>
                              <Route path="/photo" element={<UploadPhoto />} />
                              <Route path="/cv" element={<UploadCV />} />
                              <Route path="/complete" element={<Complete />} />
                              <Route path="/profile" element={<Profile />} />
                              <Route
                                path="/course-application/:id"
                                element={<CourseApplicationDetails />}
                              />
                              <Route path="/hrd/introduction" element={<HRDIntro />} />
                              <Route path="/hrd/form" element={<HRDFormPage />} />
                              <Route path="/hrd/status" element={<HRDStatus />} />
                              <Route
                                path="/hrd/payment-summary/:id"
                                element={<HRDPaymentSummary />}
                              />
                              <Route path="/payment-options/:id" element={<PaymentOption />} />{' '}
                              {/* TO CONFIRM BACK */}
                              <Route path="/upskill-payment" element={<Payment />} />
                              <Route path="/payment-summary/:id" element={<PaymentSummary />} />
                              <Route
                                path="/payment-status/:status/:orderId"
                                element={<PaymentStatus />}
                              />
                              <Route path="/playback/:id/:playbackID" element={<ZoomPlayback />} />
                              <Route path="/video-interview" element={<VideoInterview />} />
                              <Route
                                path="/video-interview/create"
                                element={<VideoInterviewCreate />}
                              />
                              <Route
                                path="/video-interview/practice"
                                element={<VideoInterviewPractice />}
                              />
                              <Route path="/track" element={<Track />} />
                            </Route>
                            {/* PRIVATE */}

                            {/*EDU */}
                            <Route path="bootcamp" element={<Outlet />}>
                              <Route index element={<EDULandingPage />} />
                              <Route path="signin" element={<EDUSignIn />} />
                              <Route path="signup" element={<EDUSignUp />} />
                              <Route path="contact-us" element={<EDUContactUs />} />

                              {/* REDIRECT FAIL */}
                              <Route
                                path="redirect/:status/:value"
                                element={<EduRedirectSocialAuth />}
                              />
                              {/* REDIRECT SUCCESS */}
                              <Route
                                path="redirect/:status/:value/:expired_at"
                                element={<EduRedirectSocialAuth />}
                              />

                              <Route element={<PrivateRoute redirectTo="signin" />}>
                                <Route path="profile" element={<EDUProfile />} />
                                <Route path="my-classes" element={<EDUMyClasses />} />
                                <Route path="my-classes/:id" element={<EDUClassesDetails />} />
                                <Route path="my-progress/:id" element={<MyProgress />} />
                              </Route>
                            </Route>
                            {/*EDU */}

                            <Route path="*" element={<NotFound />} />
                          </Routes>
                        </BrowserRouter>
                      </NotificationContextProvider>
                    </IpContextProvider>
                  </ProfileContextProvider>
                </RecentJobContextProvider>
              </EmployerCardContextProvider>
            </CourseCardContextProvider>
          </AuthProvider>
        </ThemeProvider>
      </div>
    </>
  )
}

export default App
