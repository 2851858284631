import axios, { getData } from "../../../lib/axios"

export const jobApplications = async () => {
    return axios.get("/job_seekers/job_applications").then(getData)
}

export const jobApplicationsById = async (id) => {
    return axios.get(`/job_seekers/job_applications/${id}`).then(getData)
}

export const jobApplicationStatus = async (status) => {
    return axios.get(`/job_seekers/job_applications/status/${status}`).then(getData)
}

export const selfApplied = async (data) => {
    return axios.post("/job_seekers/job_applications/self_applied", data).then(getData)
}

export const matchApplied = async (data) => {
    return axios.post("/job_seekers/job_applications/matched_applied", data).then(getData)
}

export const withDrawApplication = async (id, data) => {
    return axios.get(`/job_applications/withdraw_application/${id}`, data).then(getData)
}

export const responseToInterview = async (id, status) => {
    return axios.get(`job_seekers/interview_schedule/confirmation/${id}/status/${status}`).then(getData)
}

export const jobinterview = async (page) => {
    return axios.get(`job_seekers/interview_schedule?page=${page}`).then(getData);
}
export const jobinterviewById = async (id) => {
    return axios.get(`job_seekers/interview_schedule/${id}`).then(getData);
}

export const resheduleinterview = async (data) => {
    return axios.post(`job_seekers/interview_schedule/reschedule/`, data).then(getData);
}

export const exterlnalApplied = async (data) => {
    return axios.post("job_seekers/external_job_applications", data).then(getData)
}