/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import RecordRTC from 'recordrtc'
import { useBeforeUnload } from 'react-router-dom'

/**
 * VideoRecording component for recording video and audio, with countdown and pause/resume support.
 *
 * @param {Object} props - React props object
 * @param {function} props.setParentState - function to update state in parent component
 * @param {Object} props.parentState - state object in parent component
 * @param {number} props.parentCountdown - countdown timer in parent component
 * @param {function} props.parentSetCountdown - function to update countdown timer in parent component
 * @return {JSX.Element} React component
 */
const VideoRecording = ({ setParentState, parentState, parentCountdown, parentSetCountdown }) => {
  const videoRef = useRef(null)
  const recorderRef = useRef(null)

  const [state, setState] = useState({
    completeRecording: false,
    recordedVideo: null,
    pauseVideo: false,
    videoStart: true,
  })
  const [countdown, setCountdown] = useState(5)

  /**
   * Asks for permission to access audio and video, sets up references and starts recording.
   *
   * @return {Promise<void>} Returns a Promise that resolves with undefined.
   */
  const getMediaPermission = async () => {
    setState({ ...state, recordedVideo: null })
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      })
      videoRef.current.srcObject = stream
      recorderRef.current = new RecordRTC(stream, {
        type: 'video',
        mimeType: 'video/webm;codecs=vp9',
      })
      if (videoRef?.current?.srcObject) {
        setParentState({ ...parentState, videoStart: true })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    async function startVideo() {
      await getMediaPermission()
    }
    startVideo()
  }, [])

  useEffect(() => {
    let interval = null
    if (parentState.startInterview && countdown !== null)
      interval = setInterval(() => {
        setCountdown((secondsTemp) => secondsTemp - 1)
      }, 1000)

    if (countdown === 0) {
      clearInterval(interval)
      if (state.videoStart && countdown !== null) startRecording()
    }

    return () => {
      clearInterval(interval)
    }
  }, [parentState.startInterview, countdown])

  useEffect(() => {
    if (
      parentCountdown === 0 &&
      parentState.questionNumber <= parentState.interviewQuestions.length - 1
    ) {
      pauseRecording()
      setCountdown(5)
      parentSetCountdown(65)
    }

    if (
      parentCountdown < 1 &&
      parentState.questionNumber <= parentState.interviewQuestions.length - 1
    ) {
      setParentState((parentState) => ({
        ...parentState,
        questionNumber: parentState.questionNumber + 1,
      }))
    }

    if (
      parentCountdown === 60 &&
      parentState.questionNumber <= parentState.interviewQuestions.length - 1
    ) {
      resumeRecording()
    }

    if (
      (parentCountdown === null || parentCountdown === 0) &&
      parentState.questionNumber === parentState.interviewQuestions.length - 1
    ) {
      setCountdown(null)
      parentSetCountdown(null)
      stopRecording()
    }
  }, [parentCountdown])

  useEffect(() => {
    if (recorderRef?.current) {
      pauseRecording()
      setCountdown(5)
      parentSetCountdown(65)
    }
  }, [parentState.nextQuestion])

  /**
   * This function starts recording the media and sets the parent state to close the layer.
   * It first calls the getMediaPermission function to ensure that the media permission has been granted.
   *
   * @return {Promise<void>} - returns nothing
   */
  const startRecording = async () => {
    await getMediaPermission()
    setParentState({ ...parentState, closeLayer: true })
    recorderRef.current.startRecording()
  }

  /**
   * Pauses recording and updates the state.
   *
   * @return {Promise<void>} Resolves when recording is paused.
   */
  const pauseRecording = async () => {
    setState({ ...state, pauseVideo: true, videoStart: false })
    setParentState({
      ...parentState,
      closeLayer: false,
    })

    recorderRef.current.pauseRecording()
  }

  /**
   * Resumes recording and updates the state accordingly.
   *
   * @return {Promise<void>} Promise that resolves when recording is resumed.
   */
  const resumeRecording = async () => {
    setState({ ...state, pauseVideo: false })
    setParentState({ ...parentState, closeLayer: true })
    recorderRef.current.resumeRecording()
  }

  /**
   * Stops recording and sets the recorded video blob and complete recording state.
   * Also sets the parent state to close the layer, show the final layer, add the blob and increment the retake count.
   *
   * @return {Promise<void>} - Returns a promise that resolves after the recording is stopped.
   */
  const stopRecording = async () => {
    recorderRef.current.stopRecording(() => {
      const blob = recorderRef.current.getBlob()
      setState({ ...state, recordedVideo: blob, completeRecording: true })
      setParentState({
        ...parentState,
        closeLayer: true,
        finalLayer: true,
        blob: blob,
        retake: parentState.retake + 1,
      })
      videoRef.current.srcObject.getTracks().forEach((track) => {
        track.stop()
      })
      videoRef.current.srcObject = null
    })
  }

  /**
   * Prevents the default behavior of a context menu event.
   *
   * @param {Event} event - The context menu event.
   */
  function handleContextMenu(event) {
    event.preventDefault()
  }

  const handleBeforeUnload = (event) => {
    // window.onhashchange = function (e) {
    videoRef.current.srcObject.getTracks().forEach((track) => {
      track.stop()
    })
    videoRef.current.srcObject = null
    // }
    console.log(event)
    event.preventDefault()
  }

  useEffect(() => {
    let handler = null
    if (parentState.continueState) {
      handler = (event) => handleBeforeUnload(event)
    }
    if (handler) {
      window.addEventListener('beforeunload', handler)
    }

    return () => {
      if (handler) {
        window.removeEventListener('beforeunload', handler)
      }
    }
  }, [parentState.continueState])

  return (
    <>
      {/* Video */}
      <Box className="relative sm:flex sm:items-center sm:justify-center lg:col-span-3">
        {!parentState.videoStart && (
          <Box className="absolute z-10 h-full w-full bg-adx-darkText/[.7] lg:h-[80vh]">
            <div className="absolute top-1/2 left-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-5">
              <CircularProgress style={{ color: '#FFFFFF' }} />
              <p className="text-center text-white">Loading your camera</p>
            </div>
          </Box>
        )}

        {countdown > 0 && parentState.startInterview && (
          <Box className="absolute z-10  h-full w-full bg-adx-darkText/[.7] lg:h-[80vh]">
            <div className="absolute top-1/2 left-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-3">
              <p className="text-center text-xl font-medium text-white">Video starting in..</p>
              <p className="text-9xl font-medium text-white">{countdown}</p>
            </div>
          </Box>
        )}

        {/* {!parentState.videoStart && <video></video>} */}

        {!state.recordedVideo && (
          <div>
            <video
              ref={videoRef}
              id="videoRecord"
              className="z-0 -scale-x-100 object-cover lg:aspect-video lg:h-[80vh]"
              autoPlay
              onContextMenu={handleContextMenu}
              muted={true}
            />
          </div>
        )}

        {state.completeRecording && state.recordedVideo && (
          <video
            src={URL.createObjectURL(state.recordedVideo)}
            id="videoFinal"
            className="z-0 object-cover lg:aspect-video lg:h-[80vh]"
            controls
            autoPlay
          />
        )}
      </Box>
    </>
  )
}

export default VideoRecording
