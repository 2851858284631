import {
  Button,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  linearProgressClasses,
  styled,
} from '@mui/material'
import React, { useContext } from 'react'
import { useState } from 'react'
import { test_questions } from './test_questions'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import LoadingResultModal from '../LoadingResultModal'
import { useNavigate } from 'react-router-dom'
import { traits_avatar } from './traits_avatar'
import { submitTest } from 'services/api/personality_test'
import { authContext } from 'contexts/AuthContext'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 2.5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette['gray-background'].main,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2.5,
    backgroundColor: theme.palette.primary.main,
  },
}))

const QuestionsPage = () => {
  const navigate = useNavigate()
  const {
    auth: { isAuth },
  } = useContext(authContext)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState({ factor: 0, number: 0 })
  const [answers, setAnswers] = useState(
    test_questions.map((item, index) => ({
      factor: index + 1,
      scores: item.questions.map(() => null),
    }))
  )

  const currentPage = test_questions[page.factor].questions[page.number]
  const currentAnswer = answers[page.factor].scores[page.number]
  const lastPage = {
    factor: test_questions.length - 1,
    number: test_questions[test_questions.length - 1].questions.length - 1,
  }

  const prevPage = () => {
    setPage((prevState) => {
      if (prevState.number === 0) {
        if (prevState.factor === 0) {
          return prevState
        } else {
          const prevFactor = prevState.factor - 1
          const lastQuestion = test_questions[prevFactor].questions.length - 1
          return { factor: prevFactor, number: lastQuestion }
        }
      } else {
        return { ...prevState, number: prevState.number - 1 }
      }
    })
  }

  const nextPage = () => {
    setPage((prevState) => {
      const nextPage = prevState.number + 1
      if (nextPage === test_questions[prevState.factor].questions.length) {
        return { factor: prevState.factor + 1, number: 0 }
      } else {
        return { ...prevState, number: nextPage }
      }
    })
  }

  const progress = () => {
    const totalLength = answers.reduce((total, item) => total + item.scores.length, 0)
    const totalNullCount = answers
      .flatMap((item) => item.scores)
      .filter((value) => value === null).length
    const percentageDone = ((totalLength - totalNullCount) / totalLength) * 100

    return percentageDone
  }

  const handleAnswer = (e) => {
    const updatedAnswers = [...answers]
    updatedAnswers[page.factor].scores[page.number] = JSON.parse(e.target.value)
    setAnswers(updatedAnswers)

    if (!(page.factor === lastPage.factor && page.number === lastPage.number)) {
      nextPage()
    }
  }

  const checkTraits = (score) => {
    if (score > 50) {
      return 0 // recessive
    } else if (score === 50) {
      return 1 // balanced
    } else {
      return 2 // dominant
    }
  }

  const calculateResult = (scores, index) => {
    const maxScore = test_questions[index].max
    const totalScore = scores.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const finalScore = Math.round((totalScore / maxScore) * 50 + 50)
    const resultTraits = traits_avatar[index].traits[checkTraits(finalScore)]

    return { factor: resultTraits, score: finalScore }
  }

  const handleSubmit = async () => {
    const calculatedResult = answers.map((answer, index) => calculateResult(answer.scores, index))

    const body = {
      freedom_score: (calculatedResult[0].score / 100).toFixed(2),
      adventurous_score: (calculatedResult[1].score / 100).toFixed(2),
      teamwork_score: (calculatedResult[2].score / 100).toFixed(2),
      systematic_score: (calculatedResult[3].score / 100).toFixed(2),
      ambitious_score: (calculatedResult[4].score / 100).toFixed(2),
    }

    if (isAuth) {
      try {
        const res = await submitTest(body)
        if (res.success) {
          startTimeout(calculatedResult)
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      startTimeout(calculatedResult)
    }
  }

  // Simulate loading ===============================

  const startTimeout = (result) => {
    let timeoutId = null

    setOpen(true)

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => {
      navigate('/personality-test/result', { state: result })
    }, 2000)
  }

  // Simulate loading ===============================

  return (
    <>
      <div className="flex h-full min-h-[calc(100vh-74px)] w-full flex-col justify-between bg-adx-white lg:w-[600px]">
        <div className="px-[30px] pt-[48px] pb-[40px]">
          <p className="mb-[30px] font-medium">{currentPage.question}</p>
          <FormControl className="w-full">
            <RadioGroup value={currentAnswer} onChange={handleAnswer}>
              {currentPage.options.map((item, index) => (
                <FormControlLabel
                  key={index}
                  className="text-adx-darkerGray"
                  value={item.value}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: '#E5E5E5',
                        '&.MuiRadio-root': {
                          marginRight: '14px',
                          padding: 0,
                        },
                      }}
                    />
                  }
                  label={item.option}
                  sx={{
                    '&.MuiFormControlLabel-root': {
                      borderRadius: '4.5px',
                      backgroundColor: currentAnswer === item.value ? '#E2F9F8' : '',
                      marginBottom: '10px',
                      marginX: 0,
                      padding: '14px',
                    },
                    '.MuiFormControlLabel-label': {
                      fontSize: '14px',
                      color: currentAnswer === item.value ? '#1B8379' : '',
                    },
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div className="flex items-center justify-between gap-[30px] border-t-[1px] border-adx-addBackground px-[30px] py-[24px]">
          {/* {page !== 0 && ( */}
          <button
            onClick={prevPage}
            className="aspect-square h-[35px] rounded-[3.5px] border-[1px] border-adx-darkerGray text-adx-darkerGray disabled:border-adx-addBackground disabled:text-adx-addBackground"
          >
            <ChevronLeftRoundedIcon />
          </button>
          {/* )} */}
          <div className="w-full">
            <BorderLinearProgress variant="determinate" value={progress()} />
          </div>
          {page.factor === lastPage.factor && page.number === lastPage.number ? (
            <Button
              onClick={handleSubmit}
              disabled={currentAnswer === null}
              variant="contained"
              style={{ width: 150 }}
            >
              Submit
            </Button>
          ) : (
            <button
              disabled={currentAnswer === null}
              onClick={nextPage}
              className="aspect-square h-[35px] rounded-[3.5px] border-[1px] border-adx-darkerGray text-adx-darkerGray disabled:border-adx-addBackground disabled:text-adx-addBackground"
            >
              <ChevronRightRoundedIcon />
            </button>
          )}
        </div>
      </div>
      <LoadingResultModal open={open} setOpen={setOpen} />
    </>
  )
}

export default QuestionsPage
